import React, { Fragment, useState, useEffect,useCallback } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Dropdown,
  Accordion,
  Modal
} from "react-bootstrap";
import { useDispatch, connect } from "react-redux";
import { getProfileInfo,getuserNameSavedprofile,getOtherInfoSavedProfile,randomNo,getPasswordInfoSavedProfile, GetSelectedFileUploaded } from "../../services/profileService.js";
import { useTranslation } from "react-i18next";
import { FaInbox, FaEye, FaPlus, FaFileUpload, FaTimes } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { EncryptOrDecrypt } from "../../services/commonService";
import MaskedFormControl from 'react-bootstrap-maskedinput';

const Profile = () => {
  const dispatch = useDispatch();
  const [profileInfo, setProfileInfo] = useState({});
  const [fileslected, setFile] = React.useState([]);
  const [passwordInfo, setPasswordInfo] = useState({
      old_Password : null,
      new_Password : null,
      confirm_Password : null
  })
  const [loading, setLoading] = React.useState(false);
  const [Username, setUserName] =  React.useState('');
  const [passwordModel, setPasswordModel] = React.useState(false);
  const [generatePassinput, setGeneratePassinput] = React.useState('');
  const [toggleSwitch, setToggleSwitch] = React.useState(false);
  const [disableButton, setDisableButton] = React.useState(true);
  useEffect(() => {
    getProfileInformation();
	}, []);

 useEffect(() => {
    var EncryptUserData = '';
    const userDatas = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
    userDatas.profileImage = profileInfo.ImageURL;
    EncryptUserData = EncryptOrDecrypt(userDatas, 'E');
    localStorage.setItem('user', EncryptUserData);
    }, [profileInfo]);

  const getProfileInformation = async () => {
            setLoading(true);
        await dispatch(getProfileInfo()).then((res) => { 
            setProfileInfo(res.data.data);
            setLoading(false);
        });
  };
    
  const onDrop = useCallback(event => {
    setFile(event[0])
  }, [])
  const { acceptedFiles, isDragActive, getRootProps, getInputProps} = useDropzone({ onDrop });
  var files = acceptedFiles.map((file, i) => (
    <li key={file.path}>
     {file.name} &nbsp;&nbsp;
   </li>
  ));

  const handleInfoChange = (event) => {
    setProfileInfo({
        ...profileInfo,
        [event.target.name]: event.target.value,
      });
  };

  const submitUser = () => {
    if(Username == ""){
        toast.error('Please Enter New User Name');
    }
    else if(Username && !Username.match(/^(?=.*[a-zA-Z])([a-zA-Z0-9_]+)$/)){
        toast.error('Please Enter Valid User Name');
    }
    else {
        let param = { Username }
        getuserNameSaved(param);
    }
  }

 const getuserNameSaved = async (param) => {
    setLoading(true);
    await dispatch(getuserNameSavedprofile(param)).then((res) => { 
      if(res.data.data.Status == "N"){
        toast.error(res.data.data.MessageString);
      }
      else {
        toast.success(res.data.data.MessageString);
        getProfileInformation(); 
      }
      setLoading(false);
    });
  };

  const submitOtherInfo = () => {
    let param = profileInfo;
    let error = "N";
    let msg = "";
    if (param.Email == "") {
    error = "Y";
    msg = "Please Enter Email";
    }
    if (param.s_ScreenName == "") {
    error = "Y";
    msg = "Please Enter Screen Name";
    }
    if (param.Last_Name == "") {
    error = "Y";
    msg = "Please Enter Last Name";
    }
    if (param.s_MiddleName == "") {
    error = "Y";
    msg = "Please Enter Middle Name";
    }
    if (param.First_Name == "") {
    error = "Y";
    msg = "Please Enter First Name";
    }
    if (error == "Y") {
        toast.error(msg);
    } else {
        getOtherInfoSaved(param);
    }
  }

  const getOtherInfoSaved = async (param) => {
    setLoading(true);
    await dispatch(getOtherInfoSavedProfile(param)).then((res) => { 
      if(res.data.data.Status == "N"){
        toast.error(res.data.data.MessageString);
      }
      else {
        toast.success(res.data.data.MessageString);
        getProfileInformation(); 
      }
      setLoading(false);
    });
  };

  const newGeneratedPassword = (e) => {
    const char = randomNo(6);
    setGeneratePassinput(char);
  }
  
  const checkForUse = (e) => {
    setToggleSwitch(!toggleSwitch);
    if (toggleSwitch == true) {
       setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }
 const setPasswordInput = (e) => {
    if(toggleSwitch == true){
        setPasswordInfo({
            ...passwordInfo,
            ['new_Password']:generatePassinput,
            ['confirm_Password']:generatePassinput,
        });
      setPasswordModel(false);
    }
 }

 const handlePassInfoChange = (event) => {
    setPasswordInfo({
        ...passwordInfo,
        [event.target.name]: event.target.value,
      });
  };
  
  const submitPasswordInfo = () => {
    let param = passwordInfo;
    let error = "N";
    let msg = "";
    if (param.new_Password == null || param.new_Password == "") {
        error = "Y";
        msg = "Please Enter New Password";
      }
      if (param.old_Password == null || param.old_Password == "") {
        error = "Y";
        msg = "Please Enter Old Password";
      }
      if (param.new_Password != null || param.new_Password != "") {
        if (param.new_Password != param.confirm_Password) {
          error = "Y";
          msg = "Password and Confirm Password does not match";
        }
      }
      if (param.new_Password.length < 6) {
        error = "Y";
        msg = "The Password field must be at least 6 characters.";
      }
    if (error == "Y") {
        toast.error(msg);
    } else {
        getPasswordInfoSaved(param);
    }
  }

  const getPasswordInfoSaved = async (param) => {
    setLoading(true);
    await dispatch(getPasswordInfoSavedProfile(param)).then((res) => { 
      if(res.data.data.Status == "N"){
        toast.error(res.data.data.MessageString);
      }
      else {
        toast.success(res.data.data.MessageString);
        setPasswordInfo({
            old_Password : null,
            new_Password : null,
            confirm_Password : null
          });
        getProfileInformation(); 
      }
      setLoading(false);
    });
  };

  const uploadImage = async () => {
      if (!isEmpty(fileslected)) {
        getFileUploaded(fileslected);
      }
      else {
        toast.error("Please Select File");
      }
  }

  const getFileUploaded = async (newFile) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", newFile, newFile.name);
    await dispatch(GetSelectedFileUploaded(formData)).then((res) => { 
      if(res.data.data.status == "N"){
        toast.error(res.data.data.MessageString);
      }
      else {
        toast.success(res.data.data.message);
        setFile([]);
        getProfileInformation(); 
      }
      setLoading(false);
    });
  };

  return (
    <Fragment>
       <LoadingOverlay>
        <Loader loading={loading} />
        {!isEmpty(profileInfo) ?
        <div className="search-quote-tab-div">
            <Accordion defaultActiveKey="0" className="custom-quote-accordion">
            <Accordion.Item eventKey="0">
                <Accordion.Header className="search-quote-card-header">
                <span>User Name</span>
                </Accordion.Header>
                <Accordion.Body className="search-quote-card-body">
                    <Form autoComplete="off">
                        <Col xs="12" sm="12" md="12" lg="10" xl="10" style={{ marginTop : "10px" }}>
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="3" xl="3">
                                <Form.Group
                                    className="contact-tab-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                    Current User Name
                                    </Form.Label>
                                </Form.Group>
                                </Col>
                                <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                <Form.Group
                                    className="contact-tab-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                    { profileInfo.Username }
                                    </Form.Label>
                                </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="3" xl="3">
                                <Form.Group
                                    className="contact-tab-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                    New User Name
                                    </Form.Label>
                                </Form.Group>
                                </Col>
                                <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                <Form.Group
                                    className="contact-tab-form-group"
                                    controlId="formBasicEmail"
                                >
                                <Form.Control
                                    className="dashboard-group-input"
                                    type="text"
                                    autocomplete="new-users"
                                    onChange={(e) => setUserName(e.target.value)}
                                    />
                                </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col
                        xs="12"
                        style={{
                            display: "flex",
                            justifyContent: "start",
                            marginTop: "10px",
                        }}
                        >
                        <Button
                            id="common-btn"
                            variant="primary"
                            style={{ padding: "10px 30px 10px 30px" }}
                            onClick={() => submitUser()}
                        >
                            Change User Name
                        </Button>{" "}
                        </Col>
                    </Form>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header className="search-quote-card-header">
                <span>Change Password</span>
                </Accordion.Header>
                <Accordion.Body className="search-quote-card-body">
                <Form autoComplete="off">
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="10" xl="10" style={{ marginTop : "10px" }}>
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="3" xl="3">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                User Name
                                </Form.Label>
                            </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="4" xl="4">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                { profileInfo.Username }
                                </Form.Label>
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="3" xl="3">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                Old Password
                                </Form.Label>
                            </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="4" xl="4">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                            <Form.Control
                                className="dashboard-group-input"
                                type="text"
                                name = "old_Password"
                                onChange={(e) => handlePassInfoChange(e)}
                                value={ passwordInfo.old_Password }
                                />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="3" xl="3">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                New Password
                                </Form.Label>
                            </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="4" xl="4">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                            <Form.Control
                                className="dashboard-group-input"
                                type="password"
                                autoComplete="off"
                                name = "new_Password"
                                onChange={(e) => handlePassInfoChange(e)}
                                defaultValue={ passwordInfo.new_Password }
                                />
                            </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="4" xl="4">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                            <Button
                            id="common-btn"
                            variant="primary"
                            style={{ padding: "10px 30px 10px 30px" }}
                            onClick={(e) => setPasswordModel(e)}
                        >
                            Password Generator
                        </Button>{" "}
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="3" xl="3">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                Confirm Password
                                </Form.Label>
                            </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="4" xl="4">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                            <Form.Control
                                className="dashboard-group-input"
                                type="password"
                                name = "confirm_Password"
                                onChange={(e) => handlePassInfoChange(e)}
                                value={ passwordInfo.confirm_Password }
                                />
                            </Form.Group>
                            </Col>
                        </Row>
                        </Col>
                        <Col
                        xs="12"
                        style={{
                            display: "flex",
                            justifyContent: "start",
                            marginTop: "10px",
                        }}
                        >
                        <Button
                            id="common-btn"
                            variant="primary"
                            style={{ padding: "10px 30px 10px 30px" }}
                            onClick={() => submitPasswordInfo()}
                        >
                            Update Password
                        </Button>{" "}
                        </Col>
                    </Row>
                </Form>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header className="search-quote-card-header">
                <span>Other Info</span>
                </Accordion.Header>
                <Accordion.Body className="search-quote-card-body">
                <Row>
                    <Col xs="12" sm="12" md="12" lg="10" xl="10" style={{ marginTop : "10px" }}>
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="3" xl="3">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                First Name
                                </Form.Label>
                            </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="4" xl="4">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                            <Form.Control
                                className="dashboard-group-input"
                                type="text"
                                name = "First_Name"
                                onChange={(e) => handleInfoChange(e)}
                                value={ profileInfo.First_Name }
                                />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="3" xl="3">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                Middle Name
                                </Form.Label>
                            </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="4" xl="4">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                            <Form.Control
                                className="dashboard-group-input"
                                type="text"
                                name = "s_MiddleName"
                                onChange={(e) => handleInfoChange(e)}
                                value={ profileInfo.s_MiddleName }
                                />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="3" xl="3">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                Last Name
                                </Form.Label>
                            </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="4" xl="4">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                            <Form.Control
                                className="dashboard-group-input"
                                type="text"
                                value={ profileInfo.Last_Name }
                                onChange={(e) => handleInfoChange(e)}
                                name = "Last_Name"
                                />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="3" xl="3">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                Screen Name
                                </Form.Label>
                            </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="4" xl="4">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                            <Form.Control
                                className="dashboard-group-input"
                                type="text"
                                name = "s_ScreenName"
                                onChange={(e) => handleInfoChange(e)}
                                value={ profileInfo.s_ScreenName }
                                />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="3" xl="3">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                Email Address
                                </Form.Label>
                            </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="4" xl="4">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                            <Form.Control
                                className="dashboard-group-input"
                                type="text"
                                name = "Email"
                                onChange={(e) => handleInfoChange(e)}
                                value={ profileInfo.Email }
                                />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="3" xl="3">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                Phone
                                </Form.Label>
                            </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="4" xl="4">
                            <Form.Group
                                className="contact-tab-form-group"
                                controlId="formBasicEmail"
                            >
                            <MaskedFormControl
                            className="dashboard-group-input"
                            type="text"
                            name = "s_PhoneNumber"
                            onChange={(e) => handleInfoChange(e)}
                            value={ profileInfo.s_PhoneNumber }
                            mask="(111)111-1111"
                            />
                            </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                    xs="12"
                    style={{
                        display: "flex",
                        justifyContent: "start",
                        marginTop: "10px",
                    }}
                    >
                    <Button
                        id="common-btn"
                        variant="primary"
                        style={{ padding: "10px 30px 10px 30px" }}
                        onClick={() => submitOtherInfo()}
                    >
                        Other Info
                    </Button>{" "}
                    </Col>
                </Row>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header className="search-quote-card-header">
                <span>Upload Profile Image</span>
                </Accordion.Header>
                <Accordion.Body className="search-quote-card-body">
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ marginTop : "10px" }}>
                    <Row>
                        <Col xs="12" sm="12" md="12" xl="12" lg="12">
                        <div
                            id="drop-document-type-div"
                            {...getRootProps({ className: "dropzone" })}
                        >
                            <input  type="file"
                            multiple=""
                            autocomplete="off"
                            className="inp-file"
                            // onChange={uploadFile}
                            {...getInputProps()} />
                            <FaFileUpload size={34} color={"#64D6CD"} />
                            <p>Drag and Drop File Here</p>
                            <Button id="common-btn" variant="primary">
                            <FaPlus size={15} />
                            Add File
                            </Button>{" "}
                        </div>
                        { !isEmpty(fileslected) ? 
                        <aside className="reply-document-name">
                        <ul>{files}</ul>
                        </aside>
                        : ''
                        }
                        </Col>
                    </Row>
                    <b>&nbsp;&nbsp;&nbsp;Image Preview : </b>
                    <Row>
                        <Col xs="12" sm="12" md="12" xl="12" lg="12">
                        <div
                            id="drop-document-type-div"
                        >
                         <img src={profileInfo.ImageURL} width="150" height="150" />
                        </div>
                        </Col>
                    </Row>
                    </Col>
                    <Col
                    xs="12"
                    style={{
                        display: "flex",
                        justifyContent: "start",
                        marginTop: "10px",
                    }}
                    >
                    <Button
                        id="common-btn"
                        variant="primary"
                        style={{ padding: "10px 30px 10px 30px" }}
                        onClick={() => uploadImage()}
                    >
                        Upload
                    </Button>{" "}
                    </Col>
                </Row>
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>
        </div>
        : '' }
       </LoadingOverlay>

       <Modal
        className="custom-dashboard-modal"
        size="md"
        show={passwordModel}
        onHide={() => {setPasswordModel(false);}}
      >
        <Modal.Header closeButton  className="custom-dashboard-modal-header">
          <Modal.Title>Generate Password</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-dashboard-modal-body">
          <Row style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
               <Row>
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                    <Form.Group
                        className="contact-tab-form-group"
                        controlId="formBasicEmail"
                    >
                       <Form.Control
                        className="dashboard-group-input"
                        type="text"
                        name="s_passwordgenerated"
                        value={generatePassinput}
                      />
                    </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                    <Form.Group
                        className="contact-tab-form-group"
                        controlId="formBasicEmail"
                    >
                    <Button
                        id="common-btn"
                        variant="primary"
                        style={{ padding: "10px 30px 10px 30px" }}
                        onClick={(e) => newGeneratedPassword(e)}
                    >
                   Generate
                </Button>
                    </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="1" xl="1">
                    <Form.Group
                        className="contact-tab-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={toggleSwitch ? true : false}
                            onClick={(e) => checkForUse(e)}
                            variant={"pill"}
                            color={"info"}
                        />
                    </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="10" xl="10">
                    <Form.Group
                        className="contact-tab-form-group"
                        controlId="formBasicEmail"
                    >
                     <Form.Label> I have copied this password in a safe place.</Form.Label>
                    </Form.Group>
                    </Col>
                </Row>
                <Col xs="12">
                <div className="d-grid gap-2">
                    <Button
                    id="common-btn"
                    variant="primary"
                    disabled = {disableButton}
                    style={{ padding: "12px 42px" }}
                    onClick={(e) => setPasswordInput(e)}
                    >
                    Use Password
                    </Button>
                    <Button
                    onClick={() => {setPasswordModel(false)}}
                    id="common-cancel-btn"
                    variant="primary"
                    style={{ padding: "12px 42px" }}
                    >
                    Cancel
                    </Button>
                </div>
               </Col>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
     </Fragment>
  );
};

export default Profile;
