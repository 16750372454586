import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button,Tooltip } from "react-bootstrap";
import { FaSearch, FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "./search-policy.scss";
import {
  getPolicyListData, setPolicyPropsData, productList, verifyPolicy, getBaseData, resetCoreStore,actionTypeDD
} from "../../../services/coreSystemService";
import { useDispatch, connect } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
const SearchPolicy = (props) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [TableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [filter, setFilter] = useState({
    policyNo: "",
    product: "",
    firstName: "",
    lastName: "",
    page: 1,
    pageLength: 10,
  });

  const [actionDD, setActionDD] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      await dispatch(productList());
      setLoading(false);
    }

    if(props.allProducts && Object.keys(props.allProducts).length === 0){
      getProducts();
    }
    dispatch(actionTypeDD());
  }, []);

  useEffect(() => {
    if (props.actionTypeDD) {
      setActionDD(props.actionTypeDD.ACTIONTYPE);
    }
  }, [props]);

  useEffect(() => {
    if (isChanged) {
      PolicyListData();
    }
  }, [filter.page, filter.pageLength]);

  //fetch policy Data based on filter 
  const PolicyListData = async () => {
    if (
      filter.policyNo.length > 0 ||
      filter.product.length > 0 ||
      filter.firstName.length > 0 ||
      filter.lastName.length > 0
    ) {
      setLoading(true);
      await dispatch(getPolicyListData(filter)).then((res) => {
        setTableData(res.data.data);
        setTotal(res.data.total);
      });
      setLoading(false);
      setIsChanged(false);
    } else {
      toast.warning(t("Please enter some words for search!"));
    }
  };

  const onTableChange = (type, { page, sizePerPage }) => {
    setFilter({ ...filter, page: page, pageLength: sizePerPage });
    setIsChanged(true);
  }

  const renderShowsTotal = (from, to, size) => (
    <span style={{ paddingLeft: 10 }}>Showing {from} to {to} of {size} Results</span>
  );

  const options = {
    page: filter.page,
    sizePerPage: filter.pageLength,
    sortIndicator: false,
    pageStartIndex: 1,
    totalSize: total,
    showTotal: true,
    paginationTotalRenderer: renderShowsTotal,
  };


  // clickable URL builder for POLICY Details
  const urlBuilder = async (row , acttionType='') => {
    await dispatch(verifyPolicy(row.s_POAppNo)).then((res) => {
      if (res.status) {
        // To Reset Core System Reducer Store
        dispatch(resetCoreStore());
        const params = {
          n_POPolicyMasterFK: res.policyIdPK,
          n_POTermMasterFK: res.termMasterPK,
          n_POTransactionFK: res.poTransPK,
        };
        dispatch(setPolicyPropsData(params));
        // To Base Data (Dropdown)
        dispatch(
          getBaseData(res.productFK)
        );
        if(acttionType == 'DOWNLOADDOCUMENTS'){
          navigation(`/core/policy/${acttionType}/${row.s_POAppNo}`);
        } else {
          navigation(`/core/policy/${row.s_POAppNo}`);

        }
      } else {
        toast.error(res.message);
      }
    });
  };

  //table column formating
  const columnFormatter = (cell, row) => {
    if (row && row.s_POAppNo) {
      return (
        <a href="#" onClick={() => urlBuilder(row)}>
          {cell}
        </a>
      );
    } else {
      return row.s_POAppNo;
    }
  };

  //table column formating Action
  const columnFormatterAction = (cell, row) => {
    return (
      <Form.Select
        name="action"
        className="dashboard-group-input"
        id="select-ym-option"
        onChange={(e) => onHandleChangeSelect(e,row,cell)}
      >
        <option value=''>Select Action</option>
        {
          Object.keys(actionDD).length > 0 && actionDD.map((v, i) => {
            return <option key={i} value={v.s_AppCodeName}>{v.s_AppCodeNameForDisplay}</option>
          })
        }
      </Form.Select>
    );
  };

  const columns = [
    {
      dataField: "d_DateSubmitted",
      text: t("Submit Date"),
    },
    {
      dataField: "s_ProductName",
      text: t("Policy Type"),
    },
    {
      dataField: "s_POAppNo",
      text: t("Policy NO"),
      formatter: columnFormatter,
    },
    {
      dataField: "InsuredName",
      text: t("Insured Name"),
    },
    {
      dataField: "s_AppStatus",
      text: t("Status"),
    },
    {
      dataField: "s_AppCodeNameForDisplay",
      text: t("Remark"),
    },
    {
      dataField: "AgentName",
      text: t("Agent"),
    },
    {
      dataField: "AgencyName",
      text: t("Agency"),
    },
    {
      dataField: "s_POAppNo",
      text: t("Action"),
      formatter: columnFormatterAction,
    },
  ];

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    const tempArray = filter; // creating copy of an array
    tempArray[name] = value;
    setFilter(tempArray); // set copied array
  };

  const onHandleChangeSelect = (e,row,cell) => {
   console.log(e.target.value,row,cell);
   var acttionType = e.target.value;
    if(acttionType ==  'DOWNLOADDOCUMENTS'){
      urlBuilder(row,acttionType);
    } else {
      urlBuilder(row);
    }
  };

  return (
    <LoadingOverlay>
      <Loader loading={loading} />
      <div className="search-policy-section">
        <Row className="align-items-center">
          <Col xs="12">
            <div className="search-policy-filters">
              <h2>{t('Search Policy')}</h2>
              <Row className="align-items-end">
                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                  <Form.Group
                    className="policy-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t('Policy No')}.
                    </Form.Label>
                    <Form.Control
                      name="policyNo"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      onChange={(e) => onHandleChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                  <Form.Group
                    className="policy-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t('Last Name')}
                    </Form.Label>
                    <Form.Control
                      name="lastName"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      onChange={(e) => onHandleChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                  <Form.Group
                    className="policy-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t('First Name')}
                    </Form.Label>
                    <Form.Control
                      name="firstName"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      onChange={(e) => onHandleChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                  <Form.Group
                    className="policy-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t('Select Product')}
                    </Form.Label>
                    <Form.Select
                      name="product"
                      className="dashboard-group-input"
                      id="select-ym-option"
                      onChange={(e) => onHandleChange(e)}
                    >
                      <option key={0} value=''>Select Product</option>
                      {
                        Object.keys(props.allProducts).length > 0 && props.allProducts.map((v, i) => {
                          return <option key={i} value={v.n_ProductId_PK}>{v.s_ProductName}</option>
                        })
                      }
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="search-policy-btn-col">
                  <Button
                    id="common-btn"
                    variant="primary"
                    onClick={() => PolicyListData()}
                  >
                    <FaSearch size={15} style={{ marginRight: "10px" }} />
                    {t('Search Policy')}
                  </Button>{" "}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row id="dashboard-table-section">
          <Col xs="12" style={{ marginTop: "50px" }}>
            <div className="table-main-title-div">
              <span className="table-main-title-name">{t('Search Result')}</span>
              <div className="table-main-title-icons">
                <div className="table-square"></div>
                <FaBars size={15} color={"#fff"} />
              </div>
            </div>
          </Col>
          <Col xs="12">
            <BootstrapTable
              wrapperClasses="dashboard-table-main"
              bootstrap4
              remote
              keyField="s_POAppNo"
              data={TableData}
              columns={columns}
              hover
              pagination={paginationFactory(options)}
              onTableChange={onTableChange}
            />
          </Col>
        </Row>
      </div>
    </LoadingOverlay>
  );
};

const mapStateToProps = (state) => {
  return {
    actionTypeDD: state.coreSystem.actionTypeDD,
    allProducts: state.coreSystem.allProducts,
  };
}

export default connect(mapStateToProps)(SearchPolicy);
