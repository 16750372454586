import React, { useState, useEffect } from "react";
import { Row, Col, Table, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getEditEmptyQuoteData, updateInitQuoteApi, pivotApiCall, getPayPlanOptions, getDeductibles } from "../../../../../../services/floodService";
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Navigate, useNavigate } from "react-router-dom";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./flood-pricing-plan.scss";
import "react-overlay-loader/styles.css";

const FloodPricingPlan = (props) => {
  const [basicBuildingCvg, setBasicBuildingCvg] = useState(0);
  const [basicContentsCvg, setBasicContentsCvg] = useState(0);
  const [recommendedBuildingCvg, setRecommendedBuildingCvg] = useState(0);
  const [recommendedContentsCvg, setRecommendedContentsCvg] = useState(0);
  const [customBldgCvg, setCustomBldgCvg] = useState(0);
  const [customContentCvg, setCustomContentCvg] = useState(0);

  const [basicBuildingDed, setBasicBuildingDed] = useState(0);
  const [basicContentsDed, setBasicContentsDed] = useState(0);
  const [recommendedBuildingDed, setRecommendedBuildingDed] = useState(0);
  const [recommendedContentsDed, setRecommendedContentsDed] = useState(0);
  const [customBldgDed, setCustomBldgDed] = useState(0);
  const [customContentDed, setCustomContentDed] = useState(0);

  const [basicPrem, setBasicPrem] = useState(0);
  const [recommendedPrem, setRecommendedPrem] = useState(0);
  const [customPrem, setCustomPrem] = useState(0);
  const [basicRCV, setBasicRCV] = useState(0);
  const [recommendCV, setRecommendRCV] = useState(0);
  const [customRCV, setCustomRCV] = useState(0);
  const [isCustomChanged, setIsCustomChanged] = useState(false);
  const [maxBCoverage, setMaxBCoverage] = useState(0);
  const [maxCCoverage, setMaxCCoverage] = useState(0);
  const [bDeductible, setBDeductible] = useState([]);
  const [cDeductible, setCDeductible] = useState([]);
  const [maxBDeductible, setMaxBDeductible] = useState(0);
  const [maxCDeductible, setMaxCDeductible] = useState(0);
  const [validated, setValidated] = useState(false);
  const [billTo, setBillTo] = useState('');
  const [loading, setLoading] = useState(false);
  const [isBldgCvgDisable, setIsBldgCvgDisable] = useState(false);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { t } = useTranslation();
  const { emptyQuoteData, initApiObject, apiLoading, floodSystemDD, basicCvg, recommendedCvg, basicCvgResponse, recommendedCvgResponse, customCvg, customCvgResp, deductible } = useSelector(state => state.flood);

  // To Fetch Plan Details
  useEffect(() => {
    if (Object.keys(initApiObject).length > 0) {
      const reqJson = { ...initApiObject }; // Copy
      const request = { 'sqft': parseInt(reqJson.sqft), 'viewType': emptyQuoteData.viewType || '' };
      dispatch(getPayPlanOptions(request));
      dispatch(getDeductibles());
    }
    // set Initial State from reducer
    if (Object.keys(emptyQuoteData).length > 0) {
      setBillTo(emptyQuoteData.policyInfo.s_BillTo);
      var temp = false;
      if (emptyQuoteData.viewType === 'UNIT') {
        if (['APARTMENTUNIT20', 'COOPERATIVEUNIT20', 'RESIDENTIALCONDOUNITNRB20', 'OTHER20'].includes(emptyQuoteData.propertyInfo.s_BuildingUse)) {
          temp = true;
        }
      }
      setIsBldgCvgDisable(temp);
    }
  }, []);

  // Trigger to Updated Plan Details on Dependent State Updated
  useEffect(() => {
    if (Object.keys(basicCvg).length > 0) {
      const cvgData = basicCvg
      setBasicBuildingCvg(cvgData.totalCoverageBuilding || 0)
      setBasicContentsCvg(cvgData.totalCoverageContents || 0)
      setBasicBuildingDed(cvgData.buildingDeductibleDisplay || 0)
      setBasicContentsDed(cvgData.contentsDeductibleDisplay || 0)

      // To Call Pivot Api For Various Plan at the same time
      getPivotApiRequest(cvgData, 'Basic')
    }
  }, [basicCvg]);

  // Trigger to Updated Plan Details on Dependent State Updated
  useEffect(() => {
    if (Object.keys(recommendedCvg).length > 0) {
      const cvgData = recommendedCvg
      setRecommendedBuildingCvg(cvgData.totalCoverageBuilding || 0)
      setRecommendedContentsCvg(cvgData.totalCoverageContents || 0)
      setRecommendedBuildingDed(cvgData.buildingDeductibleDisplay || 0)
      setRecommendedContentsDed(cvgData.contentsDeductibleDisplay || 0)

      // To Call Pivot Api For Various Plan at the same time
      getPivotApiRequest(cvgData, 'Recommended')
    }
  }, [recommendedCvg]);

  // Trigger to Updated Plan Details on Dependent State Updated
  useEffect(() => {
    if (Object.keys(customCvg).length > 0) {
      const cvgData = customCvg
      setCustomBldgCvg(cvgData.totalCoverageBuilding || 0)
      setCustomContentCvg(cvgData.totalCoverageContents || 0)
      setCustomBldgDed(cvgData.buildingDeductibleDisplay || 0)
      setCustomContentDed(cvgData.contentsDeductibleDisplay || 0)

      // set state from reducer
      if (emptyQuoteData && Object.keys(emptyQuoteData.propertyCoverageInfo).length > 0) {
        if (emptyQuoteData.plan === 'Custom') {
          var propertyCoverageInfo = emptyQuoteData.propertyCoverageInfo
          setCustomBldgCvg(propertyCoverageInfo.n_BuildingCvg);
          setCustomContentCvg(propertyCoverageInfo.n_ContentCvg);
          setCustomBldgDed(parseFloat(propertyCoverageInfo.s_BuildingDeductible.replace('FLDDED', '')) || 0);
          setCustomContentDed(parseFloat(propertyCoverageInfo.s_ContentDeductible.replace('FLDDED', '')) || 0);

          cvgData.totalCoverageBuilding = customBldgCvg;
          cvgData.totalCoverageContents = customContentCvg;
          cvgData.buildingDeductibleDisplay = customBldgDed;
          cvgData.contentsDeductibleDisplay = customContentDed;
        }
      }

      // To Call Pivot Api For Various Plan at the same time
      getPivotApiRequest(cvgData, 'Custom')
    }
  }, [customCvg]);

  // set deductible options
  useEffect(() => {
    if (emptyQuoteData && Object.keys(deductible).length > 0) {
      ['HOME', 'MOBILEHOME', 'UNIT'].includes(emptyQuoteData.viewType) ? setMaxBCoverage(250000) : setMaxBCoverage(500000)
      setMaxCCoverage(100000)
      var cDeductibleOptions = {};
      var bDeductibleOptions = {};
      deductible.filter(val => [1000, 1500, 2000, 5000, 10000].includes(val.n_DeductibleIncident)).map((v) => {
        return bDeductibleOptions[v.n_DeductibleIncident] = v.n_DeductibleIncident
      });
      deductible.filter(val => [1000, 2000, 5000, 10000].includes(val.n_DeductibleIncident)).map((v) => {
        return cDeductibleOptions[v.n_DeductibleIncident] = v.n_DeductibleIncident
      });
      setBDeductible({ '0': 0, ...bDeductibleOptions })
      setCDeductible({ '0': 0, ...cDeductibleOptions })
      if (Object.keys(bDeductibleOptions).length) {
        setMaxBDeductible(bDeductibleOptions[Object.keys(bDeductibleOptions)[Object.keys(bDeductibleOptions).length - 1]])
        setMaxCDeductible(cDeductibleOptions[Object.keys(cDeductibleOptions)[Object.keys(cDeductibleOptions).length - 1]])
      }
    }
  }, [deductible])

  // Only trigger if custom details change
  useEffect(() => {
    if (isCustomChanged === true) {
      callPivotApi();
    }
  }, [isCustomChanged]);

  // Trigger to updated premium details for various plan
  useEffect(() => {
    // Basic Premium
    if (Object.keys(basicCvgResponse).length > 0) {
      if (Object.keys(basicCvgResponse.data).length > 0) {
        const code = basicCvgResponse.data.code || 0;
        if ([200, 201].includes(code) && Object.keys(basicCvgResponse.data.rreQuote).length > 0) {
          setBasicPrem(basicCvgResponse.data.rreQuote.premium.totalAnnualPayment || 0);
          setBasicRCV(basicCvgResponse.data.quoteFactors.rcvFema || 0);
        }
      }
    }
    // Recommended Premium
    if (Object.keys(recommendedCvgResponse).length > 0) {
      if (Object.keys(recommendedCvgResponse.data).length > 0) {
        const code = recommendedCvgResponse.data.code || 0;
        if ([200, 201].includes(code) && Object.keys(recommendedCvgResponse.data.rreQuote).length > 0) {
          setRecommendedPrem(recommendedCvgResponse.data.rreQuote.premium.totalAnnualPayment || 0);
          setRecommendRCV(recommendedCvgResponse.data.quoteFactors.rcvFema || 0);
        }
      }
    }
    // Custom Premium
    if (Object.keys(customCvgResp).length > 0) {
      if (Object.keys(customCvgResp.data).length > 0) {
        const code = customCvgResp.data.code || 0;
        if ([200, 201].includes(code) && Object.keys(customCvgResp.data.rreQuote).length > 0) {
          setCustomPrem(customCvgResp.data.rreQuote.premium.totalAnnualPayment || 0);
          setCustomRCV(customCvgResp.data.quoteFactors.rcvFema || 0);
        }
      }
    }
  }, [basicCvgResponse, recommendedCvgResponse, customCvgResp]);

  // Trigger Loading
  useEffect(() => {
    var temp = false;
    if (Object.keys(deductible).length === 0 || Object.keys(basicCvgResponse).length === 0 || Object.keys(recommendedCvgResponse).length === 0 || Object.keys(customCvgResp).length === 0) {
      temp = true;
    }
    setLoading(temp)
  }, [deductible, basicCvgResponse, recommendedCvgResponse, customCvgResp]);

  // To Call Pivot Api Various Plan
  const getPivotApiRequest = async (cvgData, plan) => {
    const code = 'QUOTE_NEWBUSINESS';
    const reqJson = { ...initApiObject }; // Copy
    reqJson.buildingDeductible = cvgData.buildingDeductibleDisplay
    reqJson.contentsDeductible = cvgData.contentsDeductibleDisplay
    reqJson.coverageAmountType = cvgData.coverageAmountType
    reqJson.iccPremium = cvgData.iccPremium
    reqJson.industryReplacementCost = cvgData.industryReplacementCost
    reqJson.totalCoverageBuilding = cvgData.totalCoverageBuilding
    reqJson.totalCoverageContents = cvgData.totalCoverageContents
    reqJson.whichSection = 'BUYPLAN';
    reqJson.plan = plan;
    reqJson.uniqueKey = localStorage.getItem('uniqueKey');
    // To update
    // dispatch(updateInitQuoteApi(reqJson));

    const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0 };
    // Call Api
    await dispatch(pivotApiCall(code, request, plan));
  }

  // Trigger to Call Pivot Api on Custom Plan Updated
  const callPivotApi = () => {
    var tempCustomBldgDed = customBldgDed;
    var tempCustomContentDed = customContentDed;
    if (customBldgCvg > 0 && customBldgDed === 0) {
      tempCustomBldgDed = 2000;
      setCustomBldgDed(2000);
    }
    if (customContentCvg > 0 && customContentDed === 0) {
      tempCustomContentDed = 2000;
      setCustomContentDed(2000);
    }

    const customCvgData = customCvg;
    customCvgData.totalCoverageBuilding = customBldgCvg;
    customCvgData.totalCoverageContents = customContentCvg;
    customCvgData.buildingDeductibleDisplay = tempCustomBldgDed;
    customCvgData.contentsDeductibleDisplay = tempCustomContentDed;
    getPivotApiRequest(customCvgData, 'Custom');
    setIsCustomChanged(false);
  };

  // To update selected plan details into the quote details
  const updateBasicQuoteData = (planType) => {
    if (!validateFields(billTo)) {
      var cvgData = {}, uuid = '';
      cvgData = basicCvg
      if (planType === 'Basic') {
        cvgData.premium = basicPrem;
        cvgData.totalCoverageBuilding = basicBuildingCvg;
        cvgData.totalCoverageContents = basicContentsCvg;
        cvgData.buildingDeductibleDisplay = basicBuildingDed;
        cvgData.contentsDeductibleDisplay = basicContentsDed;
        uuid = basicCvgResponse.data.transactionId;
      }
      if (planType === 'Recommended') {
        cvgData.premium = recommendedPrem;
        cvgData.totalCoverageBuilding = recommendedBuildingCvg;
        cvgData.totalCoverageContents = recommendedContentsCvg;
        cvgData.buildingDeductibleDisplay = recommendedBuildingDed;
        cvgData.contentsDeductibleDisplay = recommendedContentsDed;
        uuid = recommendedCvgResponse.data.transactionId;
      }
      if (planType === 'Custom') {
        cvgData.premium = customPrem;
        cvgData.totalCoverageBuilding = customBldgCvg;
        cvgData.totalCoverageContents = customContentCvg;
        cvgData.buildingDeductibleDisplay = customBldgDed;
        cvgData.contentsDeductibleDisplay = customContentDed;
        uuid = customCvgResp.data.transactionId;
      }
      if (Object.keys(cvgData).length > 0) {
        const emptyQuote = emptyQuoteData;
        emptyQuote.propertyCoverageInfo.n_BuildingCvg = cvgData.totalCoverageBuilding
        emptyQuote.propertyCoverageInfo.n_ContentCvg = cvgData.totalCoverageContents
        emptyQuote.propertyCoverageInfo.s_BuildingDeductible = 'FLDDED' + cvgData.buildingDeductibleDisplay
        emptyQuote.propertyCoverageInfo.s_ContentDeductible = 'FLDDED' + cvgData.contentsDeductibleDisplay
        emptyQuote.plan = planType
        emptyQuote.premium = cvgData.premium;
        emptyQuote.uniqueKey = localStorage.getItem('uniqueKey');
        emptyQuote.uuid = uuid;
        dispatch(getEditEmptyQuoteData(emptyQuote));

        const reqJson = { ...initApiObject }; // Copy
        reqJson.buildingDeductible = cvgData.buildingDeductible
        reqJson.contentsDeductible = cvgData.contentsDeductible
        reqJson.coverageAmountType = cvgData.coverageAmountType
        reqJson.iccPremium = cvgData.iccPremium
        reqJson.industryReplacementCost = cvgData.industryReplacementCost
        reqJson.totalCoverageBuilding = cvgData.totalCoverageBuilding
        reqJson.totalCoverageContents = cvgData.totalCoverageContents
        reqJson.whichSection = 'BUYPLAN';
        reqJson.uniqueKey = localStorage.getItem('uniqueKey');
        // To update
        dispatch(updateInitQuoteApi(reqJson));

        navigation('/flood/quote');
      }
    }
  }

  const validateFields = (value) => {
    var temp = false;
    if (["", null].includes(value)) {
      temp = true;
    }
    setValidated(temp);
    return temp;
  }

  /**
   * go back to previous screen
   */
  const handlePrevious = () => {
    if (emptyQuoteData.propertyInfo.s_IsNewPurchase === 'YES' && emptyQuoteData.propertyInfo.s_FloodConstructionType === 'FLDPREFIRM') {
      navigation('/flood/instantquote/step/11');
    } else if (['SLABONGRADE20', 'BASEMENT20', 'ELEVATEDWITHOUTENCLOSURE20'].includes(emptyQuoteData.propertyInfo.s_Foundation) && emptyQuoteData.propertyInfo.s_isValidElevationCert === 'YES') {
      navigation('/flood/instantquote/step/9');
    } else if (['X', 'B', 'C', 'A99', 'D'].includes(emptyQuoteData.propertyInfo.s_FloodZone) && ['SLABONGRADE20', 'BASEMENT20', 'ELEVATEDWITHOUTENCLOSURE20'].includes(emptyQuoteData.propertyInfo.s_Foundation)) {
      navigation('/flood/instantquote/step/8');
    } else {
      navigation('/flood/instantquote/step/11');
    }
  }

  /**
   * handle Change in Custom plan
   */
  const handleCustomChange = (key, value) => {
    if (!value) {
      value = 0;
    }
    switch (key) {
      case 'customBldgCvg':
        setCustomBldgCvg(value);
        setIsCustomChanged(true);
        break;
      case 'customContentCvg':
        setCustomContentCvg(value);
        if (value === 0 && customBldgDed > 0) {
          setCustomContentDed(value);
        }
        setIsCustomChanged(true);
        break;
      case 'customContentDed':
        setCustomContentDed(value);
        if (value === 0 && customContentCvg > 0) {
          setCustomContentCvg(value);
        }
        setIsCustomChanged(true);
        break;
      case 'customBldgDed':
        setCustomBldgDed(value);
        setIsCustomChanged(true);
        break;
      default:
        setIsCustomChanged(false);
        break;
    }
  }

  return (
    <LoadingOverlay >
      <Loader loading={apiLoading || loading} />
      <section className="pricing-details-section">
        {
          Object.keys(emptyQuoteData).length === 0 && <Navigate to={'/flood/instantquote/step/address'} />
        }
        {/* Form */}
        <Row className="justify-content-center">
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            className="pricing-page-heading"
          >
            <Row className="justify-content-center">
              <Col xs="12" sm="12" md="12" lg="12" xl="12">
                <h5>{t('Your Coverage Options')}</h5>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="8"
            xl="8"
            className="pricing-table-main"
          >
            <Table responsive borderless className="pricing-table">
              <thead>
                <tr>
                  <th className="flood-pricing-col1">
                    <span style={{ visibility: "hidden" }}>.</span>
                    <div className="pay-plan-col-main">
                      <span className="flood-pay-plan-h">{t('Bill to')}:</span>
                      <Form validated={validated}>
                        <Form.Select
                          className="myform-input"
                          id="flood-pricing-plan-select"
                          name="s_BillTo"
                          parentobject="policyInfo"
                          title={t('Bill to')}
                          value={billTo}
                          onChange={(e) => {
                            props.handleChange(e);
                            setBillTo(e.currentTarget.value);
                          }}
                          required
                        >
                          <option value=''>{t('Select')}</option>
                          {
                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.BILLTOFLOOD.map((v, i) => {
                              return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                            })
                          }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {t("Please Select Bill to Error")}
                        </Form.Control.Feedback>
                      </Form>
                    </div>
                  </th>
                  <th className="flood-pricing-col2">
                    <div>
                      <h5>{t('Basic')}</h5>
                      <span className="per-month">
                        ${basicPrem} /{t('Year')}
                      </span>
                      <br/>
                      <span>
                        RCVFEMA - ${basicRCV}
                      </span>
                    </div>
                  </th>
                  <th className="flood-pricing-col3">
                    <div>
                      <h5>{t('Premium')}</h5>
                      <span className="per-month">
                        ${recommendedPrem} /{t('Year')}
                      </span>
                      <br/>
                      <span>
                        RCVFEMA - ${recommendCV}
                      </span>
                    </div>
                  </th>
                  <th className="flood-pricing-col4">
                    <div>
                      <h5>{t('Customize Your Way')}</h5>
                      <span className="per-month">
                        ${customPrem} /{t('Year')}
                      </span>
                      <br/>
                      <span>
                        RCVFEMA - ${customRCV}
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="special-row">
                  <td>{t('Flood coverage')}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>{t('Building')}</td>
                  <td>${basicBuildingCvg}</td>
                  <td>${recommendedBuildingCvg}</td>
                  <td>
                    <Row className="range-full-row" id="flood-pricing-range">
                      <Col
                        xs="5"
                        sm="5"
                        md="5"
                        lg="4"
                        xl="4"
                        className="range-col-text"
                      >
                        ${customBldgCvg}
                      </Col>
                      <Col xs="7" sm="7" md="7" lg="8" xl="8">
                        <div className="slider">
                          <Slider
                            min={0}
                            max={maxBCoverage}
                            value={customBldgCvg}
                            step={1000}
                            onAfterChange={(value) => handleCustomChange('customBldgCvg', value)}
                            onChange={(e) => { setCustomBldgCvg(e) }}
                            disabled={isBldgCvgDisable}
                          />
                        </div>
                      </Col>
                    </Row>
                  </td>
                </tr>

                <tr>
                  <td>{t('Contents')}</td>
                  <td>${basicContentsCvg}</td>
                  <td>${recommendedContentsCvg}</td>
                  <td>
                    <Row className="range-full-row">
                      <Col
                        xs="5"
                        sm="5"
                        md="5"
                        lg="4"
                        xl="4"
                        className="range-col-text"
                      >
                        ${customContentCvg}
                      </Col>
                      <Col xs="7" sm="7" md="7" lg="8" xl="8">
                        <div className="slider">
                          <Slider
                            min={0}
                            max={maxCCoverage}
                            value={customContentCvg}
                            step={1000}
                            onAfterChange={(value) => handleCustomChange('customContentCvg', value)}
                            onChange={(e) => { setCustomContentCvg(e) }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </td>
                </tr>

                <tr className="special-row">
                  <td>{t('Deductibles')}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>{t('Building')}</td>
                  <td>${basicBuildingDed}</td>
                  <td>${recommendedBuildingDed}</td>
                  <td>
                    <Row className="range-full-row">
                      <Col
                        xs="5"
                        sm="5"
                        md="5"
                        lg="4"
                        xl="4"
                        className="range-col-text"
                      >
                        ${customBldgDed}
                      </Col>
                      <Col xs="7" sm="7" md="7" lg="8" xl="8">
                        <div className="slider">
                          <Slider
                            max={maxBDeductible}
                            marks={bDeductible}
                            value={customBldgDed}
                            step={null}
                            onAfterChange={(value) => handleCustomChange('customBldgDed', value)}
                            onChange={(e) => { setCustomBldgDed(e) }}
                            disabled={isBldgCvgDisable}
                          />
                        </div>
                      </Col>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <td>{t('Contents')}</td>
                  <td>${basicContentsDed}</td>
                  <td>${recommendedContentsDed}</td>
                  <td>
                    <Row className="range-full-row">
                      <Col
                        xs="5"
                        sm="5"
                        md="5"
                        lg="4"
                        xl="4"
                        className="range-col-text"
                      >
                        ${customContentDed}
                      </Col>
                      <Col xs="7" sm="7" md="7" lg="8" xl="8">
                        <div className="slider">
                          <Slider
                            max={maxCDeductible}
                            marks={cDeductible}
                            value={customContentDed}
                            step={null}
                            onAfterChange={(value) => handleCustomChange('customContentDed', value)}
                            onChange={(e) => { setCustomContentDed(e) }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </td>
                </tr>

                <tr>
                  <td
                    id="flood-pricing-buy-now"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      id="primary-grey-btn-flood"
                      variant="outline-dark"
                      onClick={() => handlePrevious()}
                      className="previous-btn"
                    >
                      {t('Previous')}
                    </Button>
                  </td>
                  <td id="flood-pricing-buy-now">
                    <Button
                      id="primary-colored-btn"
                      className="primary"
                      onClick={() => updateBasicQuoteData('Basic')}
                    >
                      {" "}
                      {t('Select')}{" "}
                    </Button>
                  </td>
                  <td id="flood-pricing-buy-now">
                    {" "}
                    <Button
                      id="primary-colored-btn"
                      className="primary"
                      onClick={() => updateBasicQuoteData('Recommended')}
                    >
                      {" "}
                      {t('Select')}{" "}
                    </Button>
                  </td>
                  <td id="flood-pricing-buy-now" className="range-col">
                    <Button
                      id="primary-colored-btn"
                      className="primary"
                      onClick={() => updateBasicQuoteData('Custom')}
                    >
                      {" "}
                      {t('Select')}{" "}
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </section>
    </LoadingOverlay >
  );
};

export default FloodPricingPlan;
