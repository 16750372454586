import React, {useEffect, useState} from "react";
import { GoogleMap, useJsApiLoader,Marker} from "@react-google-maps/api";
import { useDispatch, useSelector , connect } from 'react-redux';

const MapComponent = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBDNv5kL1hQLbbVoSFKywTs4sSTage18ps",
  });
  
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  
  useEffect(() => {
	setLat(parseFloat(props.lat));
	setLong(parseFloat(props.long));
  }, [props.lat,props.long]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "250px",
        borderRadius: "8px",
      }}
      center={{ lat: lat, lng: long }}
      zoom={18}
      mapTypeId="satellite"
      label={true}
    ><Marker position={{ lat: lat, lng: long }} /></GoogleMap>
  ) : null;
};


function mapStateToProps(state) {
	return {
	  	quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	  
		// Map Data
	  	//lat: state.common.emptyQuoteData.personAddr.s_Latitude ? parseFloat(state.common.emptyQuoteData.personAddr.s_Latitude) : '',
	  	//long: state.common.emptyQuoteData.personAddr.s_Longitude ? parseFloat(state.common.emptyQuoteData.personAddr.s_Longitude) : '',

		
		
	  
	};
  } 
  export default connect(mapStateToProps)(MapComponent);
