import React, { useState, Fragment, useEffect } from "react";
import { Row, Col, Container, Form, Button, Table, Alert } from "react-bootstrap";

import delIcon from "../../../../../assets/delete-icon.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./claim-information.scss";
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
 
const ClaimInformation = (props) => {
	const [showTable, setShowTable] = useState(false);
	const [inputFields, setInputFields] = useState(props.quoteData.lossHistoryData ? props.quoteData.lossHistoryData : []);
	const [validated, setValidated] = React.useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [msgError, setMsgError] = useState("");
	const { t } = useTranslation();
  
	var today = new Date();
	var maxDate = new Date(today.setDate(today.getDate())).toISOString().split("T")[0];
	useEffect(() => {
	  props.setDefaultData('lossHistoryData', inputFields);
	  props.setCountForLosses(inputFields.length);
	}, [inputFields]);
  
	const toggleValidate = (e) => {
	  setValidated(e);
	}
	const handleAddFields = () => {
	  const values = [...inputFields];
	  values.push({
		d_LossDate: "",
		s_LossType: "",
		s_LossDescription: "",
		n_LossAmount: "",
	  });
	  setInputFields(values);
	};
  
	const handleRemoveFields = (index) => {
	  const values = [...inputFields];
	  values.splice(index, 1);
	  setInputFields(values);
	};
  
	const handleInputChange = (index, event) => {
	  const values = [...inputFields];
	  const updatedValue = event.target.name;
	  values[index][updatedValue] = event.target.value;
  
	  setInputFields(values);
	};
  
	const handleDateChange = (index, date) => {
	  const values = [...inputFields];
	  values[index].d_LossDate = date;
  
	  setInputFields(values);
	};
  
  
	return (
	  
		<Fragment>
		  <div className="claim-add-btn">
			<Button
			  variant="primary"
			  id="common-btn"
			//  className="common-btn"
			style={{
				whiteSpace: "nowrap",
				padding: "5px",
			  }}
			  onClick={() => handleAddFields()}
			  disabled={props.isBind}
			>
			  {" "}
			  <span> + </span> {t("Add")}
			</Button>
		  </div>
		  <div className="claim-table-div">
			<Table className="claim-table" borderless responsive>
			  <thead>
				<tr>
				  <th style={{ width: "20%" }}>{t("Last date")}</th>
				  <th style={{ width: "20%" }}>{t("Loss Type")}</th>
				  <th style={{ width: "40%", minWidth: '180px' }}>{t("Loss Desc")}.</th>
				  <th style={{ width: "20%" }}>{t("Loss Amt")}</th>
				  <th>{t("Action")}</th>
				</tr>
			  </thead>
			  <tbody>
				{inputFields.length > 0 && (
				  <>
					{inputFields.map((field, index) => (
					  <tr key={index}>
						<td>
						  <Form.Group
							className="property-info-field"
							controlId="formBasicEmail"
						  >
							<Form.Control
							  type="date"
							  placeholder="Enter Date"
							  // name="d_PriorExpDate"
							  // title="policyInfo"
							  value={field.d_LossDate ? field.d_LossDate : ''}
							  onKeyDown={(e) => {
								e.preventDefault();
							  }}
							  onChange={(e) =>
								handleDateChange(index, e.target.value)
							  }
							  max={maxDate}
							  required
							/>
							<Form.Control.Feedback type="invalid">
							  "Please Add Last date"
							</Form.Control.Feedback>
						  </Form.Group>
						</td>
						<td>
						  <Form.Group
							className="property-info-field"
							controlId="formBasicEmail"
						  >
							<Form.Select
							  className="myform-input"
							  name="s_LossType"
							  value={field.s_LossType}
							  onChange={(event) =>
								handleInputChange(index, event)
							  }
							  required
							>
							  <option value="">Select</option>
							  <option value="LIABILITY">
								Liability
							  </option>
							  <option value="PROPERTY">
								Property
							  </option>
							</Form.Select>
							<Form.Control.Feedback type="invalid">
							  Please Add Lose Type Error
							</Form.Control.Feedback>
						  </Form.Group>
						</td>
						<td>
						  <Form.Group>
							<Form.Control
							  className="myform-input"
							  type="text"
							  name="s_LossDescription"
							  placeholder="Loss Description"
							  value={field.s_LossDescription}
							  onChange={(event) =>
								handleInputChange(index, event)
							  }
							  required
							/>
							<Form.Control.Feedback type="invalid">
							  Please Add Loss Desc Error
							</Form.Control.Feedback>
						  </Form.Group>
						</td>
						<td>
						  <Form.Group>
							<Form.Control
							  className="myform-input"
							  type="text"
							  name="n_LossAmount"
							  placeholder="Loss Amt"
							  value={field.n_LossAmount}
							  onChange={(event) =>
								handleInputChange(index, event)
							  }
							  required
							/>
							<Form.Control.Feedback type="invalid">
							  Please Add Lose Amt Error
							</Form.Control.Feedback>
						  </Form.Group>
						</td>
						<td className="tr-action-row">
						  <div className="tr-action-btns">
							<img
							  src={delIcon}
							  onClick={() =>
								handleRemoveFields(index)
							  }
							/>
						  </div>
						</td>
					  </tr>
					))}
				  </>
				)}
			  </tbody>
			</Table>
		  </div>
		  
		</Fragment>
					 
	);
  };
  
  function mapStateToProps(state) {
	return {
		quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	};
  }
  export default connect(mapStateToProps)(ClaimInformation);