import React, { Fragment, useState } from "react";
import { Button, Row, Col, Form, Table, Accordion } from "react-bootstrap";
import basicBadge from "../../../../../assets/badges/basic.png";
import optionalBadge from "../../../../../assets/badges/optional.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MapComponent from "../../../../../components/MapComponent";
import { useDispatch, useSelector , connect } from 'react-redux';
import { useTranslation } from "react-i18next";

const WindMitigation = (props) => {
  const [date1, setDate1] = useState(new Date());
  const { t } = useTranslation();
  return (
	<Row className="align-items-end">
	    <Col xs="12" sm="12" md="6" lg="3" xl="3">
	      <Form.Group
	        className="search-quote-form-group"
	        controlId="formBasicEmail"
	      >
	        <Form.Label className="dashboard-group-label">
	          {t("Roof Cover(FBC)")} <span className="label-asterisk">*</span>
	        </Form.Label>
	        <Form.Select
	          className="dashboard-group-input"
	          id="select-ym-option"
			  name="s_RoofCoverCode"
			  parentobject="propertyInfo"
			  title="propertyInfo"
			  value={props.quoteData.propertyInfo.s_RoofCoverCode ? props.quoteData.propertyInfo.s_RoofCoverCode : ''}
			  onChange = {props.handleChange}
			  disabled={props.isBind}
			  required
            >
              <option value="">Select</option>
              {props.getBaseData.HOROOFCOVER.map((x) => (
                <option value={x.s_AppCodeName}>
                  {x.s_AppCodeNameForDisplay}
                </option>
              ))}
	        </Form.Select>
	      </Form.Group>
	    </Col>
	    <Col xs="12" sm="12" md="6" lg="3" xl="3">
	      <Form.Group
	        className="search-quote-form-group"
	        controlId="formBasicEmail"
	      >
	        <Form.Label className="dashboard-group-label">
	          {t("Roof Deck Attachment")}{" "}
	          <span className="label-asterisk">*</span>
	        </Form.Label>
	        <Form.Select
	          className="dashboard-group-input"
	          id="select-ym-option"
			  name="s_RoofDeckAttachCode"
	          parentobject="propertyInfo"
			  title="propertyInfo"
			  value={props.quoteData.propertyInfo.s_RoofDeckAttachCode ? props.quoteData.propertyInfo.s_RoofDeckAttachCode : ''}
			  onChange = {props.handleChange}
			  disabled={props.isBind}
			  required
            >
              <option value="">Select</option>
              {props.getBaseData.HOROOFDECKATT.map((x) => (
                <option value={x.s_AppCodeName}>
                  {x.s_AppCodeNameForDisplay}
                </option>
              ))}
	        </Form.Select>
	      </Form.Group>
	    </Col>
	    <Col xs="12" sm="12" md="6" lg="3" xl="3">
	      <Form.Group
	        className="search-quote-form-group"
	        controlId="formBasicEmail"
	      >
	        <Form.Label className="dashboard-group-label">
	          {t("Roof-Wall Connection")}{" "}
	          <span className="label-asterisk">*</span>
	        </Form.Label>
	        <Form.Select
	          className="dashboard-group-input"
	          id="select-ym-option"
			  name="s_RoofWallConnectCode"
	          parentobject="propertyInfo"
			  title="propertyInfo"
			  value={props.quoteData.propertyInfo.s_RoofWallConnectCode ? props.quoteData.propertyInfo.s_RoofWallConnectCode : ''}
			  onChange = {props.handleChange}
			  disabled={props.isBind}
			  required
            >
              <option value="">Select</option>
              {props.getBaseData.HOROOFWALLCONN.map((x) => (
                <option value={x.s_AppCodeName}>
					{x.s_AppCodeNameForDisplay}
				  </option>
              ))}
	        </Form.Select>
	      </Form.Group>
	    </Col>
	    <Col xs="12" sm="12" md="6" lg="3" xl="3">
	      <Form.Group
	        className="search-quote-form-group"
	        controlId="formBasicEmail"
	      >
	        <Form.Label className="dashboard-group-label">
			 {t("Roof Shape")} <span className="label-asterisk">*</span>
	        </Form.Label>
	        <Form.Select
	          className="dashboard-group-input"
	          id="select-ym-option"
			  name="s_RoofShapeCode"
	          parentobject="propertyInfo"
			  title="propertyInfo"
			  value={props.quoteData.propertyInfo.s_RoofShapeCode ? props.quoteData.propertyInfo.s_RoofShapeCode : ''}
			  onChange = {props.handleChange}
			  disabled={props.isBind}
			  required
            >
              <option value="">Select</option>
              {props.getBaseData.PLROOFSHAPE.map((x) => (
                <option value={x.s_AppCodeName}>
					{x.s_AppCodeNameForDisplay}
				  </option>
              ))}
	        </Form.Select>
	      </Form.Group>
	    </Col>
	    <Col xs="12" sm="12" md="6" lg="3" xl="3">
	      <Form.Group
	        className="search-quote-form-group"
	        controlId="formBasicEmail"
	      >
	        <Form.Label className="dashboard-group-label">
	          {t("Opening Protection")}{" "}
	          <span className="label-asterisk">*</span>
	        </Form.Label>
	        <Form.Select
	          className="dashboard-group-input"
	          id="select-ym-option"
			  name="s_windowprotectcode"
	          parentobject="propertyInfo"
			  title="propertyInfo"
			  value={props.quoteData.propertyInfo.s_windowprotectcode ? props.quoteData.propertyInfo.s_windowprotectcode : ''}
			  onChange = {props.handleChange}
			  disabled={props.isBind}
			  required
            >
              <option value="">Select</option>
              {props.getBaseData.HOWINDOWPROTECT.map((x) => (
                <option value={x.s_AppCodeName}>
					{x.s_AppCodeNameForDisplay}
				  </option>
              ))}
	        </Form.Select>
	      </Form.Group>
	    </Col>
	    <Col xs="12" sm="12" md="6" lg="3" xl="3">
	      <Form.Group
	        className="search-quote-form-group"
	        controlId="formBasicEmail"
	      >
	        <Form.Label className="dashboard-group-label">
	          {t("Secondary Water Resistance")}
	          <span className="label-asterisk">*</span>
	        </Form.Label>
	        <Form.Select
	          className="dashboard-group-input"
	          id="select-ym-option"
			  name="s_SecWaterResCode"
	          parentobject="propertyInfo"
			  title="propertyInfo"
			  value={props.quoteData.propertyInfo.s_SecWaterResCode ? props.quoteData.propertyInfo.s_SecWaterResCode : ''}
			  onChange = {props.handleChange}
			  disabled={props.isBind}
			  required
            >
              <option value="">Select</option>
              {props.getBaseData.HOWATERRESIST.map((x) => (
                <option value={x.s_AppCodeName}>
					{x.s_AppCodeNameForDisplay}
				  </option>
              ))}
	        </Form.Select>
	      </Form.Group>
	    </Col>
	    <Col xs="12" sm="12" md="6" lg="3" xl="3">
	      <Form.Group
	        className="search-quote-form-group"
	        controlId="formBasicEmail"
	      >
	        <Form.Label className="dashboard-group-label">
	          {t("Terrain")}<span className="label-asterisk">*</span>
	        </Form.Label>
	        <Form.Select
	          className="dashboard-group-input"
	          id="select-ym-option"
			  name="s_HomeTerrainExpCode"
	          parentobject="propertyInfo"
			  title="propertyInfo"
			  value={props.quoteData.propertyInfo.s_HomeTerrainExpCode ? props.quoteData.propertyInfo.s_HomeTerrainExpCode : ''}
			  onChange = {props.handleChange}
			  disabled={props.isBind}
			  required
            >
              <option value="">Select</option>
              {props.getBaseData.TERRAINDD.map((x) => (
                <option value={x.value}>
                  {x.label}
                </option>
              ))}
	        </Form.Select>
	      </Form.Group>
	    </Col>
	    <Col xs="12" sm="12" md="6" lg="3" xl="3">
	      <Form.Group
	        className="search-quote-form-group"
	        controlId="formBasicEmail"
	      >
	        <Form.Label className="dashboard-group-label">
	          {t("Wind Speed")} <span className="label-asterisk">*</span>
	        </Form.Label>
	        <Form.Select
	          className="dashboard-group-input"
	          id="select-ym-option"
			  name="s_FBC_WindSpeed"
	          parentobject="propertyInfo"
			  title="propertyInfo"
			  value={props.quoteData.propertyInfo.s_FBC_WindSpeed ? props.quoteData.propertyInfo.s_FBC_WindSpeed : ''}
			  onChange = {props.handleChange}
			  disabled={props.isBind}
			  required
            >
              <option value="">Select</option>
              {props.getBaseData.FBCWINDSPEED.map((x) => (
                <option value={x.value}>
                  {x.label}
                </option>
              ))}
	        </Form.Select>
	      </Form.Group>
	    </Col>
	  </Row>
	);
	
};
  function mapStateToProps(state) {
	return {
	  	quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	};
  } 
  export default connect(mapStateToProps)(WindMitigation);