import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Form, Button, Tabs, Tab, Modal, Alert } from "react-bootstrap";
import { FaEdit, FaSearch } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import QuoteTab from "./QuoteTab";
import ApplicationTab from "./ApplicationTab";
import SubmitTab from "./SubmitTab";
import ActivityTab from "./ActivityTab";
import AsyncSelect from 'react-select/async';
import { useParams } from 'react-router-dom';
import { getQuoteData,getEmptyQuoteData,getBaseQuoteData,getEditEmptyQuoteData, agencyList, agentList, getAgencyDetails,getDocInfoSubmit} from "../../../services/quoteService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { useDispatch, useSelector , connect } from 'react-redux';
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  sizePerPageRenderer,
  pageButtonRenderer,
} from "../../../components/CustomTablePagination";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../../management-reports/management-reports.scss";
import Moment from 'moment';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { set } from "lodash";

const SearchQuoteTab = (props) => {
	const { t } = useTranslation();

	const [date1, setDate1] = useState(new Date());
	const [date2, setDate2] = useState(new Date());
	const [key, setKey] = useState("Quote");
	const dispatch = useDispatch();
	const [loader, setLoader] = useState(false);
	const [quoteData, setQuoteData] = useState(props.quoteData);
	const [documentData, setDocumentData] = useState([]);
  const [activityLogFlag, setActivityLogFlag] = useState('');
	//Agency 
	const [selectedAgency, setSelectedAgency] = useState(null);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [agencyId, setAgencyId] = useState(null);
    const [agentId, setAgentId] = useState(null);
    const [agencyDD, setAgencyDD] = useState([]);
    const [agentDD, setAgentDD] = useState([]);
    const [isChange, setIsChange] = useState(false);
	
	const [agencyEmail, setAgencyEmail] = useState();
	const [agencyAddr, setAgencyAddr] = useState();
	const [agencyPhone, setAgencyPhone] = useState();
	
	let params = useParams();
  

  useEffect(() => {
    var tempdate = quoteData;
    if(Object.keys(tempdate).length>0 && (tempdate.transactionInfo.n_potransaction_PK == "" || tempdate.transactionInfo.n_potransaction_PK == null)){
      var date = new Date();
      date.setDate(date.getDate() + 1);
      tempdate.transactionInfo.d_TransEffectiveFrom = Moment(date).format("YYYY-MM-DD");
      tempdate.transactionInfo.d_TransEffectiveTo = Moment(date).add(1, 'year').format("YYYY-MM-DD");
    }
    dispatch(getEditEmptyQuoteData(tempdate));
  }, [quoteData]);

useEffect(() => {
	const userAgency = quoteData.selectedAgency;
	const userAgent = quoteData.selectedAgent;
	const agencyData = quoteData.agencyData;
	
	if (Object.keys(userAgency).length > 0) {
		setAgencyDD(userAgency);
		setSelectedAgency(userAgency);
		setAgencyId(userAgency[0].NPN || 0);
		
		
	}
	if (Object.keys(userAgent).length > 0) {
		setAgentDD(userAgent);
		setSelectedAgent(userAgent);
		setAgentId(userAgent[0].NPN || 0);
	}
	
	if (Object.keys(agencyData).length > 0) {
		setAgencyEmail(agencyData.getAgencyEmail);
		setAgencyAddr(agencyData.getAgencyaddr);
		setAgencyPhone(agencyData.getAgencyPhone);
	}
}, []);

  const setValidation = (e) => {
    if(e == 'Submit' || e == "Activity-log"){
      const transpk = quoteData.transactionInfo.n_potransaction_PK;
      if(transpk == null || transpk == 0 ){
        toast.error("Please Save Application First");
        e.preventDefault();
      }else{
        setKey(e);
      }
    }else{
      setKey(e);
    }
  }

useEffect(() => {
  if(key == 'Submit' ){
    const transpk = quoteData.transactionInfo.n_potransaction_PK;
    if(transpk != null){
      getDocInfoList(transpk);
    }
  }
  if(key == 'Activity-log'){
    setActivityLogFlag('VIEW');
  }
}, [key]);

const getDocInfoList = async(transpk)  => {
  dispatch(getDocInfoSubmit(transpk)).then((res) => {
    setDocumentData(res);
  });
}


const handleChange = (e) => {
	const { name, value , title, attributes } = e.target; 
  // console.log("attributes 123", attributes);
  // if(name == "n_YearBuilt" && title == "propertyInfo"){
  //   if(attributes.alt != undefined){
  //     if(attributes.alt.value == "number"){
  //       const reg = /^[0-9\b]+$/
  //       let num=e.target.value === '' || reg.test(e.target.value)
  //       if(name == "n_YearBuilt" && title == "propertyInfo"){
  //         quoteDataVar['propertyInfo']['n_YearBuilt'] = value
  //       }
  //       e.target.value = num.substring(0,(num.length-1))
  //     }
  //   }
  // }
	//const tempArr = quoteData;
	var quoteDataVar = props.quoteData;
	const tempArr = props.quoteData;
  	tempArr[title][name] = value;
  	var key = quoteDataVar[title][name];
    if(name == "s_FirstName" && title == "personInfo"){
      quoteDataVar['personInfo']['s_FullLegalName'] = value + '' + tempArr['personInfo']['s_LastOrganizationName'];
    }
    if(name == "s_LastOrganizationName" && title == "personInfo"){
      quoteDataVar['personInfo']['s_FullLegalName'] = tempArr['personInfo']['s_FirstName'] + '' + value;
    }
    if(name == "noOfResident" && title == "propertyInfo"){
      const d = new Date();
      let year = d.getFullYear()
      quoteDataVar['propertyInfo']['s_RoofAge'] = (year - value);
      quoteDataVar['riskRenovationTypeData']['roofInfo']['s_RenovationYear'] = value;
      quoteDataVar['riskRenovationTypeData']['roofInfo']['s_RenovationTypeCode'] = "FULL";
    }
    // if(attributes.alt != undefined){
    //   if(attributes.alt.value == "number"){
    //     const reg = /^[0-9\b]+$/
    //     let num=e.target.value
    //     if (e.target.value === '' || reg.test(e.target.value)) {
    //       e.target.value = num.substring(0,(num.length-1))
    //     }
       
    //   }
    // }
  	setQuoteData({...quoteDataVar, key: value});	

}

// For All Radio Button Handler
const handleRadioChange = (e) => {
    const { name, value, id } = e.target;
	const quoteDataVar = props.quoteData;
  	const tempArr = quoteDataVar;
  	tempArr[id][name] = value;
    var key = quoteDataVar[id][name];
  	setQuoteData({...quoteDataVar, key: value});	
	  
  }
  
  // For All Radio Button Handler
const handleCheckboxChange = (e) => {
	
	const { name, value, id,attributes, checked } = e.target;
	var parentObjectName = attributes.parentobject.value;
	if(checked == true){
		  var fieldValue = attributes.checkedvalue.value;
	}else {
		var fieldValue = attributes.uncheckedvalue.value;
	}
	const quoteDataVar = props.quoteData;
    quoteDataVar[parentObjectName][name] = fieldValue;
  	setQuoteData({...quoteDataVar,quoteDataVar});
	  
  }


  const [agencyModal, setAgencyModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);

  const { SearchBar } = Search;
  const productsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        agency_code: 16000 + i,
        name: "USi Insurance Service LCC",
        city: "POLICY- NEW BUSINESS",
        zip: "TMP220304712662",
        county: `HILLSBOROUG${i}`,
      });
    }
    return items;
  };

  const products = productsGenerator(100);

  const columns = [
    {
      dataField: "agency_code",
      text: "Agency Code",

      formatter: (value, row) => (
        <Link className="management-reports-link" to={"/"}>
          {value}
        </Link>
      ),
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "city",
      text: "City",
    },
    {
      dataField: "zip",
      text: "Zip",
    },
    {
      dataField: "county",
      text: "County",
      sort: true,
    },
  ];

  const options = {
    sizePerPageRenderer,
    pageButtonRenderer,
    showTotal: true,
    alwaysShowAllBtns: true,
  };
  
  
  const handleChangeAsyncSelect = (value, state) => {
	var NPN = 0;
	if (![null, ''].includes(value.NPN)) {
		NPN = value.NPN;
	}
	switch (state) {
		case 'Agency':
			setSelectedAgency(value);
			setAgencyId(NPN);
			loadAgentOptions(value.value);
			dispatch(getAgencyDetails(value.value))
				.then((res) => {

					setAgencyEmail(res.getAgencyEmail);
					setAgencyAddr(res.getAgencyaddr);
					setAgencyPhone(res.getAgencyPhone);
				})
			break;
		case 'Agent':
			setSelectedAgent(value);
			setAgentId(NPN);
			break;
		default:
			break;
	}
	setIsChange(true);
}


const loadAgencyOptions = (inputValue, callback) => {
	if (inputValue.length > 3) {
		setLoader(true);
		var request = {
			'searchParams': inputValue
		}
		dispatch(agencyList(request))
			.then((res) => {
				setAgencyDD(res);
				callback(filterData(res, inputValue, 'Agency'));
				setLoader(false);
			})
			.catch(() => toast.error(t('Something Went Wrong!')))
	} else {
		callback(filterData(agencyDD, inputValue, 'Agency'));
	}
}

const filterData = (result, inputValue = '', from) => {
	var dd = [];
	if (from === 'Agency') {
		dd = result.filter(i =>
			i.label.toLowerCase().includes(inputValue.toLowerCase())
		);
	}
	if (from === 'Agent') {
		dd = result.filter(i =>
			i.label.toLowerCase().includes(inputValue.toLowerCase())
		);
	}
	return dd;
};

const filterAgentOptions = (value, callback) => {
	callback(filterData(agentDD, value, 'Agent'));
}

const loadAgentOptions = (value) => {
	setLoader(true);
	
	dispatch(agentList(value, 13))
	.then((res) => {
			setAgentDD(res);
			var agent = [], NPN = 0;
			if (res.length > 0) {
				agent = res[0];
				if (![null, ''].includes(res[0].NPN)) {
					NPN = res[0].NPN;
				}
				setSelectedAgent(agent);
				setAgentId(NPN);
				setIsChange(true);
			}
			setLoader(false);
		})
		.catch(() => toast.error(t('Something Went Wrong!')))
}

useEffect(() => {
	if (isChange === true) {
		var quoteDataVar = props.quoteData;
		
		quoteDataVar['selectedAgency'][0] = selectedAgency;
        quoteDataVar['selectedAgent'][0] = selectedAgent;
		
        quoteDataVar['policyInfo']['n_AgencyPeson_FK'] = selectedAgency.value;
        quoteDataVar['policyInfo']['n_SubAgentPersoninfo_FK'] = selectedAgent.value;
		dispatch(getEditEmptyQuoteData(quoteDataVar));
		setIsChange(!isChange);
	}
}, [isChange])

  
  return (

    <Fragment>
      <Form validated={props.ValidationError}>
      <div className="custom-tab-header-fill">
        <span>{t("Policy Details")}</span>
      </div>
	  <LoadingOverlay >
      	<Loader loading={loader} />
	    {
      <div className="custom-tab-body">
        <Row className="align-items-baseline">
          <Col xs="12" sm="12" md="12" lg="2" xl="2">
            <Form.Group
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <Form.Label className="dashboard-group-label">
                {t("Quote No")}
              </Form.Label>
              <Form.Control
                className="dashboard-group-input"
                type="text"
                placeholder=""
				value={quoteData.transactionInfo.s_PolicyQuoteNo ? quoteData.transactionInfo.s_PolicyQuoteNo : ''}
				name="s_PolicyQuoteNo"
                title="transactionInfo"
				onChange = {handleChange}
				disabled
				

              />
            </Form.Group>
          </Col>
          <Col xs="12" sm="12" md="12" lg="2" xl="2">
            <Form.Group
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <Form.Label className="dashboard-group-label">
                {t("Effective Date")}
              </Form.Label>
              <Form.Control
				  className="dashboard-group-input"
				  type="date"
				  value={quoteData.transactionInfo.d_TransEffectiveFrom ? quoteData.transactionInfo.d_TransEffectiveFrom : ''}
          disabled={props.isBind}
				  name="d_TransEffectiveFrom"
		          title="transactionInfo"
				  onChange = {props.handleChange}
				/>
            </Form.Group>
          </Col>
          <Col xs="12" sm="12" md="12" lg="2" xl="2">
            <Form.Group
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <Form.Label className="dashboard-group-label">
                {t("Effective To")}
              </Form.Label>
              <Form.Control
				  className="dashboard-group-input"
				  type="date"
				  value={quoteData.transactionInfo.d_TransEffectiveTo ? quoteData.transactionInfo.d_TransEffectiveTo : ''}
				  name="d_TransEffectiveTo"
		          title="transactionInfo"
				  readOnly
				/>
            </Form.Group>
          </Col>
          <Col xs="12" sm="12" md="12" lg="2" xl="2">
            <Form.Group
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <Form.Label className="dashboard-group-label">
                {t("Binder Date")}
              </Form.Label>
              <Form.Control
                className="dashboard-group-input"
                type="date"
                placeholder=""
				value={quoteData.policyInfo.d_BinderDate ? quoteData.policyInfo.d_BinderDate : ''}
				readOnly
              />
            </Form.Group>
          </Col>
          <Col xs="12" sm="12" md="12" lg="2" xl="2">
            <Form.Group
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <Form.Label className="dashboard-group-label">
                Estm Prem
              </Form.Label>
              <Form.Control
                className="dashboard-group-input"
                type="text"
                placeholder=""
				value={quoteData.premiumData && quoteData.premiumData.FinalPremium ? quoteData.premiumData.FinalPremium : ''}
				name="FinalPremium"
                title="transactionInfo"
				onChange = {handleChange}
				readOnly
              />
            </Form.Group>
          </Col>
          <Col xs="12" sm="12" md="12" lg="2" xl="2">
            <div className="flex-start-class">
              <Form.Group
                className="search-quote-form-group"
                id="search-quote-uw"
                controlId="formBasicEmail"
              >
                <Form.Label className="dashboard-group-label">
                  {t("UW. App. Status")}
                </Form.Label>
                <Form.Select
                  className="dashboard-group-input"
                  id="select-ym-option"
                  disabled
                >
                  <option>open</option>
                  <option>closed</option>
                </Form.Select>
              </Form.Group>
              {props.quoteData.transactionInfo.n_potransaction_PK != null ?
                <FaEdit
                  size={25}
                  color={"#64D6CD"}
                  style={{ cursor: "pointer" }}
                  onClick={() => setStatusModal(true)}
                /> 
                : ""
              }
            </div>
          </Col>
		</Row>
		{quoteData.policyInfo.d_BinderDate ? 
		<Row className="align-items-baseline">
			<Col xs="12" sm="12" md="12" lg="2" xl="2">
	            <Form.Group
	              className="search-quote-form-group"
	              controlId="formBasicEmail"
	            >
              <Form.Label className="dashboard-group-label">
                {t("Policy No")}
              </Form.Label>
              <Form.Control
                className="dashboard-group-input"
                type="text"
                placeholder=""
				value={quoteData.policyInfo.Policy_No ? quoteData.policyInfo.Policy_No : ''}
				name="Policy_No"
                title="policyInfo"
				
				readOnly

              />
            </Form.Group>
          </Col>
		</Row>
		 : null}
		
		<Row className="align-items-baseline">
          <Col xs="12" sm="12" md="12" lg="6" xl="6">
            <div className="justify-start-class">
              <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
                style={{ width: "100%" }}
              >
                <Form.Label className="dashboard-group-label">
                  {t("Agency Name")}
                </Form.Label>
				<AsyncSelect
                    cacheOptions
                    name='agency_id'
                    id="select-ym-option"
                    loadOptions={loadAgencyOptions}
                    defaultOptions={agencyDD}
                    value={selectedAgency}
                    onChange={(selectedValue) => handleChangeAsyncSelect(selectedValue, 'Agency')}
                    className="dashboard-group-input"
                    required
                />
                {/*<Form.Select
                  className="dashboard-group-input"
                  id="select-ym-option"
				  name="s_ConstructionType"
                  value={quoteData.agencyInfo && quoteData.agencyInfo[0].agencyPersonPK ? quoteData.agencyInfo[0].agencyPersonPK : ''}
                  title="propertyInfo"
                //  onChange={props.handleChange}
                disabled={props.isBind}
                  required
                >
                  <option value="">Select</option>
	                  {props.getBaseData.CONSTRTYPE.map((x) => (
	                    <option value={x.s_AppCodeName}>
	                      {x.s_AppCodeNameForDisplay}
	                    </option>
	                  ))}
                </Form.Select>*/}
              </Form.Group>
              {/*<Button
                id="common-btn"
                variant="primary"
                style={{
                  whiteSpace: "nowrap",
                  padding: "8px",
                  marginLeft: "12px",
                }}
                onClick={() => setAgencyModal(true)}
                disabled={props.isBind}
              >
                <FaSearch size={15} style={{ marginRight: "10px" }} />
                Search Agency
              </Button>{" "}*/}
            </div>
          </Col>

          <Col xs="12" sm="12" md="12" lg="4" xl="4">
            <Form.Group
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <Form.Label className="dashboard-group-label">
                {t("Producer Name")}
              </Form.Label>
			  <AsyncSelect
                    cacheOptions
                    name='agent_id'
                    id="select-ym-option"
                    loadOptions={filterAgentOptions}
                    // defaultOptions={agentDD}
                    value={selectedAgent}
                    onChange={(selectedValue) => handleChangeAsyncSelect(selectedValue, 'Agent')}
                    className="dashboard-group-input"
                    required
                />
              {/*<Form.Select
                className="dashboard-group-input"
                id="select-ym-option"
				value={props.s_ConstructionType}
        disabled={props.isBind}
              >
                <option value="">Select</option>
                  {props.getBaseData.CONSTRTYPE.map((x) => (
                    <option value={x.s_AppCodeName}>
                      {x.s_AppCodeNameForDisplay}
                    </option>
                  ))}
              </Form.Select>*/}
            </Form.Group>
          </Col>
          <Col xs="12" sm="12" md="12" lg="2" xl="2">
            <Form.Group
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <Form.Label className="dashboard-group-label">
                {t("Phone No")}<span className="label-asterisk">*</span>
              </Form.Label>
              <Form.Control
                className="dashboard-group-input"
                type="text"
                placeholder="(111) 111-1111"
				value={agencyPhone ? agencyPhone : ''}
				//name="FinalPremium"
                //title="transactionInfo"
				//onChange = {handleChange}
				readOnly
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="12" lg="6" xl="6">
            <span className="search-quote-title">
              {t("Email Address")} :{" "}
              <span className="search-quote-value">
                {agencyEmail ? agencyEmail : ''}
              </span>
            </span>
          </Col>
          <Col xs="12" sm="12" md="12" lg="6" xl="6">
            <span className="search-quote-title">
              {t("Agency Address")} :
              <span className="search-quote-value">
			  	{agencyAddr ? agencyAddr : ''}
              </span>
            </span>
          </Col>
        </Row>
      </div>
	  }
	  </LoadingOverlay>
      <Row>
        { props.isBind == true ? 
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          style={{ padding: 0, marginTop: "20px" }}
        >
        
          <Alert variant="warning">
            Application Submitted: UW will review for issuance.
          </Alert>
        </Col>
        : ""}
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          style={{ padding: 0, marginTop: "10px" }}
        >
          <Tabs
            defaultActiveKey="Quote"
            id="uncontrolled-tab-example"
            activeKey={key}
            onSelect={(k) => setValidation(k)}
          >
            <Tab eventKey="Quote" title={t("Quote")}>
              <QuoteTab setDefaultData={props.setDefaultData} setKey={setKey} handleChange={handleChange} handleRadioChange={handleRadioChange} handleCheckboxChange={handleCheckboxChange} getZipCodeDetails={props.getZipCodeDetails} isBind={props.isBind} rateSubmit={props.rateSubmit} transId={quoteData.transactionInfo.n_potransaction_PK} callGeneratePdf={props.callGeneratePdf} rateLoader={props.rateLoader}/>
            </Tab>
            <Tab eventKey="Applicant" title={t("Applicant")}>
              <ApplicationTab setKey={setKey} handleChange={handleChange}  isBind={props.isBind} rateSubmit={props.rateSubmit} transId={quoteData.transactionInfo.n_potransaction_PK} callGeneratePdf={props.callGeneratePdf} rateLoader={props.rateLoader}/>
            </Tab>
            <Tab eventKey="Submit" title={t("Submit")}>
              <SubmitTab setKey={setKey} documentData={documentData} transId={quoteData.transactionInfo.n_potransaction_PK} handleSubmitBound={props.handleSubmitBound} isBind={props.isBind} callGeneratePdf={props.callGeneratePdf} rateLoader={props.rateLoader}/>
            </Tab>
            <Tab eventKey="Activity-log" title={t("Activity-log")}>
              <ActivityTab setKey={setKey} flag={activityLogFlag} sourceCode={quoteData.policyInfo.Policy_No ? quoteData.policyInfo.Policy_No : ''} transId={quoteData.transactionInfo.n_potransaction_PK} isBind={props.isBind} callGeneratePdf={props.callGeneratePdf} rateLoader={props.rateLoader}/>

            </Tab>
          </Tabs>
        </Col>
      </Row>

      {/* search agency modal */}
      <Modal
        className="custom-dashboard-modal"
        size="xl"
        show={agencyModal}
        onHide={() => setAgencyModal(false)}
      >
        <Modal.Header closeButton className="custom-dashboard-modal-header">
          <Modal.Title>Agency Search</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="custom-dashboard-modal-body"
          style={{ padding: 0 }}
        >
          <Row>
            <Col xs="12" id="search-quote-tab-all-filters">
              <Row className="align-items-baseline">
                <Col xs="12" sm="12" md="6" lg="4" xl="4">
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Agency Code
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="number"
                      placeholder="Type here"
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="4" xl="4">
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Zip
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="number"
                      placeholder="Type here"
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="4" xl="4">
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Principal Name
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Type here"
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="4" xl="4">
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Agency Name
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Type here"
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="4" xl="4">
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      County
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Type here"
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="4" xl="4">
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Manager Name
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Type here"
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="4" xl="4">
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      FEIN Number
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="number"
                      placeholder="Type here"
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="4" xl="4">
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      State
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Type here"
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="4" xl="4">
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      DBA Name
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Type here"
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="4" xl="4">
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      City
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Type here"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xs="12" style={{ padding: 0 }}>
              <div id="search-agency-modal-table">
                <ToolkitProvider
                  keyField="agency_code"
                  data={products}
                  columns={columns}
                  bootstrap4
                  search
                >
                  {(props) => (
                    <div className="search-agency-table-searchbar">
                      <SearchBar {...props.searchProps} />
                      <div className="management-reports-table">
                        <BootstrapTable
                          wrapperClasses="management-functional-table table-responsive"
                          hover
                          bordered={false}
                          pagination={paginationFactory(options)}
                          selectRow={{ mode: "checkbox" }}
                          tabIndexCell
                          noDataIndication={
                            <div className="center-div-class">No data</div>
                          }
                          {...props.baseProps}
                        />
                      </div>
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* status modal */}
      <Modal
        className="custom-dashboard-modal"
        size="md"
        show={statusModal}
        onHide={() => setStatusModal(false)}
      >
        <Modal.Header closeButton className="custom-dashboard-modal-header">
          <Modal.Title>App UW. Status Information</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-dashboard-modal-body">
          <Row style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <Form.Label className="dashboard-group-label">
                  Select Reason
                </Form.Label>
                <Form.Select
                  className="dashboard-group-input"
                  id="select-ym-option"
                >
                  <option>App UW. Status Information</option>
                  <option>Open</option>
                  <option>Closed</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <Form.Label className="dashboard-group-label">Note</Form.Label>
                <Form.Control
                  className="dashboard-group-input"
                  as="textarea"
                  placeholder="Type here"
                />
              </Form.Group>
            </Col>
            <Col xs="12">
              <div className="d-grid gap-2">
                <Button
                  id="common-btn"
                  variant="primary"
                  style={{ padding: "12px 42px" }}
                >
                  Update Status
                </Button>
                <Button
                  onClick={() => setStatusModal(false)}
                  id="common-cancel-btn"
                  variant="primary"
                  style={{ padding: "12px 42px" }}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      </Form>
    </Fragment>
  );
	
};



function mapStateToProps(state) {
	return {
    	quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	  
	};
  } 
  export default connect(mapStateToProps)(SearchQuoteTab);

