import React, { useState, useEffect } from 'react';
import ZipDetailsView from '../Modal/ZipDetailsView';
import { Row, Col, Form, Modal } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';
import { customStyles } from "../../../../../components/reactSelectCustomStyle";
import { toast } from 'react-toastify';
import { getEditEmptyQuoteData, getZipDetails } from '../../../../../services/floodService';
import { useTranslation } from 'react-i18next';
import '../../quote/quote.scss';

const MailingAddress = ({ emptyQuoteData, handleChange, isBind }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [streetName, setStreetName] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [countryName, setCountyName] = useState('');
    const [stateName, setStateName] = useState('');
    const [cityName, setCityName] = useState('');
    const [multipleCity, setMultipleCity] = useState(null);
    const [googlePlace, setGooglePlace] = useState('');
    const [isGPUpdate, setIsGPUpdate] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (Object.keys(emptyQuoteData).length > 0) {
            if (emptyQuoteData.personAddrMail) {
                // Address
                setStreetName(emptyQuoteData.personAddrMail.s_StreetName || '');
                // create object as per the google place autocomplete require using same value as label & value
                // setGooglePlace({ label: emptyQuoteData.personAddrMail.s_StreetName || '', value: emptyQuoteData.personAddrMail.s_StreetName || '' });
                setCountyName(emptyQuoteData.personAddrMail.s_CountyName || '');
                setStateName(emptyQuoteData.personAddrMail.s_StateName || '');
                setCityName(emptyQuoteData.personAddrMail.s_CityName || '');
                setZipCode(emptyQuoteData.personAddrMail.s_PostalCode || '');
            }
        }
    }, [emptyQuoteData])

    // trigger on update streetName
    useEffect(() => {
        if (streetName && isGPUpdate) {
            geocodeByAddress(streetName)
                .then(addr => {
                    addr && addr[0].address_components.forEach(e => {
                        if (e.types.includes('postal_code')) {
                            setZipCode(e.short_name);
                        }
                    });
                });
        }
    }, [streetName])

    // trigger on update zipCode
    useEffect(() => {
        if (zipCode && isGPUpdate) {
            getDataFromZip(zipCode);
            setIsGPUpdate(false);
        }
    }, [zipCode])

    /**
     * Handle Google Place Autocomplete
     * @param {element values} e 
     */
    const handleGooglePlace = (e) => {
        setGooglePlace(e); // setting element value to google place autocomplete
        setStreetName(e.value.structured_formatting.main_text); // setting only address line 1 as streen name
        setIsGPUpdate(true); // to trigger dependent function
    }

    // trigger to set selected address street name
    const handleGooglAutoComplete = (val, status) => {
        if (val) {
            if (status.action == "input-change") {
                setStreetName(val);
            } else if (["set-value", "menu-close", "input-blur", "menu-close"].includes(status.action)) {
                setStreetName(streetName.toUpperCase());
            }
        }
    }

    // get zip details from address
    const getDataFromZip = (zipCodeVal) => {
        if (streetName === '') {
            toast.error('Please Enter Street Name!');
            setZipCode('')
            return false;
        }
        if (zipCodeVal !== '') {
            handleMailingAddressChange('s_StreetName', streetName);
            handleMailingAddressChange('s_PostalCode', zipCodeVal);
            dispatch(getZipDetails('10', zipCodeVal, 'APPLICANT'))
                .then((res) => {
                    if (res.success === true) {
                        var data = res.data;
                        if (data.status === 'Y') {
                            if (data.count === 1) {
                                setZipDetailsData(data.zipData[0]);
                            } else {
                                showMultipleCityModal(data.zipData);
                            }
                        } else {
                            toast.error(res.errorMsg);
                        }
                    }
                });
        } else {
            toast.error('Zip Code should not be empty!');
        }
    }

    // open Zip Modal
    const showMultipleCityModal = (zipData) => {
        setMultipleCity(<ZipDetailsView zipData={zipData} setZipDetailsData={setZipDetailsData} />)
        setShow(true);
    }

    // get selected zip data
    const setZipDetailsData = (zipData) => {
        var addressPK = {
            'n_CityId_FK': zipData.n_CityId_PK,
            's_ZipCounty': zipData.s_ZipCounty.toUpperCase(),
            'n_CountyId_FK': zipData.n_CountyId_FK,
            's_StateCode': zipData.s_StateCode.toUpperCase(),
            's_StateName': zipData.s_StateCode.toUpperCase(),
            'n_StateId_PK': zipData.n_StateId_PK,
            's_CityName': zipData.s_CityName.toUpperCase(),
            'n_CityId_PK': zipData.n_CityId_PK,
            'n_CountryId_FK': zipData.n_CountryId_FK,
            'n_Zipcodes_PK': zipData.n_Zipcodes_PK,
            's_CountyName': zipData.s_ZipCounty.toUpperCase(),
        };
        setCountyName(zipData.s_ZipCounty.toUpperCase());
        setStateName(zipData.s_StateCode.toUpperCase());
        setCityName(zipData.s_CityName.toUpperCase());
        handleMailingAddressChange('', addressPK);
        setMultipleCity(null);
        setShow(false);
    }

    // to update value in reducer
    const handleMailingAddressChange = (key, value) => {
        var quoteDataVar = emptyQuoteData;
        if (key.length === 0) {
            for (const [key, val] of Object.entries(value)) {
                quoteDataVar['personAddrMail'][key] = val;
            }
        } else {
            if (key === 's_StreetName') {
                quoteDataVar['personAddrMail']['s_AddressLine1'] = value;
            }
            quoteDataVar['personAddrMail'][key] = value;
        }
        quoteDataVar['personAddrMail']['s_AddressTypeCode'] = "MAILING";
        dispatch(getEditEmptyQuoteData(quoteDataVar));
    }

    // Inserting style only for address field
    useEffect(() => {
        document.head.insertAdjacentHTML("beforeend", `<style>#react-select-3-input { opacity: 2 !important; width: 250px !important;}</style>`)
    }, []);

    return (
        <>
            <hr />
            <Row className="mt-2">
                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Mailing Address')}
                        </Form.Label>
                        {
                            isBind ? (
                                <Form.Control
                                    className="dashboard-group-input-flood"
                                    type="text"
                                    placeholder={t("Mailing Address")}
                                    title={t('Mailing Address')}
                                    value={streetName}
                                    disabled={isBind}
                                />
                            ) : (
                                <GooglePlacesAutocomplete
                                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                    selectProps={{
                                        styles: customStyles,
                                        inputValue: streetName,
                                        value: googlePlace,
                                        onInputChange: handleGooglAutoComplete,
                                        onChange: handleGooglePlace,
                                        name: "googlePlacesStreetName",
                                        id: "googlePlacesStreetName",
                                        placeholder: "Enter a location",
                                        isDisabled: isBind
                                    }}
                                />
                            )
                        }
                    </Form.Group>
                </Col>

                <Col xs="12" sm="12" md="6" lg="1" xl="1">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Zip')}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t("Zip")}
                            name="s_PostalCode"
                            title={t('Zip')}
                            parentobject="personAddrMail"
                            value={zipCode}
                            onChange={(e) => {
                                handleChange(e);
                                setZipCode(e.currentTarget.value);
                            }}
                            required
                            autoComplete="off"
                            disabled={isBind}
                        />
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t("County")}
                        </Form.Label>
                        <Form.Control
                            className="dashboard-group-input-flood"
                            type="text"
                            placeholder={t("County")}
                            name="s_CountyName"
                            title={t('County')}
                            parentobject="personAddrMail"
                            value={countryName}
                            readOnly
                        />
                    </Form.Group>
                </Col>

                <Col xs="12" sm="12" md="6" lg="1" xl="1">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('State')}
                        </Form.Label>
                        <Form.Control
                            className="dashboard-group-input-flood"
                            type="text"
                            placeholder={t("State")}
                            name="s_StateName"
                            title={t('State')}
                            parentobject="personAddrMail"
                            value={stateName}
                            readOnly
                        />
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t("City")}
                        </Form.Label>
                        <Form.Control
                            className="dashboard-group-input-flood"
                            type="text"
                            placeholder={t("City")}
                            name="s_CityName"
                            title={t('City')}
                            parentobject="personAddrMail"
                            value={cityName}
                            readOnly
                        />
                    </Form.Group>
                </Col>
            </Row>
            {/* Zip Details Modal */}
            <Modal size="lg" show={show} className="zip-details-modal">
                <Modal.Body className="zip-details-modal-body">
                    {multipleCity}
                </Modal.Body>
            </Modal>
        </>
    );
}



export default MailingAddress;