import * as ActionTypes from '../action-types';

const initialState = {
  apiLoading: false,
  ZDDetails: {},
  emptyQuoteData: {},
  initApiObject: {},
  quoteResponseDetails: {},
  basicCvg: {},
  recommendedCvg: {},
  basicCvgResponse: {},
  recommendedCvgResponse: {},
  customCvg: {},
  customCvgResp: {},
  whichSection: 'DEFAULT',
  lastSection: 1,
  deductible: {},
  createQuoteResponse: {},
  initPaymentObject: {},
  propertyDataFromAdd: {},
  propertyDataAutocompleteStored: {},
  floodSystemDD: {}
}

// Overwrite Redux Store
const getZDDetails = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    ZDDetails: payload,
  });
  return stateObj;
}

// Overwrite Redux Store
const getQuoteResponseDetails = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    quoteResponseDetails: payload,
  });
  return stateObj;
}

// Overwrite Redux Store
const getBasicCvgDetails = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    basicCvg: payload,
  });
  return stateObj;
}

// Overwrite Redux Store
const getRecommendedCvgDetails = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    recommendedCvg: payload,
  });
  return stateObj;
}

// Overwrite Redux Store
const getBasicCvgResponse = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    basicCvgResponse: payload,
  });
  return stateObj;
}

// Overwrite Redux Store
const getRecommendedCvgResponse = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    recommendedCvgResponse: payload,
  });
  return stateObj;
}

// Overwrite Redux Store
const getCustomCvgDetails = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    customCvg: payload,
  });
  return stateObj;
}

// Overwrite Redux Store
const getCustomCvgResponse = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    customCvgResp: payload,
  });
  return stateObj;
}

const getEmptyQuoteData = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    emptyQuoteData: payload,
  });
  return stateObj;
};

const getPropertyDataFromAddress = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    propertyDataFromAdd: payload.data,
  });
  return stateObj;
};

const getPropertyDataAutocompleteStore = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    propertyDataAutocompleteStored: payload,
  });
  return stateObj;
};

const Flood = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.IS_LOADING:
      return Object.assign({}, state, { apiLoading: action.payload });
    case ActionTypes.GET_PROPERTY_DATA_FROM_ADDRESS:
      return getPropertyDataFromAddress(state, action.payload);
    case ActionTypes.GET_PROPERTY_AUTOCOMPLETE_STORED:
      return getPropertyDataAutocompleteStore(state, action.payload);
    case ActionTypes.GET_EMPTY_QUOTE_DATA:
      return getEmptyQuoteData(state, action.payload);
    case ActionTypes.GET_INIT_QUOTE_API:
      return Object.assign({}, state, { initApiObject: action.payload });
    case ActionTypes.GET_ZD_DETAILS:
      return getZDDetails(state, action.payload);
    case ActionTypes.GET_QUOTE_RESPONSE_DETAILS:
      return getQuoteResponseDetails(state, action.payload);
    case ActionTypes.GET_BASIC_COVERAGE_DETAILS:
      return getBasicCvgDetails(state, action.payload);
    case ActionTypes.GET_RECOMMENDED_COVERAGE_DETAILS:
      return getRecommendedCvgDetails(state, action.payload);
    case ActionTypes.GET_BASIC_COVERAGE_RESPONSE:
      return getBasicCvgResponse(state, action.payload);
    case ActionTypes.GET_RECOMMENDED_COVERAGE_RESPONSE:
      return getRecommendedCvgResponse(state, action.payload);
    case ActionTypes.GET_CUSTOM_COVERAGE_DETAILS:
      return getCustomCvgDetails(state, action.payload);
    case ActionTypes.GET_CUSTOM_COVERAGE_RESPONSE:
      return getCustomCvgResponse(state, action.payload);
    case ActionTypes.GET_WHICH_SECTION:
      return Object.assign({}, state, { whichSection: action.payload });
    case ActionTypes.GET_LAST_SECTION:
      return Object.assign({}, state, { lastSection: action.payload });
    case ActionTypes.GET_DEDUCTIBLE_OPTIONS:
      return Object.assign({}, state, { deductible: action.payload });
    case ActionTypes.GET_CREATE_QUOTE_RESPONSE:
      return Object.assign({}, state, { createQuoteResponse: action.payload });
    case ActionTypes.GET_INIT_PAYMENT_DATA:
      return Object.assign({}, state, { initPaymentObject: action.payload });
    case ActionTypes.GET_FLOOD_SYSTEM_DD:
      return Object.assign({}, state, { floodSystemDD: action.payload });
    case ActionTypes.RESET_FLOOD_STORE:
      return initialState;
    default:
      return state;
  }
}

export default Flood;
