import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { FaInbox, FaEye, FaPlus, FaFilter } from "react-icons/fa";
import { Link } from "react-router-dom";
import Moment from 'moment';
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useDispatch, connect } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { useNavigate } from "react-router-dom";
import { getActivityListData } from "../../../services/activityService.js";
import { showDataTableTotalText, EncryptOrDecrypt } from "../../../services/commonService";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./inbox.scss";
import { useTranslation } from "react-i18next";

const Inbox = (props) => {
  var d = new Date();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const FilterCriteriaValue = props.sourceCode != undefined ? props.sourceCode : '';
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableTotal, setTableTotal] = useState([]);
  const [total, setTotal] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [ScheduledFrom, setScheduledFrom] = useState(d.setMonth(d.getMonth() - 6));
  const [ScheduledTo, setScheduledTo] = useState(new Date());
  const user = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
  
  const [filter, setFilter] = useState({
      FilterCriteria : "ALL",
      FilterCriteriaValue :FilterCriteriaValue,
      FilterByFlag : "",
      FilterByFlagStatus : "",
      Display: "",
      ScheduledFrom: ScheduledFrom,
      ScheduledTo: ScheduledTo,
      ActivityUserID: user.Admin_ID,
      ActivityPersonPK: user.n_PersonInfoId_FK,
      sourceCode : "",
      sourceType : "",
      page: 1,
      pageLength: 10,
  });

  useEffect(() => {
    searchActivityList();
  }, []);

  useEffect(() => {
    searchActivityList();
  }, [filter.page])

  const searchActivityList = async () => {
    const tempArray = filter;
    tempArray['ScheduledFrom'] = Moment(ScheduledFrom).format("YYYY-MM-DD");
    tempArray['ScheduledTo'] = Moment(ScheduledTo).format("YYYY-MM-DD");
    setFilter(tempArray);
    setLoading(true);
    await dispatch(getActivityListData(filter)).then((res) => { 
       setTableData(res.data.data);
       setTableTotal(res.data.total);
       setLoading(false);
    });
  };

  const renderFormatter = (value, row) => {
    if (FilterCriteriaValue === '') {
      return (
        <Link
          className="management-reports-link"
          to={`/active-log/${row.MessageInfo_PK}`}
        >
          {value}
        </Link>
      )
    } else {
      return (
        <a
          className="management-reports-link"
          style={{ cursor: 'pointer' }}
          onClick={() => props.setValuesCondition('DETAIL', row.MessageInfo_PK)}
        >
          {value}
        </a>
      )
    }
  }

  const columns = [
    {
      dataField: "MessageFrom",
      text: "From",
      formatter: (value, row) => renderFormatter(value, row)
    },
    {
      dataField: "MessageSubject",
      text: "Subject",
    },
    {
      dataField: "MessageSourceType",
      text: "Type",
    },
    {
      dataField: "PolicyHolderName",
      text: "Name",
    },
    {
      dataField: "MessageSourceCode",
      text: "Source",
    },
    {
      dataField: "MessageCreatedDate",
      text: "Date",
      sort: true,
    },
    {
      dataField: "Scheduled_Date",
      text: "Date Scheduled",
      sort: true,
    },
    {
      dataField: "MessageStatusTask",
      text: "Status",
      sort: true,
      formatter: (value, row) => (
        <Form.Select
          className="dashboard-group-input"
          id="table-select-options"
          name="FilterByFlag"
          value={row.MessageStatusTask}
          // onChange={(e) => statusChangeRow(e, row)}
        >
          <option value={"OPEN"}>Open</option>
          <option value={"PENDING"}>Pending</option>
          <option value={"CLOSED"}>Closed</option>
        </Form.Select>
      ),
    },
  ];

  const rowStyle = (row, rowIndex) => {
    let style = {};
    if(row){
      if(row.InboxUnreadCount > 0){
        style = {
          fontWeight : 'bold !important'
        }
      }
    }
    return style;
  };

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    const tempArray = filter; // creating copy of an array
    tempArray[name] = value;
    setFilter(tempArray); // set copied array
  };

  const onHandleChangeDate = (value, field) => {
    if(field == "ScheduledFrom") {
     setScheduledFrom(value);
    }
    else {
      setScheduledTo(value);
    }
    const tempArray = filter; // creating copy of an array
    tempArray[field] = Moment(value).format('YYYY-MM-DD');
    setFilter(tempArray); // set copied array
  };

  const handlePageChange = (page, sizePerPage) => {
    setFilter({...filter,page:page,pageLength:sizePerPage});
  };

  const sizePerPageListChange = (sizePerPage) => {
    setFilter({...filter,pageLength:sizePerPage});
  };

  const renderShowsTotal = (start, to, total) => {
    return showDataTableTotalText(start, to, total);
  };

  const options = {
    page: filter.page,
    sizePerPage: filter.pageLength,
    sortIndicator: false,
    pageStartIndex: 1,
    paginationShowsTotal: renderShowsTotal,
    onPageChange: handlePageChange,
    totalSize:30
  };

  // const options = {
  //   page: filter.page,
  //   sizePerPage: filter.pageLength,
  //   sortIndicator: false,
  //   pageStartIndex: 1,
  //   paginationShowsTotal: renderShowsTotal,
  //   onPageChange: handlePageChange,
  //   totalSize:tableTotal
  //   //sizePerPageDropDown: renderSizePerPageDropDown
  // };
  return (
    <LoadingOverlay>
    <Loader loading={loading} />
    <div className="inbox-page-section">
      <h4 className="activity-log-title">{t("Communication")}</h4>
      <Row>
        <Col xs="12">
          <div className="card-header-main">
            <div className="card-header-main-title">
              <FaInbox size={21} color={"#fff"} />
              <span className="card-header-main-text">{t("Inbox")}</span>
            </div>
            <div className="card-header-btns-div">
              <div className="card-header-action">
                <FaEye size={21} color={"#fff"} />
                <span className="card-header-action-title">View Log</span>
              </div>
              <Button
                id="common-outline-btn"
                className="card-header-btn0"
                variant="outline-primary"
                   onClick={()=> 
                    {
                       FilterCriteriaValue != '' ?
                       props.viewPart('NEW')
                    :
                       navigation('/active-log/new-activity')
                    }}
              >
                <FaPlus size={15} /> Add New
              </Button>{" "}
            </div>
          </div>
          <div className="card-body-main">
            <Row className="align-items-baseline">
              <Col xs="12" sm="12" md="4" lg="3" xl="2">
                <Form.Group
                  className="activity-group-forms"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="dashboard-group-label">
                    Filter By Gen
                  </Form.Label>
                  <Form.Select
                     name="FilterByFlag"
                     className="dashboard-group-input"
                     id="select-ym-option"
                     onChange={(e) => onHandleChange(e)}
                  >
                    <option value={""}>All</option>
                    <option value={"USER"}>User genrated</option>
                    <option value={"SYSTEM"}>System Genrated</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="4" lg="3" xl="2">
                <Form.Group
                  className="activity-group-forms"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="dashboard-group-label">
                    Filter By Status
                  </Form.Label>
                  <Form.Select
                    name="FilterByFlagStatus"
                    className="dashboard-group-input"
                    id="select-ym-option"
                    onChange={(e) => onHandleChange(e)}
                  >
                    <option value={""}>All</option>
                    <option value={"OPEN"}>OPEN</option>
                    <option value={"PENDING"}>PENDING</option>
                    <option value={"CLOSED"}>CLOSED</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="4" lg="3" xl="2">
                <Form.Group
                  className="activity-group-forms"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="dashboard-group-label">
                    Display
                  </Form.Label>
                  <Form.Select
                    name="Display"
                    className="dashboard-group-input"
                    id="select-ym-option"
                    onChange={(e) => onHandleChange(e)}
                  >
                    <option value={""}>ALL</option>
                    <option value={"U"}>Unread</option>
                    <option value={"R"}>Read </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="4" lg="3" xl="2">
                <Form.Group
                  className="activity-group-forms"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="dashboard-group-label">
                    Action
                  </Form.Label>
                  <Form.Select
                    name="Action"
                    className="dashboard-group-input"
                    id="select-ym-option"
                    //onChange={(e) => onHandleChange(e)}
                  >
                    <option value={"None"}>None</option>
                    <option value={"U"}>Mark as Unread</option>
                    <option value={"R"}>Mark as Read </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="4" lg="3" xl="2">
                <Form.Group
                  className="activity-group-forms"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="dashboard-group-label">
                    From
                  </Form.Label>
                  <DatePicker
                    name="ScheduledFrom"
                    id="dashboard-datepicker"
                    className="dashboard-datepicker-main"
                    selected={ScheduledFrom}
                    onChange={(date) => onHandleChangeDate(date, 'ScheduledFrom')}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="4" lg="3" xl="2">
                <Form.Group
                  className="activity-group-forms"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="dashboard-group-label">To</Form.Label>
                  <DatePicker
                    name="ScheduledTo"
                    id="dashboard-datepicker"
                    className="dashboard-datepicker-main"
                    selected={ScheduledTo}
                    onChange={(date) => onHandleChangeDate(date, 'ScheduledTo')}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" className="activity-filter-btn-div">
                <Button
                  id="common-btn"
                  className="activity-filter-btn"
                  onClick={() => searchActivityList()}
                  variant="primary"
                >
                  <FaFilter size={18} />
                  Filter
                </Button>{" "}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <div
            className="inbox-table"
            id="inbox-table-outer-div"
            style={{ paddingBottom: "20px", overflowX: "scroll" }}
          >
             <BootstrapTable
              wrapperClasses="management-functional-table"
              bootstrap4
              keyField="BaseMessageLink_IdFk"
              data={tableData}
              columns={columns}
              rowStyle = {rowStyle} 
              selectRow={{ mode: "checkbox"}}
              hover
              bordered={false}
              pagination={paginationFactory(options)}
              tabIndexCell
            />
          </div>
        </Col>
      </Row>
    </div>
    </LoadingOverlay>
  );
};

function mapStateToProps(state) {
  return {
    getActivityList: state.common.getActivityList ? state.common.getActivityList : {},
  };
}
export default connect(mapStateToProps)(Inbox);
