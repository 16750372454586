import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import CommonHeader from "./CommonHeader";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { currencyFormat } from "../../../services/commonService";
import { useTranslation } from "react-i18next";

const CoverageTab = (props) => {
  const { t } = useTranslation();
  const [basicProducts, setBasicProducts] = useState([]);
  const [feesData, setFeesData] = useState([]);
  const [optionalProducts, setOptionalProducts] = useState([]);
  const [discountProducts, setDiscountProducts] = useState([]);
  const [product, setProduct] = useState(props.applicationData.n_ProductId_FK || null);
  const [deductible, setDeductible] = useState([])
  const [ddHDeductible, setDdHDeductible] = useState([])
  const [ddNHDeductible, setDdNHDeductible] = useState([])
  const [bldgDed, setBldgDed] = useState('')
  const [contDed, setContDed] = useState('')

  useEffect(() => {
    if (Object.keys(props.cvgDetails).length > 0) {
      setBasicProducts(props.cvgDetails.basicCvgData)
    }
    if (Object.keys(props.cvgDetails).length > 0) {
      setFeesData(props.cvgDetails.feesData || [])
    }
    if (Object.keys(props.cvgDetails).length > 0) {
      setOptionalProducts(props.cvgDetails.optionalCvgData)
    }
    if (Object.keys(props.cvgDetails).length > 0) {
      setDiscountProducts(props.cvgDetails.discountData)
    }
    if (Object.keys(props.cvgDetails).length > 0) {
      setDeductible(props.cvgDetails.deductible || [])
    }
    if (Object.keys(props.cvgDetails).length > 0) {
      setDdHDeductible(props.cvgDetails.ddHDeductible || [])
    }
    if (Object.keys(props.cvgDetails).length > 0) {
      setDdNHDeductible(props.cvgDetails.ddNHDeductible || [])
    }
    if (Object.keys(props.applicationData).length > 0) {
      setProduct(props.applicationData.n_ProductId_FK)
    }
  }, [props.cvgDetails, props.applicationData])

  useEffect(() => {
    if (basicProducts.length > 0 && product === 10) {
      var basicCvgData = props.cvgDetails.basicCvgData;
      setBldgDed(basicCvgData.filter(x => ['FLDBLDCVGAMT'].includes(x.s_CoverageCode))[0].s_PrDiscountCode);
      setContDed(basicCvgData.filter(x => ['FLDCONTCVGAMT'].includes(x.s_CoverageCode))[0].s_PrDiscountCode);
    }
  }, [basicProducts, product])

  const basicColumns = [
    {
      dataField: "s_CoverageName",
      text: t("Basic Coverage"),
      sort: true,
    },
    {
      dataField: "n_InsuredValue",
      text: t("Limit"),
      sort: true,
      formatter: (value) => <>{currencyFormat(value)}</>,
    },
    {
      dataField: "n_AnnualGrossPremium",
      text: t("Premium"),
      sort: true,
      formatter: (value, row) => (value !== 0 || row.s_CoverageCode === "HOMCVGF") ? currencyFormat(value) : 'Include',
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   sort: true,
    // },
  ];

  const optionalColumns = [
    {
      dataField: "s_CoverageName",
      text: t("Optional Coverage"),
      sort: true,
    },
    {
      dataField: "n_InsuredValue",
      text: t("Limit"),
      sort: true,
      formatter: (value) => <>{currencyFormat(value)}</>,
    },
    {
      dataField: "n_AnnualGrossPremium",
      text: t("Premium"),
      sort: true,
      formatter: (value) => (value !== 0 ? currencyFormat(value) : 'Include'),
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   sort: true,
    // },
  ];

  const defaultSorted = [{
    dataField: 's_CoverageName',
    order: 'asc'
  }];

  const defaulDiscountSorted = [{
    dataField: 's_ScreenName',
    order: 'asc'
  }];

  const discountColumns = [
    {
      dataField: "s_ScreenName",
      text: t("Discount/ Surchges Name"),
      sort: true,
    },
    {
      dataField: "d_DiscEffectiveFrom",
      text: t("Effective From"),
      sort: true,
    },
    {
      dataField: "d_DiscEffectiveTo",
      text: t("Effective To"),
      sort: true,
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   sort: true,
    // },
  ];

  const feesColumns = [
    {
      dataField: "s_ScreenName",
      text: "Fees"
    },
    {
      dataField: "n_PremiumTaxAmount",
      text: "Premium"
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   sort: true,
    // },
  ];

  return (
    <Row>
      <Col xs="12" className="billing-tab-row1">
        <CommonHeader />
      </Col>

      <Col xs="12" className="billing-tab-row2">
        <div id="dashboard-table-section">
          {
            product === 10 ? (
              <div
                className="table-main-title-div"
                style={{
                  backgroundColor: "#fff",
                  borderLeft: "1px solid rgba(27, 43, 64, 0.2)",
                  borderRight: "1px solid rgba(27, 43, 64, 0.2)",
                  borderTop: "1px solid rgba(27, 43, 64, 0.2)",
                }}
              >
                <div>
                  <Form.Group
                    id="coverage-tab-selection"
                    className="policy-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label
                      className="dashboard-group-label"
                      style={{ whiteSpace: "nowrap", marginRight: "12px" }}
                    >
                      {t('Building Deductible')}
                    </Form.Label>
                    <Form.Select
                      className="dashboard-group-input"
                      id="select-ym-option"
                      value={bldgDed}
                      disabled
                    >
                      {deductible != null && deductible != undefined ? deductible.map((data, i) => {
                        return (
                          <option key={i} value={data.s_DiscountCode}>{data.s_ScreenName}</option>
                        );
                      }) : null}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div>
                  {" "}
                  <Form.Group
                    id="coverage-tab-selection"
                    className="policy-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label
                      className="dashboard-group-label"
                      style={{ whiteSpace: "nowrap", marginRight: "12px" }}
                    >
                      {t('Content Deductible')}
                    </Form.Label>
                    <Form.Select
                      className="dashboard-group-input"
                      id="select-ym-option"
                      value={contDed}
                      disabled
                    >
                      {deductible != null && deductible != undefined ? deductible.map((data, i) => {
                        return (
                          <option key={i} value={data.s_DiscountCode}>{data.s_ScreenName}</option>
                        );
                      }) : null}
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
            )
              :
              (
                <div
                  className="table-main-title-div"
                  style={{
                    backgroundColor: "#fff",
                    borderLeft: "1px solid rgba(27, 43, 64, 0.2)",
                    borderRight: "1px solid rgba(27, 43, 64, 0.2)",
                    borderTop: "1px solid rgba(27, 43, 64, 0.2)",
                  }}
                >
                  <div>
                    <Form.Group
                      id="coverage-tab-selection"
                      className="policy-form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label
                        className="dashboard-group-label"
                        style={{ whiteSpace: "nowrap", marginRight: "12px" }}
                      >
                        {t('Non Hurricane Deductibles')}
                      </Form.Label>
                      <Form.Select
                        className="dashboard-group-input"
                        id="select-ym-option"
                      >
                        {ddNHDeductible != null && ddNHDeductible != undefined ? ddNHDeductible.map((data, i) => {
                          return (
                            <option key={i} value={data.value}>{data.label}</option>
                          );
                        }) : null}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div>
                    {" "}
                    <Form.Group
                      id="coverage-tab-selection"
                      className="policy-form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label
                        className="dashboard-group-label"
                        style={{ whiteSpace: "nowrap", marginRight: "15px" }}
                      >
                        {t('Hurricane Deductible')}
                      </Form.Label>
                      <Form.Select
                        className="dashboard-group-input"
                        id="select-ym-option"
                      >
                        {ddHDeductible != null && ddHDeductible != undefined ? ddHDeductible.map((data, i) => {
                          return (
                            <option key={i} value={data.value}>{data.label}</option>
                          );
                        }) : null}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
              )
          }
          <BootstrapTable
            wrapperClasses="coverage-detail-table-main cdtmm"
            bootstrap4
            keyField="s_CoverageName"
            defaultSorted={defaultSorted}
            data={basicProducts}
            columns={basicColumns}
            hover
          />
        </div>
      </Col>
      {
        (product === 10) && (
          <Col xs="12" className="billing-tab-row2">
            <div id="dashboard-table-section">
              <BootstrapTable
                wrapperClasses="coverage-detail-table-main cdtmm"
                bootstrap4
                keyField="s_ScreenName"
                data={feesData}
                columns={feesColumns}
                hover
              />
            </div>
          </Col>
        )
      }
      {
        // For Other Products
        (product !== 10) && (
          <React.Fragment>
            <Col xs="12" className="billing-tab-row2">
              <div id="dashboard-table-section">
                <BootstrapTable
                  wrapperClasses="coverage-detail-table-main cdtmm"
                  bootstrap4
                  keyField="s_CoverageName"
                  defaultSorted={defaultSorted}
                  data={optionalProducts}
                  columns={optionalColumns}
                  hover
                />
              </div>
            </Col>
            <Col xs="12" className="billing-tab-row2">
              <div id="dashboard-table-section">
                <BootstrapTable
                  wrapperClasses="coverage-detail-table-main cdtmm"
                  bootstrap4
                  keyField="s_ScreenName"
                  defaultSorted={defaulDiscountSorted}
                  data={discountProducts}
                  columns={discountColumns}
                  hover
                />
              </div>
            </Col>
          </React.Fragment>
        )
      }

    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    cvgDetails: state.coreSystem.cvgDetails,
    applicationData: state.coreSystem.applicationData,
  };
}

export default connect(mapStateToProps)(CoverageTab);
