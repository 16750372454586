import React, { Fragment, useState, useEffect } from "react";
import { Button, Row, Col, Form, Table, Accordion, ListGroup } from "react-bootstrap";
import basicBadge from "../../../../../assets/badges/basic.png";
import optionalBadge from "../../../../../assets/badges/optional.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MapComponent from "../../../../../components/MapComponent";
import { useDispatch, useSelector , connect } from 'react-redux';
import { getQuoteData,getEmptyQuoteData,getBaseQuoteData,getEditEmptyQuoteData } from "../../../../../services/quoteService";
import { useTranslation } from "react-i18next";

const UnderwritingQuestion = (props) => {

  const [date1, setDate1] = useState(new Date());
  const [quoteData, setQuoteData] = useState(props.quoteData);
  const { t } = useTranslation();
  const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getEditEmptyQuoteData(quoteData));
	}, [quoteData]);
	
	const handleQRadioChange = (e) => {
		const { name, value , title,questionFK,attributes,checked,id } = e.target; 
		var questionKey = 'Question_'+attributes.questionFK.value;
		const questionKeyy = 'Question_'+attributes.questionFK.value;
		var uWkey = props.quoteData.allAnswerData;
		//var tempArr = quoteData.allAnswerData;
		var quoteDataVar = props.quoteData;
	
		if (uWkey != null && uWkey.hasOwnProperty(questionKey)) {
			const tempArr = props.quoteData.allAnswerData;
			tempArr[questionKey]['s_PoAdditionalStatsAnswer'] = value;
			var key = uWkey[questionKey]['s_PoAdditionalStatsAnswer'];
			setQuoteData({...quoteDataVar, key: value});
		}else{
	
			const questionD = props.quoteData.allAnswerData;
			questionD[questionKeyy] = {
				's_PoAdditionalStatsAnswer': value
			};
			setQuoteData({...quoteDataVar,quoteDataVar});
	
		}
	}

  return (
	<>
	<div className="search-quote-question-header">
        <span className="search-quote-question-text">{t("Questions")}</span>
        <span className="search-quote-question-text">{t("Answer")} </span>
      </div>
      <div className="search-quote-question-body">
        <div className="search-questions-subdiv">
          <ListGroup className="search-questions-list" as="ol" numbered>
            <ListGroup.Item as="li">
              <span>
			  {t("Do you have wood burning stove at home")}? *
              </span>
              <div
                id="sear-question-list-answers"
                className="custom-radio-check0"
              >
               <Form.Check
                  type="radio"
                  label={t("Yes")}
                  className="check-question-radio"
                  name="check-ques-radio2_12984303"
				  questionFK="12984303"
				//  checked={questionValue2}
				  value={'YES'}
				  checked={quoteData.allAnswerData.Question_12984303 && quoteData.allAnswerData.Question_12984303.s_PoAdditionalStatsAnswer && quoteData.allAnswerData.Question_12984303.s_PoAdditionalStatsAnswer === "YES" ? true : false}
				  onChange = {handleQRadioChange}
          disabled={props.isBind}
				  
                />
                <Form.Check
                  type="radio"
                  label={t("No")}
                  name="check-ques-radio2_12984303"
				  questionFK="12984303"
				//  checked={questionValue2}
				  value={'NO'}
				  checked={quoteData.allAnswerData.Question_12984303 && quoteData.allAnswerData.Question_12984303.s_PoAdditionalStatsAnswer && quoteData.allAnswerData.Question_12984303.s_PoAdditionalStatsAnswer === "NO" ? true : false}
				  onChange = {handleQRadioChange}
          disabled={props.isBind}
                />
              </div>
            </ListGroup.Item>

            <ListGroup.Item as="li">
              <span>
			  	{t("Does the applicant have at least 1 year of current homeowner insurance, unless a first time home buyer")}? *
              </span>
              <div
                id="sear-question-list-answers"
                className="custom-radio-check0"
              >
               <Form.Check
                  type="radio"
                  label={t("Yes")}
                  className="check-question-radio"
                  name="check-ques-radio2_12984362"
				  questionFK="12984362"
				//  checked={questionValue2}
				  value={'YES'}
				  checked={quoteData.allAnswerData.Question_12984362 && quoteData.allAnswerData.Question_12984362.s_PoAdditionalStatsAnswer && quoteData.allAnswerData.Question_12984362.s_PoAdditionalStatsAnswer === "YES" ? true : false}
				  onChange = {handleQRadioChange}
          disabled={props.isBind}
				  
                />
                <Form.Check
                  type="radio"
                  label={t("No")}
                  name="check-ques-radio2_12984362"
				  questionFK="12984362"
				//  checked={questionValue2}
				  value={'NO'}
				  checked={quoteData.allAnswerData.Question_12984362 && quoteData.allAnswerData.Question_12984362.s_PoAdditionalStatsAnswer && quoteData.allAnswerData.Question_12984362.s_PoAdditionalStatsAnswer === "NO" ? true : false}
				  onChange = {handleQRadioChange}
          disabled={props.isBind}
                />
              </div>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>
	  </>
	);
	
};
  function mapStateToProps(state) {
	return {
	  	quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	};
  } 
  export default connect(mapStateToProps)(UnderwritingQuestion);