import * as ActionTypes from '../action-types';

const initialState = {
  allProducts: {},
  n_POTransactionFK: '',
  n_POTermMasterFK: '',
  n_POPolicyMasterFK: '',
  termSelection: {},
  transactionDD: {},
  applicationData: {},
  cvgDetails: {},
  additionalData: [],
  mortgageeData: [],
  householdData: [],
  addiIntData: [],
  priorPolicyLossData: [],
  formCopyData: {},
  billingData: {},
  accountData: [],
  receivableData: [],
  invoiceData: [],
  invoicePreData: {},
  propertyInfo: {},
  baseData: {},
  actionTypeDD: {}
}

const setCorePolicyState = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    n_POTransactionFK: payload.n_POTransactionFK,
    n_POTermMasterFK: payload.n_POTermMasterFK,
    n_POPolicyMasterFK: payload.n_POPolicyMasterFK,
  });
  return stateObj;
};

const setAllproducts = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    allProducts: payload
  });
  return stateObj;
};

const setActionTypeDD = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    actionTypeDD: payload
  });
  return stateObj;
};



const setTermMaster = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    n_POTermMasterFK: payload
  });
  return stateObj;
};

const setTransaction = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    n_POTransactionFK: payload
  });
  return stateObj;
};

const setTermSelection = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    termSelection: payload
  });
  return stateObj;
};

const setTransactionDD = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    transactionDD: payload
  });
  return stateObj;
};

const setApplicationData = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    applicationData: payload
  });
  return stateObj;
};

const setCvgDetails = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    cvgDetails: payload
  });
  return stateObj;
};

const setAdditionalData = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    additionalData: payload
  });
  return stateObj;
};

const setMortgageeData = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    mortgageeData: payload
  });
  return stateObj;
};

const setHouseholdData = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    householdData: payload
  });
  return stateObj;
};

const setAdditionalInterestData = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    addiIntData: payload
  });
  return stateObj;
};

const setPriorPolicyLossData = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    priorPolicyLossData: payload
  });
  return stateObj;
};

const setFormCopyData = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    formCopyData: payload
  });
  return stateObj;
};

const getBillingData = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    billingData: payload
  });
  return stateObj;
};

const getAccountData = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    accountData: payload
  });
  return stateObj;
};

const getReceivableData = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    receivableData: payload
  });
  return stateObj;
};

const getInvoiceData = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    invoiceData: payload
  });
  return stateObj;
};

const getInvoicePreFeeData = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    invoicePreData: payload
  });
  return stateObj;
};

const setPropertyInfo = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    propertyInfo: payload
  });
  return stateObj;
};
const setBaseData = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    baseData: payload
  });
  return stateObj;
};

const CoreSystem = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.RESET_CORE_STORE:
      return initialState;
    case ActionTypes.SET_CORE_POLICY_DATA:
      return setCorePolicyState(state, action.payload)
    case ActionTypes.SET_PRODUCT_LIST:
      return setAllproducts(state, action.payload)
    case ActionTypes.SET_CORE_POLICY_TERM:
      return setTermMaster(state, action.payload)
    case ActionTypes.SET_CORE_POLICY_TRANS:
      return setTransaction(state, action.payload)
    case ActionTypes.SET_TERM_SELECTION:
      return setTermSelection(state, action.payload)
    case ActionTypes.SET_TRANSACTION_DD:
      return setTransactionDD(state, action.payload)
    case ActionTypes.SET_APPLICATION_DATA:
      return setApplicationData(state, action.payload)
    case ActionTypes.SET_POLICY_COVERAGE_DATA:
      return setCvgDetails(state, action.payload)
    case ActionTypes.SET_ADDITIONAL_DATA:
      return setAdditionalData(state, action.payload)
    case ActionTypes.SET_MORTGAGEE_DATA:
      return setMortgageeData(state, action.payload)
    case ActionTypes.SET_HOUSEHOLD_DATA:
      return setHouseholdData(state, action.payload)
    case ActionTypes.SET_ADDITIONAL_INTEREST_DATA:
      return setAdditionalInterestData(state, action.payload)
    case ActionTypes.SET_PRIOR_POLICY_LOSS_DATA:
      return setPriorPolicyLossData(state, action.payload)
    case ActionTypes.SET_FORM_COPY_DATA:
      return setFormCopyData(state, action.payload)
    case ActionTypes.SET_BILLING_DATA:
      return getBillingData(state, action.payload)
    case ActionTypes.SET_ACCOUNT_DATA:
      return getAccountData(state, action.payload)
    case ActionTypes.SET_RECEIVABLE_DATA:
      return getReceivableData(state, action.payload)
    case ActionTypes.SET_INVOICE_DATA:
      return getInvoiceData(state, action.payload)
    case ActionTypes.SET_INVOICE_PREMIUM_FEE_DATA:
      return getInvoicePreFeeData(state, action.payload)
    case ActionTypes.SET_POLICY_PROPERTY_INFO:
      return setPropertyInfo(state, action.payload)
    case ActionTypes.SET_BASE_DATA:
      return setBaseData(state, action.payload)
    case ActionTypes.SET_ACTION_TYPE_DD:
      return setActionTypeDD(state, action.payload)
    default:
      return state;
  }
}

export default CoreSystem;
