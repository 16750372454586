import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import CommonHeader from "./CommonHeader";
import { connect, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {
  accountData, receivableInvoice, invoiceData, invoicePreFee
} from "../../../services/coreSystemService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { currencyFormat } from "../../../services/commonService";
import { useTranslation } from "react-i18next";

const BillingTab = (props) => {
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState([]);
  const [receivableData, setReceivableData] = useState([]);
  const [invoicingData, setInvoicingData] = useState([]);
  const [invoicePreDetails, setInvoicePreDetails] = useState([]);
  const [invoiceFeesDetails, setInvoiceFeesDetails] = useState([]);

  const setAction = async (view) => {
    setLoading(true);
    if (view === 0) {
      await dispatch(accountData(props.n_POTermMasterFK, props.billingData.acctNoPK));
    }
    if (view === 1) {
      await dispatch(receivableInvoice(props.n_POTermMasterFK, props.billingData.acctNoPK));
    }
    if (view === 2) {
      await dispatch(invoiceData(props.n_POTermMasterFK, props.billingData.acctNoPK));
    }
    setLoading(false);
  }

  useEffect(() => {
    if (Object.keys(props.accountData).length > 0) {
      setAccount(props.accountData || [])
    }
    if (Object.keys(props.receivableData).length > 0) {
      setReceivableData(props.receivableData || [])
    }
    if (Object.keys(props.invoiceData).length > 0) {
      setInvoicingData(props.invoiceData || [])
    }
    if (Object.keys(props.invoicePreData).length > 0) {
      setInvoicePreDetails(props.invoicePreData.InvPremium || [])
    }
    if (Object.keys(props.invoicePreData).length > 0) {
      setInvoiceFeesDetails(props.invoicePreData.InvPremFees || [])
    }
  }, [props.accountData, props.receivableData, props.invoiceData, props.invoicePreData])

  useEffect(() => {
    if (Object.keys(props.billingData).length > 0) {
      setAction(0)
    }
  }, [props.billingData]);

  /**
   * To get Invoice Premium & Fees Details
   * @param {Object} row 
   */
  const getInvoicePremFees = async (row) => {
    setLoading(true);
    await dispatch(invoicePreFee(row.n_PAInvoiceMaster_PK));
    setLoading(false);
  };

  //table column formating
  const columnFormatter = (cell, row) => {
    if (row && row.s_InvoiceNo) {
      return (
        <a href="#" onClick={() => getInvoicePremFees(row)}>
          {cell}
        </a>
      );
    } else {
      return row.s_InvoiceNo;
    }
  };

  //Account Tab Columns
  const accountColumns = [
    {
      dataField: 'n_PATransactionMaster_PK',
      hidden: true
    },
    {
      dataField: "d_AccountingDate",
      text: t("ACCOUNTING DT"),
    },
    {
      dataField: "s_TransTypeCode",
      text: t("TRANS TYPE"),
    },
    {
      dataField: "s_TransRefNo",
      text: t("TRANS REF"),
    },
    {
      dataField: "s_ScreenName",
      text: t("USER ID"),
    },
    {
      dataField: "origType",
      text: t("ORIG TRANS"),
    },
    {
      dataField: "Unallocatedamount",
      text: t("UNALLOCATED"),
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: "debit",
      text: t("DEBIT"),
      formatter: (value) => currencyFormat((value >= 0 ? value : 0)),
    },
    {
      dataField: "credit",
      text: t("CREDIT"),
      formatter: (value) => currencyFormat((value <= 0 ? value : 0)),
    },
    {
      dataField: "runningbalance",
      text: t("BALANCE"),
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: "d_CreatedDate",
      text: t("SYSTEM DT"),
    },
  ];

  // Receivable Tab Columns
  const receivableColumns = [
    {
      dataField: 'n_POTransactionFK',
      hidden: true
    },
    {
      dataField: "order1",
      text: t("ACCOUNTING DT"),
    },
    {
      dataField: "s_TranTypeScreenName",
      text: t("TRANS TYPE"),
    },
    {
      dataField: "s_TranSubtypeScreenName",
      text: t("TRANS REF"),
    },
    {
      dataField: "d_TransEffectiveFrom",
      text: t("EFF DT"),
    },
    {
      dataField: "debit",
      text: t("DEBIT"),
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: "credit",
      text: t("CREDIT"),
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: "runningbalance",
      text: t("RUNNING BALANCE"),
      formatter: (value) => currencyFormat(value),
    },
  ];

  // Invoice Tab Columns
  const invoiceColumns = [
    {
      dataField: "d_InvoiceDate",
      text: t("Invoice Date"),
    },
    {
      dataField: "s_InvoiceNo",
      text: t("Invoice No"),
      formatter: columnFormatter,
    },
    {
      dataField: "Done",
      text: t("Status"),
    },
    {
      dataField: "n_InvoicePremAmt",
      text: t("Premium"),
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: "n_InvoiceOtherCharge",
      text: t("Other Charges"),
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: "DueAmount",
      text: t("Due AMT"),
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: "n_PreviousInvoiceBalance",
      text: t("Prev Balance"),
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: "Pmts_Adjust_amt",
      text: t("PMTS/Adjust"),
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: "n_FinalInvoiceAmount",
      text: t("Invoice AMT"),
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: "d_InvoiceDueDate",
      text: t("Due Date"),
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    // },
  ];

  // Invoice Premium Columns
  const invoicePreDetailsColumns = [
    {
      dataField: "s_AccountingDate",
      text: t("Transfer Date"),
    },
    {
      dataField: "Policy_No",
      text: t("Policy NO"),
    },
    {
      dataField: "s_TranTypeScreenName",
      text: t("Transfer Type"),
    },
    {
      dataField: "n_PremiumAmount",
      text: t("Transfer AMT"),
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: "n_DetailAmt",
      text: t("Billed AMT"),
      formatter: (value) => currencyFormat(value),
    },
  ];

  // Invoice Premium and Fees Columns
  const invoiceFeesDetailsColumns = [
    {
      dataField: 'n_PAInvoiceDetail_PK',
      hidden: true
    },
    {
      dataField: "s_SubtypeScreenName",
      text: t("Charge Name"),
    },
    {
      dataField: "s_PercentOrAmount",
      text: t("Amount%"),
    },
    {
      dataField: "n_DetailAmt",
      text: t("Value"),
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: "s_PercentOrAmount",
      text: t("Charges AMT"),
      formatter: (value) => currencyFormat(value),
    },
  ];

  return (
    <LoadingOverlay>
      <Loader loading={loading} />
      <Row>
        <Col xs="12" className="billing-tab-row1">
          <CommonHeader />
        </Col>

        <Col xs="12" className="billing-tab-row2">
          <div id="dashboard-table-section">
            <div className="table-main-title-div">
              <span className="table-main-title-name">
                {tab === 0 && t('Policy Transaction')}
                {tab === 1 && t('Receivable Details')}
                {tab === 2 && t('Policy Transaction')}
              </span>
              <div>
                <Button
                  id="common-outline-btn"
                  variant="outline-primary"
                  onClick={() => { setTab(0); setAction(0); }}
                  onSelect={setAction}
                  style={{
                    backgroundColor: tab === 0 && "white",
                    color: tab === 0 && "#64d6cd",
                  }}
                >
                  {t('Account View')}
                </Button>{" "}
                <Button
                  id="common-outline-btn"
                  variant="outline-primary"
                  onClick={() => { setTab(1); setAction(1); }}
                  style={{
                    backgroundColor: tab === 1 && "white",
                    color: tab === 1 && "#64d6cd",
                  }}
                >
                  {t('Receivable View')}
                </Button>{" "}
                <Button
                  id="common-outline-btn"
                  variant="outline-primary"
                  onClick={() => { setTab(2); setAction(2); }}
                  style={{
                    backgroundColor: tab === 2 && "white",
                    color: tab === 2 && "#64d6cd",
                  }}
                >
                  {t('Invoicing')}
                </Button>{" "}
              </div>
            </div>
            {
              // Account View
              tab === 0 && (
                <BootstrapTable
                  wrapperClasses="coverage-detail-table-main cdtmm"
                  bootstrap4
                  keyField="n_PATransactionMaster_PK"
                  data={account}
                  columns={accountColumns}
                  hover
                />
              )
            }
            {
              // Receivable View
              tab === 1 && (
                <BootstrapTable
                  wrapperClasses="coverage-detail-table-main cdtmm"
                  bootstrap4
                  keyField="n_POTransactionFK"
                  data={receivableData}
                  columns={receivableColumns}
                  hover
                />
              )
            }
            {
              // Invoce View
              tab === 2 && (
                <BootstrapTable
                  wrapperClasses="coverage-detail-table-main cdtmm"
                  bootstrap4
                  remote
                  keyField="s_InvoiceNo"
                  data={invoicingData}
                  columns={invoiceColumns}
                  hover
                />
              )
            }
          </div>
        </Col>
        {
          tab === 2 && (
            <React.Fragment>
              <Col xs="6">
                <div id="dashboard-table-section">
                  <div className="table-main-title-div">
                    <span className="table-main-title-name">Invoice Details</span>
                  </div>
                  <BootstrapTable
                    wrapperClasses="coverage-detail-table-main cdtmm"
                    bootstrap4
                    keyField="s_AccountingDate"
                    data={invoicePreDetails}
                    columns={invoicePreDetailsColumns}
                    hover
                  />
                </div>
              </Col>
              <Col xs="6">
                <div id="dashboard-table-section">
                  <div className="table-main-title-div">
                    <span className="table-main-title-name">
                      Invoice Fees Details
                    </span>
                  </div>
                  <BootstrapTable
                    wrapperClasses="coverage-detail-table-main cdtmm"
                    bootstrap4
                    keyField="n_PAInvoiceDetail_PK"
                    data={invoiceFeesDetails}
                    columns={invoiceFeesDetailsColumns}
                    hover
                  />
                </div>
              </Col>
            </React.Fragment>
          )
        }
      </Row>
    </LoadingOverlay>
  );
};

const mapStateToProps = (state) => {
  return {
    n_POTermMasterFK: state.coreSystem.n_POTermMasterFK,
    billingData: state.coreSystem.billingData,
    accountData: state.coreSystem.accountData,
    receivableData: state.coreSystem.receivableData,
    invoiceData: state.coreSystem.invoiceData,
    invoicePreData: state.coreSystem.invoicePreData,
  };
};
export default connect(mapStateToProps)(BillingTab);