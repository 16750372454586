import React, { Fragment, useState, useEffect } from "react";
import { Button, Row, Col, Form, Table, Accordion } from "react-bootstrap";
import basicBadge from "../../../../../assets/badges/basic.png";
import optionalBadge from "../../../../../assets/badges/optional.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MapComponent from "../../../../../components/MapComponent";
import { useDispatch, useSelector , connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";

const PropertyDetails = (props) => {
  const { t } = useTranslation();
  const [date1, setDate1] = useState(new Date());
  const [roofType, setRoofType] = useState("");
  const [roofSubType, setRoofSubType] = useState("");
  const [hideRoofSubType, setHideRoofSubType] = useState(true);
  const [renovation, setRenovation] = useState("");
  const [roofOfAge, setRoofOfAge] = useState("");
  const [isValidRoofOfAge, setIsValidRoofOfAge] = useState(false);
  const [isValidYearBuilt, setIsValidYearBuilt] = useState(false)
  
  useEffect(() => {
    setRoofType(props.quoteData.propertyInfo.s_RoofType ? props.quoteData.propertyInfo.s_RoofType : '');
    setRoofSubType(props.quoteData.propertyInfo.s_RoofSubType ? props.quoteData.propertyInfo.s_RoofSubType : '');
    setRoofOfAge(props.quoteData.propertyInfo.s_RoofAge ? props.quoteData.propertyInfo.s_RoofAge : '');
    setRenovation((props.quoteData.riskRenovationTypeData.roofInfo != undefined ) ? props.quoteData.riskRenovationTypeData.roofInfo.s_RenovationYear : '')
  }, [props.quoteData]);

  useEffect(() => {
    if(roofType == 'COMPOSITION'){
      setHideRoofSubType(false);
    }else{
      setHideRoofSubType(true);
    }
  })

  const roofAge = (e) => {
    const d = new Date();
    let year = d.getFullYear();
    setRoofOfAge(year - renovation);

    if(year >= e){
      setIsValidRoofOfAge(true);
    }else{
      setIsValidRoofOfAge(false);
      toast.error("Year Should Not Be Greater Than Current Year");
    }
  }

  const yearBuiltD = (e) => {
    const d = new Date();
    let year = d.getFullYear();
    if(year >= e){
      setIsValidYearBuilt(true);
    }else{
      setIsValidYearBuilt(false);
      toast.error("Year Should Not Be Greater Than Current Year");
    }
  }

  return (
	<Row className="align-items-end">
        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <div className="justify-start-class">
            <Form.Group
              className="search-quote-form-group"
              controlId="formBasicEmail"
              style={{ width: "100%" }}
            >
              <Form.Label className="dashboard-group-label">
                {t("ISO RCV")} <span className="label-asterisk">*</span>
              </Form.Label>
              <Form.Control
                className="dashboard-group-input"
                type="text"
                placeholder=""
				value={props.quoteData.propertyInfo.n_ReplacementCost ? props.quoteData.propertyInfo.n_ReplacementCost : ''}
				name="n_ReplacementCost"
		        title="propertyInfo"
				onChange = {props.handleChange}
				readOnly
              />
            </Form.Group>
            <Button
              id="common-btn"
              variant="primary"
              style={{
                whiteSpace: "nowrap",
                marginLeft: "12px",
                padding: "8px",
              }}
              disabled={props.isBind}
            >
              Get
            </Button>{" "}
          </div>
        </Col>
        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Structure Type")} <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Select className="table-show-entries-select"
				id="select-ym-option"
				value={props.quoteData.propertyInfo.s_StructureTypeCode ? props.quoteData.propertyInfo.s_StructureTypeCode : ''}
				name="s_StructureTypeCode"
				title="propertyInfo"
        disabled={props.isBind}
				onChange={props.handleChange}
        required
        >
				
				<option value="">Select</option>
                  {props.getBaseData.STRUCTYPE.map((x) => (
                    <option value={x.s_AppCodeName}>
                      {x.s_AppCodeNameForDisplay}
                    </option>
                  ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("No. of residents")}
            </Form.Label>
            <Form.Control
              className="dashboard-group-input"
              placeholder=""
			  value={props.quoteData.propertyInfo.n_NoOfResidents ? props.quoteData.propertyInfo.n_NoOfResidents : ''}
			  name="n_NoOfResidents"
	          title="propertyInfo"
			  onChange = {props.handleChange}
        disabled={props.isBind}
            />
          </Form.Group>
        </Col>
        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Area SqFt")}<span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Control
              className="dashboard-group-input"
              placeholder=""
			  value={props.quoteData.propertyInfo.n_AreaSqtft ? props.quoteData.propertyInfo.n_AreaSqtft : ''}
			  name="n_AreaSqtft"
	          title="propertyInfo"
			  onChange = {props.handleChange}
        disabled={props.isBind}
        required
            />
          </Form.Group>
        </Col>
        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("No. of Stories")}
            </Form.Label>
            <Form.Control
              className="dashboard-group-input"
              placeholder=""
			  value={props.quoteData.propertyInfo.n_NoOfStories ? props.quoteData.propertyInfo.n_NoOfStories : ''}
			  name="n_NoOfStories"
	          title="propertyInfo"
			  onChange = {props.handleChange}
        disabled={props.isBind}
            />
          </Form.Group>
        </Col>
        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("PPC")} <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Select className="table-show-entries-select"
				value={props.quoteData.propertyInfo.n_PPC ? props.quoteData.propertyInfo.n_PPC : ''}
				name="n_PPC"
				title="propertyInfo"
				onChange={props.handleChange}
        disabled={props.isBind}
        required >
				
				<option value="">Select</option>
                  {props.getBaseData.PROTECTIONCLS.map((x) => (
                    <option value={x.s_AppCodeName}>
                      {x.s_AppCodeNameForDisplay}
                    </option>
                  ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("BCEG")} <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Select className="table-show-entries-select"
				value={props.quoteData.propertyInfo.s_BCEG ? props.quoteData.propertyInfo.s_BCEG : ''}
				name="s_BCEG"
				title="propertyInfo"
				onChange={props.handleChange}
        disabled={props.isBind}
        required 
        >
				
				<option value="">Select</option>
                  {props.getBaseData.HOBCEGCODE.map((x) => (
                    <option value={x.s_AppCodeName}>
                      {x.s_AppCodeNameForDisplay}
                    </option>
                  ))}
              
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Year Built")} <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Control
              className="dashboard-group-input"
              placeholder=""
              maxLength="4"
              alt={"number"}
              isValid={!isValidYearBuilt ? "" : "Please Select Proper Year"}
              onBlur={(e) => yearBuiltD(e.target.value)}
              onKeyPress="/^[0-9\b]+$/"
              value={props.quoteData.propertyInfo.n_YearBuilt ? props.quoteData.propertyInfo.n_YearBuilt : ''}
              name="n_YearBuilt"
              title="propertyInfo"
              onChange = {props.handleChange}
              disabled={props.isBind}
              required
            />
          </Form.Group>
        </Col>
        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Usage")} <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Select className="table-show-entries-select"
				value={props.quoteData.propertyInfo.s_UsageTypeCode ? props.quoteData.propertyInfo.s_UsageTypeCode : ''}
				name="s_UsageTypeCode"
				title="propertyInfo"
				onChange={props.handleChange}
        disabled={props.isBind}
				required
        >
				<option value="">Select</option>
                  {props.getBaseData.HOUSAGETYPE.map((x) => (
                    <option value={x.s_AppCodeName}>
                      {x.s_AppCodeNameForDisplay}
                    </option>
                  ))}
              
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Construction Type")}<span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Select className="table-show-entries-select"
				value={props.quoteData.propertyInfo.s_ConstructionType ? props.quoteData.propertyInfo.s_ConstructionType : ''}
				name="s_ConstructionType"
				title="propertyInfo" 
				onChange={props.handleChange}
        disabled={props.isBind}
				required
        >
				<option value="">Select</option>
                  {props.getBaseData.CONSTRTYPE.map((x) => (
                    <option value={x.s_AppCodeName}>
                      {x.s_AppCodeNameForDisplay}
                    </option>
                  ))}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Roof Type")} <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Select className="table-show-entries-select"
				value={roofType}
				name="s_RoofType"
				title="propertyInfo"
        onChange = {(e) => {
				  props.handleChange(e);
				  setRoofType(e.target.value);
			  }}
        disabled={props.isBind}>
				
				<option value="">Select</option>
                  {props.getBaseData.ROOFTYPE.map((x) => (
                    <option value={x.s_AppCodeName}>
                      {x.s_AppCodeNameForDisplay}
                    </option>
                  ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs="12" sm="12" md="6" lg="2" xl="2" hidden={hideRoofSubType}>
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Roof Sub-Type")}<span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Select className="table-show-entries-select"
              value={roofSubType}
              name="s_RoofSubType"
              title="propertyInfo"
              onChange = {(e) => {
                props.handleChange(e);
                setRoofSubType(e.target.value);
              }}
              disabled={props.isBind}>
				
				      <option value="">Select</option>
                {props.getBaseData.ROOFSUBTYPE.map((x) => (
                  <option value={x.s_AppCodeName}>
                    {x.s_AppCodeNameForDisplay}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Roof Age")} <span className="label-asterisk">*</span>
            </Form.Label>

            <Form.Control
              className="dashboard-group-input"
              placeholder=""
              disabled
              value={roofOfAge}
              name="s_RoofAge"
	            title="propertyInfo"
              onChange = {(e) => {
                props.handleChange(e);
                setRoofOfAge(e.target.value);
              }}
              
            />
          </Form.Group>
        </Col>
        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Distance To Water")}<span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Control
              className="dashboard-group-input"
              type="text"
              placeholder=""
              disabled
              value={props.quoteData.propertyInfo.n_DitanceToCost ? props.quoteData.propertyInfo.n_DitanceToCost : ''}
              name="n_DitanceToCost"
	            title="propertyInfo"
			        onChange = {props.handleChange}
            />
          </Form.Group>
        </Col>
        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Distance To Fire Stn(miles)")}{" "}
              <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Control
              className="dashboard-group-input"
              type="text"
              placeholder=""
              value={props.quoteData.propertyInfo.n_DistanceToFire ? props.quoteData.propertyInfo.n_DistanceToFire : ''}
              name="n_DistanceToFire"
              title="propertyInfo"
              onChange = {props.handleChange}
              disabled={props.isBind}
            />
          </Form.Group>
        </Col>
        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Distance To Hydrant(feet)")}{" "}
              <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Control
              className="dashboard-group-input"
              type="text"
              placeholder=""
              value={props.quoteData.propertyInfo.n_DistanceToHydrant ? props.quoteData.propertyInfo.n_DistanceToHydrant : ''}
              name="n_DistanceToHydrant"
              title="propertyInfo"
              onChange = {props.handleChange}
              disabled={props.isBind}
            />
          </Form.Group>
        </Col>
        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Occupancy Type")}<span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Select className="table-show-entries-select"
              value={props.quoteData.propertyInfo.s_Occupancy ? props.quoteData.propertyInfo.s_Occupancy : ''}
              name="s_Occupancy"
              title="propertyInfo"
              onChange={props.handleChange}
              disabled={props.isBind}>
				
				      <option value="">Select</option>
                {props.getBaseData.OCCUPYTYPE.map((x) => (
                  <option value={x.s_AppCodeName}>
                    {x.s_AppCodeNameForDisplay}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Roof Renovation Year")}?{" "}
              <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Control
              className="dashboard-group-input"
              placeholder=""
              value={renovation}
              name="noOfResident"
              title="propertyInfo"
              maxLength="4"
              isValid={!isValidRoofOfAge ? "" : "Please Select Proper Year"}
              onChange = {(e) => {
                props.handleChange(e);
                setRenovation(e.target.value);
              }}
              disabled={props.isBind}
              onBlur={(e) => roofAge(e.target.value)}
              required
            />
          </Form.Group>
        </Col>
        <Col xs="12" sm="12" md="6" lg="4" xl="4">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Is this a new purchase")}?
              <span className="label-asterisk">*</span>
            </Form.Label>
            <div
              style={{ display: "flex", justifyContent: "flex-start" }}
              className="custom-radio-check0"
            >
              <Form.Check
				type="radio"
				label="Yes"
				style={{ marginRight: "10%" }}
				value={'YES'}
				checked={(props.isPriorAddr == true) ? true : false}
				name="isPriorAddr"
                id=""
				onChange={(e) => props.setPriorAddrFunc('YES')}
        		disabled={props.isBind}
			  />
			  <Form.Check 
			  	type="radio" 
				label="No"
				value={'NO'}
				checked={props.isPriorAddr == false ? true : false}
				name="isPriorAddr"
                id=""
				onChange={(e) => props.setPriorAddrFunc('NO')}
        		disabled={props.isBind}
				/>
            </div>
          </Form.Group>
        </Col>
      </Row>
	);
	
};
  function mapStateToProps(state) {
	return {
	  	quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	};
  } 
  export default connect(mapStateToProps)(PropertyDetails);