import Http from '../Http';
import * as action from '../store/actions';

export function login(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post("/api/Login", params)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          if (err.response) {
            return resolve(err.response.data);
          }
        });
    })
  );
}

export function logout() {
  return dispatch => (
    dispatch(action.authLogout())
  );
}

export function authCheck() {
  return dispatch => (
    dispatch(action.authCheck())
  );
}

export function refreshToken() {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get("/api/refreshToken")
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          if (err.response) {
            return resolve(err.response.data);
          }
        });
    })
  );
}
  
  export function getSiteData(params) {
    return dispatch => (
        new Promise((resolve, reject) => {
        const url = "/api/getSiteData";
        Http.get(url)
            .then((res) => {
              dispatch(action.getSiteData(res.data));
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}