import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getEditEmptyQuoteData, updateInitQuoteApi } from "../../../../../../services/floodService";
import DatePicker from "react-datepicker";
import Validation from "../../Validation";
import "react-datepicker/dist/react-datepicker.css";
import "./waiting-period.scss";

const WaitingPeriod = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { emptyQuoteData, initApiObject, floodSystemDD } = useSelector(state => state.flood);
  const currentDate = new Date();
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [waitingPeriod, setWaitingPeriod] = useState('STANDARD30');
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [validated, setValidated] = useState(false);
  const [isWPChange, setIsWPChange] = useState(false);

  // set initial state from reducer
  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0) {
      if (props.checkDateisValid(emptyQuoteData.effectiveDate)) {
        setEffectiveDate(new Date(emptyQuoteData.effectiveDate));
      }
      // update date if null
      if ([null, ''].includes(emptyQuoteData.effectiveDate)) {
        var tempCurrDate = currentDate;
        var date = tempCurrDate.setDate(tempCurrDate.getDate() + 30);
        props.handleDateChange(new Date(date), 'effectiveDate');
        setEffectiveDate(new Date(date));
      }
      if (![null, ''].includes(emptyQuoteData.propertyInfo.s_PolicyWaitingPeriod)) {
        setWaitingPeriod(emptyQuoteData.propertyInfo.s_PolicyWaitingPeriod);
      }
    }
  }, [])

  // trigger on update waiting period to set effective date based on waiting period type
  useEffect(() => {
    var date = new Date(); // current date
    var tempCurrDate = currentDate; // copy of current Date
    var minDate = '', maxdate = '';
    if (waitingPeriod === "STANDARD30" || waitingPeriod === "LENDER30") {
      date = tempCurrDate.setDate(tempCurrDate.getDate() + 30); // ADD 30 DAYS
      minDate = date;
    } else if (waitingPeriod === "MAPREVONEDAY") {
      date = tempCurrDate.setDate(tempCurrDate.getDate() + 1); // ADD 1 DAYS
      minDate = date;
    } else if (waitingPeriod === "LOANNOWAIT") {
      // Not For Risn Rating 2.0
      if (!props.isRiskRating2) {
        minDate = date;
        maxdate = tempCurrDate.setDate(tempCurrDate.getDate() + 60); // ADD 60 DAYS
      }
    } else if (waitingPeriod === "ROLLOVER_RENEWAL") {
      var temp = new Date(); // current date
      minDate = tempCurrDate.setDate(tempCurrDate.getDate() - 29); // SUB 29 DAYS
      maxdate = temp.setDate(temp.getDate() + 60); // ADD 60 DAYS
    }

    setMinDate(minDate); // setting min date
    setMaxDate(maxdate) // setting max date
    if (isWPChange) {
      setEffectiveDate(new Date(date)); // setting effective date
      setIsWPChange(false);
      props.handleDateChange(new Date(date), 'effectiveDate');
      if (waitingPeriod === "ROLLOVER_RENEWAL") {
        props.handleDateChange(new Date(date), 's_PriorPolicyExpDt', 'propertyInfo');
      }
      if (waitingPeriod === "LOANNOWAIT") {
        props.handleDateChange(new Date(date), 'd_FloodLoanClosingDt', 'propertyInfo');
      }
    }

    if (Object.keys(emptyQuoteData).length > 0) {
      // For Quote Details Object Update
      const emptyQuote = { ...emptyQuoteData };
      emptyQuote.propertyInfo.s_PolicyWaitingPeriod = waitingPeriod
      var temp = 'NO';
      if (waitingPeriod === "ROLLOVER_RENEWAL") {
        temp = 'YES';
      }
      emptyQuote.propertyInfo.s_IsNewPurchase = temp
      dispatch(getEditEmptyQuoteData(emptyQuote));
    }
  }, [waitingPeriod])

  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0 && Object.keys(initApiObject).length > 0) {
      // For Quote Details Object Update
      const emptyQuote = { ...emptyQuoteData };
      emptyQuote.transactionInfo.d_TransEffectiveFrom = emptyQuoteData.effectiveDate

      // For Pivot Api Object Update
      const reqJson = { ...initApiObject }; // Copy
      reqJson.effectiveDate = emptyQuoteData.effectiveDate

      dispatch(getEditEmptyQuoteData(emptyQuote));
      dispatch(updateInitQuoteApi(reqJson));

      var loanClosingDate = null, priorPolixyExDate = null;
      if (['LOANNOWAIT'].includes(waitingPeriod) && props.checkDateisValid(effectiveDate)) {
        loanClosingDate = effectiveDate;
      }
      if (['ROLLOVER_RENEWAL'].includes(waitingPeriod) && props.checkDateisValid(effectiveDate)) {
        priorPolixyExDate = effectiveDate;
      }
      props.handleDateChange(loanClosingDate, 'd_FloodLoanClosingDt', 'propertyInfo');
      props.handleDateChange(priorPolixyExDate, 's_PriorPolicyExpDt', 'propertyInfo');
    }
  }, [effectiveDate]);

  const toggle = (e) => {
    setValidated(e);
  }

  return (
    <section className="waiting-period-section">
      {
        Object.keys(emptyQuoteData).length === 0 && <Navigate to={'/flood/instantquote/step/address'} />
      }
      {/* Form */}
      <Row className="justify-content-center">
        <Col
          xs="12"
          sm="12"
          md="10"
          lg="8"
          xl="8"
          className="waiting-period-form"
        >
          <Row className="justify-content-center" id="waiting-period-div">
            <Col xs="12" sm="10" md="10" lg="10" xl="10">
              <h1>{t('Waiting Period')}</h1>
              <Row className="justify-content-start">
                <Col xs="12">
                  <Form validated={validated} className="confirm-address-form">
                    <Row>
                      <Col xs="12">
                        <Form.Group
                          className="confirm-address-field"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className="confirm-address-label">
                            {t('Waiting Period')}
                          </Form.Label>
                          <Form.Select
                            className="confirm-address-input"
                            name="s_PolicyWaitingPeriod"
                            title={t('Waiting Period')}
                            parentobject="propertyInfo"
                            value={waitingPeriod}
                            onChange={(e) => {
                              setWaitingPeriod(e.currentTarget.value);
                              setIsWPChange(true);
                            }}
                            required
                          >
                            <option value={''}>{t('Select')}</option>
                            {
                              Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODWAITINGPERIOD.map((v, i) => {
                                return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                              })
                            }
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {t("Please Select Waiting Period Error")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs="12">
                        <Form.Group
                          controlId="formBasicPassword"
                          className="confirm-address-field"
                        >
                          <Form.Label className="confirm-address-label">
                            {t('Effective Date')}
                          </Form.Label>
                          <DatePicker
                            className="custom-info-date-picker"
                            placeholderText="MM/DD/YYYY"
                            selected={effectiveDate}
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={(date) => {
                              setEffectiveDate(date);
                              props.handleDateChange(date, 'effectiveDate');
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
            <Col xs="12" className="contact-form-btns">
              <Validation currentStep={4} toggle={toggle} />
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
};

export default WaitingPeriod;
