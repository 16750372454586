import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Alert } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { customStyles } from "../../../../components/reactSelectCustomStyle";
import { getEditEmptyQuoteData } from '../../../../services/floodService';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";

const Policy = (props) => {
    const { emptyQuoteData, floodSystemDD, handleChange, handleDateChange, handleStateChange, checkDateisValid, currentMapDate, isMortgage, validated, isBind } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const currentDate = new Date();
    const [effectiveDate, setEffectiveDate] = useState(null);
    const [waitingPeriod, setWaitingPeriod] = useState('STANDARD30');
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const [loanClosingDate, setLoanClosingDate] = useState(null);
    const [showWarning, setShowWarning] = useState(false);
    const [priorPolicyNo, setPriorPolicyNo] = useState('');
    const [priorCompanyNo, setPriorCompanyNo] = useState('');
    const [PNBD, setPNBD] = useState(null);
    const [PPED, setPPED] = useState(null);
    const [NAICList, setNAICList] = useState({});
    const [isWPChange, setIsWPChange] = useState(false);

    // set state
    useEffect(() => {
        if (Object.keys(emptyQuoteData).length > 0) {
            setWaitingPeriod(emptyQuoteData.propertyInfo.s_PolicyWaitingPeriod || null);
            if (checkDateisValid(emptyQuoteData.effectiveDate)) {
                setEffectiveDate(new Date(emptyQuoteData.effectiveDate) || null);
            }
            if (checkDateisValid(emptyQuoteData.propertyInfo.d_FloodLoanClosingDt)) {
                setLoanClosingDate(new Date(emptyQuoteData.propertyInfo.d_FloodLoanClosingDt) || null);
            }
            setPriorPolicyNo(emptyQuoteData.propertyInfo.s_PriorPolicyNo || null);
            setPriorCompanyNo(emptyQuoteData.propertyInfo.s_PriorCompanyNAIC || null);
            if (checkDateisValid(emptyQuoteData.propertyInfo.d_PriorNBDate)) {
                setPNBD(new Date(emptyQuoteData.propertyInfo.d_PriorNBDate) || null);
            }
            if (checkDateisValid(emptyQuoteData.propertyInfo.s_PriorPolicyExpDt)) {
                setPPED(new Date(emptyQuoteData.propertyInfo.s_PriorPolicyExpDt) || null);
            }
        }
        if (Object.keys(floodSystemDD).length > 0) {
            setNAICList(floodSystemDD.WYOList || null);
        }
    }, [emptyQuoteData, floodSystemDD])

    // trigger on update waiting period to set effective date based on waiting period type
    useEffect(() => {
        const { date, minDate, maxdate } = getMinMaxDate(waitingPeriod);

        setMinDate(minDate); // setting min date
        setMaxDate(maxdate) // setting max date

        if (isWPChange) {
            setEffectiveDate(new Date(date)); // setting effective date
            setIsWPChange(false);
            handleDateChange(new Date(date), 'effectiveDate');
        }
        handleStateChange('POLICY', waitingPeriod, 'waitingPeriod')
    }, [waitingPeriod])

    // show warning
    useEffect(() => {
        var warning = false;
        if (!isMortgage && waitingPeriod === 'LOANNOWAIT') {
            warning = true;
        }
        if (![null, ''].includes(waitingPeriod) && isWPChange) {
            if (waitingPeriod === 'LOANNOWAIT') {
                handleDateChange(new Date(), 'd_FloodLoanClosingDt', 'propertyInfo');
                handleDateChange(new Date(), 'effectiveDate');
            }
            if (!['LOANNOWAIT'].includes(waitingPeriod)) {
                handleDateChange(null, 'd_FloodLoanClosingDt', 'propertyInfo');
                setLoanClosingDate(null);
            }
            if (waitingPeriod !== 'ROLLOVER_RENEWAL') {
                var temp = { s_NAICNumber: null, s_WYOCompany: "" }
                setPNBD(null);
                setPPED(null);
                handleDateChange(null, 'd_PriorNBDate', 'propertyInfo');
                handleNAICChange(temp, '');
            }
            if (waitingPeriod === 'ROLLOVER_RENEWAL') {
                const { date, minDate, maxdate } = getMinMaxDate(waitingPeriod);
                setMinDate(minDate); // setting min date
                setMaxDate(maxdate) // setting max date
                handleDateChange(date, 's_PriorPolicyExpDt', 'propertyInfo');
                handleDateChange(date, 'effectiveDate');
            }
        }
        setShowWarning(warning);
    }, [isMortgage, waitingPeriod, isWPChange]);

    // update state in reducer
    const handleNAICChange = (value, PPN = '') => {
        var emptyQuote = emptyQuoteData;
        emptyQuote['propertyInfo']['s_PriorCompanyNAIC'] = value.s_NAICNumber;
        var tempPriorPolicyNo = priorPolicyNo;
        if (PPN.length === 0) {
            tempPriorPolicyNo = PPN;
        }
        emptyQuote['propertyInfo']['s_PriorPolicyNo'] = tempPriorPolicyNo;
        setPriorPolicyNo(tempPriorPolicyNo);
        setPriorCompanyNo(value.s_NAICNumber);
        dispatch(getEditEmptyQuoteData(emptyQuote));
    }

    // trigger to hide/show Prior Flood Determination Tab
    useEffect(() => {
        if (![null, '', "0000-00-00"].includes(effectiveDate) && ![null, '', "0000-00-00"].includes(currentMapDate)) {
            var months;
            months = (effectiveDate.getFullYear() - currentMapDate.getFullYear()) * 12;
            months -= currentMapDate.getMonth();
            months += effectiveDate.getMonth();
            var temp = false;
            if (months > 13) {
                temp = true;
            }
            handleStateChange('DETERMINATION', temp, 'isCurrMapDateGreater');
        }
    }, [effectiveDate, currentMapDate])

    // get min-max date
    const getMinMaxDate = (waitingPeriod) => {
        var date = new Date(); // current date
        var tempCurrDate = currentDate; // copy of current Date
        var minDate = null, maxdate = null;
        if (['STANDARD30', 'LENDER30'].includes(waitingPeriod)) {
            date = tempCurrDate.setDate(tempCurrDate.getDate() + 30); // ADD 30 DAYS
            minDate = date;
        } else if (waitingPeriod === "MAPREVONEDAY") {
            date = tempCurrDate.setDate(tempCurrDate.getDate() + 1); // ADD 1 DAYS
            minDate = date;
        } else if (waitingPeriod === "ROLLOVER_RENEWAL") {
            var temp = new Date(); // current date
            minDate = tempCurrDate.setDate(tempCurrDate.getDate() - 29); // SUB 29 DAYS
            maxdate = temp.setDate(temp.getDate() + 60); // ADD 60 DAYS
        }

        return {
            date, minDate, maxdate
        }
    }

    return (
        <Form validated={validated}>
            {
                showWarning && (
                    <Col md='12' className='p-0'>
                        <Alert color={'warning'} isOpen={showWarning} className='p-1 pl-2 m-0 mb-1' >
                            <i className='fa fa-exclamation-triangle'></i>&nbsp;&nbsp;When using the loan closing effective date type a lender must be listed. If a lender is not listed at the time of issuance the policy will be adjusted to a 30 day wait.
                        </Alert>
                    </Col>
                )
            }
            <Row className="align-items-end">
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Waiting Period')}
                        </Form.Label>
                        <Form.Select
                            className="table-show-entries-select"
                            name="s_PolicyWaitingPeriod"
                            title={t('Waiting Period')}
                            parentobject="propertyInfo"
                            value={waitingPeriod}
                            onChange={(e) => {
                                setWaitingPeriod(e.currentTarget.value);
                                setIsWPChange(true);
                                handleChange(e);
                            }}
                            required
                            disabled={isBind}
                        >
                            <option value={''}>{t('Select')}</option>
                            {
                                Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODWAITINGPERIOD.map((v, i) => {
                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>

                {
                    waitingPeriod === "LOANNOWAIT" && (
                        <Col xs="12" sm="12" md="6" lg="2" xl="2">
                            <Form.Group
                                className="search-quote-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                    {t('Loan Closing Date')}
                                </Form.Label>
                                <DatePicker
                                    id="dashboard-datepicker"
                                    className={(isBind ? "dashboard-datepicker-main-bg" : "dashboard-datepicker-main")}
                                    placeholderText="MM/DD/YYYY"
                                    selected={loanClosingDate}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    onChange={(date) => {
                                        setLoanClosingDate(date);
                                        setEffectiveDate(date);
                                        handleDateChange(date, 'd_FloodLoanClosingDt', 'propertyInfo');
                                        handleDateChange(date, 'effectiveDate');
                                    }}
                                    autoComplete="off"
                                    disabled={isBind}
                                />
                            </Form.Group>
                        </Col>
                    )
                }

                {
                    waitingPeriod === "ROLLOVER_RENEWAL" && (
                        <>
                            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t('Prior Policy Exp')}
                                    </Form.Label>
                                    <DatePicker
                                        id="dashboard-datepicker"
                                        className={(isBind ? "dashboard-datepicker-main-bg" : "dashboard-datepicker-main")}
                                        placeholderText="MM/DD/YYYY"
                                        selected={PPED}
                                        onChange={(date) => {
                                            setPPED(date);
                                            setEffectiveDate(date);
                                            handleDateChange(date, 's_PriorPolicyExpDt', 'propertyInfo');
                                            handleDateChange(date, 'effectiveDate');
                                        }}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        autoComplete="off"
                                        disabled={isBind}
                                    />
                                </Form.Group>
                            </Col>
                        </>
                    )
                }

                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Effective Date')}
                        </Form.Label>
                        <DatePicker
                            id="dashboard-datepicker"
                            className={(isBind ? "dashboard-datepicker-main-bg" : "dashboard-datepicker-main")}
                            
                            placeholderText="MM/DD/YYYY"
                            selected={effectiveDate}
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={(date) => {
                                setEffectiveDate(date);
                                handleDateChange(date, 'effectiveDate');
                            }}
                            autoComplete="off"
                            readOnly={['ROLLOVER_RENEWAL', 'LOANNOWAIT'].includes(waitingPeriod)}
                            disabled={isBind}
                        />
                    </Form.Group>
                </Col>

                {
                    waitingPeriod === "ROLLOVER_RENEWAL" && (
                        <>
                            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t('Prior Company NAIC')}
                                    </Form.Label>
                                    {
                                        Object.keys(NAICList).length > 0 && (
                                            <Select
                                                styles={customStyles}
                                                title="propertyInfo"
                                                name="s_PriorCompanyNAIC"
                                                options={NAICList}
                                                value={NAICList.filter(item => item.s_NAICNumber === priorCompanyNo)}
                                                getOptionLabel={(option) => option.s_WYOCompany}
                                                getOptionValue={(option) => option.s_NAICNumber}
                                                onChange={(value) => handleNAICChange(value, priorPolicyNo)}
                                                disabled={isBind}
                                            />
                                        )
                                    }
                                </Form.Group>
                            </Col>

                            <Col xs="12" sm="12" md="6" lg="1" xl="1">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t('Policy NO')}
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input-flood"
                                        type="number"
                                        placeholder="0000000000"
                                        name="s_PriorPolicyNo"
                                        title={t('Policy NO#')}
                                        parentobject="propertyInfo"
                                        value={priorPolicyNo || ''}
                                        min={0}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setPriorPolicyNo(e.currentTarget.value);
                                        }}
                                        disabled={isBind}
                                        required
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t('Prior New Business')}
                                    </Form.Label>
                                    <DatePicker
                                        id="dashboard-datepicker"
                                        className={(isBind ? "dashboard-datepicker-main-bg" : "dashboard-datepicker-main")}
                                        placeholderText="MM/DD/YYYY"
                                        selected={PNBD}
                                        onChange={(date) => {
                                            setPNBD(date);
                                            handleDateChange(date, 'd_PriorNBDate', 'propertyInfo');
                                        }}
                                        autoComplete="off"
                                        disabled={isBind}
                                    />
                                </Form.Group>
                            </Col>
                        </>
                    )
                }


            </Row>
        </Form>
    )
}

export default Policy