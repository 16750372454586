import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { getEditEmptyQuoteData, updateInitQuoteApi, pivotApiCall, updateWhichSection, updateLastSection } from "../../../../../../services/floodService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Validation from "../../Validation";
import "react-datepicker/dist/react-datepicker.css";
import "./construction.scss";
import "react-overlay-loader/styles.css";

const ConstructionFormOne = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { emptyQuoteData, initApiObject, apiLoading, whichSection, lastSection } = useSelector(state => state.flood);
  const [validated, setValidated] = useState(false);
  const [isPreFirm, setIsPreFirm] = useState(false);
  const [substantialImprovement, setSubstantialImprovement] = useState('NO');
  const [substantialImprovmentDate, setSubstantialImprovmentDate] = useState(null);
  const [isInsuredNonProfitEnt, setIsInsuredNonProfitEnt] = useState('NO');
  const [buildingCourseConstruction, setBuildingCourseConstruction] = useState('NO');
  const [isBldgNRHP, setIsBldgNRHP] = useState('NO');
  const [isStateSet, setIsStateSet] = useState(false);
  const currentStep = 5;

  // set Initial State from reducer
  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0) {
      setSubstantialImprovement(emptyQuoteData.propertyInfo.s_SubstantialImprovement);
      if (props.checkDateisValid(emptyQuoteData.propertyInfo.d_SubstantialImprovmentDate)) {
        setSubstantialImprovmentDate(new Date(emptyQuoteData.propertyInfo.d_SubstantialImprovmentDate));
      }
      setIsInsuredNonProfitEnt(emptyQuoteData.propertyInfo.s_IsInsuredNonProfitEnt);
      setBuildingCourseConstruction(emptyQuoteData.propertyInfo.s_BuildingCourseConstruction);
      setIsBldgNRHP(emptyQuoteData.propertyInfo.s_IsBldgNRHP);
      setIsStateSet(true);
    }
  }, [])

  useEffect(() => {
    if (isStateSet === true) {
      if (emptyQuoteData && initApiObject) {
        const reqJson = { ...initApiObject }; // Copy
        reqJson.buildingUnderConstruction = emptyQuoteData.propertyInfo.s_IsInsuredNonProfitEnt
        reqJson.substantialImprovementDate = emptyQuoteData.propertyInfo.d_SubstantialImprovmentDate

        // To update
        if (currentStep > lastSection) {
          dispatch(updateWhichSection('FIRST'));
          dispatch(updateLastSection(currentStep));
        }

        reqJson.whichSection = whichSection;

        // To update
        dispatch(updateInitQuoteApi(reqJson));

        const code = 'QUOTE_NEWBUSINESS';
        const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0 };

        // Original Construction Date must be less than Substantial Improvement
        if (emptyQuoteData.propertyInfo.s_SubstantialImprovement === 'YES' && ((![null, ''].includes(emptyQuoteData.propertyInfo.d_SubstantialImprovmentDate) && (emptyQuoteData.propertyInfo.d_SubstantialImprovmentDate < emptyQuoteData.propertyInfo.d_DateOfConstruction)) || [null, ''].includes(emptyQuoteData.propertyInfo.d_SubstantialImprovmentDate))) {
          toast.error(t('Original Construction Date must be less than Substantial Improvement Date'));
        } else {
          // Call Api
          dispatch(pivotApiCall(code, request));
        }
        // Check Firm Type
        checkFirmType()
      }
    }
  }, [isStateSet, buildingCourseConstruction, substantialImprovmentDate]);

  useEffect(() => {
    if (isStateSet === true) {
      var temp = null;
      if (['YES'].includes(substantialImprovement)) {
        temp = substantialImprovmentDate;
      }
      if (!['YES'].includes(substantialImprovement)) {
        temp = null;
      }
      const emptyQuote = { ...emptyQuoteData }; // Copy
      emptyQuote.propertyInfo.d_SubstantialImprovmentDate = temp;
      setSubstantialImprovmentDate(temp);
      dispatch(getEditEmptyQuoteData(emptyQuote));

      const reqJson = { ...initApiObject }; // Copy
      reqJson.substantialImprovementDate = temp;
      reqJson.whichSection = 'FIRST';

      // To update
      dispatch(updateInitQuoteApi(reqJson));
    }
  }, [isStateSet, substantialImprovement]);

  const toggle = (e) => {
    setValidated(e);
  }

  //Check If Firm is Pre-Firm Or not
  const checkFirmType = () => {
    const emptyQuote = { ...emptyQuoteData };
    var temp = emptyQuote.propertyInfo.s_FloodConstructionType;
    if ((new Date(emptyQuoteData.propertyInfo.d_DateOfConstruction) <= new Date('1974-12-31')) || (new Date(emptyQuoteData.propertyInfo.d_DateOfConstruction) < new Date(emptyQuoteData.propertyInfo.d_InitialFirmDate))) {
      temp = 'FLDPREFIRM';
    }
    if (substantialImprovement === 'YES' && ![null, ''].includes(substantialImprovmentDate)) {
      temp = 'FLDPOSTFIRM';
    }
    // default historical question to no if it is post firm, and if it is pre firm ask the question
    if (temp === 'FLDPOSTFIRM') {
      emptyQuote.propertyInfo.s_IsBldgNRHP = 'NO';
    }

    emptyQuote.propertyInfo.s_FloodConstructionType = temp;
    dispatch(getEditEmptyQuoteData(emptyQuote));
    setIsPreFirm(temp)
  }

  // handle switch Change
  const handleChange = (e, state) => {
    var temp = 'NO';
    const { attributes, checked } = e.target;
    if (checked) {
      temp = attributes.checkedvalue.value;
    }
    switch (state) {
      case 'substantialImprovement':
        setSubstantialImprovement(temp);
        break;
      case 'isInsuredNonProfitEnt':
        setIsInsuredNonProfitEnt(temp);
        break;
      case 'buildingCourseConstruction':
        setBuildingCourseConstruction(temp);
        break;
      case 'isBldgNRHP':
        setIsBldgNRHP(temp);
        break;
      default:
        break;
    }
    props.handleSwitchChange(e);
  }

  return (
    <LoadingOverlay >
      <Loader loading={apiLoading} />
      <section className="flood-construction-section">
        {
          Object.keys(emptyQuoteData).length === 0 && <Navigate to={'/flood/instantquote/step/address'} />
        }
        {/* Form */}
        <Row className="justify-content-center">
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="8"
            xl="8"
            className="flood-construction-form-section"
          >
            <Row className="justify-content-center">
              <Col xs="10">
                <div className="flood-construction-header">
                  <div className="flood-construction-title">
                    <h4 className="flood-construction-main-title">
                      {t('Construction')}
                    </h4>
                    <p className="flood-construction-main-desc">
                      {t('Please answer all the construction related questions:')}.
                    </p>
                  </div>
                  <div className="flood-construction-pricing">
                    <h5 className="flood-construction-pricing-title">
                      {t('Current Premium')}
                    </h5>
                    <p className="flood-construction-pricing-value">
                      {'$' + props.premium}<span>/{t('Year')}</span>
                    </p>
                  </div>
                </div>
                <Row>
                  <Col xs="12">
                    <div className="flood-construction-row">
                      <div className="flood-construction-question-div">
                        <p>{t('Has the building been substantially improved')}?</p>
                        <div className="flood-construction-row-actions">
                          <span>{t('No')}</span>
                          <div className="custom-main-swtich">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              className="flood-construction-row-switch"
                              label=""
                              parentobject="propertyInfo"
                              name="s_SubstantialImprovement"
                              checked={substantialImprovement === 'YES' ? true : false}
                              onChange={(e) => { handleChange(e, 'substantialImprovement') }}
                              checkedvalue='YES'
                              uncheckedvalue='NO'
                            />
                          </div>
                          <span>{t('Yes')}</span>
                        </div>
                      </div>
                      {substantialImprovement === 'YES' && (
                        <>
                          <div className="flood-construction-answer-div">
                            <p>{t('Date of substantial improvement')}</p>
                            <div className="flood-construction-answer-actions">
                              <DatePicker
                                id="flood-construction-date-picker"
                                className="custom-info-date-picker"
                                placeholderText="MM/DD/YYYY"
                                selected={substantialImprovmentDate}
                                onChange={(date) => {
                                  setSubstantialImprovmentDate(date);
                                  props.handleDateChange(date, 'd_SubstantialImprovmentDate', 'propertyInfo');
                                }}
                              />
                            </div>
                          </div>
                          {
                            isPreFirm && (
                              <div className="flood-construction-question-div">
                                <p>{t('Is building listed in the National Register of Historic Places')}?</p>
                                <div className="flood-construction-row-actions">
                                  <span>{t('No')}</span>
                                  <div className="custom-main-swtich">
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      className="flood-construction-row-switch"
                                      label=""
                                      parentobject="propertyInfo"
                                      name="s_IsBldgNRHP"
                                      checked={isBldgNRHP === 'YES' ? true : false}
                                      onChange={(e) => { handleChange(e, 'isBldgNRHP') }}
                                      checkedvalue='YES'
                                      uncheckedvalue='NO'
                                      disabled={!isPreFirm}
                                    />
                                  </div>
                                  <span>{t('Yes')}</span>
                                </div>
                              </div>
                            )
                          }
                        </>
                      )}
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="flood-construction-row">
                      <div className="flood-construction-question-div">
                        <p>{t('Is the building currently under construction')}?</p>
                        <div className="flood-construction-row-actions">
                          <span>{t('No')}</span>
                          <div className="custom-main-swtich">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              className="flood-construction-row-switch"
                              label=""
                              parentobject="propertyInfo"
                              name="s_BuildingCourseConstruction"
                              checked={buildingCourseConstruction === 'YES' ? true : false}
                              onChange={(e) => { handleChange(e, 'buildingCourseConstruction') }}
                              checkedvalue='YES'
                              uncheckedvalue='NO'
                            />
                          </div>
                          <span>{t('Yes')}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="flood-construction-row">
                      <div className="flood-construction-question-div">
                        <p>{t('Is the insured a non-profit entity')}?</p>
                        <div className="flood-construction-row-actions">
                          <span>{t('No')}</span>
                          <div className="custom-main-swtich">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              className="flood-construction-row-switch"
                              label=""
                              parentobject="propertyInfo"
                              name="s_IsInsuredNonProfitEnt"
                              checked={isInsuredNonProfitEnt === 'YES' ? true : false}
                              onChange={(e) => { handleChange(e, 'isInsuredNonProfitEnt') }}
                              checkedvalue='YES'
                              uncheckedvalue='NO'
                            />
                          </div>
                          <span>{t('Yes')}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" className="form-btns">
                    <Validation currentStep={5} toggle={toggle} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </LoadingOverlay >

  );
};

export default ConstructionFormOne;
