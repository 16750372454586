import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import i18n from "./i18n";
import LangContextProvider from "./contexts/LangContext";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./store"

ReactDOM.render(
  <React.StrictMode>
    <LangContextProvider>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </LangContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
