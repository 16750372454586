import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getEditEmptyQuoteData } from '../../../../services/floodService';

const BuildingFoundation = (props) => {
    const { emptyQuoteData, floodSystemDD, handleChange, handleStateChange, occupancyType, isMobileHome, validated, isBind } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [BOW, setBOW] = useState('');
    const [CONTYPE, setCONTYPE] = useState('');
    const [buildingFoundationType, setBuildingFoundationType] = useState('');
    const [noOfFloor, setNoOfFloor] = useState('');
    const [NODS, setNODS] = useState('');
    const [TSF, setTSF] = useState('');
    const [NOE, setNOE] = useState('');
    const [onWhichFloor, setOnWhichFloor] = useState('');
    const [sizeOfEnclosure, setSizeOfEnclosure] = useState('');
    const [lable, setLable] = useState('');

    // set state
    useEffect(() => {
        if (Object.keys(emptyQuoteData).length > 0) {
            setBOW(emptyQuoteData.propertyInfo.s_BuildingOverWater || null);
            setBuildingFoundationType(emptyQuoteData.propertyInfo.s_Foundation || null);
            setNoOfFloor(emptyQuoteData.propertyInfo.n_NoOfFloors || null);
            setTSF(emptyQuoteData.propertyInfo.s_TotalSqFootage || null);
            setNOE(emptyQuoteData.propertyInfo.n_NoOfElevator || null);
            setOnWhichFloor(emptyQuoteData.propertyInfo.s_UnitLocatedOnFloor || null)
            setCONTYPE(emptyQuoteData.propertyInfo.s_BldgConstructionType || null)
            setNODS(emptyQuoteData.propertyInfo.s_NoOfDetatchedStruct || null)
            setSizeOfEnclosure(emptyQuoteData.propertyInfo.s_AreaInSqFt || null)
        }
    }, [emptyQuoteData])

    // To Update parent fundationtype state
    useEffect(() => {
        handleStateChange('BUILDING', buildingFoundationType, 'FT');
        handleState('FT', buildingFoundationType);
    }, [buildingFoundationType]);

    // To Update label
    useEffect(() => {
        var temp = 'Total sq footage of ' + (['RESIDENTIALUNIT20', 'NONRESIDENTIALUNIT20'].includes(occupancyType) ? 'unit' : 'building');
        setLable(temp);
        handleState('OT', occupancyType);
    }, [occupancyType, noOfFloor]);

    // To Update reducer state
    useEffect(() => {
        handleState('isMobileHome', isMobileHome);
    }, [isMobileHome]);

    // To Update reducer state
    const handleState = (state, value) => {
        var emptyQuote = emptyQuoteData;
        if(![null, ''].includes(value)){
            switch (state) {
                case 'isMobileHome':
                    if (value === false) {
                        emptyQuote['propertyInfo']['s_NoOfElevator'] = 0;
                        setNOE(0);
                    }
                    break;
                case 'FT':
                    if (!(['ELEVATEDWITHENCLOSURE20', 'ELEVATEDWITHENCLOSURENOTPPP20', 'ELEWATEDONCRAWLSPACE20'].includes(value))) {
                        emptyQuote['propertyInfo']['s_AreaInSqFt'] = 0;
                        setSizeOfEnclosure(0);
                    }
                    break;
                case 'OT':
                    if (!((['RESIDENTIALUNIT20', 'NONRESIDENTIALUNIT20'].includes(value)) && noOfFloor > 1)) {
                        emptyQuote['propertyInfo']['s_UnitLocatedOnFloor'] = 0;
                        setOnWhichFloor(0);
                    }
                    break;
                default:
                    break;
            }
            dispatch(getEditEmptyQuoteData(emptyQuote));
        }
    }

    return (
        <Form validated={validated}>
            <Row className="align-items-end">
                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Select Building Foundation Type')}:
                        </Form.Label>
                        <Form.Select
                            className="dashboard-group-input-flood"
                            id="select-ym-option"
                            name="s_Foundation"
                            title={t('Select Building Foundation Type')}
                            parentobject="propertyInfo"
                            value={buildingFoundationType}
                            onChange={(e) => {
                                setBuildingFoundationType(e.currentTarget.value);
                                handleChange(e);
                            }}
                            disabled={isBind}
                        >
                            <option value={''}>{t('Select')}</option>
                            {
                                Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODFOUNDATION20.map((v, i) => {
                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Is the Building Over Water')}?
                        </Form.Label>
                        <Form.Select
                            className="dashboard-group-input-flood"
                            id="select-ym-option"
                            name="s_BuildingOverWater"
                            title={t('Is the Building Over Water')}
                            parentobject="propertyInfo"
                            value={BOW}
                            onChange={(e) => {
                                setBOW(e.currentTarget.value);
                                handleChange(e);
                            }}
                            required
                            disabled={isBind}
                        >
                            <option value={''}>{t('Select')}</option>
                            {
                                Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODBULGOVERWATER.map((v, i) => {
                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t(lable)}:
                        </Form.Label>
                        <Form.Control
                            className="dashboard-group-input-flood"
                            type="number"
                            placeholder="0"
                            name="s_TotalSqFootage"
                            title={t('in feet')}
                            parentobject="propertyInfo"
                            value={TSF}
                            min={0}
                            onChange={(e) => {
                                handleChange(e);
                                setTSF(e.currentTarget.value);
                            }}
                            required
                            disabled={isBind}
                        />
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Total No of Floor')}:
                        </Form.Label>
                        <Form.Control
                            className="dashboard-group-input-flood"
                            type="number"
                            placeholder="0"
                            name="n_NoOfFloors"
                            title={t('in feet')}
                            parentobject="propertyInfo"
                            value={noOfFloor}
                            min={0}
                            onChange={(e) => {
                                handleChange(e);
                                setNoOfFloor(e.currentTarget.value);
                            }}
                            required
                            disabled={isBind}
                        />
                    </Form.Group>
                </Col>
                {
                    !isMobileHome && (
                        <Col xs="12" sm="12" md="6" lg="2" xl="2">
                            <Form.Group
                                className="search-quote-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                    {t('No of Elevators')}
                                </Form.Label>
                                <Form.Control
                                    className="dashboard-group-input-flood"
                                    type="number"
                                    placeholder="0"
                                    name="s_NoOfElevator"
                                    title={t('in feet')}
                                    parentobject="propertyInfo"
                                    value={NOE}
                                    min={0}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setNOE(e.currentTarget.value);
                                    }}
                                    required
                                    disabled={isBind}
                                />
                            </Form.Group>
                        </Col>
                    )
                }
                {
                    ((['RESIDENTIALUNIT20', 'NONRESIDENTIALUNIT20'].includes(occupancyType)) && noOfFloor > 1) && (
                        <Col xs="12" sm="12" md="6" lg="2" xl="2">
                            <Form.Group
                                className="search-quote-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                    {t('Which floor is the unit located on')}
                                </Form.Label>
                                <Form.Control
                                    className="dashboard-group-input-flood"
                                    type="number"
                                    placeholder="0"
                                    name="s_UnitLocatedOnFloor"
                                    title={t('in feet')}
                                    parentobject="propertyInfo"
                                    value={onWhichFloor}
                                    min={0}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setOnWhichFloor(e.currentTarget.value);
                                    }}
                                    disabled={isBind}
                                />
                            </Form.Group>
                        </Col>
                    )
                }

                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Building Construction Type')}?
                        </Form.Label>
                        <Form.Select
                            className="dashboard-group-input-flood"
                            id="select-ym-option"
                            name="s_BldgConstructionType"
                            title={t('Building Construction Type')}
                            parentobject="propertyInfo"
                            value={CONTYPE}
                            onChange={(e) => {
                                setCONTYPE(e.currentTarget.value);
                                handleChange(e);
                            }}
                            required
                            disabled={isBind}
                        >
                            <option value={''}>{t('Select')}</option>
                            {
                                Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODCONSTRUCTIONTYPE20.map((v, i) => {
                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Number Of Detached Structures')}?
                        </Form.Label>
                        <Form.Control
                            className="dashboard-group-input-flood"
                            type="number"
                            placeholder="0"
                            name="s_NoOfDetatchedStruct"
                            title={t('Number Of Detached Structures')}
                            parentobject="propertyInfo"
                            value={NODS}
                            min={0}
                            onChange={(e) => {
                                handleChange(e);
                                setNODS(e.currentTarget.value);
                            }}
                            disabled={isBind}
                        />
                    </Form.Group>
                </Col>
                {
                    ['ELEVATEDWITHENCLOSURE20', 'ELEVATEDWITHENCLOSURENOTPPP20', 'ELEWATEDONCRAWLSPACE20'].includes(buildingFoundationType) && (
                        <Col xs="12" sm="12" md="6" lg="2" xl="2">
                            <Form.Group
                                className="search-quote-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                    {t('Size of enclosure in Sq feet')}?
                                </Form.Label>
                                <Form.Control
                                    className="dashboard-group-input-flood"
                                    type="number"
                                    placeholder="0"
                                    name="s_AreaInSqFt"
                                    title={t('In feet')}
                                    parentobject="propertyInfo"
                                    value={sizeOfEnclosure}
                                    min={0}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setSizeOfEnclosure(e.currentTarget.value);
                                    }}
                                    disabled={isBind}
                                />
                            </Form.Group>
                        </Col>
                    )
                }
            </Row>
        </Form>
    )
}

export default BuildingFoundation