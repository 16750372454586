import React, { Fragment, useState, useEffect } from "react";
import { Button, Row, Col, Form, Table, Accordion } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import ErrorLog from "./modals/ErrorLog.js"
import HistoryData from "./modals/HistoryData.js"
import { useDispatch, useSelector , connect } from 'react-redux';
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useTranslation } from "react-i18next";
const QuoteButtons = (props) => {
    const [isOpen, setIsopen] = useState(false);
    const [viewPart, setViewPart] = useState("");
    const { t } = useTranslation();

    const Setcondition = ($con) => {
      if($con == "ERROR-LOG" && props.quoteData.transactionInfo.n_potransaction_PK != null){
        setIsopen(true);
        setViewPart("ERROR-LOG");
      }
      if($con == "HISTORY-DATA" && props.quoteData.premiumData.n_potranpremdetails_PK != null){
        setIsopen(true);
        setViewPart("HISTORY-DATA");
      }
    }
	
	const callGeneratePdf = (source) => {
		props.callGeneratePdf(source);
	  }

    return(<>
	<LoadingOverlay >
		<Loader loading={props.rateLoader} />
	  { 
        <Fragment>
            { props.quoteData.transactionInfo.n_potransaction_PK != null ? 
                <> 
                <Button variant="primary" id="search-quote-btn" onClick={() => callGeneratePdf('RATESHEET')}>
                    {" "}
                    {t("Rate Sheet")}{" "}
                </Button>{" "}
                <Button variant="primary" id="search-quote-btn" onClick={() => callGeneratePdf('PRINTQUOTE')}>
                    {t("Print Qoute")}
                </Button>
                <Button variant="primary" id="search-quote-btn">
                    {t("Send for E-Signature")}
                </Button>{" "}
                <Button variant="primary" id="search-quote-btn" onClick={() => callGeneratePdf('PRINTAPP')}>
                    {t("Print App")}
                </Button>{" "}
                <Button variant="primary" onClick={() => {Setcondition('HISTORY-DATA')}}  id="search-quote-btn" >
                    {t("History Data")}
                </Button>{" "}
                <Button variant="primary" onClick={() => {Setcondition('ERROR-LOG')}} id="search-quote-btn" >
                    {t("Error Log")}
                </Button>{" "}
                <Button variant="primary" id="search-quote-btn" >
                    {t("FhCF Rate Sheet")}
                </Button>{" "}
                <Button variant="primary" id="close-all-btn" >
                    {t("Close All")}
                </Button>{" "}
                </>
                : null
            } 

            { viewPart == "ERROR-LOG" && props.quoteData.transactionInfo.n_potransaction_PK != null ?
                <ErrorLog
                n_potransaction_PK ={props.quoteData.transactionInfo.n_potransaction_PK} 
                isOpen = {setIsopen}
                isShow = {isOpen}
                />
                : 
                viewPart == "HISTORY-DATA" && props.quoteData.premiumData.n_potranpremdetails_PK != null ?
                <HistoryData
                n_potranpremdetails_PK ={props.quoteData.premiumData.n_potranpremdetails_PK} 
                n_potransaction_PK ={props.quoteData.transactionInfo.n_potransaction_PK} 
                isOpen = {setIsopen}
                isShow = {isOpen}
                />
                :
                null
            }

        </Fragment>
		}
		</LoadingOverlay>
		</>
    )
};

function mapStateToProps(state) {
	return {
	  	quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	};
  } 

export default connect(mapStateToProps)(QuoteButtons)