import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, Modal, ModalDialog } from "react-bootstrap";
import GoogleMap from "../google-map/GoogleMap";
import { useTranslation } from "react-i18next";
import "./flood-property-detail.scss";
import { getEditEmptyQuoteData, updateInitQuoteApi } from "../../../../../../services/floodService";
import { Navigate } from "react-router-dom";
import Validation from "../../Validation";
import DatePicker from "react-datepicker";
import Draggable from 'react-draggable';

const FloodPropertyDetail = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { emptyQuoteData, initApiObject, floodSystemDD } = useSelector(state => state.flood);
  const [validated, setValidated] = useState(false);
  const [yearBuilt, setYearBuiltd] = useState('');
  const [usage, setUsage] = useState('');
  const [foundation, setFoundation] = useState('');
  const [bldgConstructionType, setBldgConstructionType] = useState('');
  const [noOfFloors, setNoOfFloors] = useState('');
  const [areaInSqFt, setAreaInSqFt] = useState('');
  const [totalSqFootage, setTotalSqFootage] = useState('');
  const [viewType, setViewType] = useState('');
  const [isLocated, setIsLocated] = useState(false);
  const [DOC, setDOC] = useState(null);
  const [label, setLabel] = useState('');
  const [isStateSet, setIsStateSet] = useState(false);
  const [model, setModal] = useState(null);
  const [sflabel, setSflabel] = useState('Square footage');
  const [isResidentialUnit, setIsResidentialUnit] = useState('YES');
  const [condoAssociation, setCondoAssociation] = useState('');
  const [floorOfUnit, setFloorOfUnit] = useState('');
  const [description, setDescription] = useState('');
  const [descLabel, setDescLabel] = useState('Building Description');
  const [noOfUnit, setNoOfUnit] = useState(0);
  const [descDD, setDescDD] = useState([]);
  const [noOfFloorLabel, setNoOfFloorLabel] = useState('');

  const toggle = (e) => {
    setValidated(e);
    setModal(null);
  }

  // Trigger to Set Primary data
  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0) {
      var noOflabel = 'Number of stories';
      setYearBuiltd(emptyQuoteData.propertyInfo.s_YearBuilt || '')
      setUsage(emptyQuoteData.propertyInfo.s_Usage || '')
      setFoundation(emptyQuoteData.propertyInfo.s_Foundation || '')
      setBldgConstructionType(emptyQuoteData.propertyInfo.s_BldgConstructionType || '')
      setNoOfFloors(emptyQuoteData.propertyInfo.n_NoOfFloors || '')
      setAreaInSqFt(emptyQuoteData.propertyInfo.s_AreaInSqFt || '')
      setTotalSqFootage(emptyQuoteData.propertyInfo.s_TotalSqFootage || '')
      setViewType(emptyQuoteData.viewType);

      // MOBILEHOME
      if (emptyQuoteData.viewType === 'MOBILEHOME') {
        if (props.checkDateisValid(emptyQuoteData.propertyInfo.d_DateOfConstruction)) {
          setDOC(new Date(emptyQuoteData.propertyInfo.d_DateOfConstruction));
        }
        var temp = emptyQuoteData.propertyInfo.s_DtOfConstructionSource === 'LOCATEDIN' ? true : false;
        setIsLocated(temp);
      }

      if (['UNIT', 'RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(emptyQuoteData.viewType)) {
        noOflabel = 'Number of Floor';
        var view = emptyQuoteData.viewType;
        var label = 'Unit Square footage';
        var bldgDescription = 'Unit Description';
        if (view === 'UNIT') {
          setFloorOfUnit(emptyQuoteData.propertyInfo.s_UnitLocatedOnFloor);
        }
        if (['RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(view)) {
          label = 'Building Square footage';
          bldgDescription = 'Building Description'
        }
        setDescLabel(bldgDescription);
        setSflabel(label);
        setCondoAssociation(emptyQuoteData.propertyInfo.s_CondoOwnership);
        setDescription(emptyQuoteData.propertyInfo.s_BuildingUse);
        setNoOfUnit(emptyQuoteData.propertyInfo.s_NoOfUnits);
      }
      setNoOfFloorLabel(noOflabel);
      setIsStateSet(true);
    }
  }, [emptyQuoteData]);

  // set Dropdown
  useEffect(() => {
    if (Object.keys(floodSystemDD).length > 0) {
      var dropdown = floodSystemDD.FLOODBUILDINGUSE20;
      var dd = [];
      if (emptyQuoteData.viewType === 'UNIT') {
        if (isResidentialUnit === 'YES' && condoAssociation === 'NO') {
          dd = dropdown.filter(x => ['COOPERATIVEUNIT20', 'APARTMENTUNIT20'].includes(x.s_AppCodeName))
        }
        if (isResidentialUnit === 'YES' && condoAssociation === 'YES') {
          dd = dropdown.filter(x => ['RESIDENTIALCONDOUNITRB20', 'RESIDENTIALCONDOUNITNRB20'].includes(x.s_AppCodeName))
        }

        if (isResidentialUnit === 'NO') {
          dd = dropdown.filter(x => ['RECREATIONBLDG20', 'COMMERCIAL20', 'HOUSEOFWORSHIP20', 'GOVOWNED20'].includes(x.s_AppCodeName))
        }
      }

      if (viewType === 'RESIDENTIALBLDG') {
        if (condoAssociation === 'NO' && noOfUnit > 0) {
          var array = [];
          var unit = parseInt(noOfUnit);
          if(unit >= 1 && unit <= 4){
            array.push('DETACHEDGUESTHOUSE20');  
          }
          if (unit >= 2) {
            array.push('ENTIREAPARTMENTBLDG20', 'ENTIRECOOPERATIVEBLDG20');
            if(unit <= 4){
              array.push('MAINHOUSEBLDG20', 'DETACHEDGUESTHOUSE20');
            }
          }
          dd = dropdown.filter(x => array.includes(x.s_AppCodeName));
        }
      }

      if (viewType === 'NONRESIDENTIALBLDG') {
        dd = dropdown.filter(x => ['COMMERCIAL20', 'RECREATIONBLDG20', 'GOVOWNED20', 'AGRICULTURALBLDG20', 'TOOLSTORAGESHED20', 'DETACHEDGARAGE20', 'HOUSEOFWORSHIP20'].includes(x.s_AppCodeName));
      }
      setDescDD(dd);
    }
  }, [noOfUnit, isResidentialUnit, condoAssociation]);

  // Trigger to update primary residency values based on Usages
  useEffect(() => {
    if (isStateSet === true) {
      if (Object.keys(emptyQuoteData).length > 0 && Object.keys(initApiObject)) {
        const reqJson = initApiObject;
        const emptyQuote = emptyQuoteData;

        // Allow Primary Residency Discount If Usage not equal to SEASONAL, RENT OR UNDERCONST
        var temp = 'YES', isTenantorOwner = 'OWNER';
        if (['SEASONAL', 'RENT', 'UNDERCONST'].includes(usage)) {
          temp = 'NO'
        }
        if ((['UNIT'].includes(viewType) && condoAssociation === 'YES') || (['RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(viewType))) {
          temp = 'NO';
        }

        reqJson.primaryResidence = temp;
        reqJson.type = emptyQuoteData.viewType;

        emptyQuote.propertyInfo.s_InsuredResides = temp

        temp = 'NO';
        if (['RENT', 'TENANT'].includes(usage)) {
          temp = 'YES';
          isTenantorOwner = 'TENANT';
        }
        emptyQuote.propertyInfo.s_isInsuredTenant = isTenantorOwner;
        emptyQuote.propertyInfo.s_RentalProperty = temp;


        if (viewType === 'RESIDENTIALBLDG' && condoAssociation === 'YES') {
          reqJson.occupancyType = 'RESIDENTIALCONDOBUILD20';
          reqJson.buildingDescription = 'ENTIRERESCONDOBLDG20';
          emptyQuote.propertyInfo.s_Occupancy = 'RESIDENTIALCONDOBUILD20';
          emptyQuote.propertyInfo.s_BuildingUse = 'ENTIRERESCONDOBLDG20';
        } 
        if (viewType === 'RESIDENTIALBLDG' && condoAssociation === 'NO') {
          reqJson.occupancyType = '';
          emptyQuote.propertyInfo.s_Occupancy = '';
        }

        // To update
        dispatch(updateInitQuoteApi(reqJson));
        dispatch(getEditEmptyQuoteData(emptyQuote));
      }
    }
  }, [usage, emptyQuoteData, condoAssociation]);

  useEffect(() => {
    var temp = isLocated === true ? 'LOCATEDIN' : 'LOCATEDOUTSIDE';
    if (Object.keys(emptyQuoteData).length > 0) {
      const emptyQuote = { ...emptyQuoteData };
      emptyQuote.propertyInfo.s_DtOfConstructionSource = temp;
      dispatch(getEditEmptyQuoteData(emptyQuote));
    }
    var label = 'Date of Permanent Placement';
    if (isLocated === true) {
      label = 'Date of Park/Subdivision was established';
    }
    setLabel(label);
  }, [isLocated]);

  const openModal = () => {
    setModal(<Modal size="lg" id='confirmPropInfo' dialogAs={DraggableModalDialog} show={true}>
      <Modal.Header>
        <Modal.Title>{t('Confirm Your Property Information')}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'justify' }}>
        <h4>{t("Please verify the information displayed. By confirming the details, we can provide the most accurate premium. Then click 'Next' to continue")}.</h4>
      </Modal.Body>
      <Modal.Footer>
        <Validation currentStep={1} toggle={toggle} closeModal={setModal} />
      </Modal.Footer>
    </Modal >)
  }

  // Draggable Modal
  const DraggableModalDialog = (props) => {
    return (
      <Draggable handle={'#confirmPropInfo'}>
        <ModalDialog {...props} />
      </Draggable>
    )
  }

  const handleStateChange = (e, state) => {
    var temp = 'NO';
    const { value, attributes, checked } = e.target;

    if (checked && ['s_IsResidentialUnit', 's_CondoOwnership'].includes(state)) {
      temp = attributes.checkedvalue.value;
    }

    switch (state) {
      case 's_IsResidentialUnit':
        setIsResidentialUnit(temp);
        break;
      case 's_CondoOwnership':
        setCondoAssociation(temp);
        break;
      case 's_UnitLocatedOnFloor':
        setFloorOfUnit(value);
        break;
      case 's_BuildingUse':
        setDescription(value);
        break;
      case 's_NoOfUnits':
        setNoOfUnit(value);
        break;
      default:
        break;
    }

    if (['s_CondoOwnership'].includes(state)) {
      updateUnitDescription();
      props.handleSwitchChange(e);
    } else {
      if (!['s_IsResidentialUnit'].includes(state)) {
        props.handleChange(e);
      }
    }
  }

  // 
  const updateUnitDescription = () => {
    const emptyQuote = { ...emptyQuoteData };
    emptyQuote.propertyInfo.s_BuildingUse = '';
    setDescription('');
    dispatch(getEditEmptyQuoteData(emptyQuote));
  }

  return (
    <section className="property-details-section">
      {
        Object.keys(emptyQuoteData).length === 0 && <Navigate to={'/flood/instantquote/step/address'} />
      }
      {/* Form */}
      <Row className="justify-content-center">
        <Col xs="12" sm="12" md="10" lg="8" xl="8" className="p-form">
          <h1 style={{ paddingLeft: "42px" }}>
            {t('Please Check Property Details')}
          </h1>
          <Row className="justify-content-start">
            <Col xs="12">
              <Form validated={validated} className="confirm-address-form">
                <Row>
                  <Col xs="12" sm="6" md="6" lg="4" xl="4">
                    <Form.Group
                      className="confirm-address-field"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="confirm-address-label">
                        {t(sflabel)}
                      </Form.Label>
                      <Form.Control
                        className="confirm-address-input"
                        type="text"
                        placeholder=""
                        name='s_TotalSqFootage'
                        title={t(sflabel)}
                        parentobject="propertyInfo"
                        value={totalSqFootage}
                        defaultValue={''}
                        onChange={(e) => {
                          props.handleChange(e);
                          setTotalSqFootage(e.currentTarget.value);
                        }}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {t('Please Add Square footage error')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {
                    ['HOME', 'UNIT', 'RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(viewType) && (
                      <Col xs="12" sm="6" md="6" lg="4" xl="4">
                        <Form.Group
                          controlId="formBasicPassword"
                          className="confirm-address-field"
                        >
                          <Form.Label className="confirm-address-label">
                            {t('Year built')}
                          </Form.Label>
                          <Form.Control
                            className="confirm-address-input"
                            type="text"
                            placeholder=""
                            name='s_YearBuilt'
                            title={t('Year built')}
                            parentobject="propertyInfo"
                            value={yearBuilt}
                            defaultValue={''}
                            onChange={(e) => {
                              props.handleChange(e);
                              setYearBuiltd(e.currentTarget.value);
                            }}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t('Please Add Year Build Error')}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    )
                  }
                  <Col xs="12" sm="6" md="6" lg="4" xl="4">
                    <Form.Group className="confirm-address-field">
                      <Form.Label className="confirm-address-label">
                        {t('Construction type')}
                      </Form.Label>
                      <Form.Select
                        className="confirm-address-input"
                        name='s_BldgConstructionType'
                        title={t('Construction type')}
                        parentobject="propertyInfo"
                        value={bldgConstructionType}
                        onChange={(e) => {
                          props.handleChange(e);
                          setBldgConstructionType(e.currentTarget.value);
                        }}
                        required
                      >
                        <option value={''}>{t('Select')}</option>
                        {
                          Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODCONSTRUCTIONTYPE20.map((v, i) => {
                            return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                          })
                        }
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {t('Please Select Construction Type Error')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="6" md="6" lg="4" xl="4">
                    <Form.Group
                      className="confirm-address-field"
                      controlId="formBasicPassword"
                    >
                      <Form.Label className="confirm-address-label">
                        {t(noOfFloorLabel)}
                      </Form.Label>
                      <Form.Control
                        className="confirm-address-input"
                        type="number"
                        placeholder=""
                        name='n_NoOfFloors'
                        title={t('Number of stories')}
                        parentobject="propertyInfo"
                        value={noOfFloors}
                        defaultValue={''}
                        min={0}
                        onChange={(e) => {
                          props.handleChange(e);
                          setNoOfFloors(e.currentTarget.value);
                        }}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("Please Add " + noOfFloorLabel + " Error")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {
                    !['RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(viewType) && (
                      <Col xs="12" sm="6" md="6" lg="4" xl="4">
                        <Form.Group
                          className="confirm-address-field"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className="confirm-address-label">
                            {t('Usage')}
                          </Form.Label>
                          <Form.Select
                            className="confirm-address-input"
                            name="s_Usage"
                            title={t('Usage')}
                            parentobject="propertyInfo"
                            value={usage}
                            onChange={(e) => {
                              props.handleChange(e);
                              setUsage(e.currentTarget.value);
                            }}
                            required
                          >
                            <option value={''}>{t('Select')}</option>
                            {
                              Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLDUSAGETYPE.map((v, i) => {
                                return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                              })
                            }
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {t('Please Select Usage Error')}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    )
                  }
                  {
                    ['UNIT', 'RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(viewType) && (
                      <>
                        {
                          viewType === 'UNIT' && (
                            <Col xs="12" sm="6" md="6" lg="4" xl="4">
                              <Form.Group
                                className="confirm-address-field"
                                controlId="formBasicPassword"
                              >
                                <Form.Label className="confirm-address-label">
                                  {t('Residential Unit')}
                                </Form.Label>
                                <div className="flood-construction-row">
                                  <div className="flood-construction-row-actions">
                                    <span>{t('No')}</span>
                                    <div className="custom-main-swtich">
                                      <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        className="flood-construction-row-switch"
                                        parentobject="propertyInfo"
                                        name="s_IsResidentialUnit"
                                        checked={isResidentialUnit === 'YES' ? true : false}
                                        onChange={(e) => handleStateChange(e, 's_IsResidentialUnit')}
                                        checkedvalue='YES'
                                        uncheckedvalue='NO'
                                      />
                                    </div>
                                    <span>{t('Yes')}</span>
                                  </div>
                                </div>
                              </Form.Group>
                            </Col>
                          )
                        }
                        {
                          ((viewType === 'UNIT' && isResidentialUnit === 'YES') || viewType === 'RESIDENTIALBLDG') && (
                            <Col xs="12" sm="6" md="6" lg="4" xl="4">
                              <Form.Group
                                className="confirm-address-field"
                                controlId="formBasicPassword"
                              >
                                <Form.Label className="confirm-address-label">
                                  {t('Condominium Association')}
                                </Form.Label>
                                <div className="flood-construction-row">
                                  <div className="flood-construction-row-actions">
                                    <span>{t('No')}</span>
                                    <div className="custom-main-swtich">
                                      <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        className="flood-construction-row-switch"
                                        parentobject="propertyInfo"
                                        name="s_CondoOwnership"
                                        checked={condoAssociation === 'YES' ? true : false}
                                        onChange={(e) => handleStateChange(e, 's_CondoOwnership')}
                                        checkedvalue='YES'
                                        uncheckedvalue='NO'
                                      />
                                    </div>
                                    <span>{t('Yes')}</span>
                                  </div>
                                </div>
                              </Form.Group>
                            </Col>
                          )
                        }
                        {
                          <>
                            {
                              (viewType === 'UNIT' && noOfFloors > 1) && (
                                <Col xs="12" sm="6" md="6" lg="4" xl="4">
                                  <Form.Group
                                    className="confirm-address-field"
                                    controlId="formBasicPassword"
                                  >
                                    <Form.Label className="confirm-address-label">
                                      {t('Floor of Unit')}
                                    </Form.Label>
                                    <Form.Control
                                      className="myform-input"
                                      type="number"
                                      placeholder="0000"
                                      id="flood-constructon-input"
                                      name="s_UnitLocatedOnFloor"
                                      title={t('Floor of Unit')}
                                      parentobject="propertyInfo"
                                      value={floorOfUnit}
                                      min={0}
                                      onChange={(e) => handleStateChange(e, 's_UnitLocatedOnFloor')}
                                      required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {t('Please Enter Floor of Unit')}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                              )
                            }
                            <Col xs="12" sm="12" md="6" lg="4" xl="4">
                              <Form.Group
                                className="confirm-address-field"
                                controlId="formBasicEmail"
                              >
                                <Form.Label className="confirm-address-label">
                                  {t('Number of Units')}:
                                </Form.Label>
                                <Form.Control
                                  className="myform-input"
                                  type="number"
                                  placeholder="0"
                                  name="s_NoOfUnits"
                                  title={t('in feet')}
                                  parentobject="propertyInfo"
                                  value={noOfUnit}
                                  min={0}
                                  onChange={(e) => handleStateChange(e, 's_NoOfUnits')}
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  {t('Please Enter Number of Units')}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </>
                        }
                      </>
                    )
                  }
                  {
                    ((['UNIT', 'NONRESIDENTIALBLDG'].includes(viewType)) || (viewType === 'RESIDENTIALBLDG' && condoAssociation === 'NO' && noOfUnit > 0)) && (
                      <Col xs="12" sm="6" md="6" lg="4" xl="4">
                        <Form.Group className="confirm-address-field">
                          <Form.Label className="confirm-address-label">
                            {t(descLabel)}
                          </Form.Label>
                          <Form.Select
                            className="confirm-address-input"
                            name='s_BuildingUse'
                            title={t(descLabel)}
                            parentobject="propertyInfo"
                            value={description}
                            onChange={(e) => handleStateChange(e, 's_BuildingUse')}
                            required
                          >
                            <option value={''}>{t('Select')}</option>
                            {
                              descDD.length > 0 && descDD.map((v, i) => {
                                return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                              })
                            }
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {t('Please Select Buildind Description')}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    )
                  }
                  <Col xs="12" sm="6" md="6" lg="4" xl="4">
                    <Form.Group className="confirm-address-field">
                      <Form.Label className="confirm-address-label">
                        {t('Foundation type')}
                      </Form.Label>
                      <Form.Select
                        className="confirm-address-input"
                        name="s_Foundation"
                        title={t('Foundation type')}
                        parentobject="propertyInfo"
                        value={foundation}
                        onChange={(e) => {
                          props.handleChange(e);
                          setFoundation(e.currentTarget.value);
                        }}
                        required
                      >
                        <option value={''}>{t('Select')}</option>
                        {
                          Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODFOUNDATION20.map((v, i) => {
                            return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                          })
                        }
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {t('Please Select Foundation Type Error')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {
                    ['ELEWATEDONCRAWLSPACE20', 'ELEVATEDWITHENCLOSURE20', 'ELEVATEDWITHENCLOSURENOTPPP20'].includes(foundation) && (
                      <Col xs="12" sm="6" md="6" lg="4" xl="4">
                        <Form.Group
                          className="confirm-address-field"
                        >
                          <Form.Label className="confirm-address-label">
                            {t('Size of Enclosure')}
                          </Form.Label>
                          <Form.Control
                            className="confirm-address-input"
                            type="text"
                            placeholder=""
                            name="s_AreaInSqFt"
                            title={t('Size of Enclosure')}
                            parentobject="propertyInfo"
                            value={areaInSqFt}
                            defaultValue={''}
                            onChange={(e) => {
                              props.handleChange(e);
                              setAreaInSqFt(e.currentTarget.value);
                            }}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t('Please Enter Size of Enclosure')}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    )
                  }
                  {
                    viewType === 'MOBILEHOME' && (
                      <>
                        <Col xs="12" sm="6" md="6" lg="4" xl="4">
                          <Form.Group
                            className="confirm-address-field"
                            controlId="formBasicPassword"
                          >
                            <Form.Label className="confirm-address-label">
                              {t('Located in Park/Subdivision')}
                            </Form.Label>
                            <div className="flood-construction-row">
                              <div className="flood-construction-row-actions">
                                <span>{t('No')}</span>
                                <div className="custom-main-swtich">
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    className="flood-construction-row-switch"
                                    parentobject="propertyInfo"
                                    name="s_DtOfConstructionSource"
                                    checked={isLocated}
                                    onChange={() => setIsLocated(!isLocated)}
                                  />
                                </div>
                                <span>{t('Yes')}</span>
                              </div>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col xs="12" sm="6" md="6" lg="4" xl="4">
                          <Form.Group
                            controlId="formBasicPassword"
                            className="confirm-address-field"
                          >
                            <Form.Label className="confirm-address-label">
                              {t(label)}
                            </Form.Label>
                            <DatePicker
                              className="custom-info-date-picker"
                              placeholderText="MM/DD/YYYY"
                              selected={DOC}
                              // minDate={DOC}
                              onChange={(date) => {
                                props.handleDateChange(date, 'd_DateOfConstruction', 'propertyInfo');
                                setDOC(date);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </>
                    )
                  }
                  <Col xs="12">
                    <div className="form-map">
                      <GoogleMap />
                    </div>
                  </Col>
                  <Col xs="12" className="form-btns">
                    <div className="flood-bts-div">
                      <Button
                        id="primary-grey-btn-flood"
                        variant="outline-dark"
                        disabled={true}
                      >
                        {t('Previous')}
                      </Button>{" "}
                      <Button
                        id="primary-colored-btn"
                        variant="primary"
                        onClick={() => openModal()}
                      >
                        {t('Next')}
                      </Button>{" "}
                    </div>
                    {model}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row >
    </section >
  );
};

export default FloodPropertyDetail;
