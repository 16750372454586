import React, { Fragment ,useState, useEffect} from "react";
import {
  Button,
  Row,
  Col,
  Form,
  ListGroup,
  Accordion,
  Modal,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
const PriorCoverage = (props) => {
    const [date1, setDate1] = useState(new Date());
    const { t } = useTranslation();
    useEffect(() => {
      }, [props]); 
  return (
    <Fragment>
        <Row className="align-items-end">
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
                >
                <Form.Label className="dashboard-group-label">
                    {t("Prior Carrier")}
                </Form.Label>
                <Form.Control
                    name="s_PriorCarrierName"
                    title="policyInfo"
                    className="dashboard-group-input"
                    type="text"
                    placeholder=""
                    onChange={props.propsData.handleChange}
                    value={props.quoteData.policyInfo.s_PriorCarrierName}
                    disabled={props.isBind}
                />
                </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
                >
                <Form.Label className="dashboard-group-label">
                    {t("Prior Policy No")}
                </Form.Label>
                <Form.Control
                    name="s_PriorPolicyNo"
                    title="policyInfo"
                    className="dashboard-group-input"
                    type="text"
                    onChange={props.propsData.handleChange}
                    value={props.quoteData.policyInfo.s_PriorPolicyNo}
                    disabled={props.isBind}
                />
                </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
                >
                <Form.Label className="dashboard-group-label">
                    {t("Policy Expiration Date")}
                </Form.Label>
                <Form.Control
                    className="dashboard-group-input"
                    type="date"
                    value={props.quoteData.policyInfo.d_PriorExpDate}
                    name="d_PriorExpDate"
                    title="policyInfo"
                    onChange = {props.propsData.handleChange}
                    disabled={props.isBind}
                />
                {/* <DatePicker
                    id="dashboard-datepicker"
                    name="d_PriorExpDate"
                    title="policyInfo"
                    className="dashboard-datepicker-main"
                    onChange={props.propsData.handleDateChange}
                    value={props.quoteData.policyInfo.d_PriorExpDate}
                    // selected={date1}
                    // onChange={(e) => setDate1(e)}
                    disabled={props.isBind}
                /> */}
                </Form.Group>
            </Col>
        </Row>
    </Fragment>
  );
};


function mapStateToProps(state) {
	return {
	  	quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	};
  } 
  export default connect(mapStateToProps)(PriorCoverage);
