import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import "./flood-pay-now.scss";

const FloodPayNow = () => {
  let { corePoTransPK } = useParams();
  const navigation = useNavigate();
  const { t } = useTranslation();
  const { emptyQuoteData, apiLoading } = useSelector(state => state.flood);
  const [quoteNo, setQuoteNo] = useState('');
  const [policyNo, setPolicyNo] = useState('');
  const [mortgageeData, setMortgageeData] = useState({});
  const [premium, setPremium] = useState(0);

  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0) {
      setPremium(emptyQuoteData.premium || 0);
      setPolicyNo(emptyQuoteData.policyInfo.Policy_No || null);
      setQuoteNo(emptyQuoteData.policyInfo.Policy_No_Quote || null);
      setMortgageeData(emptyQuoteData.mortgageeData || [])
    }
  }, [emptyQuoteData]);

  const handleNavigate = (from) => {
    var path = '';
    switch (from) {
      case 'Mortgage':
        path = "/flood/thank-you";
        break;
      case 'ACH':
        path = "/flood/payment/ACH/" + corePoTransPK;
        break;
      case 'ECHECK':
        path = "/flood/payment/ECHECK/" + corePoTransPK;
        break;
      default:
        break;
    }
    path.length > 0 && navigation(path);
  }

  return (
    <LoadingOverlay >
      <Loader loading={apiLoading} />
      <section className="pay-now-section">
        {/* Form */}
        <Row className="justify-content-center">
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="8"
            xl="8"
            className="pay-now-form-section"
          >
            <div className="pay-now-headings" id="flood-pay-now-headings">
              <h6>{t('Pay Now')}</h6>
              <div className="yearly-plan">
                <span>
                  <span className="pn-amount" id="flood-pn-amount">
                    ${premium}
                  </span>
                  <span className="pn-slash">/</span>
                  <span className="pn-year">{t('Year')}</span><br />
                </span>
              </div>
            </div>
            <div className="pay-now-form-main" id="flood-pay-now-form-main">
              <Row>
                <Col xs="12" sm="12" md="6" lg="6" xl="6">
                  <div className="flood-policy-no-div" >
                    <div className="flood-policy-no-row">
                      <p>
                        {t('Temp Policy Number')}
                      </p>
                      <div className="flood-policy-no">
                        {quoteNo}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6" xl="6">
                  <div className="flood-policy-no-div" >
                    <div className="flood-policy-no-row">
                      <p>
                        {t('Policy Number')}
                      </p>
                      <div className="flood-policy-no">
                        {policyNo}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <p
                className="payment-method-title"
                id="flood-payment-method-title"
              >
                {t('Please add a payment method')}
              </p>

              <div className="credit-card-section">
                {mortgageeData.length > 0 && (
                  <Button
                    type="button"
                    variant="light"
                    className="credit-card-main"
                    style={{ marginRight: "25px" }}
                    onClick={() => handleNavigate('Mortgage')}
                  >
                    <div className="credit-card-icon3"></div>
                    <span className="credit-card-h">{t('Mortgage')}</span>
                  </Button>
                )}
                <Button
                  className="credit-card-main"
                  type="button"
                  variant="light"
                  style={{ marginRight: "25px" }}
                  onClick={() => handleNavigate('ECHECK')}
                >
                  <div className="credit-card-icon1"></div>
                  <span className="credit-card-h">{t('Credit Card')}</span>
                </Button>
                <Button
                  className="credit-card-main"
                  variant="light"
                  type="button"
                  onClick={() => handleNavigate('ACH')}
                >
                  <div className="credit-card-icon2"></div>
                  <span className="credit-card-h">{t('eCheck')}</span>
                </Button>
              </div>
              <div
                className="payment-method-details"
                id="flood-payment-method-details"
              >
                <p className="pmd-heading">
                  {t('Make your check payable to')}{" "}
                  <span className="avatar-insurance">{t('Unifi Management')}</span> {t('and include your policy number')}
                </p>
                <div className="pmd-address-div">
                  <span className="pmd-address">{t('Our Address')}:</span>
                  <div className="pmd-address-details">
                    {/* <p>Avatar Insurance Image Remit P.O. Box 30537</p>
                    <p>Tampa Fl 33630-3537</p> */}
                  </div>
                </div>

                <div className="pmd-mortage-div">
                  <span className="pmd-mortage-address">
                    {t('For Mortgagees & Late Payments, please use our Express Overnight Address')}:
                  </span>
                  <div className="pmd-mortage-details">
                    <p>Cash Management Solutions/Image Remit</p>
                    {/* <p>Attn: Avatar #30537</p>
                    <p>14450 46th Street N,Suite 112</p>
                    <p>Clearwater, FL 33762</p> */}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </LoadingOverlay>
  );
};

export default FloodPayNow;
