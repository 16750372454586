import React from "react";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis } from "recharts";
import { Row, Col, Form } from "react-bootstrap";
import "./statistics.scss";

const Statistics = () => {
  const data = [
    { name: "Hillsborogh county", value: 92 },
    { name: "Hillsborogh county", value: 56 },
    { name: "Charlotte", value: 49 },
    { name: "Pinellas County", value: 74 },
    { name: "Charlotte", value: 43 },
    { name: "Pinellas County", value: 32 },
    { name: "Hillsborogh", value: 34 },
    { name: "Leon", value: 64 },
    { name: "Hillsborogh county", value: 18 },
    { name: "Pinellas County", value: 10 },
  ];

  return (
    <div className="stat-page-section">
      <Row>
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="8"
          xl="8"
          className="stat-horizontal-bar"
        >
          <div className="stat-horizontal-header">
            <span className="stat-horizontal-title">
              Top Ten County Wise Quote
            </span>
            <div className="stat-horizontal-header-subdiv">
              <span className="stat-horizontal-subtitle">
                <div className="stat-square"></div>
                Top 10 Counties
              </span>

              <div className="stat-select-div">
                <p className="dashboard-group-label" id="stat-select-label">
                  Select Year
                </p>
                <div className="stat-page-selections">
                  <Form.Select className="dashboard-group-select">
                    <option>2022</option>
                    <option>2021</option>
                    <option>2020</option>
                  </Form.Select>
                </div>
              </div>
            </div>
          </div>
          <ResponsiveContainer width="100%" height={428}>
            <BarChart
              margin={{ right: 30, left: 60 }}
              width={500}
              height={100}
              data={data}
              layout="vertical"
            
            >
              <XAxis type="number" />
              <YAxis type="category" label dataKey="name" />
              <Bar label dataKey="value" fill="#23C6C8" />
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </div>
  );
};

export default Statistics;
