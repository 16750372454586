import React from "react";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import "./layout.scss";

const MainLayout = ({ children, ...rest }) => {
  return (
    <div className="dashboard-container">
      <div className="sidebar-container">
        <Sidebar />
      </div>
      <div className="header-container">
        <Header />
      </div>
      <div className="dashboard-content-container">{children}</div>
    </div>
  );
};

export default MainLayout;
