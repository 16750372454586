import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import Inbox from "./pages/activity-log/inbox/Inbox";
import MessageView from "./pages/activity-log/message-view/MessageView";
import Reply from "./pages/activity-log/reply/Reply";
import Dashboard from "./pages/dashboard/Dashboard";
import ManagementReports from "./pages/management-reports/ManagementReports";
import OtherLinks from "./pages/other-links/OtherLinks";
import PolicyDetails from "./pages/policy/policy-details/PolicyDetails";
import SearchPolicy from "./pages/policy/search-policy/SearchPolicy";
import Quote from "./pages/quote/Quote";
import Report from "./pages/report/Report";
import BlankLayout from "./components/layout/BlankLayout/BlankLayout";
import CancelTransactions from "./pages/dashboard/cancel-transactions/CancelTransaction";
import NewClaims from "./pages/dashboard/new-claims/NewClaims";
import PaymentDue from "./pages/dashboard/payment-due/PaymentDue";
import RecentTransactions from "./pages/dashboard/recent-transactions/RecentTransactions";
import UpcomingRenewals from "./pages/dashboard/upcoming-renewals/UpcomingRenewals";
import MarketingTools from "./pages/marketing-tools/MarketingTools";
import ProductOfferings from "./pages/product-offerings/ProductOfferings";
import FloodRoute from "./pages/quote/flood/floodroutes";
import InstaFrontRoute from "./pages/quote/instaQuote/InstaFrontRoute";
import Login from "./pages/login/Login";
import Statistics from "./pages/statistics/Statistics";
import EditApplication from "./pages/quote/EditApplication";
import ProfileInfo from "./pages/profile/Profile";
import Mortgagee from "./pages/mortgagee/Mortgagee";
import MortgageeInformation from "./pages/mortgagee/MortgageeInformation";
import Resources from "./pages/dashboard/resources/Resources";
import { Provider } from 'react-redux';
import store from './store';
import * as actions from './store/actions';

// Check Login
function CheckAuth({ children, redirectTo }) {
  store.dispatch(actions.authCheck());
  let isAuthenticated = store.getState().auth.isAuthenticated;
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

function App() {
  return (
    <>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route
              path="/login"
              element={
                <BlankLayout>
                  <Login />
                </BlankLayout>
              }
            />
            <Route
              path="/"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Dashboard />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/cancel-transactions"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <CancelTransactions />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/new-claims"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <NewClaims />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/quote/editApplication/:productID/:transID"
              element={
                <CheckAuth redirectTo="/login">
                <MainLayout>
                  <EditApplication />
                </MainLayout>
                </CheckAuth>
              }
            />
			<Route
              path="/quote/editApplication/:productID"
              element={
                <CheckAuth redirectTo="/login"> 
                <MainLayout>
                  <EditApplication />
                </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/payment-due"
              element={
                <CheckAuth redirectTo="/login">
                <MainLayout>
                  <PaymentDue />
                </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/recent-transactions"
              element={
                <CheckAuth redirectTo="/login">
                <MainLayout>
                  <RecentTransactions />
                </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/upcoming-renewals"
              element={
                <CheckAuth redirectTo="/login">
                <MainLayout>
                  <UpcomingRenewals />
                </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/quote"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Quote />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/core/policy"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <SearchPolicy />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/core/policy/:policyNumber"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <PolicyDetails />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/core/policy/:type/:policyNumber"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <PolicyDetails />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/active-log"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Inbox />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/active-log/:id"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <MessageView />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/active-log/:id/reply"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Reply />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/active-log/new-activity"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Reply />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/reports"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Report />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/other-links"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <OtherLinks />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/statistics"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Statistics />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/management-reports"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <ManagementReports />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/marketing-tools"
              element={
                <CheckAuth redirectTo="/login">
                <MainLayout>
                  <MarketingTools />
                </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/flood/*"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <FloodRoute />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/instaQuote/*"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <InstaFrontRoute />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/product-offerings"
              element={
                <CheckAuth redirectTo="/login">
                <MainLayout>
                  <ProductOfferings />
                </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="*"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Dashboard />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/profile"
              element={
                <CheckAuth redirectTo="/login">
                <MainLayout>
                  <ProfileInfo />
                </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/mortgagee"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Mortgagee />
                  </MainLayout>
                </CheckAuth>
              }
            />
             <Route
              path="/mortgagee/information/:policyNumber"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <MortgageeInformation />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/resources"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Resources />
                  </MainLayout>
                </CheckAuth>
              }
            />
            
          </Routes>
        </Router>
      </Provider>
    </>
  );
}

export default App;
