import React, { Fragment, useState } from "react";
import { Button, Row, Col, Form, Table, Accordion } from "react-bootstrap";
import basicBadge from "../../../../../assets/badges/basic.png";
import optionalBadge from "../../../../../assets/badges/optional.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MapComponent from "../../../../../components/MapComponent";
import { useDispatch, useSelector , connect } from 'react-redux';
import { useTranslation } from "react-i18next";

const Deductible = (props) => {

  const [date1, setDate1] = useState(new Date());
  const { t } = useTranslation();

  return (
	<Row className="align-items-end">
        <Col xs="12" sm="12" md="6" lg="3" xl="3">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Hurricane")} <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Select
              className="dashboard-group-input"
              id="select-ym-option"
			  name="s_HurricaneDedutible"
              parentobject="propertyCoverageInfo"
			  title="propertyCoverageInfo"
			  value={props.quoteData.propertyCoverageInfo.s_HurricaneDedutible ? props.quoteData.propertyCoverageInfo.s_HurricaneDedutible : ''}
			  onChange = {props.handleChange}
        disabled={props.isBind}
            >
              <option value="">Select</option>
              {props.getBaseData.ddDeductHurricane.map((x) => (
                <option value={x.value}>
                  {x.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs="12" sm="12" md="6" lg="3" xl="3">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Non Hurricane")} <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Select
              className="dashboard-group-input"
              id="select-ym-option"
			  name="s_AOPDedutible"
              parentobject="propertyCoverageInfo"
			  title="propertyCoverageInfo"
			  value={props.quoteData.propertyCoverageInfo.s_AOPDedutible ? props.quoteData.propertyCoverageInfo.s_AOPDedutible : ''}
			  onChange = {props.handleChange}
        disabled={props.isBind}
            >
              <option value="">Select</option>
              {props.getBaseData.ddDeductNonHurricane.map((x) => (
                <option value={x.value}>
                  {x.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
	);
	
};
  function mapStateToProps(state) {
	return {
	  	quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	};
  } 
  export default connect(mapStateToProps)(Deductible);