import Http from '../Http';
import * as action from '../store/actions';

export function getProfileInfo() {
    return dispatch => (
        new Promise((resolve, reject) => {
        const url = `/api/profile/getprofile`;
        Http.get(url)
            .then((res) => {
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}

export function randomNo(length) {
    var i = 0
    var result = '';
    var characters = '0123456789bcdfghjnmkpqrtsvxwyza';
    var charactersLength = characters.length;
    for (i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result
  }

export function getuserNameSavedprofile(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
        const url = `/api/profile/changeUserName`;
        Http.post(url, data)
            .then((res) => {
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
  }

  export function getOtherInfoSavedProfile(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
        const url = `/api/profile/saveOtherInfo`;
        Http.post(url, data)
            .then((res) => {
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
  }

  export function getPasswordInfoSavedProfile(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
        const url = `/api/profile/savePasswordInfo`;
        Http.post(url, data)
            .then((res) => {
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
  }

  export function GetSelectedFileUploaded(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
        const url = `/api/profile/uploadProfile`;
        Http.post(url, data)
            .then((res) => {
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
  }