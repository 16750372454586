import React, { useState, useEffect } from "react";
import { Row, Col, Tabs, Tab, Form, Button , Modal} from "react-bootstrap";
import { FaBars, FaSearch } from "react-icons/fa";
import SearchQuoteTab from "./search-quote/SearchQuoteTab";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./quote.scss";
import { useDispatch, useSelector , connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getQuoteData,getEmptyQuoteData,getEditEmptyQuoteData,getBaseData,saveAndRate,dataTransferToCore,getBindApplicationHO3,getErrorMsg,callGeneratePdfApi } from "../../services/quoteService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import Moment from 'moment';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

const EditApplication = (props) => {
	const navigation = useNavigate();
	const dispatch = useDispatch();
  	const [loader, setLoader] = useState(true);
  	const [loader2, setLoader2] = useState(false);
  	const [rateLoader, setRateLoader] = useState(false);
  	const [quoteData, setquoteData] = useState({});
	  
    let params = useParams();
    const [createQuote, setCreateQuote] = useState(false);
  	const [allData, setAllData] = useState();
    const [isBind, setIsBind] = useState(false);
    const [alertMessage, setAlertMessage] = useState();
    const [alertInterval, setAlertInterval] = useState();
	const [Show, setShow] = useState(false);
	const [errors, setErrors] = useState(false);
	const [ValidationError, setValidationError] = useState(false);
	
	useEffect(() => {
		var product = 13;
		dispatch(getBaseData(product));
		if(typeof params.transID != 'undefined') {
			getQuoteforEdit(params.transID);
		}else {
			getEmptyQuote();
		}  

	}, []);
	
	const setDefaultData = (objectName, objectValue) => {
		var quoteDataVar = props.quoteData;
		quoteDataVar[objectName] = objectValue;
		
		dispatch(getEditEmptyQuoteData(quoteDataVar));
	  }
	  
	const getEmptyQuote = async () => {
			dispatch(getEmptyQuoteData()).then((res) => {
			dispatch(getEditEmptyQuoteData(res));
			//setAllData(res.data);
			setLoader(false);
		});
	}
	
	const getQuoteforEdit = async (transID) => {
		await dispatch(getQuoteData(transID)).then((res) => { 
			//setAllData(res);
      if(res.policyInfo.d_BinderDate != "" && res.policyInfo.d_BinderDate != null){
        setIsBind(true);
      }
			dispatch(getEditEmptyQuoteData(res));
			setLoader(false);
		});
	};
	
  const productsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        policy_no: "HO32014012226",
        insured_name: "ROGE DAMEUS",
        product_type: "HO6 (Homeowners)",
        renew_date: "MM/DD/YYYY",
      });
    }
    return items;
  };

  const products = productsGenerator(5);

  const columns = [
    {
      dataField: "policy_no",
      text: "Policy No",
    },
    {
      dataField: "insured_name",
      text: "Insured Name",
    },
    {
      dataField: "product_type",
      text: "Product Type",
    },
    {
      dataField: "renew_date",
      text: "Renew Date",
    },
  ];
  
  const handleChange = (e) => {

	const { name, value , title } = e.target; 


  var quoteDataVar = props.quoteData;
  
  if(name == 'd_TransEffectiveFrom') {
	const value2 = Moment(value).add(1, 'year').toISOString().split("T")[0];
	quoteDataVar[title]['d_TransEffectiveTo'] = value2;
	
  }
  
  const tempArr = props.quoteData;
  tempArr[title][name] = value;
  quoteDataVar[title][name] = value;
  setAllData(quoteDataVar);
  dispatch(getEditEmptyQuoteData(quoteDataVar));
} 

const ValiditeForm= () => {
    const params = props.quoteData;
	const errorArray = []; 
	if(props.quoteData.personInfo.s_FirstName == '' || props.quoteData.personInfo.s_FirstName == null){
		errorArray.push("3049");
	}
	if(props.quoteData.personInfo.s_LastOrganizationName == '' || props.quoteData.personInfo.s_LastOrganizationName == null){
		errorArray.push("3050");
	}
	if(props.quoteData.personAddr.s_HouseNo == '' || props.quoteData.personAddr.s_HouseNo == null){
		errorArray.push("3489");
	}
	if(props.quoteData.personAddr.s_StreetName == '' || props.quoteData.personAddr.s_StreetName == null){
		errorArray.push("3489");
	}
	if(props.quoteData.personAddr.s_PostalCode == '' || props.quoteData.personAddr.s_PostalCode == null){
		errorArray.push("3052");
	}
	if(props.quoteData.personAddr.s_CountyName == '' || props.quoteData.personAddr.s_CountyName == null){
		errorArray.push("1045");
	}
	if(props.quoteData.personAddr.s_StateName == '' || props.quoteData.personAddr.s_StateName == null){
		errorArray.push("1066");
	}
	if(props.quoteData.personAddr.s_CityName == '' || props.quoteData.personAddr.s_CityName == null){
		errorArray.push("1065");
	}
	if(props.quoteData.propertyInfo.s_ConstructionType == '' || props.quoteData.propertyInfo.s_ConstructionType == null){
		errorArray.push("4042");
	}
	if(props.quoteData.propertyCoverageInfo.n_coverageE == '' || props.quoteData.propertyCoverageInfo.n_coverageE == null || props.quoteData.propertyCoverageInfo.n_coverageE == "0.00" ){
		errorArray.push("4050");
	}

	if(props.quoteData.propertyInfo.n_PPC == '' || props.quoteData.propertyInfo.n_PPC == null){
		errorArray.push("3320");
	}

	
	if(props.quoteData.propertyInfo.n_AreaSqtft == '' || props.quoteData.propertyInfo.n_AreaSqtft == null){
		errorArray.push("2256");
	}
	if(props.quoteData.propertyInfo.s_BCEG == '' || props.quoteData.propertyInfo.s_BCEG == null){
		errorArray.push("4040");
	}
	if(props.quoteData.propertyInfo.n_YearBuilt == '' || props.quoteData.propertyInfo.n_YearBuilt == null){
		errorArray.push("2223");
	}
	if(props.quoteData.propertyCoverageInfo.n_coverageA == '' || props.quoteData.propertyCoverageInfo.n_coverageA == null){
		errorArray.push("3321");
	}
	if(props.quoteData.personEmailContact.s_CommValue == '' || props.quoteData.personEmailContact.s_CommValue == null){
		errorArray.push("3210");
	}
	if(props.quoteData.personPhoneContact.s_CommValue == '' || props.quoteData.personPhoneContact.s_CommValue == null){
		errorArray.push("3595");
	}

	if(props.quoteData.propertyCoverageInfo.s_HurricaneDedutible == '' || props.quoteData.propertyCoverageInfo.s_HurricaneDedutible == null){
		errorArray.push("4049");
	}
	if(props.quoteData.propertyCoverageInfo.s_AOPDedutible == '' || props.quoteData.propertyCoverageInfo.s_AOPDedutible == null){
		errorArray.push("4048");
	}
	//wind mitigation

	if(props.quoteData.propertyInfo.s_RoofCoverCode == '' || props.quoteData.propertyInfo.s_RoofCoverCode == null){
		errorArray.push("4111");
	}
	if(props.quoteData.propertyInfo.s_RoofDeckAttachCode == '' || props.quoteData.propertyInfo.s_RoofDeckAttachCode == null){
		errorArray.push("4107");
	}
	if(props.quoteData.propertyInfo.s_RoofWallConnectCode == '' || props.quoteData.propertyInfo.s_RoofWallConnectCode == null){
		errorArray.push("4108");
	}
	if(props.quoteData.propertyInfo.s_RoofShapeCode == '' || props.quoteData.propertyInfo.s_RoofShapeCode == null){
		errorArray.push("4106");
	}
	if(props.quoteData.propertyInfo.s_windowprotectcode == '' || props.quoteData.propertyInfo.s_windowprotectcode == null){
		errorArray.push("4109");
	}
	if(props.quoteData.propertyInfo.s_SecWaterResCode == '' || props.quoteData.propertyInfo.s_SecWaterResCode == null){
		errorArray.push("4110");
	}

	if(props.quoteData.allAnswerData.Question_12984369.s_PoAdditionalStatsAnswer == '' || props.quoteData.allAnswerData.Question_12984369.s_PoAdditionalStatsAnswer == null){
		errorArray.push("4059");
	}

	
	
	
	const errorArrayMerge = [];
	if(props.quoteData.personInfo.s_FullLegalName == '' || props.quoteData.personInfo.s_FullLegalName == null){
		errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add Full Legal Name"});
	}
	
	if(props.quoteData.personInfo.s_IsMailingDiff == "YES"){
		if(props.quoteData.personMailingAddr.s_HouseNo == '' || props.quoteData.personMailingAddr.s_HouseNo == null){
			errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add Mailing Address House No"});
		}
		if(props.quoteData.personMailingAddr.s_StreetName == '' || props.quoteData.personMailingAddr.s_StreetName == null){
			errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add Mailing Address Street Name"});
		}
		if(props.quoteData.personMailingAddr.s_CountyName == '' || props.quoteData.personMailingAddr.s_CountyName == null){
			errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add Mailing Address County Name"});
		}
		if(props.quoteData.personMailingAddr.s_StateName == '' || props.quoteData.personMailingAddr.s_StateName == null){
			errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add Mailing Address State Name"});
		}
		if(props.quoteData.personMailingAddr.s_CityName == '' || props.quoteData.personMailingAddr.s_CityName == null){
			errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add Mailing Address City Name"});
		}
		if(props.quoteData.personMailingAddr.s_PostalCode == '' || props.quoteData.personMailingAddr.s_PostalCode == null){
			errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add Mailing Address Zip Code"});
		}
		
	}

	if((props.quoteData.personPriorAddr.isNewPurchase && props.quoteData.personPriorAddr.isNewPurchase == "Yes") || (props.quoteData.personPriorAddr.n_PersonAddressesId_PK != null) ){
		if(props.quoteData.personPriorAddr.isNewPurchase && props.quoteData.personPriorAddr.isNewPurchase == "No"){
		} else {
			if(props.quoteData.personPriorAddr.s_HouseNo == '' || props.quoteData.personPriorAddr.s_HouseNo == null){
				errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add Prior Address House No"});
			}
			if(props.quoteData.personPriorAddr.s_StreetName == '' || props.quoteData.personPriorAddr.s_StreetName == null){
				errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add Mailing Address Street Name"});
			}
			if(props.quoteData.personPriorAddr.s_CountyName == '' || props.quoteData.personPriorAddr.s_CountyName == null){
				errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add Mailing Address County Name"});
			}
			if(props.quoteData.personPriorAddr.s_StateName == '' || props.quoteData.personPriorAddr.s_StateName == null){
				errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add Mailing Address State Name"});
			}
			if(props.quoteData.personPriorAddr.s_CityName == '' || props.quoteData.personPriorAddr.s_CityName == null){
				errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add Mailing Address City Name"});
			}
			if(props.quoteData.personPriorAddr.s_PostalCode == '' || props.quoteData.personPriorAddr.s_PostalCode == null){
				errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add Mailing Address Zip Code"});
			}
		}
	}
	if(props.quoteData.propertyInfo.s_StructureTypeCode == '' || props.quoteData.propertyInfo.s_StructureTypeCode == null){
		errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add Structure Code"});
	}
	if(props.quoteData.propertyInfo.s_UsageTypeCode == '' || props.quoteData.propertyInfo.s_UsageTypeCode == null){
		errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add Usage Code In Property Details"});
	}
	
	if((props.quoteData.propertyInfo.noOfResident == '' || props.quoteData.propertyInfo.noOfResident == null) && (props.quoteData.riskRenovationTypeData.roofInfo.s_RenovationYear != undefined && (props.quoteData.riskRenovationTypeData.roofInfo.s_RenovationYear == '' || props.quoteData.riskRenovationTypeData.roofInfo.s_RenovationYear == null))){
		errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add Roof Renovation Year"});
	}
	if(props.quoteData.propertyInfo.s_RoofAge == '' || props.quoteData.propertyInfo.s_RoofAge == null){
		errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add Roof Age"});
	}
	

	if(props.quoteData.allAnswerData.Question_12984370.s_PoAdditionalStatsAnswer == 'YES'){
		if(props.quoteData.lossHistoryData.length == 0){
			errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add At Least One Loss In Claim Questions"});
		
		}

		if(props.quoteData.lossHistoryData.length > 0){
			var status = false;
			props.quoteData.lossHistoryData.map((value) => {
				if(value.d_LossDate == '' || value.s_LossType == '' || value.s_LossDescription == '' || value.n_LossAmount == '' ){
					status = true;
				}
			})
			if(status == true){
				errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please add all fields in losses"});
			}
		} 
	}
	
	if(props.quoteData.propertyInfo.s_HomeTerrainExpCode == '' || props.quoteData.propertyInfo.s_HomeTerrainExpCode == null){
		errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add Tarrain In Wind Mitigation"});
	}

	if(props.quoteData.propertyInfo.s_FBC_WindSpeed == '' || props.quoteData.propertyInfo.s_FBC_WindSpeed == null){
		errorArrayMerge.push({n_ErrorNo_PK : null ,s_ErrorDescription : "Please Add Wind In Wind Mitigation"});
	}

	dispatch(getErrorMsg(errorArray)).then((res) => {
		if(res.length > 0 || errorArrayMerge.length > 0 ){
			const finalErrorArray = res.concat(errorArrayMerge);
			setErrors(finalErrorArray);
			setValidationError(true);
			setShow(true);
		} else {
			Submit();
		}
	});
}

const rateSubmit= async () => {
	//setLoader2(true);
	const result = ValiditeForm();
}

const Submit= async () => {
	await dispatch(saveAndRate(props.quoteData)).then((res) => { 
		setLoader2(false);
		toast.success('Application Rate Successfully!');
		if(typeof params.transID != 'undefined') {
			getQuoteforEdit(res.transactionPK);
		}else {
			setTimeout(()=>{
				window.location = '/quote/editApplication/13/'+res.transactionPK;
		}, 3000);
			
		} 
	});
}


const submitBound = async () => {
	
	Swal.fire({
		title: 'Please Wait',
		html: '<b>Saving Data...</b>',
		allowOutsideClick: false,
		didOpen: () => {
			Swal.showLoading()
		  }
	  
	  }).then(function() {
			window.location.reload();
		});

	await dispatch(saveAndRate(props.quoteData)).then((res) => { 

		Swal.getHtmlContainer().querySelector('b').textContent = 'Binding Application...';
		var transferData = {};
        transferData.n_potransaction_PK = props.quoteData.transactionInfo.n_potransaction_PK;
        transferData.product = props.quoteData.policyInfo.n_ProductId_FK;
	   
		let actions3 = dispatch(dataTransferToCore(transferData)).then((res2) => {

			Swal.getHtmlContainer().querySelector('b').textContent = 'Binding Application...';
			let PotransactionPK = res2.data.transferData.PotransactionPK;
			const paramData = {
				n_potransaction_PK: PotransactionPK,
				product: props.quoteData.policyInfo.n_ProductId_FK,
				quoteTransactionPK: props.quoteData.transactionInfo.n_potransaction_PK
				
			  }; 
			let action = dispatch(getBindApplicationHO3(paramData)).then((res3) => {

				if(res3.data.Status == 'Y') {
					Swal.update({
						icon: 'success',
						html: '<b>Binding Application Successfully</b>',
						title: '',
						confirmButtonColor: '#66e3d8',
					})
					Swal.hideLoading();
				}else{
					Swal.update({
						icon: 'warning',
						html: '<b>Something Went Wrong...</b>',
						title: '',
						confirmButtonColor: '#66e3d8',
					})
					Swal.hideLoading();
				} 
			  });
			});
	});
}

const callGeneratePdf = async (source) => {
	setRateLoader(true);
	await dispatch(callGeneratePdfApi(source,params.transID)).then((res) => { 
		if(res.status == 'Y'){
			
			setRateLoader(false);
			window.open(res.url)
		}else{
			toast.error('Something went wrong!');
		}
	});
};

  return (
    <div className="quote-page-section">
      <Row>
        <Col xs="12" className="custom-tabs">
			
			{!loader ? 
			<LoadingOverlay >
				<Loader loading={loader2} />
			  { 
			  	<SearchQuoteTab isLoading={loader} handleChange={handleChange} setDefaultData={setDefaultData} rateSubmit={rateSubmit} isBind={isBind} handleSubmitBound={submitBound} ValidationError={ValidationError} callGeneratePdf={callGeneratePdf} rateLoader={rateLoader}/>
			  }
			</LoadingOverlay>
			: 
			<LoadingOverlay >
				<Loader loading={loader} />
			  { 
			  	
			  }
			</LoadingOverlay>
			
			}
        </Col>
      </Row>
	  	{
			Show && (
				<Modal size="md" show={Show} className="custom-dashboard-modal" >
					<Modal.Header className="custom-dashboard-modal-header">
						{'Errors'}
					</Modal.Header>
					<Modal.Body >
						{
							errors.map((data,i) => (
								<span><label style={{color:"red"}}>{i + 1} . {data.s_ErrorDescription}</label><br></br></span>

							))
						}
					</Modal.Body> 
					<Modal.Footer className="custom-dashboard-modal-footer">
						<Button id="common-btn" variant="primary" class="btn btn-primary" onClick={() => setShow(false)}>close</Button>
					</Modal.Footer>
				</Modal>
			)
		}
    </div>
  );
};

function mapStateToProps(state) {
	return {
	   
		quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
	   getQuoteDataHO3: state.common.getQuoteDataHO3 ? state.common.getQuoteDataHO3 : {},
	   
	};
  } 
  
  export default connect(mapStateToProps)(EditApplication);

