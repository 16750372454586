import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Error = ({ show, error, toggle }) => {
    const { t } = useTranslation();

    return (
        <Modal
            size="lg"
            show={show}
            className="custom-dashboard-modal"
            onHide={() => toggle(!show)}
        >
            <Modal.Header
                closeButton
                className="custom-dashboard-modal-header"
            >
                <Modal.Title>{t('Error')}</Modal.Title>
            </Modal.Header>
            <Modal.Body
                className="custom-dashboard-modal-body"
                style={{ overflow: 'scroll', maxHeight: '500px' }}
            >
                {
                    error.length > 0 && (
                        <ul>
                            {error.map((v,i) => {
                                return (
                                    ![null, ''].includes(v) &&
                                    <li style={{ color: 'red' }} key={i}>{t(v)}</li>
                                )
                            })}
                        </ul>
                    )
                }
            </Modal.Body>
        </Modal>
    );
}

export default Error;