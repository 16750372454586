import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ListAttachment from "./ListAttachment";

const Index = (props) => {
    const { t } = useTranslation();
    const { show, setShow, policyNumber, policyPK } = props;

    return (
        <Modal
            size="xl"
            show={show}
            className="custom-dashboard-modal"
            onHide={() => setShow(!show)}
        >
            <Modal.Header
                closeButton
                className="custom-dashboard-modal-header"
            >
                <Modal.Title>{t('Attachment')}</Modal.Title>
            </Modal.Header>
            <Modal.Body
                className="custom-dashboard-modal-body"
            >
                <div className="inbox-page-section">
                    <Row>
                        <Col xs="12">
                            <ListAttachment
                                policyNumber={policyNumber}
                                policyPK={policyPK}
                            />
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default Index;