import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import common from './common';
import auth from './Auth';
import flood from './flood';
import coreSystem from './coreSystem';

export const history = createBrowserHistory();
const RootReducer = combineReducers({
  router: connectRouter(history),
  common,
  auth,
  flood,
  coreSystem
});

export default RootReducer;
