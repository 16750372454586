import React from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import TrainingTab from "./TrainingTab";
import LinksTab from "./LinksTab";
import MarketingTab from "./MarketingTab";
import { useTranslation } from "react-i18next";

const Resources = () => {
  const { t } = useTranslation();
    return(
        <div className="management-page-section">
        <h5 className="management-page-title">{t("Resources")}</h5>
  
        <Row>
          <Col xs="12" className="custom-tabs">
            <Tabs defaultActiveKey="Training" id="uncontrolled-tab-example">
              <Tab eventKey="Training" title="Training">
                  <TrainingTab/>
              </Tab>
              <Tab eventKey="Links" title="Links">
                  <LinksTab/>
              </Tab>
              <Tab eventKey="Marketing" title="Marketing">
                  <MarketingTab/>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </div>
    )
}

export default Resources;