import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import "./policy-details.scss";
import {
    Row,
    Col,
    Table,
} from "react-bootstrap";
import { currencyFormat } from '../../../services/commonService';
import { useTranslation } from "react-i18next";

function CommonHeader(props) {

    const [applicantData, setApplicantData] = useState({});
    const { t } = useTranslation();
    useEffect(() => {
        setApplicantData(props.applicationData)
    }, [props.applicationData]);

    return (
        <div>
            <div className="billing-tab-row1-header">
                <span>{(Object.keys(applicantData).length > 0 && applicantData.Policy_No)}</span>
            </div>
            <div className="billing-tab-row1-body">
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12" xl="6">
                        <Table borderless className="policy-details-info-table">
                            <tbody>
                                <tr>
                                    <th>{t('Policy #/Holder Name')}</th>
                                    <td>
                                        {
                                            (Object.keys(applicantData).length > 0 && applicantData.Policy_No) + '/' + (Object.keys(applicantData).length > 0 && applicantData.n_TermSequence) + '/' + (Object.keys(applicantData).length > 0 && applicantData.s_FullLegalName)
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Terms Start date')}</th>
                                    <td>{Object.keys(applicantData).length > 0 && applicantData.d_TermStartDate}</td>
                                </tr>
                                <tr>
                                    <th>{t('Transaction Effective date')}</th>
                                    <td>{Object.keys(applicantData).length > 0 && applicantData.d_TransEffectiveFrom}</td>
                                </tr>
                                <tr>
                                    <th>{t('Transaction Type')}</th>
                                    <td>{Object.keys(applicantData).length > 0 && applicantData.s_TranTypeScreenName + ' - ' + applicantData.s_TranSubtypeScreenName}</td>
                                </tr>
                                <tr>
                                    <th>{t('Premium')}</th>
                                    <td>{Object.keys(applicantData).length > 0 && currencyFormat(applicantData.n_NetPremium)}</td>
                                </tr>
                                <tr>
                                    <th>{t('Total Premuim')}</th>
                                    <td>{Object.keys(applicantData).length > 0 && currencyFormat(applicantData.totalPremium)}</td>
                                </tr>
                                <tr>
                                    <th>{t('Total Claim')}</th>
                                    <td>
                                        {Object.keys(applicantData).length > 0 && applicantData.totalClaims} <span className="span-diff-color">Policyholder Copy Agent Copy</span>{" "}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Serv Rep')}</th>
                                    <td>{
                                        Object.keys(applicantData).length > 0 && applicantData.serviceRep.s_ScreenName
                                    }</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="12" xl="6">
                        <Table borderless className="policy-details-info-table">
                            <tbody>
                                <tr>
                                    <th>{t('Payment Plan')}</th>
                                    <td>{
                                        Object.keys(applicantData).length > 0 && applicantData.billPay.s_PayPlanScreenName
                                    }</td>
                                </tr>
                                <tr>
                                    <th>{t('Term End Date')}</th>
                                    <td>{Object.keys(applicantData).length > 0 && applicantData.d_TermEndDate}</td>
                                </tr>
                                <tr>
                                    <th>{t('Transaction Exp. Dt')}.</th>
                                    <td> {Object.keys(applicantData).length > 0 && applicantData.d_TransEffectiveTo}</td>
                                </tr>
                                <tr>
                                    <th>{t('Bill to')}</th>
                                    <td>{
                                        Object.keys(applicantData).length > 0 && applicantData.billPay.s_BillToType
                                    }</td>
                                </tr>
                                <tr>
                                    <th>{t('Premium Change')}</th>
                                    <td>{Object.keys(applicantData).length > 0 && currencyFormat(applicantData.n_PremiumChange)}</td>
                                </tr>
                                <tr>
                                    <th>{t('Product Name')}</th>
                                    <td>{Object.keys(applicantData).length > 0 && applicantData.s_ProductName}</td>
                                </tr>
                                <tr>
                                    <th>{t('Inception Date')}</th>
                                    <td>
                                        {Object.keys(applicantData).length > 0 && applicantData.d_InceptionDate}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('U/writer')}</th>
                                    <td>{Object.keys(applicantData).length > 0 && applicantData.underwriter.s_ScreenName}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        applicationData: state.coreSystem.applicationData,
    };
};
export default connect(mapStateToProps)(CommonHeader);