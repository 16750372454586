import React, { Fragment, useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  ListGroup,
  Accordion,
  Modal,
} from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  sizePerPageRenderer,
  pageButtonRenderer,
} from "../../../components/CustomTablePagination";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector, connect } from 'react-redux';
import Select from "react-select";
import BillingInfo from "./components/ApplicantTabComponents/BillingInfo";
import PriorCoverage from "./components/ApplicantTabComponents/PriorCoverage";
import RenovationDetails from "./components/ApplicantTabComponents/RenovationDetails";
import GeneralQuestions from "./components/ApplicantTabComponents/GeneralQuestions";
import PolicyInterests from "./components/ApplicantTabComponents/PolicyInterests";
import FooterButton from "./components/FooterButton";
import QuoteButtons from "./components/QuoteTabComponents/QuoteButtons";
const ApplicationTab = (props) => {
  const { t } = useTranslation();


  const [date1, setDate1] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [selectInterest, setSelectInterest] = useState("PRIMARY");
  const [selectedName, setSelectedName] = useState(null);
  const [inputFields, setInputFields] = useState([]);

  const [interestValue, setInterestValue] = useState({
    value: "PRIMARY",
    label: "First Mortgagee",
  });
  const interestOptions = [
    { value: "PRIMARY", label: "First Mortgagee" },
    { value: "SECOND", label: "Second Mortgagee" },
    { value: "THIRD", label: "Third Mortgagee" },
    { value: "ADDITIONALINSURED", label: "Additional Insured" },
    { value: "ADDITIONALINTEREST", label: "Additional Interest" },
  ];
  const nameOptions = [
    { value: "Brad", label: "Brad" },
    { value: "Lester", label: "Lester" },
    { value: "John", label: "John" },
    { value: "Albert", label: "Albert" },
    { value: "Micheal", label: "Micheal" },
  ];

  const [data, setData] = useState({
    name: "",
    firstName: "",
    middleName: "",
    lastName: "",
    type: "",
    mailAddress1: "",
    mailAddress2: "",
    city: "",
    state: "",
    zipCode: null,
    loadNo: "",
  });

  const handleInputDataChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSaveInterest = () => {
    const values = [...inputFields];
    if (
      selectInterest === "ADDITIONALINSURED" ||
      selectInterest === "ADDITIONALINTEREST"
    ) {
      setData({
        ...data,
        name: `${data.firstName} ${data.middleName} ${data.lastName}`,
      });
      values.push({
        name: `${data.firstName} ${data.middleName} ${data.lastName}`,
        interestType: interestValue.label,
        loadNo: data.loadNo,
      });
    } else {
      values.push({
        name: data.name,
        interestType: interestValue.label,
        loadNo: data.loadNo,
      });
    }

    setInputFields(values);
    setData({});
    setShowModal(false);
  };

  const columns = [
    {
      dataField: "name",
      text: "Name ",
    },
    {
      dataField: "interestType",
      text: "Interest Type",
    },
    {
      dataField: "loadNo",
      text: "Loan No",
    },
    {
      dataField: "action",
      text: "Action",
    },
  ];

  const options = {
    sizePerPageRenderer,
    pageButtonRenderer,
    showTotal: true,
    alwaysShowAllBtns: true,
  };

  const renovationProducts = [
    {
      renovations: "Electrical",
      type: "1",
      year: "2",
    },
  ];

  const renovationColumns = [
    {
      dataField: "renovations",
      text: "Renovations",
      formatter: (value, row) => (
        <div className="checkbox-table-row">
          <Form.Check
            className="table-checkbox"
            type="checkbox"
            label=""
            id="custom-checker"
          />
          {value}
        </div>
      ),
    },
    {
      dataField: "type",
      text: "Type",
      formatter: (value, row) => (
        <Form.Select
          className="dashboard-group-input"
          id="select-ym-option"
          style={{ maxWidth: "200px" }}
        >
          <option>Full</option>
        </Form.Select>
      ),
    },
    {
      dataField: "year",
      text: "Year",
      formatter: (value, row) => (
        <Form.Control
          className="dashboard-group-input"
          type="number"
          placeholder="2009"
          style={{ maxWidth: "200px" }}
        />
      ),
    },
  ];

  return (
    <Fragment> 
      <div className="search-quote-tab-div">
        <div className="search-quote-btns-div">
          <QuoteButtons isBind={props.isBind} callGeneratePdf={props.callGeneratePdf} rateLoader={props.rateLoader}/>
        </div>

        <Accordion defaultActiveKey="0" className="custom-quote-accordion">
          {/* billing info */}
          <Accordion.Item eventKey="0">
            <Accordion.Header className="search-quote-card-header">
              <span>{t("Billing Info")}</span>
            </Accordion.Header>
            <Accordion.Body className="search-quote-card-body">
              {/* <Row className="align-items-end">
                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Bill To<span className="label-asterisk">*</span>
                    </Form.Label>
                    <Form.Select className="table-show-entries-select">
                      <option>Full Pay</option>
                      <option>Full Pay</option>
                      <option>Full Pay</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Pay Plan <span className="label-asterisk">*</span>
                    </Form.Label>
                    <Form.Select
                      className="dashboard-group-input"
                      id="select-ym-option"
                    >
                      <option>Full Pay</option>
                      <option>Full Pay</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Amt Included with App
                      <span className="label-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="TEST1010"
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row> */}
              <BillingInfo propsData={props} isBind={props.isBind}/>
            </Accordion.Body>
          </Accordion.Item>

          {/* prior coverage */}
          <Accordion.Item eventKey="1">
            <Accordion.Header className="search-quote-card-header">
              <span>{t("Prior Coverage")}</span>
            </Accordion.Header>
            <Accordion.Body className="search-quote-card-body">
              {/* <Row className="align-items-end">
                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Prior Carrier<span className="label-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Avatar Insurance"
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Prior Policy No<span className="label-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="EPC201900005469"
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Policy Expiration Date
                      <span className="label-asterisk">*</span>
                    </Form.Label>
                    <DatePicker
                      id="dashboard-datepicker"
                      className="dashboard-datepicker-main"
                      selected={date1}
                      onChange={(date) => setDate1(date)}
                    />
                  </Form.Group>
                </Col>
              </Row> */}
              <PriorCoverage propsData={props} isBind={props.isBind} />
            </Accordion.Body>
          </Accordion.Item>

          {/* policy Interests */}
          <Accordion.Item eventKey="2">
            <Accordion.Header className="search-quote-card-header">
              <span>{t("Policy Interests")}</span>
            </Accordion.Header>
            <Accordion.Body
              className="search-quote-card-body"
              style={{ padding: 0 }}
            >
              <PolicyInterests isBind={props.isBind}/>
            </Accordion.Body>
          </Accordion.Item>

          {/* renovation details card */}
          <Accordion.Item eventKey="3">
            <Accordion.Header className="search-quote-card-header">
              <span>{t("Renovation Details")}</span>
            </Accordion.Header>
            <Accordion.Body className="search-quote-card-body">
              <RenovationDetails isBind={props.isBind}/>
              {/* <BootstrapTable
                wrapperClasses="coverage-detail-table-main"
                bootstrap4
                keyField="renovations"
                data={renovationProducts}
                columns={renovationColumns}
                bordered={false}
                hover
              /> */}
            </Accordion.Body>
          </Accordion.Item>

          {/* general questions card */}
          <Accordion.Item eventKey="4">
            <Accordion.Header className="search-quote-card-header">
              <span>{t("General Questions")}</span>
            </Accordion.Header>
            <Accordion.Body
              className="search-quote-card-body"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <GeneralQuestions isBind={props.isBind} />
              {/* <div className="search-quote-question-header">
                <span className="search-quote-question-text">Questions</span>
                <span className="search-quote-question-text">Answer </span>
              </div>
              <div className="search-quote-question-body">
                <div className="search-questions-subdiv">
                  <span className="search-questions-subdiv-title">
                    OCCUPANCY INFORMATION
                  </span>
                  <ListGroup className="search-questions-list" as="ol" numbered>
                    <ListGroup.Item as="li">
                      <span>
                        Is the residence premises vacant or unoccupied?
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          name="check-ques-radio1"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="check-ques-radio1"
                        />
                      </div>
                    </ListGroup.Item>

                    <ListGroup.Item as="li">
                      <span>
                        Will the residence premises be occupied by the applicant
                        and/or co-applicant?
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          name="check-ques-radio2"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="check-ques-radio2"
                        />
                      </div>
                    </ListGroup.Item>

                    <ListGroup.Item as="li">
                      <span>
                        Will the residence premises be the applicant and/or
                        co-applicant's Primary residence
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          name="check-ques-radio3"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="check-ques-radio3"
                        />
                      </div>
                    </ListGroup.Item>

                    <ListGroup.Item as="li">
                      <span>
                        Will the residence premises be the applicant and/or
                        co-applicant's Secondary residence?
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          name="check-ques-radio4"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="check-ques-radio4"
                        />
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item as="li">
                      <span>
                        Will the residence premises be the applicant and/or
                        co-applicant's Seasonal residence?
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          name="check-ques-radio5"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="check-ques-radio5"
                        />
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item as="li">
                      <span>
                        How many families will occupy the residence premises?
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          name="check-ques-radio6"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="check-ques-radio6"
                        />
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item as="li">
                      <span>
                        Will the residence premises have continuous unoccupancy
                        of 3 or more consecutive months, or a total unoccupancy
                        of six or more months during any 1 year period?
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Select
                          className="dashboard-group-input check-question-select"
                          id="select-ym-option check-question-select"
                          check-question-select
                        >
                          <option>2</option>
                          <option>3</option>
                        </Form.Select>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item as="li">
                      <span>
                        Will more than two boarders reside at the residence
                        premises?{" "}
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          name="check-ques-radio8"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="check-ques-radio8"
                        />
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>

                <div className="search-questions-subdiv">
                  <span className="search-questions-subdiv-title">
                    ELIGIBILITY INFORMATION:
                  </span>
                  <ListGroup className="search-questions-list" as="ol" numbered>
                    <ListGroup.Item as="li">
                      <span>
                        Is the residence premises vacant or unoccupied?
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          name="check-ques-radio1"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="check-ques-radio1"
                        />
                      </div>
                    </ListGroup.Item>

                    <ListGroup.Item as="li">
                      <span>
                        Will the residence premises be occupied by the applicant
                        and/or co-applicant?
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          name="check-ques-radio2"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="check-ques-radio2"
                        />
                      </div>
                    </ListGroup.Item>

                    <ListGroup.Item as="li">
                      <span>
                        Will the residence premises be the applicant and/or
                        co-applicant's Primary residence
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          name="check-ques-radio3"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="check-ques-radio3"
                        />
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" id="search-questions-subdiv">
                      a. Is the swimming pool completely and permanently secured
                      with a self-latching gate or pool cage?
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          name="sub-check-radio"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="sub-check-radio"
                        />
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" id="search-questions-subdiv">
                      b. Is there a pool slide and/or diving board on the
                      property?
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          name="sub-check-radio1"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="sub-check-radio1"
                        />
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>

                <div className="search-questions-subdiv">
                  <span className="search-questions-subdiv-title">
                    OCCUPANCY INFORMATION
                  </span>
                  <ListGroup className="search-questions-list" as="ol" numbered>
                    <ListGroup.Item as="li">
                      <span>
                        Is the residence premises vacant or unoccupied?
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          name="check-ques-radio1"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="check-ques-radio1"
                        />
                      </div>
                    </ListGroup.Item>

                    <ListGroup.Item as="li">
                      <span>
                        Will the residence premises be occupied by the applicant
                        and/or co-applicant?
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          name="check-ques-radio2"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="check-ques-radio2"
                        />
                      </div>
                    </ListGroup.Item>

                    <ListGroup.Item as="li">
                      <span>
                        Will the residence premises be the applicant and/or
                        co-applicant's Primary residence
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          name="check-ques-radio3"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="check-ques-radio3"
                        />
                      </div>
                    </ListGroup.Item>

                    <ListGroup.Item as="li">
                      <span>
                        Will the residence premises be the applicant and/or
                        co-applicant's Secondary residence?
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          name="check-ques-radio4"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="check-ques-radio4"
                        />
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item as="li">
                      <span>
                        Will the residence premises be the applicant and/or
                        co-applicant's Seasonal residence?
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          name="check-ques-radio5"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="check-ques-radio5"
                        />
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item as="li">
                      <span>
                        How many families will occupy the residence premises?
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          name="check-ques-radio6"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="check-ques-radio6"
                        />
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item as="li">
                      <span>
                        Will the residence premises have continuous unoccupancy
                        of 3 or more consecutive months, or a total unoccupancy
                        of six or more months during any 1 year period?
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Select
                          className="dashboard-group-input check-question-select"
                          id="select-ym-option check-question-select"
                          check-question-select
                        >
                          <option>2</option>
                          <option>3</option>
                        </Form.Select>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item as="li">
                      <span>
                        Will more than two boarders reside at the residence
                        premises?{" "}
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          name="check-ques-radio8"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="check-ques-radio8"
                        />
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div> */}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
     
      <FooterButton rateSubmit={props.rateSubmit} isBind={props.isBind} />

      {/* add interest modal */}
      <Modal
        className="custom-dashboard-modal"
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton className="custom-dashboard-modal-header">
          <Modal.Title>Add Interest</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="custom-dashboard-modal-body"
          style={{ paddingTop: "40px", paddingBottom: "40px" }}
        >
          <Row className="align-items-end">
            <Col xs="12" sm="12" md="12" lg="6" xl="6">
              <Form.Group
                controlId="formBasicEmail"
                className="search-quote-form-group"
              >
                <Form.Label className="dashboard-group-label">
                  Select Interest
                </Form.Label>
                <Select
                  options={interestOptions}
                  className="custom-select-autocomplete"
                  value={interestValue}
                  onChange={(e) => {
                    setInterestValue(e);
                    setSelectInterest(e.value);
                  }}
                />
              </Form.Group>
            </Col>

            <Col xs="12" sm="12" md="12" lg="6" xl="6">
              <Form.Group
                controlId="formBasicEmail"
                className="search-quote-form-group"
              >
                <div className="d-grid gap-2">
                  <Button
                    size="lg"
                    id="common-btn"
                    variant="primary"
                    style={{
                      whiteSpace: "nowrap",
                      padding: "9px",
                    }}
                  >
                    <FaPlus size={15} style={{ marginRight: "10px" }} />
                    Add Additional Interests
                  </Button>{" "}
                </div>
              </Form.Group>
            </Col>
          </Row>
          {(selectInterest === "PRIMARY" ||
            selectInterest === "SECOND" ||
            selectInterest === "THIRD") && (
              <Row>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  style={{ marginBottom: "30px" }}
                >
                  <Select
                    options={nameOptions}
                    className="custom-select-autocomplete"
                    value={selectedName}
                    onChange={(e) => {
                      setSelectedName(e);
                      setData({
                        ...data,
                        name: e.value,
                      });
                    }}
                    placeholder="Input search text"
                  />
                </Col>
              </Row>
            )}

          <Row>
            {selectInterest === "ADDITIONALINSURED" ||
              selectInterest === "ADDITIONALINTEREST" ? (
              <Fragment>
                <Col xs="12" sm="12" md="12" lg="4" xl="4">
                  <Form.Group
                    controlId="formBasicEmail"
                    className="search-quote-form-group"
                  >
                    <Form.Label className="dashboard-group-label">
                      First Name
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Type here"
                      name="firstName"
                      value={data.firstName}
                      onChange={(e) => handleInputDataChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="12" lg="4" xl="4">
                  <Form.Group
                    controlId="formBasicEmail"
                    className="search-quote-form-group"
                  >
                    <Form.Label className="dashboard-group-label">
                      Middle Name
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Type here"
                      name="middleName"
                      value={data.middleName}
                      onChange={(e) => handleInputDataChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="12" lg="4" xl="4">
                  <Form.Group
                    controlId="formBasicEmail"
                    className="search-quote-form-group"
                  >
                    <Form.Label className="dashboard-group-label">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Type here"
                      name="lastName"
                      value={data.lastName}
                      onChange={(e) => handleInputDataChange(e)}
                    />
                  </Form.Group>
                </Col>
              </Fragment>
            ) : (
              <Col xs="12">
                <Form.Group
                  controlId="formBasicEmail"
                  className="search-quote-form-group"
                >
                  <Form.Label className="dashboard-group-label">
                    Full Name
                  </Form.Label>
                  <Form.Control
                    className="dashboard-group-input"
                    type="text"
                    placeholder="Type here"
                    name="name"
                    value={data.name}
                    onChange={(e) => handleInputDataChange(e)}
                  />
                </Form.Group>
              </Col>
            )}
            {selectInterest === "ADDITIONALINTEREST" && (
              <Col xs="12" sm="12" md="12" lg="12" xl="12">
                <Form.Group
                  controlId="formBasicEmail"
                  className="search-quote-form-group"
                >
                  <Form.Label className="dashboard-group-label">
                    Type
                  </Form.Label>
                  <Form.Select
                    className="dashboard-group-input"
                    name="type"
                    value={data.type}
                    onChange={(e) => handleInputDataChange(e)}
                  >
                    <option>Select</option>
                    <option value="PROPMGR">Property Manager</option>
                    <option value="OTHERS">Others</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            )}
            <Col xs="12">
              <Form.Group
                controlId="formBasicEmail"
                className="search-quote-form-group"
              >
                <Form.Label className="dashboard-group-label">
                  Mailing Address{" "}
                </Form.Label>
                <Form.Control
                  className="dashboard-group-input"
                  type="text"
                  placeholder="Type here"
                  name="mailAddress1"
                  value={data.mailAddress1}
                  onChange={(e) => handleInputDataChange(e)}
                />
              </Form.Group>
            </Col>
            <Col xs="12">
              <Form.Group
                controlId="formBasicEmail"
                className="search-quote-form-group"
              >
                <Form.Label className="dashboard-group-label">
                  Mailing Address 1{" "}
                </Form.Label>
                <Form.Control
                  className="dashboard-group-input"
                  type="text"
                  placeholder="Type here"
                  name="mailAddress2"
                  value={data.mailAddress2}
                  onChange={(e) => handleInputDataChange(e)}
                />
              </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="6" lg="6" xl="6">
              <Form.Group
                controlId="formBasicEmail"
                className="search-quote-form-group"
              >
                <Form.Label className="dashboard-group-label">City</Form.Label>
                <Form.Control
                  className="dashboard-group-input"
                  type="text"
                  placeholder="Type here"
                  name="city"
                  value={data.city}
                  onChange={(e) => handleInputDataChange(e)}
                />
              </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="6" lg="6" xl="6">
              <Form.Group
                controlId="formBasicEmail"
                className="search-quote-form-group"
              >
                <Form.Label className="dashboard-group-label">State</Form.Label>
                <Form.Control
                  className="dashboard-group-input"
                  type="text"
                  placeholder="Type here"
                  name="state"
                  value={data.state}
                  onChange={(e) => handleInputDataChange(e)}
                />
              </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="6" lg="6" xl="6">
              <Form.Group
                controlId="formBasicEmail"
                className="search-quote-form-group"
              >
                <Form.Label className="dashboard-group-label">
                  Zip Code
                </Form.Label>
                <Form.Control
                  className="dashboard-group-input"
                  type="text"
                  placeholder="Type here"
                  name="zipCode"
                  value={data.zipCode}
                  onChange={(e) => handleInputDataChange(e)}
                />
              </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="6" lg="6" xl="6">
              <Form.Group
                controlId="formBasicEmail"
                className="search-quote-form-group"
              >
                <Form.Label className="dashboard-group-label">
                  Loan Number
                </Form.Label>
                <Form.Control
                  className="dashboard-group-input"
                  type="text"
                  placeholder="Type here"
                  name="loadNo"
                  value={data.loadNo}
                  onChange={(e) => handleInputDataChange(e)}
                />
              </Form.Group>
            </Col>
            <Col xs="12">
              <div className="center-div-class">
                <Button
                  onClick={() => setShowModal(false)}
                  id="common-cancel-btn"
                  variant="primary"
                  style={{ padding: "8px 24px", marginRight: "21px" }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => handleSaveInterest()}
                  id="common-btn"
                  variant="primary"
                  style={{ padding: "8px 24px" }}
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    getQuoteData: state.common.getQuoteData ? state.common.getQuoteData : {},
    getQuoteDataHO3: state.common.getQuoteDataHO3 ? state.common.getQuoteDataHO3 : {},
  };
}
export default connect(mapStateToProps)(ApplicationTab);
