import Http from "../Http";
import * as action from "../store/actions";

// To get All Products List
export function productList(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("api/corePolicies/products/list")
        .then((res) => {
          dispatch(action.setAllProducts(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Action Type Dropdown
export function actionTypeDD() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("api/corePolicies/getActionType")
        .then((res) => {
          dispatch(action.setActionTypeDD(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get List Of Issued Policies
export function getPolicyListData(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      var url = `/api/corePolicies/list?`;
      Object.entries(params).forEach(v => {
        if (v[1] !== '') {
          url = url + '&' + v[0] + '=' + v[1];
        }
      });
      Http.get(url)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To verify Policy & Agency Status
export function verifyPolicy(policyNo) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("api/corePolicies/verify/" + policyNo)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To Reset Core System Reducer Store
export function resetCoreStore() {
  return (dispatch) => dispatch(action.resetCoreStore());
}

//set Poilicy_PK termMaster_PK Transaction_PK in redux store
export function setPolicyPropsData(params) {
  return (dispatch) => dispatch(action.setPolicyPropsData(params));
}

//set termMaster_PK in redux store
export function setTermMaster(termMasterPK) {
  return (dispatch) => dispatch(action.setTermMaster(termMasterPK));
}

//set Transaction_PK in redux store
export function setTransaction(poTransPK) {
  return (dispatch) => dispatch(action.setTransaction(poTransPK));
}

// To get Term Selection
export function termSelection(policyNoID) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("api/corePolicies/termDD/" + policyNoID)
        .then((res) => {
          dispatch(action.setTermSelection(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}
// To get Transaction DD
export function transactionDD(policyIdPK, termMasterPK) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/transactionDD/" +
        policyIdPK +
        "/" +
        termMasterPK
      )
        .then((res) => {
          dispatch(action.setTransactionDD(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Policy-details --application tab
export function applicationData(transactionPk) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("api/corePolicies/getPolicyDetails/" + transactionPk)
        .then((res) => {
          dispatch(action.setApplicationData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

//get Policy Coverage Details
export function getCvg(policyPK, poTransPK) {
  return dispatch => (
    new Promise((resolve, reject) => {
      const url = `/api/corePolicies/getCvgData/` + policyPK + "/" + poTransPK;
      Http.get(url)
        .then((res) => {
          dispatch(action.getCvgData(res.data))
          return resolve(res);
        }).catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    })
  );
}

// To get Additional Data
export function additionalData(policyPK, poTransPK) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/getAdditionalData/" + policyPK + "/" + poTransPK
      )
        .then((res) => {
          dispatch(action.getAdditionalData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Mortgagee Data
export function mortgageeData(poTransPK) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/getMortgageeData/" + poTransPK
      )
        .then((res) => {
          dispatch(action.getMortgageeData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Household Data
export function householdData(poTransPK) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/getHouseholdMember/" + poTransPK
      )
        .then((res) => {
          dispatch(action.getHouseholdData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Additional Interest Data
export function addiIntData(poTransPK) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/getAdditionalInterest/" + poTransPK
      )
        .then((res) => {
          dispatch(action.getAddiInterestData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Prior Policy Loss Data
export function priorPolicyLossData(poTransPK) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/getPriorPolicyLoss/" + poTransPK
      )
        .then((res) => {
          dispatch(action.getPriorPolicyLossData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Form Copy Data
export function formCopyData(formInitial, poTransPK) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/getFormCopy/" + formInitial + "/" + poTransPK
      )
        .then((res) => {
          dispatch(action.getFormCopyData(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Billing Data
export function getBillingData(policyNo) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/getBilling/" + policyNo
      )
        .then((res) => {
          dispatch(action.getBillingData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Account View Data
export function accountData(termMaster, accountNo) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/billing/account/" + termMaster + "/" + accountNo
      )
        .then((res) => {
          dispatch(action.getAccountData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Receivable Data
export function receivableInvoice(termMaster, accountNo) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/billing/receivable/" + termMaster + "/" + accountNo
      )
        .then((res) => {
          dispatch(action.getReceivableData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Invoicing Data
export function invoiceData(termMaster, accountNo) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/billing/invoicing/" + termMaster + "/" + accountNo
      )
        .then((res) => {
          dispatch(action.getInvoiceData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Invoice Premium and Fees
export function invoicePreFee(invoicePK) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/billing/invPremiumFees/" + invoicePK
      )
        .then((res) => {
          dispatch(action.getInvoicePreFeeData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

//get Policy Property Info
export function getPropertyInfo(policyNoID, termMasterPK) {
  return dispatch => (
    new Promise((resolve, reject) => {
      const url = `/api/corePolicies/getPropertyData/` + policyNoID + '/' + termMasterPK;
      Http.get(url)
        .then((res) => {
          dispatch(action.setPropertyInfo(res.data))
          return resolve();
        }).catch((err) => {
          if (err.response) {
            console.log("error");
            return resolve(err.response);
          }
        });
    })
  );
}

//get Base Data
export function getBaseData(productIdFk) {
  return dispatch => (
    new Promise((resolve, reject) => {
      const url = `/api/quote/getBaseData/` + productIdFk;
      Http.get(url)
        .then((res) => {
          dispatch(action.setBaseData(res.data))
          return resolve();
        }).catch((err) => {
          if (err.response) {
            console.log("error");
            return resolve(err.response);
          }
        });
    })
  );
}

// save email Merge Commit - Developed by Vighnesh
export function saveEmail(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post("/api/corePolicies/updateEmail", params)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          if (err.response) {
            return resolve(err.response.data);
          }
        });
    })
  );
}

/**
 * To get Document Type Drop Down
 * @returns 
 */
export function getDocTypes(module, subModule) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/corePolicies/getDocTypes/dd/' + module + '/' + subModule)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          if (err.response) {
            return reject(err.response);
          }
        });
    })
  );
}

/**
 * To Get Document List For policy
 * @returns 
 */
export function getDocList(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      params = 'pageLength=' + params.pageLength + '&page=' + params.page + '&sourceId=' + params.sourceId + '&module=' + params.module + '&submodule=' + params.submodule + '&searchKey=' + params.searchKey
      Http.get('/api/corePolicies/getDocList?' + params)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          if (err.response) {
            return reject(err.response);
          }
        });
    })
  );
}

/**
 * To Get Document Temp URL
 * @returns 
 */
export function generateTempUrl(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/corePolicies/generateTempurl/' + params.id + '/' + params.type + '/' + params.module)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          if (err.response) {
            return reject(err.response);
          }
        });
    })
  );
}