import React, { useState, useEffect } from "react";
import { Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import { FaBars, FaSearch } from "react-icons/fa";
import SearchQuoteTab from "./search-quote/SearchQuoteTab";
import Icon1 from "../../assets/Icon1.png";
import Icon2 from "../../assets/Icon2.png";
import Icon3 from "../../assets/Icon3.png";
import Icon4 from "../../assets/Icon4.png";
import Flood from "../../assets/flood_house.png"
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./quote.scss";
import {Link } from 'react-router-dom';
import { resetFloodStore } from "../../services/floodService";
import { getQuoteListData,getProductListData,getAppStatusDD } from "../../services/quoteService";
import { useDispatch, connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showDataTableTotalText } from "../../services/commonService";
const Quote = (props) => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [createQuote, setCreateQuote] = useState(false);
  const [TableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({
    quoteNo: "",
    policyNo: "",
    product: "",
    appStatus: "",
    firstName: "",
    lastName: "",
    agencyCode: "",
    agencyName: "",
    page:1,
    pageLength:10
  });
  const [ProductList, setProductList] = useState([]);
  const [AppStatus, setAppStatus] = useState([]);
  const [tableTotal, setTableTotal] = useState([]);
  
  useEffect(() => {
    searchQuoteList();
    getProductList();
  }, []);
  
  useEffect(() => {
    searchQuoteList();
  }, [filter.page]);

  useEffect(() => {
    setProductList(props.getProductList.data);
    setAppStatus(props.getAppStatusDDList.data);
  }, [props]);

  const searchQuoteList = async () => {
    await dispatch(getQuoteListData(filter)).then((res) => { 
     setTableData(res.data.data);
     setTableTotal(res.data.total);
    });
  };

  const getProductList = async () => {
    await dispatch(getProductListData());
    await dispatch(getAppStatusDD());
  };

  const productsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        policy_no: "HO32014012226",
        insured_name: "ROGE DAMEUS",
        product_type: "HO6 (Homeowners)",
        renew_date: "MM/DD/YYYY",
      });
    }
    return items;
  };

  const products = productsGenerator(5);

  const columnFormatter = (cell, row, rowIndex, formatExtraData) => {
  if(row && row.Policy_No && row.n_ProductId_PK === 10){
    return <Link to={`/flood/quote/${row.n_potransaction_PK}`} onClick={() => dispatch(resetFloodStore())}>{cell}</Link>
  } else if (row && row.Policy_No) {
		return <Link to={`editApplication/${row.n_ProductId_PK}/${row.n_potransaction_PK}`}>{cell}</Link>
	} else {
	  return row.Policy_No;
	}
  };
  
  const columns = [
    {
      dataField: "d_CreatedDate",
      text: t("Submit Date"),
    },
    {
      dataField: "s_ProductName",
      text: t("Product Type"),
    },
    {
      dataField: "s_PolicyQuoteNo",
      text: t("Quote No"),
	  formatter: columnFormatter
    },
    {
      dataField: "s_FullLegalName",
      text: t("Insured Name"),
    },
    {
      dataField: "s_TransactionCycleCode",
      text: t("Status"),
    },
    {
      dataField: "Policy_No",
      text: t("Policy No"),
    },

  ];
  const onHandleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    const tempArray = filter; // creating copy of an array
    tempArray[name] = e.target.value
    setFilter(tempArray); // set copied array
  }

  const handlePageChange = (page, sizePerPage) => {
    setFilter({...filter,page:page,pageLength:sizePerPage});
  };

  const sizePerPageListChange = (sizePerPage) => {
    setFilter({...filter,pageLength:sizePerPage});
  };

  const renderShowsTotal = (start, to, total) => {
    return showDataTableTotalText(start, to, total);
  };

  const options = {
    page: filter.page,
    sizePerPage: filter.pageLength,
    sortIndicator: false,
    pageStartIndex: 1,
    paginationShowsTotal: renderShowsTotal,
    onPageChange: handlePageChange,
    totalSize:tableTotal
    //sizePerPageDropDown: renderSizePerPageDropDown
  };

  return (
    <div className="quote-page-section">
      <Row>
        <Col xs="12" className="custom-tabs">
          <Tabs defaultActiveKey="search" id="uncontrolled-tab-example">
            <Tab eventKey="search" title={t("Search Quote")}>
              <Row className="align-items-baseline">
                <Col xs="12" className="quote-page-tab1">
                  <Row className="align-items-center">
                    <Col xs="12" sm="12" md="4" lg="2" xl="2">
                      <Form.Group
                        className="dashboard-group-forms"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("Quote No")}.
                        </Form.Label>
                        <Form.Control
                          name="quoteNo"
                          className="dashboard-group-input"
                          type="text"
                          placeholder=""
                          onChange={(e) => onHandleChange(e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="2" xl="2">
                      <Form.Group
                        className="dashboard-group-forms"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("Last Name")}
                        </Form.Label>
                        <Form.Control
                          name="lastName"
                          className="dashboard-group-input"
                          type="text"
                          placeholder=""
                          onChange={(e) => onHandleChange(e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="2" xl="2">
                      <Form.Group
                        className="dashboard-group-forms"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("First Name")}
                        </Form.Label>
                        <Form.Control
                          name="firstName"
                          className="dashboard-group-input"
                          type="text"
                          placeholder=""
                          onChange={(e) => onHandleChange(e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="2" xl="2">
                      <Form.Group
                        className="dashboard-group-forms"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("App. Status")}:
                        </Form.Label>
                        <Form.Select
                          name="appStatus"
                          className="dashboard-group-input"
                          id="select-ym-option"
                          onChange={(e) => onHandleChange(e)}
                        >
                          <option value={''} className="myform-input custom-select-autocomplete">Select</option>
                          {
                            AppStatus && AppStatus.map((v, i) => {
                              return <option className="myform-input custom-select-autocomplete" key={i} value={v.value} >{v.label}</option>
                            })
                          }
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="2" xl="2">
                      <Form.Group
                        className="dashboard-group-forms"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("Policy No")}
                        </Form.Label>
                        <Form.Control
                          name="policyNo"
                          className="dashboard-group-input"
                          type="text"
                          placeholder=""
                          onChange={(e) => onHandleChange(e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="2" xl="2">
                      <Form.Group
                        className="dashboard-group-forms"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("Select Product")}
                        </Form.Label>
                        <Form.Select
                          name="product"
                          className="dashboard-group-input"
                          id="select-ym-option"
                          onChange={(e) => onHandleChange(e)}
                        >
                          <option value={''} className="myform-input custom-select-autocomplete">Select</option>
                          {
                            ProductList && ProductList.map((v, i) => {
                              return <option className="myform-input custom-select-autocomplete" key={i} value={v.value} >{v.label}</option>
                            })
                          }
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="2" xl="2">
                      <Form.Group
                        className="dashboard-group-forms"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("Agency Name")}
                        </Form.Label>
                        <Form.Control
                          name="agencyName"
                          className="dashboard-group-input"
                          type="text"
                          placeholder=""
                          onChange={(e) => onHandleChange(e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="2" xl="2">
                      <Form.Group
                        className="dashboard-group-forms"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("Agency Code")}
                        </Form.Label>
                        <Form.Control
                          name="agencyCode"
                          className="dashboard-group-input"
                          type="text"
                          placeholder=""
                          onChange={(e) => onHandleChange(e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                      <Button id="common-btn" variant="primary" onClick={() => searchQuoteList()}>
                        <FaSearch size={15} style={{ marginRight: "14px" }} />
                        {t("Search Policy")}
                      </Button>{" "}
                    </Col>
                  </Row>
                </Col>
                <Row
                  id="dashboard-table-section"
                  style={{ padding: "55px 0px 0px 0px" }}
                >
                  <Col xs="12" style={{ padding: 0 }}>
                    <div className="table-main-title-div">
                      <span className="table-main-title-name">
                        {t("Search Result")}
                      </span>
                      <div className="table-main-title-icons">
                        <div className="table-square"></div>
                        <FaBars size={15} color={"#fff"} />
                      </div>
                    </div>
                  </Col>
                  <Col xs="12" style={{ padding: 0 }}>
                    <BootstrapTable
                      wrapperClasses="dashboard-table-main"
                      bootstrap4
                      remote
                      keyField="Policy_No"
                      data={TableData}
                      columns={columns}
                      hover
                      pagination={paginationFactory(options)}
                    />
                  </Col>
                </Row>
              </Row>
            </Tab>
            <Tab eventKey="create" title={t("Create Quote")}>
              {createQuote ? (
                <SearchQuoteTab />
              ) : (
                <Row>
                  <Col xs="12" className="quote-page-tab2">
                    <Row>
                      <Col xs="12" sm="12" md="6" lg="3" xl="3">
                        <div
                          className="quote-page-cards-div qpc1"
                          onClick={() => navigation('/quote/editApplication/13')}
                        >
                          <div className="quote-page-cards-bg1"></div>
                          <div className="quote-page-cards-bg2"></div>

                          <img className="quote-page-cards-icon" src={Icon1} />

                          <div className="quote-page-cards-title">{t("Home")}</div>
                        </div>
                      </Col>

                      <Col xs="12" sm="12" md="6" lg="3" xl="3">
                        <div
                          className="quote-page-cards-div qpc2"
                          onClick={() => setCreateQuote(true)}
                        >
                          <div className="quote-page-cards-bg1"></div>
                          <div className="quote-page-cards-bg2"></div>

                          <img className="quote-page-cards-icon" src={Icon2} />

                          <div className="quote-page-cards-title">
                            {t("Condominium")}
                          </div>
                        </div>
                      </Col>

                      <Col xs="12" sm="12" md="6" lg="3" xl="3">
                        <div
                          className="quote-page-cards-div qpc3"
                          onClick={() => setCreateQuote(true)}
                        >
                          <div className="quote-page-cards-bg1"></div>
                          <div className="quote-page-cards-bg2"></div>

                          <img className="quote-page-cards-icon" src={Icon3} />

                          <div className="quote-page-cards-title">
                            {t("Mobil Home Owners Program")}{" "}
                          </div>
                        </div>
                      </Col>

                      <Col xs="12" sm="12" md="6" lg="3" xl="3">
                        <div
                          className="quote-page-cards-div qpc4"
                          onClick={() => setCreateQuote(true)}
                        >
                          <div className="quote-page-cards-bg1"></div>
                          <div className="quote-page-cards-bg2"></div>

                          <img className="quote-page-cards-icon" src={Icon4} />

                          <div className="quote-page-cards-title">
                            {t("Mobil Home Dwelling Program")}{" "}
                          </div>
                        </div>
                      </Col>

                      <Col xs="12" sm="12" md="6" lg="3" xl="3">
                        <div
                          className="quote-page-cards-div qpc5"
                          onClick={() => navigation('/flood/instantquote/step/address')}
                        >
                          <div className="quote-page-cards-bg1"></div>
                          <div className="quote-page-cards-bg2"></div>

                          <img className="quote-page-cards-icon" src={Flood} />

                          <div className="quote-page-cards-title">
                            {t("Flood")}{" "}
                          </div>
                        </div>
                      </Col>

                      <Col xs="12" sm="12" md="6" lg="3" xl="3">
                        <div
                          className="quote-page-cards-div qpc1"
                          onClick={() => navigation('/instaQuote/InstaSteps/address')}
                        >
                          <div className="quote-page-cards-bg1"></div>
                          <div className="quote-page-cards-bg2"></div>

                          <img className="quote-page-cards-icon" src={Icon1} />

                          <div className="quote-page-cards-title">{t("Home(InstaQuote)")}</div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    getQuoteList: state.common.getQuoteList ? state.common.getQuoteList : {},
    getProductList: state.common.getProductList ? state.common.getProductList : {},
    getAppStatusDDList: state.common.getAppStatusDDList ? state.common.getAppStatusDDList : {},
    
  };
}
export default connect(mapStateToProps)(Quote);