import React, { useState,useEffect  } from 'react';
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { useDispatch, connect } from "react-redux";
import { getErrorLogListData } from "../../../../../../services/quoteService";
import { showDataTableTotalText } from "../../../../../../services/commonService";

const ErrorLog = (props) => {
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);
    const [tableTotal, setTableTotal] = useState([]);
    const [loading, setLoading] = useState(false);

    const [filter, setFilter] = useState({
        n_potransaction_PK : props.n_potransaction_PK,
        page:1,
        pageLength:10
    });
    
    useEffect(() => {
        if(filter.n_potransaction_PK != null && props.isShow == true){
            getErrorLogList();
        }
    }, [props]);

    useEffect(() => {
        getErrorLogList();
    }, [filter.page]);
    
    const getErrorLogList = async () => {
      setLoading(true);
    await dispatch(getErrorLogListData(filter)).then((res) => { 
        setTableData(res.data.data);
        setTableTotal(res.data.total);
    });
      setLoading(false);
    };
      const columns = [
        {
          dataField: "n_ErrorNo_FK",
          text: "Error Code",
          width: '800px',
        //   formatter: actionsButtons
        },
        {
          dataField: "s_ErrorDescription",
          text: "Error Description",
        },
        {
          dataField: "s_UnderWriterApproved",
          text: "UnderWriter Approved",
          style: {
            width:"200px"
          },
        },
        {
          dataField: "s_ErrorSource",
          text: "Error Source",
        },
        {
          dataField: "s_ActionSource",
          text: "Action Source",
        },
        {
            dataField: "d_CreatedDate",
            text: "Created date",
            style: {
                width:"120px"
            },
        },
    
      ];
    
      const handlePageChange = (page, sizePerPage) => {
        setFilter({...filter,page:page,pageLength:sizePerPage});
      };
    
      const sizePerPageListChange = (sizePerPage) => {
        setFilter({...filter,pageLength:sizePerPage});
      };
    
      const renderShowsTotal = (start, to, total) => {
        return showDataTableTotalText(start, to, total);
      };
    
      const options = {
        page: filter.page,
        sizePerPage: filter.pageLength,
        sortIndicator: false,
        pageStartIndex: 1,
        paginationShowsTotal: renderShowsTotal,
        onPageChange: handlePageChange,
        totalSize:tableTotal
        //sizePerPageDropDown: renderSizePerPageDropDown
      };    
    

  return (
    <Modal
        className="custom-dashboard-modal"
        size="xl"
        show={props.isShow}
        onHide={() => {props.isOpen(false);}}
      >
        <Modal.Header closeButton  className="custom-dashboard-modal-header">
          <Modal.Title>Bound Errors</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-dashboard-modal-body">
          <Row style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              <LoadingOverlay>
                <Loader loading={loading} />
                <BootstrapTable
                  wrapperClasses="dashboard-table-main"
                  bootstrap4
                  remote
                  keyField="n_PoPolicyUnBoundErrorsId_PK"
                  data={tableData}
                  columns={columns}
                  hover
                  pagination={paginationFactory(options)}
                />
              </LoadingOverlay>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
  )
}

export default ErrorLog