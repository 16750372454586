import React, { Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  sizePerPageRenderer,
  pageButtonRenderer,
} from "../../../components/CustomTablePagination";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const TrainingTab = () => {
  
  const tableData = () => {
    const items = [
    {title:'Flood Basics' ,  data : [{label:'Flood Basics',value:'https://edu.myflood.com/course/flood-basics/'}]},
    {title:'Advanced Flood Topics', data : [{label:'Advanced Flood Topics',value:'https://edu.myflood.com/course/advanced-flood-topics/'}]},
    {title:'Existing Policies', data : [{label:'Existing Policies',value:'https://edu.myflood.com/course/existing-flood-policies/'}]},
    {title:'Flood Claims', data : [{label:'Flood Claims',value:'https://edu.myflood.com/course/claims/'}]},
    {title:'Flood Risks', data : [{label:'Flood Risks',value:'https://edu.myflood.com/course/flood-risks/'}]},
    {title:'Hurricane and Flood Events', data : [{label:'Hurricane and Flood Events',value:'https://edu.myflood.com/course/hurricanes-flood-events/'}]},
    {title:'Selling Flood Insurance', data : [{label:'Selling Flood Insurance',value:'https://edu.myflood.com/course/selling-flood-insurance/'}]},
    {title:'Risk Rating 2.0', data : [{label:'Risk Rating 2.0',value:''}]},
  ];
    return items;
  };

  const listData = tableData();

   //table column formating
  const columnFormatter = (cell, row) => {
    return (
      <div>
        {
          row.data.map((val) =>(
            <div>
              <div>
              <a href={val.value} target="_blank">{cell}</a>
              </div>
              <br></br>
            </div>
            )
          )
        }
      </div>
    );
  };

  const columns = [
    {
      dataField: "title",
      text: "",
      formatter: columnFormatter,
    },
  ];

  const options = {
    sizePerPageRenderer,
    pageButtonRenderer,
    showTotal: true,
    alwaysShowAllBtns: true,
  };
  
  return (
    <Fragment>
      <div className="custom-tab-header-fill">
        <span></span>
      </div>
      <div className="managment-tab-body">
        <div
          className="management-reports-table"
          id="management-reports-table-outer-div"
        >
          <BootstrapTable
            wrapperClasses="management-functional-table"
            bootstrap4
            keyField="title"
            data={listData}
            columns={columns}
            hover
            bordered={false}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default TrainingTab;
