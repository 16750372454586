import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import userImg from "../../../../../assets/thank-you.svg";
import "./flood-thank-you.scss";

const FloodThankYou = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { emptyQuoteData } = useSelector(state => state.flood);
  const [poTransPK, setPoTransPK] = useState(0);

  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0) {
      setPoTransPK(emptyQuoteData.transactionInfo.n_potransaction_PK || '');
    }
  }, [emptyQuoteData]);

  return (
    <section className="thank-you-section">
      {/* Form */}
      <Row className="justify-content-center">
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="8"
          xl="8"
          className="thank-you-form-section"
          style={{ paddingBottom: "80px" }}
        >
          <div className="thank-you-form-main">
            <div className="thank-you-main">
              <div className="thank-you-heading">
                <h6>{t("Thank You")}!</h6>
              </div>
              <div className="thank-you-details">
                <img src={userImg} />
                <p>
                  {t(
                    "Our underwriting team will look over your application and send you updates within 24-48 hours. Have any more questions, please contact one of our agents at"
                  )}{" "}
                  <br />
                  <span>
                    813-555-5555 <br />{" "}
                  </span>
                  {t("Or, leave us an email at")}{" "}
                  <span>info@insurance.com.</span>
                </p>
              </div>
              <div
                className="pay-now-action-btn"
                style={{ marginBottom: "18px" }}
              >
                {
                  ![null, ''].includes(poTransPK) && (
                    <Button
                      className="mr-2"
                      id="primary-colored-btn"
                      variant="primary"
                      onClick={() => navigation('/flood/quote/' + poTransPK)}
                    >
                      <span>{t('Back To Policy')}</span>
                    </Button>
                  )
                }
                <Button
                  id="primary-colored-btn"
                  variant="primary"
                  onClick={() => navigation('/flood')}
                >
                  <span>{t('Back')}</span>
                </Button>
              </div>
            </div>
          </div>
        </Col>

      </Row>
    </section>
  );
};

export default FloodThankYou;
