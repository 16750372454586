import "bootstrap-daterangepicker/daterangepicker.css";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useDropzone } from "react-dropzone";
import {
  FaFileUpload,
  FaPlus,
  FaRegFileImage,
  FaRegIdBadge,
} from "react-icons/fa";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { connect, useDispatch } from "react-redux";
import {
  verifyPolicy, setPolicyPropsData, setTermMaster, setTransaction, applicationData, termSelection,
  transactionDD, getCvg, additionalData, mortgageeData, householdData, addiIntData, priorPolicyLossData, getBillingData,
  getPropertyInfo, getBaseData
} from "../../../services/coreSystemService";
import AdditionalInfoTab from "./AdditionalInfoTab";
import BillingTab from "./BillingTab";
import CoverageTab from "./CoverageTab";
import FormTab from "./FormTab";
import PropertyInfoTab from "./PropertyInfoTab";
import ApplicationTab from "./ApplicationTab";
import FloodInfoTab from "./FloodPropertyInfo";
import "./policy-details.scss";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ActivityLogModal from "../Common/ActivityLogModal";
import AttachmentModal from "../Common/Attachment/index";
import ReactTooltip from "react-tooltip";

const PolicyDetails = (props) => {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('application');
  const { policyNumber ,type = ''} = useParams();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showActivityLogModal, setShowActivityLogModal] = useState(false);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  // Verify Policy If Policy ID, Term Master ID & Transaction ID Empty
  useEffect(() => {
    const verify = async () => {
      setLoading(true);
      await dispatch(verifyPolicy(policyNumber)).then((res) => {
        if (res.status) {
          const params = {
            n_POPolicyMasterFK: res.policyIdPK,
            n_POTermMasterFK: res.termMasterPK,
            n_POTransactionFK: res.poTransPK,
          };
          dispatch(setPolicyPropsData(params));
          dispatch(
            getBaseData(res.productFK)
          );
          setLoading(false);
        } else {
          toast.error(res.message);
          navigation(`/core/policy`);
        }
      });
    };

    if (
      props.n_POPolicyMasterFK === "" &&
      props.n_POTermMasterFK === "" &&
      props.n_POTransactionFK === ""
    ) {
      verify();
    }
  }, []);

  // Trigger on update Policy ID
  useEffect(() => {
    const getTerm = () => {
      dispatch(termSelection(props.n_POPolicyMasterFK));
    };
    if (props.n_POPolicyMasterFK !== "") {
      getTerm();
    }
  }, [dispatch, props.n_POPolicyMasterFK]);

  // Trigger on update Policy ID & Term Master
  useEffect(() => {
    if (props.n_POPolicyMasterFK !== "" && props.n_POTermMasterFK !== "") {
      getTransactions(props.n_POPolicyMasterFK, props.n_POTermMasterFK);
    }
  }, [props.n_POPolicyMasterFK, props.n_POTermMasterFK]);

  // Trigger on update Transaction
  useEffect(() => {
    if (props.n_POTransactionFK !== "") {
      getApplicant(props.n_POTransactionFK);
    }
  }, [props.n_POTransactionFK]);


  useEffect(() => {
    if(type == 'DOWNLOADDOCUMENTS'){
      setActiveTab('forms');
    }
  }, [type]);


  // Fetch Transaction List
  const getTransactions = (policyIdPK, termMasterPK) => {
    dispatch(transactionDD(policyIdPK, termMasterPK));
  };

  // Fetch Policy & Applicant Details
  const getApplicant = async (poTransPK) => {
    setLoading(true);
    await dispatch(applicationData(poTransPK));
    setLoading(false);
  };

  // Fetch Transaction List On Change Term
  const onTermChange = (termMasterPK) => {
    setActiveTab('application');
    getTransactions(props.n_POPolicyMasterFK, termMasterPK);
    dispatch(setTermMaster(termMasterPK));
  };

  // Fetch Policy & Applicant Details On Change Trnasctions
  const onTransactionChanged = (poTransPK) => {
    setActiveTab('application');
    getApplicant(poTransPK);
    dispatch(setTransaction(poTransPK));
  };

  const handleSelect = async (key) => {
    setActiveTab(key);
    switch (key) {
      case 'coverage':
        setLoading(true);
        await dispatch(getCvg(props.n_POPolicyMasterFK, props.n_POTransactionFK));
        setLoading(false);
        break;
      case "property-info":
        setLoading(true);
        await dispatch(
          getPropertyInfo(props.n_POPolicyMasterFK, props.n_POTransactionFK)
        );
        setLoading(false);
        break;
      case 'additional-info':
        setLoading(true);
        await dispatch(additionalData(props.n_POPolicyMasterFK, props.n_POTransactionFK));
        await dispatch(mortgageeData(props.n_POTransactionFK));
        await dispatch(householdData(props.n_POTransactionFK));
        await dispatch(addiIntData(props.n_POTransactionFK));
        await dispatch(priorPolicyLossData(props.n_POTransactionFK));
        setLoading(false);
        break;
      case 'billings':
        setLoading(true);
        await dispatch(getBillingData(policyNumber));
        setLoading(false);
        break;
      default:
        break;
    }
  };


  return (
    <LoadingOverlay>
      <Loader loading={loading} />
      <div className="policy-details-section">
        <Row>
          <Col xs="12">
            <div
              className="policy-details-header"
              style={{
                padding: "10px",
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginRight: "10%",
                }}
              >
                <div
                  className="policy-details-icons-section"
                  onClick={() => setShowActivityLogModal(!showActivityLogModal)}
                >
                  <FaRegIdBadge size={20} color="#64D6CD" />
                  <span>{t('log')}</span>
                </div>
                <div
                  className="policy-details-icons-section"
                  onClick={() => setShowModal(true)}
                >
                  <FaRegFileImage size={20} color="#64D6CD" />
                  <span>{t('Attachment')}</span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                <Form.Group
                  className="policy-details-group-form"
                  controlId="formBasicEmail"
                >
                  <Form.Label
                    className="dashboard-group-label"
                    style={{ marginRight: "10px" }}
                  >
                    {t('Terms Selection')}
                  </Form.Label>
                  <Form.Select
                    name="termSelection"
                    className="dashboard-group-input"
                    id="select-ym-option"
                    value={props.n_POTermMasterFK}
                    onChange={(e) => onTermChange(e)}
                  >
                    {Object.keys(props.termSelection).length > 0 &&
                      props.termSelection.map((v, i) => {
                        return (
                          <option key={i} value={v.n_TermMaster_PK}>
                            {v.term}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Form.Group>

                <Form.Group
                  className="policy-details-group-form"
                  controlId="formBasicEmail"
                >
                  <Form.Label
                    className="dashboard-group-label"
                    style={{ marginRight: "10px" }}
                  >
                    {t('Transaction Selection')}
                  </Form.Label>
                  <Form.Select
                    className="dashboard-group-input"
                    id="select-ym-option"
                    value={props.n_POTransactionFK}
                    onChange={(e) =>
                      onTransactionChanged(e.currentTarget.value)
                    }
                  >
                    {Object.keys(props.transactionDD).length > 0 &&
                      props.transactionDD.map((v, i) => {
                        return (
                          <option key={i} value={v.n_potransaction_PK}>
                            {v.code}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Form.Group>
                <Form.Group
                  className="policy-details-group-form"
                  controlId="formBasicEmail"
                >

                  {/* <a data-for="soclose" href='#'>Document Help</a> */}
                  <div className="block">
                    <a data-for="soclose"  href='#' data-tip="7">
                      Document Help
                    </a>
                  </div>
                  <ReactTooltip
                  id='soclose'
                  getContent={() => (
                    <div>
                      <p>Having trouble finding the form you're looking for ? Try <br></br> toggling the Terms Selection or Transaction Selection <br></br> dropdowns to view various copies of the policy documents</p>
                    </div>
                  )}
                  effect="solid"
                  delayHide={500}
                  delayShow={500}
                  delayUpdate={500}
                  place="bottom"
                  border={true}
                  type="light"
                />

                </Form.Group>
              </div>
            </div>
          </Col>

          <Col xs="12" id="policy-details-tabs-section" className="custom-tabs">
            <Tabs
              activeKey={activeTab}
              id="uncontrolled-tab-example"
              onSelect={handleSelect}
            >
              <Tab eventKey="application" title={t("Application")}>
                <ApplicationTab />
              </Tab>
              <Tab eventKey="coverage" title={t("Coverage")}>
                <CoverageTab />
              </Tab>

              <Tab eventKey="property-info" title={t("Property Info")}>

                {Object.keys(props.applicationData).length > 0 &&
                  props.applicationData.n_ProductId_FK == 10 ? (
                  <FloodInfoTab />
                ) : (
                  <PropertyInfoTab />
                )}
              </Tab>
              <Tab eventKey="additional-info" title={t("Additional Info")}>
                <AdditionalInfoTab />
              </Tab>
              <Tab eventKey="forms" title={t("Forms")}>
                <FormTab />
              </Tab>
              <Tab eventKey="billings" title={t("Billings")}>
                <BillingTab />
              </Tab>
            </Tabs>
          </Col>
        </Row>

        {/* attachment modal */}
        {
          showModal && (
            <AttachmentModal
              show={showModal}
              setShow={setShowModal}
              policyNumber={policyNumber}
              policyPK={props.n_POPolicyMasterFK}
            />
          )
        }
        {/* <Modal
          className="custom-dashboard-modal"
          size="xl"
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <Modal.Header closeButton className="custom-dashboard-modal-header">
            <Modal.Title>Attachment</Modal.Title>
          </Modal.Header>
          <Modal.Body className="custom-dashboard-modal-body">
            <Row>
              <Col xs="12">
                <span className="dashboard-group-label">
                  Select Document Type
                </span>
                <div className="select-document-type-div">
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="6" xl="3">
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>All Documents</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Inspection Report</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Police Report</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Letter of Acknowledgement</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Signed Application</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Four Point Inspection</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Wind Mitigation Inspection</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Photos </span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Proof of Prior Insurance</span>
                      </div>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="3">
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>HUD Statement</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Loss History - lexis Nexis</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Loss History - A plus</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Burglar Alarm Certificate </span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Fire & Burglar Alarm Certificate</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Driver's License</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Endorsement Request</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Sinkhole Coverage Exclusion form signed </span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Animal Liability Exclusion form Signed</span>
                      </div>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="3">
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>
                          Building Law & Ordination Building & Ordination{" "}
                        </span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Building Permit records</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Roof permit records</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>County Appraisal records</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Return Mail PH</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Return Mail MTG</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Email from Agent</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Email from PH </span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Complaint</span>
                      </div>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="3">
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>POP </span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Reissue Refund Check Request</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Reissue Claim Check Request</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Reissue Commission Check Request</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Others</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Property Appraisal</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Claim Supporting Document</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col xs="12" style={{ marginTop: "25px" }}>
                <span className="dashboard-group-label">Attachment</span>
                <div
                  id="drop-document-type-div"
                  {...getRootProps({ className: "dropzone" })}
                >
                  <input {...getInputProps()} />
                  <FaFileUpload size={34} color={"#64D6CD"} />
                  <p>Drag and Drop File Here</p>
                  <Button id="common-btn" variant="primary">
                    <FaPlus size={15} />
                    Add File
                  </Button>{" "}
                </div>

                <aside className="reply-document-name">
                  <ul>{files}</ul>
                </aside>
              </Col>
            </Row>
          </Modal.Body>
        </Modal> */}
        {/* Show Activity Log Modal */}
        {
          showActivityLogModal && (
            <ActivityLogModal
              show={showActivityLogModal}
              setShow={setShowActivityLogModal}
              policyNumber={policyNumber}
            />
          )
        }

      </div>
    </LoadingOverlay>
  );
};

const mapStateToProps = (state) => {
  return {
    transactionDD: state.coreSystem.transactionDD,
    termSelection: state.coreSystem.termSelection,
    n_POPolicyMasterFK: state.coreSystem.n_POPolicyMasterFK,
    n_POTermMasterFK: state.coreSystem.n_POTermMasterFK,
    n_POTransactionFK: state.coreSystem.n_POTransactionFK,
    applicationData: state.coreSystem.applicationData,
  };
};
export default connect(mapStateToProps)(PolicyDetails);
