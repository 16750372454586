import React, { useEffect, useState } from "react";
import { Button, Row, Col, Accordion, Alert } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getBaseData, getQuoteData, getEditEmptyQuoteData, getEmptyQuoteData, rateorSaveApplication, resetFloodStore, createApplication, getGeneratedDocs } from "../../../../services/floodService";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import Applicant from "./Applicant";
import BuildingFoundation from "./BuildingFoundation";
import Community from "./Community";
import Construction from "./Construction";
import Coverage from "./Coverage";
import MachineryEquipment from "./MachineryEquipment";
import Mortgagee from "./Mortgagee/FloodAddMortages";
import Occupancy from "./Occupancy";
import Policy from "./Policy";
import PriorPolicyInfo from "./PriorPolicyInfo";
import AdditionalInsured from "./Mortgagee/FloodAddInsured";
import NewlyMapDiscount from "./NewlyMapDiscount";
import ElevationCertificate from "./ElevationCertificate";
import FloodVents from "./FloodVents";
import CreateApplication from "./Modal/CreateApplication";
import Validation from "./Validation";
import ErrorModal from "./Modal/Error";
import "react-overlay-loader/styles.css";
import PivotLog from "./Modal/PivotLog";
import PremiumDetails from "./Modal/PremiumDetails";
import { FaSave, FaCheckDouble, FaPrint, FaHistory, FaListAlt, FaExclamationTriangle, FaCheckCircle } from "react-icons/fa";

const AccordionView = () => {
  let { poTransPk } = useParams();
  const navigation = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { emptyQuoteData, apiLoading, floodSystemDD } = useSelector(state => state.flood);
  const [isFirmUpdated, setIsFirmUpdated] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isBind, setIsBind] = useState(false);
  const [isCondoAssociation, setIsCondoAssociation] = useState('');
  const [showMortgagee, setShowMortgagee] = useState(false);
  const [showAddInsureds, setShowAddInsureds] = useState(false);
  const [isMaillingAddrDiffer, setIsMaillingAddrDiffer] = useState(false);
  const [waitingPeriod, setWaitingPeriod] = useState('');
  const [initFirmDate, setInitFirmDate] = useState(null);
  const [baseFlood, setBaseFlood] = useState(null);
  const [isPreFirm, setIsPreFirm] = useState(false);
  const [substantialImprovement, setSubstantialImprovement] = useState('NO');
  const [substantialImprovmentDate, setSubstantialImprovmentDate] = useState(null);
  const [DOC, setDOC] = useState(null);
  const [isMobileHome, setIsMobileHome] = useState(false);
  const [occupancyType, setOccupancyType] = useState('');
  const [NOU, setNOU] = useState(0);
  const [isInsuredResides, setIsInsuredResides] = useState(false);
  const [buildingType, setBuildingType] = useState('');
  const [foundationType, setFoundationType] = useState('');
  const [isCurrMapDateGreater, setIsCurrMapDateGreater] = useState(false);
  const [isNewlyMapped, setIsNewlyMapped] = useState(false);
  const [currentMapDate, setCurrentMapDate] = useState(null);
  const [isEC, setIsEC] = useState(false);
  const [floodZone, setFloodZone] = useState('');
  const [preFirmSubsidyEligible, setPreFirmSubsidyEligible] = useState(false);
  const [isTheInsured, setIsTheInsured] = useState('');
  const [poTransPK, setPoTransPK] = useState(null);
  const [showSubmitAppModal, setShowSubmitAppModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [index, setIndex] = useState(['0']);
  const [btLabel, setBtLabel] = useState('Expand All');
  const [isExpand, setIsExpand] = useState(false);
  const [pivotLog, showPivotLog] = useState(null);
  const [showPremium, setShowPremium] = useState(false);
  const [show, setShow] = useState(false);
  const [bindAlert, setBindAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');

  useEffect(() => {
    if (isExpand) {
      setIndex(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13']);
      setBtLabel('Close All');
    } else {
      setIndex(['0']);
      setBtLabel('Expand All');
    }
  }, [isExpand]);

  useEffect(() => {
    // Get Quote Data
    if (poTransPk) {
      getDropDown();
      getQuoteDataFLD(poTransPk, 'INIT');
    }
    // get emptyquote object
    if (!poTransPk && Object.keys(emptyQuoteData).length === 0) {
      getEmptyQuoteObject();
      getDropDown();
    }
  }, []);

  // isbinder
  useEffect(() => {
    // Get Quote Data
    if (Object.keys(emptyQuoteData).length > 0) {
      var temp = false;
      if (checkDateisValid(emptyQuoteData.policyInfo.d_BinderDate)) {
        temp = true;
      }
      setPoTransPK(emptyQuoteData.transactionInfo.n_potransaction_PK || null);
      setIsBind(temp);
    }
  }, [emptyQuoteData]);

  // Binder Alert
  useEffect(() => {
    setBindAlert(isBind);
    if (isBind) {
      setAlertMsg(t('Application Submitted: UW will review for issuance.'));
    }
  }, [isBind]);

  // Check Firm type
  useEffect(() => {
    checkFirmType();
  }, [DOC, substantialImprovement, substantialImprovmentDate]);

  // check firm type
  useEffect(() => {
    checkFirmType();
  }, [isFirmUpdated])

  // get dropdown
  const getDropDown = async () => {
    await dispatch(getBaseData(10));
  }

  // get emptyquote object
  const getEmptyQuoteObject = async () => {
    // reset store
    dispatch(resetFloodStore());
    await dispatch(getEmptyQuoteData(10));
  }

  // get quote data based on poTransPk
  const getQuoteDataFLD = async (poTransPk, from = null) => {
    if(from === 'INIT'){
      // reset store
      dispatch(resetFloodStore());
    }
    await dispatch(getQuoteData(poTransPk));
  }

  // For All Input/Select Handler
  const handleChange = (e, fullNameChange = '') => {
    var quoteDataVar = emptyQuoteData;
    // Change if Applicant Type Change
    if (fullNameChange.length > 0) {
      quoteDataVar['personInfo']['s_FirstName'] = '';
      quoteDataVar['personInfo']['s_MiddleName'] = '';
      quoteDataVar['personInfo']['s_LastOrganizationName'] = '';
      quoteDataVar['personInfo']['s_FullLegalName'] = '';
    } else {
      const { name, value, attributes } = e.target;
      const parentKey = attributes.parentobject.value;
      quoteDataVar[parentKey][name] = value;

      // update full name
      if (['s_FirstName', 's_MiddleName', 's_LastOrganizationName'].includes(name)) {
        var fullName = '';
        if (name === 's_FirstName') {
          fullName = value + ' ' + quoteDataVar[parentKey]['s_LastOrganizationName'];
        }
        if (name === 's_LastOrganizationName') {
          fullName = quoteDataVar[parentKey]['s_FirstName'] + ' ' + value;
        }
        if (name === 's_MiddleName') {
          fullName = quoteDataVar[parentKey]['s_FirstName'] + ' ' + value + ' ' + quoteDataVar[parentKey]['s_LastOrganizationName'];
        }
        quoteDataVar[parentKey]['s_FullLegalName'] = fullName.toUpperCase();
      }

      // Update Date Of Construction If Year Built Changed
      if (['s_YearBuilt'].includes(name)) {
        quoteDataVar[parentKey]['d_DateOfConstruction'] = value ? new Date(value + '-01-01') : '';
        setIsFirmUpdated(false);
      }
    }

    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  // For All App Switch Handler
  const handleSwitchChange = (e) => {
    const { name, attributes, checked } = e.target;
    var parentObjectName = attributes.parentobject.value;
    var quoteDataVar = emptyQuoteData;
    if (checked) {
      quoteDataVar[parentObjectName][name] = attributes.checkedvalue.value;
    } else {
      quoteDataVar[parentObjectName][name] = attributes.unCheckedValue.value;
      if (name === 's_IsAddInsureds') {
        quoteDataVar['additionaInsuredData'] = [];
      }
      if (name === 's_IsPolicyMortgage') {
        quoteDataVar['mortgageeData'] = [];
      }
      // Updated Dependent Question Value
      if (name === 's_IsPriorNFIPLapsed' && ['', 'NO'].includes(attributes.unCheckedValue.value)) {
        quoteDataVar[parentObjectName]['s_IsCommunitySuspension'] = '';
      }
    }
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  // handle State Change 
  const handleStateChange = (view, value, field = '') => {
    if (view === 'APPLICANT') {
      var temp = '';
      if (field === 'MORTGAGE') {
        setShowMortgagee(value);
      }
      if (field === 'condoAssociation') {
        temp = value === 'YES' ? true : false;
        setIsCondoAssociation(temp);
      }
      if (field === 'ADDINSUREDS') {
        setShowAddInsureds(value);
      }
      if (field === 'isMaillingAddrDiffer') {
        temp = value === 'YES' ? true : false;
        setIsMaillingAddrDiffer(temp);
      }
      if (field === 'isTheInsured') {
        setIsTheInsured(value);
      }
    }
    if (view === 'POLICY') {
      if (field === 'waitingPeriod') {
        setWaitingPeriod(value);
      }
    }
    if (view === 'COMMUNITY') {
      if (field === 'EC') {
        setBaseFlood(value);
      }
      if (field === 'InitFirmDate') {
        setInitFirmDate(value);
      }
      if (field === 'currentMapDate') {
        setCurrentMapDate(value);
      }
      if (field === 'FloodZone') {
        setFloodZone(value);
      }
      if (field === 'isEC') {
        setIsEC(value);
      }
    }
    if (view === 'DETERMINATION') {
      if (field === 'isNewlyMapped') {
        setIsNewlyMapped(value);
      }
      if (field === 'isCurrMapDateGreater') {
        setIsCurrMapDateGreater(value);
      }
    }
    if (view === 'CONSTRUCTION') {
      if (field === 's_SubstantialImprovement') {
        setSubstantialImprovement(value);
      } else if (field === 'd_SubstantialImprovmentDate') {
        setSubstantialImprovmentDate(value);
      } else if (field === 'MOBILE') {
        setIsMobileHome(value);
      } else if (field === 'DOC') {
        setDOC(value);
      }
    }
    if (view === 'OCCUPANCY') {
      if (field === 'OccupancyType') {
        setOccupancyType(value);
      }
      if (field === 'NOU') {
        setNOU(value);
      }
      if (field === 'isInsuredResides') {
        setIsInsuredResides(value);
      }
      if (field === 'buildingType') {
        setBuildingType(value);
      }
    }
    if (view === 'BUILDING') {
      if (field === 'FT') {
        setFoundationType(value);
      }
    }
    if (view === 'PREFIRM') {
      if (field === 'preFirmSubsidyEligible') {
        setPreFirmSubsidyEligible(value);
      }
    }
  }

  // For All Date Handler
  const handleDateChange = (date, name, parent) => {
    var quoteDataVar = emptyQuoteData;
    if (parent) {
      quoteDataVar[parent][name] = date;
    } else {
      quoteDataVar[name] = date;
    }
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  // check date if valid or not 
  const checkDateisValid = (date) => {
    return !isNaN(Date.parse(date));
  }

  //Check If Firm is Pre-Firm Or not
  const checkFirmType = () => {
    if (Object.keys(emptyQuoteData).length > 0) {
      const emptyQuote = { ...emptyQuoteData };
      var temp = emptyQuote.propertyInfo.s_FloodConstructionType;
      if ((new Date(emptyQuoteData.propertyInfo.d_DateOfConstruction) <= new Date('1974-12-31')) || (new Date(emptyQuoteData.propertyInfo.d_DateOfConstruction) < new Date(emptyQuoteData.propertyInfo.d_InitialFirmDate))) {
        temp = 'FLDPREFIRM';
      } else {
        temp = 'FLDPOSTFIRM'
      }
      if (substantialImprovement === 'YES' && ![null, '', '0000-00-00'].includes(substantialImprovmentDate)) {
        temp = 'FLDPOSTFIRM';
      }
      // default historical question to no if it is post firm, and if it is pre firm ask the question
      if (temp === 'FLDPOSTFIRM') {
        emptyQuote.propertyInfo.s_IsBldgNRHP = 'NO';
      }

      emptyQuote.propertyInfo.s_FloodConstructionType = temp;
      dispatch(getEditEmptyQuoteData(emptyQuote));
      setIsPreFirm(temp)
    }
  }

  // Change Date Formate to Y-m-d
  const changeDateFormate = (request) => {
    for (const [key, val] of Object.entries(request)) {
      if (['personInfo', 'propertyInfo', 'policyInfo', 'transactionInfo'].includes(key)) {
        for (const [innerKey, innerVal] of Object.entries(val)) {
          if ((innerKey.indexOf('d_') === 0 && !['d_CreatedDate', 'd_UpdatedDate'].includes(innerKey)) || ['s_PriorPolicyExpDt'].includes(innerKey)) {
            if (checkDateisValid(innerVal)) {
              request[key][innerKey] = new Date(innerVal).toISOString().slice(0, 10);
            }
          }
        }
      }

      if (key === 'effectiveDate') {
        if (checkDateisValid(val)) {
          request[key] = new Date(val).toISOString().slice(0, 10);
        }
      }
    }
    return request;
  }

  // open accordion
  const toggleAccordion = (index) => {
    setIndex(index);
  }

  // validation
  const validateFields = (formData = emptyQuoteData) => {
    // Change Date Formate
    var request = changeDateFormate(formData);
    const { toastArray, indexArray, status } = Validation(request, checkDateisValid);
    if (toastArray.length > 0) {
      toggleAccordion(indexArray);
      setErrorList(toastArray);
      setShowErrorModal(!showErrorModal);
    }
    setValidated(!status);
    return { status, request };
  }

  const handleSubmit = async (type) => {
    // validate first
    const { status, request } = validateFields(emptyQuoteData);
    setValidated(!status);
    if (status === true) {
      request.whichButton = type;
      await dispatch(rateorSaveApplication(request, type)).then((res) => {
        if (res.status === 'Y') {
          toast.success(res.msg);
          setAlertMsg(res.msg);
          setShow(true);
          if (poTransPK) {
            // To Fetch Quote Data
            getQuoteDataFLD(poTransPK);
          } else {
            getQuoteDataFLD(res.transactionPK);
            setPoTransPK(res.transactionPK);
            window.history.replaceState(null, null, '/flood/quote/' + res.transactionPK)
          }
          // For Create Application
          if (type === 'Submit') {
            setShowSubmitAppModal(true);
          }
        } else {
          if (res.hasOwnProperty('validation')) {
            setErrorList(res.validation);
            setShowErrorModal(!showErrorModal);
          }
          toast.error(res.msg);
        }
      }).catch(() => {
        toast.error(t('Something Went Wrong!'));
      })
    }
  }

  const submitApplication = async () => {
    await dispatch(createApplication(poTransPK)).then((res) => {
      if (res.success) {
        const emptyQuote = { ...emptyQuoteData };
        emptyQuote.policyInfo.Policy_No = res.data.data.Policy_No;
        emptyQuote.corePoTransPK = res.data.data.PotransactionPK;
        dispatch(getEditEmptyQuoteData(emptyQuote));
        navigation('/flood/pay-now/' + res.data.data.PotransactionPK);
      }
    }).catch(() => {
      toast.error(t('Something Went Wrong!'));
    })
  }

  /**
   * To generate document
   * @param {String} source (RATESHEET, PRINTQUOTE, PRINTAPP)
   */
  const generateDoc = async (source) => {
    await dispatch(getGeneratedDocs(source, poTransPK)).then((res) => {
      if (res.status === 'Y') {
        window.open(res.url);
      } else {
        var msg = res.msg ? res.msg : 'Something Went Wrong!'
        toast.error(t(msg));
      }
    }).catch(() => {
      toast.error(t('Something Went Wrong!'));
    })
  }

  return (
    <LoadingOverlay >
      <Loader loading={apiLoading} />
      {/* Success Alert */}
      <Alert show={show} variant={'success'} style={{ marginTop: '5px' }} onClose={() => setShow(false)} dismissible>
        <FaCheckCircle />&nbsp;&nbsp;{alertMsg}
      </Alert>
      {/* Warning Alert */}
      <Alert show={bindAlert} variant={'warning'} style={{ marginTop: '5px' }}>
        <FaExclamationTriangle />&nbsp;&nbsp;{alertMsg}
      </Alert>
      {
        Object.keys(emptyQuoteData).length > 0 && (
          <Row>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style={{ padding: 0 }}
            >
              <div className="search-quote-tab-div">
                <div className="search-quote-btns-div">
                  {
                    errorList.length > 0 && (
                      <Button
                        variant="primary"
                        id="search-quote-btn"
                        onClick={() => validateFields()}
                      >
                        <FaHistory />&nbsp;&nbsp;
                        {t('Error Log')}
                      </Button>
                    )
                  }{" "}
                  <Button
                    variant="primary"
                    id="search-quote-btn"
                    onClick={() => setShowPremium(true)}
                  >
                    <FaListAlt />&nbsp;&nbsp;
                    {t('Premium Details')}
                  </Button>
                  {
                    ![null, ''].includes(poTransPK) && (
                      <React.Fragment>
                        <Button variant="primary" id="search-quote-btn" onClick={() => generateDoc('PRINTQUOTE')}>
                          <FaPrint />&nbsp;&nbsp;
                          {t('Print Quote')}
                        </Button>
                        {
                          isBind && (
                            <Button variant="primary" id="search-quote-btn" onClick={() => generateDoc('PRINTAPP')}>
                              <FaPrint />&nbsp;&nbsp;
                              {t('Print App')}
                            </Button>
                          )
                        }
                        <Button variant="primary" id="search-quote-btn" onClick={() => showPivotLog(<PivotLog showPivotLog={showPivotLog} poTransPK={poTransPK}/>)}>
                          <FaHistory />&nbsp;&nbsp;
                          {t('Pivot Api Logs')}
                        </Button>
                        {
                          !isBind && (
                            <Button
                              variant="primary"
                              id="search-quote-btn"
                              onClick={() => handleSubmit('Submit')}
                            >
                              <FaSave />&nbsp;&nbsp;
                              {t('Create Application')}
                            </Button>
                          )
                        }
                        {" "}
                      </React.Fragment>
                    )
                  }
                  <Button variant="primary" id="close-all-btn" onClick={() => setIsExpand(!isExpand)}>
                    {t(btLabel)}
                  </Button>{" "}
                </div>

                <Accordion activeKey={index} className="custom-quote-accordion">
                  {/* Applicant & Property */}
                  <Accordion.Item eventKey="0" onClick={() => toggleAccordion('0')}>
                    <Accordion.Header className="search-quote-card-header">
                      <span>{t('APPLICANT AND PROPERTY')}</span>
                    </Accordion.Header>
                    <Accordion.Body className="search-quote-card-body">
                      <Applicant
                        emptyQuoteData={emptyQuoteData}
                        floodSystemDD={floodSystemDD}
                        validated={validated}
                        isBind={isBind}
                        handleChange={handleChange}
                        handleSwitchChange={handleSwitchChange}
                        handleStateChange={handleStateChange}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* MORTGAGEE(S) */}
                  <Accordion.Item eventKey="1" hidden={!showMortgagee} onClick={() => toggleAccordion('1')}>
                    <Accordion.Header className="search-quote-card-header">
                      <span>{t('MORTGAGEE(S)')}</span>
                    </Accordion.Header>
                    <Accordion.Body className="search-quote-card-body">
                      <Mortgagee
                        showMortgagee={showMortgagee}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* ADDITIONAL INSURED(S) */}
                  <Accordion.Item eventKey="10" hidden={!showAddInsureds} onClick={() => toggleAccordion('10')}>
                    <Accordion.Header className="search-quote-card-header">
                      <span>{t('ADDITIONAL INSURED(S)')}</span>
                    </Accordion.Header>
                    <Accordion.Body className="search-quote-card-body">
                      <AdditionalInsured
                        showAddInsureds={showAddInsureds}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* Policy */}
                  <Accordion.Item eventKey="2" onClick={() => toggleAccordion('2')}>
                    <Accordion.Header className="search-quote-card-header">
                      <span>{t('POLICY')}</span>
                    </Accordion.Header>
                    <Accordion.Body className="search-quote-card-body">
                      <Policy
                        emptyQuoteData={emptyQuoteData}
                        floodSystemDD={floodSystemDD}
                        validated={validated}
                        isBind={isBind}
                        isMortgage={showMortgagee}
                        currentMapDate={currentMapDate}
                        handleChange={handleChange}
                        handleDateChange={handleDateChange}
                        handleStateChange={handleStateChange}
                        checkDateisValid={checkDateisValid}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* community */}
                  <Accordion.Item eventKey="3" onClick={() => toggleAccordion('3')}>
                    <Accordion.Header className="search-quote-card-header">
                      <span>{t('COMMUNITY')}</span>
                    </Accordion.Header>
                    <Accordion.Body className="search-quote-card-body">
                      <Community
                        poTransPK={poTransPK}
                        generateDoc={generateDoc}
                        emptyQuoteData={emptyQuoteData}
                        floodSystemDD={floodSystemDD}
                        validated={validated}
                        isBind={isBind}
                        handleChange={handleChange}
                        handleDateChange={handleDateChange}
                        handleStateChange={handleStateChange}
                        checkDateisValid={checkDateisValid}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* NEWLY MAPPED DISCOUNT ELIGIBILITY */}
                  <Accordion.Item eventKey="11" hidden={!isNewlyMapped} onClick={() => toggleAccordion('11')}>
                    <Accordion.Header className="search-quote-card-header">
                      <span>{t('NEWLY MAPPED DISCOUNT ELIGIBILITY')}</span>
                    </Accordion.Header>
                    <Accordion.Body className="search-quote-card-body">
                      <NewlyMapDiscount
                        emptyQuoteData={emptyQuoteData}
                        floodSystemDD={floodSystemDD}
                        isCurrMapDateGreater={isCurrMapDateGreater}
                        isPropNewlyMapped={isNewlyMapped}
                        validated={validated}
                        isBind={isBind}
                        handleChange={handleChange}
                        handleDateChange={handleDateChange}
                        checkDateisValid={checkDateisValid}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* construction */}
                  <Accordion.Item eventKey="4" onClick={() => toggleAccordion('4')}>
                    <Accordion.Header className="search-quote-card-header">
                      <span>{t('CONSTRUCTION')}</span>
                    </Accordion.Header>
                    <Accordion.Body className="search-quote-card-body">
                      <Construction
                        emptyQuoteData={emptyQuoteData}
                        floodSystemDD={floodSystemDD}
                        validated={validated}
                        isBind={isBind}
                        handleChange={handleChange}
                        handleDateChange={handleDateChange}
                        handleStateChange={handleStateChange}
                        checkDateisValid={checkDateisValid}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* occupancy */}
                  <Accordion.Item eventKey="5" onClick={() => toggleAccordion('5')}>
                    <Accordion.Header className="search-quote-card-header">
                      <span>{t('OCCUPANCY')}</span>
                    </Accordion.Header>
                    <Accordion.Body className="search-quote-card-body">
                      <Occupancy
                        emptyQuoteData={emptyQuoteData}
                        floodSystemDD={floodSystemDD}
                        validated={validated}
                        isMobileHome={isMobileHome}
                        isCondoAssociation={isCondoAssociation}
                        isAddressSame={isMaillingAddrDiffer}
                        isBind={isBind}
                        handleSwitchChange={handleSwitchChange}
                        handleChange={handleChange}
                        handleStateChange={handleStateChange}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* BUILDING AND FOUNDATION */}
                  <Accordion.Item eventKey="6" onClick={() => toggleAccordion('6')}>
                    <Accordion.Header className="search-quote-card-header">
                      <span>{t('BUILDING AND FOUNDATION')}</span>
                    </Accordion.Header>
                    <Accordion.Body className="search-quote-card-body">
                      <BuildingFoundation
                        emptyQuoteData={emptyQuoteData}
                        floodSystemDD={floodSystemDD}
                        validated={validated}
                        isMobileHome={isMobileHome}
                        occupancyType={occupancyType}
                        isBind={isBind}
                        handleChange={handleChange}
                        handleStateChange={handleStateChange}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* Machinery And Equipment */}
                  <Accordion.Item eventKey="7" onClick={() => toggleAccordion('7')}>
                    <Accordion.Header className="search-quote-card-header">
                      <span>MACHINERY AND EQUIPMENT</span>
                    </Accordion.Header>
                    <Accordion.Body className="search-quote-card-body">
                      <MachineryEquipment
                        emptyQuoteData={emptyQuoteData}
                        floodSystemDD={floodSystemDD}
                        validated={validated}
                        foundationType={foundationType}
                        isBind={isBind}
                        handleChange={handleChange}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* Elevation Certificate */}
                  <Accordion.Item eventKey="12" hidden={isEC} onClick={() => toggleAccordion('12')}>
                    <Accordion.Header className="search-quote-card-header">
                      <span>{t('ELEVATION CERTIFICATE')}</span>
                    </Accordion.Header>
                    <Accordion.Body className="search-quote-card-body">
                      <ElevationCertificate
                        emptyQuoteData={emptyQuoteData}
                        floodSystemDD={floodSystemDD}
                        validated={validated}
                        floodZone={floodZone}
                        isEC={isEC}
                        baseFloodEle={baseFlood}
                        occupancyType={occupancyType}
                        isBind={isBind}
                        handleChange={handleChange}
                        handleDateChange={handleDateChange}
                        checkDateisValid={checkDateisValid}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* Flood Vent */}
                  <Accordion.Item eventKey="13" hidden={!['ELEWATEDONCRAWLSPACE20', 'ELEVATEDWITHENCLOSURE20', 'ELEVATEDWITHENCLOSURENOTPPP20'].includes(foundationType)} onClick={() => toggleAccordion('13')}>
                    <Accordion.Header className="search-quote-card-header">
                      <span>{t('FLOOD VENTS')}</span>
                    </Accordion.Header>
                    <Accordion.Body className="search-quote-card-body">
                      <FloodVents
                        emptyQuoteData={emptyQuoteData}
                        floodSystemDD={floodSystemDD}
                        validated={validated}
                        foundationType={foundationType}
                        isBind={isBind}
                        handleChange={handleChange}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* Prior Policy Information */}
                  <Accordion.Item eventKey="8" hidden={!(isPreFirm === 'FLDPREFIRM' && isInsuredResides)} onClick={() => toggleAccordion('8')}>
                    <Accordion.Header className="search-quote-card-header">
                      <span>{t('PRIOR POLICY INFORMATION')}</span>
                    </Accordion.Header>
                    <Accordion.Body className="search-quote-card-body">
                      <PriorPolicyInfo
                        emptyQuoteData={emptyQuoteData}
                        floodSystemDD={floodSystemDD}
                        initFirmDate={initFirmDate}
                        firmType={isPreFirm}
                        isInsuredResides={isInsuredResides}
                        waitingPeriod={waitingPeriod}
                        DOC={DOC}
                        validated={validated}
                        isBind={isBind}
                        checkFirmType={checkFirmType}
                        handleChange={handleChange}
                        handleStateChange={handleStateChange}
                        checkDateisValid={checkDateisValid}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* COVERAGRE */}
                  <Accordion.Item eventKey="9" onClick={() => toggleAccordion('9')}>
                    <Accordion.Header className="search-quote-card-header">
                      <span>{t('COVERAGE')}</span>
                    </Accordion.Header>
                    <Accordion.Body className="search-quote-card-body">
                      <Coverage
                        emptyQuoteData={emptyQuoteData}
                        floodSystemDD={floodSystemDD}
                        preFirmSubsidyEligible={preFirmSubsidyEligible}
                        occupancyType={occupancyType}
                        isTheInsured={isTheInsured}
                        buildingType={buildingType}
                        NOU={NOU}
                        handleChange={handleChange}
                        isBind={isBind}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                {/* Create Application Modal */}
                {
                  showSubmitAppModal && (
                    <CreateApplication
                      showSubmitAppModal={showSubmitAppModal}
                      waitingPeriod={waitingPeriod}
                      submitApplication={submitApplication}
                      setShowSubmitAppModal={setShowSubmitAppModal}
                    />
                  )
                }
                {/* Error Modal */}
                {
                  showErrorModal === true && (
                    <ErrorModal show={showErrorModal} error={errorList} toggle={setShowErrorModal} />
                  )
                }
                {/* Pivot Api Resp Log */}
                {pivotLog}
                {/* Premium Details Modal */}
                <PremiumDetails showPremium={showPremium} setShowPremium={setShowPremium} poTransPK={poTransPK} />
              </div>
              {
                !isBind && (
                  <div className="activity-log-tab-bts-div">
                    <Button
                      variant="info"
                      onClick={() => handleSubmit('Save')}
                      style={{ position: "fixed", bottom: "150px" }}
                    >
                      <FaSave />&nbsp;&nbsp;
                      {t('Save')}{" "}
                    </Button>{" "}
                    <Button
                      variant="primary"
                      onClick={() => handleSubmit('Rate')}
                      style={{ position: "fixed", bottom: "200px" }}
                    >
                      <FaCheckDouble />&nbsp;&nbsp;
                      {t('Rate')}
                    </Button>{" "}
                  </div>
                )
              }
            </Col >
          </Row >
        )
      }
    </LoadingOverlay >
  );
};

export default AccordionView;
