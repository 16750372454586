import React, { Fragment ,useState, useEffect} from "react";
import {
  Button,
  Row,
  Col,
  Form,
  ListGroup,
  Accordion,
  Modal,
  Table,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect,useDispatch } from 'react-redux';
import { getEditEmptyQuoteData } from "../../../../../services/quoteService";
import { useTranslation } from "react-i18next";
const GeneralQuestions = (props) => {
    const [date1, setDate1] = useState(new Date());
    const [quoteData, setQuoteData] = useState(props.quoteData);
    const [selectedYes, setSelectedYes] = useState([]);
    const { t } = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
    //    props.getBaseData.GENERAL.map((user) => (

    //   ))
      }, [props]);  
    
      useEffect(() => {
        dispatch(getEditEmptyQuoteData(quoteData));
      }, [quoteData]);

      const handleQRadioChange = (e) => {
        const { name, value , title,questionFK,attributes,checked,id } = e.target; 
        const questionObj = 'Question_'+attributes.questionFK.value+'_RADIOYESNO';
		var quoteDataVar = props.quoteData;
        const questionD = props.quoteData.allAnswerData;
        questionD[questionObj]['s_PoAdditionalStatsAnswer'] = value
        setQuoteData({...quoteDataVar,quoteDataVar});
      }

      const handleQInputChange = (e) => {
        const { name, value , title,questionFK,attributes,checked,id } = e.target; 
        const questionKeyy = 'Question_'+attributes.questionFK.value+'_N';
		var quoteDataVar = props.quoteData;
        var uWkey = props.quoteData.allAnswerData;
        if (uWkey.hasOwnProperty(questionKeyy)) {
          const tempArr = props.quoteData.allAnswerData;
          tempArr[questionKeyy]['s_PoAdditionalStatsAnswer'] = value;
          var key = uWkey[questionKeyy]['s_PoAdditionalStatsAnswer'];
          setQuoteData({...quoteDataVar, key: value});
        }else{
          const questionD = props.quoteData.allAnswerData;
          questionD[questionKeyy]['s_PoAdditionalStatsAnswer'] = value
          setQuoteData({...quoteDataVar,quoteDataVar});
        }
      }

      const handleQDateChange = (e) => {
        const { name, value , title,questionFK,attributes,checked,id } = e.target; 
        const questionKeyy = 'Question_'+attributes.questionFK.value+'_N';
		var quoteDataVar = props.quoteData;
        var uWkey = props.quoteData.allAnswerData;
        if (uWkey.hasOwnProperty(questionKeyy)) {
          const tempArr = props.quoteData.allAnswerData;
          tempArr[questionKeyy]['s_PoAdditionalStatsAnswer'] = value;
          var key = uWkey[questionKeyy]['s_PoAdditionalStatsAnswer'];
          setQuoteData({...quoteDataVar, key: value});
        }else{
          const questionD = props.quoteData.allAnswerData;
          questionD[questionKeyy]['s_PoAdditionalStatsAnswer'] = value
          setQuoteData({...quoteDataVar,quoteDataVar});
        }
      }

      const handleQSelectChange = (e) => {
        const { name, value , title,questionFK,attributes,checked,id } = e.target; 
        const questionKeyy = 'Question_'+attributes.questionFK.value;
		var quoteDataVar = props.quoteData;
        var uWkey = props.quoteData.allAnswerData;
        if (uWkey.hasOwnProperty(questionKeyy)) {
          const tempArr = props.quoteData.allAnswerData;
          tempArr[questionKeyy]['s_PoAdditionalStatsAnswer'] = value;
          var key = uWkey[questionKeyy]['s_PoAdditionalStatsAnswer'];
          setQuoteData({...quoteDataVar, key: value});
        }else{
          const questionD = props.quoteData.allAnswerData;
          questionD[questionKeyy] = {
            's_PoAdditionalStatsAnswer': value
          };
          setQuoteData({...quoteDataVar,quoteDataVar});
        }
      }
      

      const fields = (data, i) => {
        if(data.s_PRQuestionSection != 'HEADER'){
          if(data.n_PRResponseTypeCode == 'TEXT'){
            const questionObj = 'Question_'+data.n_PRPolicyAddStatQuestions_Pk+'_N';
            var selectedAnswersText = props.quoteData.allAnswerData[questionObj]['s_PoAdditionalStatsAnswer'];
              return (
                  <Fragment>
                    <Form.Group
                      className="search-quote-form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        className="dashboard-group-input"
                        type="text"
                        onChange = {handleQInputChange}
                        questionFK={data.n_PRPolicyAddStatQuestions_Pk}
                        disabled={props.isBind}
                        name={"text_" + data.n_PRPolicyAddStatQuestions_Pk}
                        value = {selectedAnswersText}
                      />
                    </Form.Group>
                  </Fragment>
              );
          } else if(data.n_PRResponseTypeCode == 'RADIOYESNO'){
            const questionObj = 'Question_'+data.n_PRPolicyAddStatQuestions_Pk+'_RADIOYESNO';
            var checkedValueYes = props.quoteData.allAnswerData[questionObj]['s_PoAdditionalStatsAnswer'] == 'YES' ? true : false;
            var checkedValueNo = props.quoteData.allAnswerData[questionObj]['s_PoAdditionalStatsAnswer'] == 'NO' ? true : false;
              return (
                  <Fragment>
                     <Form.Check
                          type="radio"
                          label="Yes"
                          className="check-question-radio"
                          disabled={props.isBind}
                          id={questionObj}
                          name={questionObj}
                          onChange = {handleQRadioChange}
                          value={'YES'}
                          questionFK={data.n_PRPolicyAddStatQuestions_Pk}
                          checked={checkedValueYes}
                          
                      />
                      <Form.Check
                          type="radio"
                          label="No"
                          disabled={props.isBind}
                          name={questionObj}
                          id={questionObj}
                          onChange = {handleQRadioChange}
                          value={'NO'}
                          questionFK={data.n_PRPolicyAddStatQuestions_Pk}
                          checked={checkedValueNo}
                      />
                  </Fragment>
              );
          } else if(data.n_PRResponseTypeCode == 'DATE'){
            const questionObj = 'Question_'+data.n_PRPolicyAddStatQuestions_Pk+'_N';
            var selectedAnswersDate = props.quoteData.allAnswerData[questionObj]['s_PoAdditionalStatsAnswer'];
              return (
                  <Fragment>
                      <Form.Control
                        className="dashboard-group-input"
                        type="date"
                        onChange = {handleQDateChange}
                        questionFK={data.n_PRPolicyAddStatQuestions_Pk}
                        disabled={props.isBind}
                        name={"text_" + data.n_PRPolicyAddStatQuestions_Pk}
                        value={selectedAnswersDate}
                      />
                  </Fragment>
              )
          } else if(data.n_PRResponseTypeCode == 'DROPDOWN'){
            const questionObj = 'Question_'+data.n_PRPolicyAddStatQuestions_Pk+'_N';
            var selectedAnswersDropDown = props.quoteData.allAnswerData[questionObj]['s_PoAdditionalStatsAnswer'];
              return (
                  <Form.Select
                      className="dashboard-group-input check-question-select"
                      id="select-ym-option check-question-select"
                      check-question-select
                      questionFK={data.n_PRPolicyAddStatQuestions_Pk}
                      disabled={props.isBind}
                      onChange = {handleQSelectChange}
                      value={selectedAnswersDropDown}
                  >
                      <option key="0" value="">No of Families</option>
                          <option key="1" value="1">1</option>
                          <option key="2" value="2">2</option>
                          <option key="3" value="3">3</option>
                          <option key="4" value="4">4</option>
                          <option key="5" value="5">4+</option>
                  </Form.Select>
              )   
          }
        }
      }


      // const tempArr = quoteData.uwData;
      //     tempArr[questionKey]['s_PoAdditionalStatsAnswer'] = date;
      //     var key = uWkey[questionKey]['s_PoAdditionalStatsAnswer'];
      //     setQuoteData({...quoteData, key: date});


      const selectAllYes = () => {
		var quoteDataVar = props.quoteData;
        const user = props.quoteData.allAnswerData;
        for (const key in user) {
          const tempArr = props.quoteData.allAnswerData;
          if(key.includes("RADIOYESNO") ){
            tempArr[key]['s_PoAdditionalStatsAnswer'] = "YES";
            setQuoteData({...quoteDataVar, 'allAnswerData': tempArr});
          }
        }
      }

      const selectAllNo = () => {
		var quoteDataVar = props.quoteData;
        const answersData = props.quoteData.allAnswerData;
        for (const key in answersData) {
          const tempArr = props.quoteData.allAnswerData;
          if(key.includes("RADIOYESNO") ){
            tempArr[key]['s_PoAdditionalStatsAnswer'] = "NO";
            setQuoteData({...quoteDataVar, 'allAnswerData': tempArr});
          }
        }
      }
  return (
    <Fragment>
        <div className="search-quote-question-header">
            <span className="search-quote-question-text">{t("Questions")}</span>
            <span className="search-quote-question-text">{t("Answer")} </span>
        </div>
        <div className="search-quote-question-body">
            <div className="search-questions-subdiv">
            <ListGroup className="search-questions-list" as="ol" numbered>
            </ListGroup>
            <div style={{textAlign: "end",paddingRight:"3%"}}>
              <a href='#' onClick={(e) => selectAllYes()} ><strong>{t("YES")}</strong></a> &nbsp;&nbsp;&nbsp;&nbsp;
              <a href='#' onClick={(e) => selectAllNo()} ><strong>{t("NO")}</strong></a>
            </div>

            {props.getBaseData.GENERAL.map((data,i) => (
                <ListGroup className="search-questions-list" as="ol" numbered>
                  {/* <tr>
                  <td></td>
                  <td style={{ width: '20%', textAlign: "center" }}>
                    <td style={{ width: '10%', borderTop: 'none' }}><a href='#' onClick={(e) => { e.preventDefault(); if (!props.mainState.isBind) { selectAllYes() } }} ><strong>YES</strong></a></td>
                    <td style={{ width: '10%', borderTop: 'none' }}><a href='#' onClick={(e) => { e.preventDefault(); if (!props.mainState.isBind) { selectAllNo() } }} ><strong>NO</strong></a></td>
                  </td>
                </tr> */}
                    <ListGroup.Item >
                      <span>
                        {data.s_PRQuestionDesc}
                      </span>
                      <div
                        id="sear-question-list-answers"
                        className="custom-radio-check0"
                      >
                        {fields(data,i)}
                      </div>
                    </ListGroup.Item>
                </ListGroup>

            ))}
            </div>
        </div>
    </Fragment>
  );
};


function mapStateToProps(state) {
	return {
	  	quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	};
  } 
  export default connect(mapStateToProps)(GeneralQuestions);
