import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { getEditEmptyQuoteData, agencyList, agentList } from '../../../../services/floodService';
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import UwAppStatus from '../../common/UwAppStatus';
import AsyncSelect from 'react-select/async';
import "react-overlay-loader/styles.css";

const Header = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { emptyQuoteData, apiLoading, floodSystemDD } = useSelector(state => state.flood);
    const [uwModel, setUWModel] = useState(null);
    const [selectedAgency, setSelectedAgency] = useState(null);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [agencyId, setAgencyId] = useState(null);
    const [agentId, setAgentId] = useState(null);
    const [agencyDD, setAgencyDD] = useState([]);
    const [agentDD, setAgentDD] = useState([]);
    const [isChange, setIsChange] = useState(false);
    const [policyNo, setPolicyNo] = useState(null);
    const [premium, setPremium] = useState(0);
    const [policyNoQuote, setPolicyNoQuote] = useState(null);
    const [isBind, setIsBind] = useState(false);

    useEffect(() => {
        if (Object.keys(floodSystemDD).length > 0) {
            const { defaultAgency, defaultAgent } = floodSystemDD;
            if (Object.keys(defaultAgency).length > 0) {
                setAgencyDD(defaultAgency);
                setSelectedAgency(defaultAgency);
                setAgencyId(defaultAgency[0].NPN || 0);
            }
            if (Object.keys(defaultAgent).length > 0) {
                setAgentDD(defaultAgent);
                setSelectedAgent(defaultAgent);
                setAgentId(defaultAgent[0].NPN || 0);
            }
            updateAgencyAgentFK(defaultAgency, defaultAgent);
            setIsChange(true);
        }
    }, [floodSystemDD])

    useEffect(() => {
        if (Object.keys(emptyQuoteData).length > 0) {
            if (Object.keys(emptyQuoteData.policyInfo).length > 0) {
                setPremium(emptyQuoteData.premium || 0);
                setPolicyNo(emptyQuoteData.policyInfo.Policy_No || null);
                setPolicyNoQuote(emptyQuoteData.policyInfo.Policy_No_Quote || null);
                var temp = false;
                if (!isNaN(Date.parse(emptyQuoteData.policyInfo.d_BinderDate))) {
                    temp = true;
                }
                setIsBind(temp);
            }
        }
    }, [apiLoading, emptyQuoteData])

    useEffect(() => {
        if (isChange === true) {
            updateAgencyAgentFK(selectedAgency, selectedAgent);
            setIsChange(!isChange);
        }
    }, [isChange])

    const toggle = () => {
        setUWModel(null);
    }

    const UWModel = () => {
        setUWModel(<UwAppStatus toggle={toggle} />);
    }

    const handleChange = (value, state) => {
        var NPN = 0;
        if (![null, ''].includes(value.NPN)) {
            NPN = value.NPN;
        }
        switch (state) {
            case 'Agency':
                setSelectedAgency(value);
                setAgencyId(NPN);
                loadAgentOptions(value.value);
                break;
            case 'Agent':
                setSelectedAgent(value);
                setAgentId(NPN);
                break;
            default:
                break;
        }
        setIsChange(true);
    }

    const loadAgencyOptions = (inputValue, callback) => {
        if (inputValue.length > 3) {
            var request = {
                'searchParams': inputValue
            }
            dispatch(agencyList(request))
                .then((res) => {
                    setAgencyDD(res);
                    callback(filterData(res, inputValue, 'Agency'));
                })
                .catch(() => toast.error(t('Something Went Wrong!')))
        } else {
            callback(filterData(agencyDD, inputValue, 'Agency'));
        }
    }

    const loadAgentOptions = (value) => {
        dispatch(agentList(value, 10))
            .then((res) => {
                setAgentDD(res);
                var agent = [], NPN = 0;
                if (res.length > 0) {
                    agent = res[0];
                    if (![null, ''].includes(res[0].NPN)) {
                        NPN = res[0].NPN;
                    }
                    setSelectedAgent(agent);
                    setAgentId(NPN);
                    setIsChange(true);
                }
            })
            .catch(() => toast.error(t('Something Went Wrong!')))
    }

    const filterData = (result, inputValue = '', from) => {
        var dd = [];
        if (from === 'Agency') {
            dd = result.filter(i =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            );
        }
        if (from === 'Agent') {
            dd = result.filter(i =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            );
        }
        return dd;
    };

    const filterAgentOptions = (value, callback) => {
        callback(filterData(agentDD, value, 'Agent'));
    }

    const updateAgencyAgentFK = (agency, agent) => {
        if (Object.keys(emptyQuoteData).length > 0) {
            var quoteDataVar = emptyQuoteData;
            quoteDataVar['selectedAgency'][0] = agency;
            quoteDataVar['policyInfo']['n_AgencyPeson_FK'] = (agency[0] ? agency[0].value : agency.value);
            quoteDataVar['selectedAgent'][0] = agent;
            quoteDataVar['policyInfo']['n_SubAgentPersoninfo_FK'] = (agent[0] ? agent[0].value : agent.value);
            dispatch(getEditEmptyQuoteData(quoteDataVar));
        }
    }

    return (
        <LoadingOverlay >
            <Loader loading={apiLoading} />
            {
                <React.Fragment>
                    <div className="custom-tab-header-fill">
                        <span>APPLICATION</span>
                    </div>
                    <div className="custom-tab-body">
                        <Row className="align-items-baseline">
                            <Col xs="12" sm="12" md="12" lg="2" xl="2">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t('Quote No')}
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input-flood"
                                        type="text"
                                        placeholder="Quote No"
                                        value={policyNoQuote}
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            {
                                ![null, ''].includes(policyNo) && (
                                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                                        <Form.Group
                                            className="search-quote-form-group"
                                            controlId="formBasicEmail"
                                        >
                                            <Form.Label className="dashboard-group-label">
                                                {t('Policy No')}
                                            </Form.Label>
                                            <Form.Control
                                                className="dashboard-group-input-flood"
                                                type="text"
                                                placeholder="Policy No"
                                                value={policyNo}
                                                readOnly
                                            />
                                        </Form.Group>
                                    </Col>
                                )
                            }
                            <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                <div className="justify-start-class">
                                    <Form.Group
                                        className="search-quote-form-group"
                                        controlId="formBasicEmail"
                                        style={{ width: "100%" }}
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('Agency Name')}
                                        </Form.Label>
                                        <AsyncSelect
                                            cacheOptions
                                            name='agency_id'
                                            id="select-ym-option"
                                            loadOptions={loadAgencyOptions}
                                            defaultOptions={agencyDD}
                                            value={selectedAgency}
                                            onChange={(selectedValue) => handleChange(selectedValue, 'Agency')}
                                            className="dashboard-group-input-flood"
                                            required
                                            isDisabled={isBind}
                                        />
                                    </Form.Group>
                                </div>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="1" xl="1">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t('Agency NPN')}
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input-flood"
                                        type="text"
                                        placeholder="00"
                                        value={agencyId}
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="4" xl="2">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t('Producer Name')}
                                    </Form.Label>
                                    <AsyncSelect
                                        cacheOptions
                                        name='agent_id'
                                        id="select-ym-option"
                                        loadOptions={filterAgentOptions}
                                        // defaultOptions={agentDD}
                                        value={selectedAgent}
                                        onChange={(selectedValue) => handleChange(selectedValue, 'Agent')}
                                        className="dashboard-group-input-flood"
                                        required
                                        isDisabled={isBind}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="1" xl="1">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t('Agent NPN')}
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input-flood"
                                        type="text"
                                        placeholder="00"
                                        value={agentId}
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="2" xl="2">
                                <div className="flex-start-class">
                                    <Form.Group
                                        className="search-quote-form-group"
                                        id="search-quote-uw"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('UW. App. Status')}
                                        </Form.Label>
                                        <Form.Select
                                            className="dashboard-group-input-flood"
                                            id="select-ym-option"
                                            disabled
                                        >
                                            <option>open</option>
                                            <option>closed</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <FaEdit
                                        size={25}
                                        color={"#64D6CD"}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => UWModel()}
                                    />
                                </div>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="2" xl="2">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t('Estimate Premium')}
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input-flood"
                                        type="text"
                                        placeholder=""
                                        value={'$' + premium}
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    {/* UW App Status Model View */}
                    {uwModel}
                </React.Fragment>
            }
        </LoadingOverlay>
    )
}

export default Header