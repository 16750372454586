import React, { Fragment, useState } from "react";
import { Button, Row, Col, Form, Table, Accordion } from "react-bootstrap";
import basicBadge from "../../../assets/badges/basic.png";
import optionalBadge from "../../../assets/badges/optional.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector , connect } from 'react-redux';
import ApplicantInfo from "./components/QuoteTabComponents/ApplicantInfo";
import PropertyDetails from "./components/QuoteTabComponents/PropertyDetails";
import CurrentAddress from "./components/QuoteTabComponents/CurrentAddress";
import Coverage from "./components/QuoteTabComponents/Coverage";
import Deductible from "./components/QuoteTabComponents/Deductible";
import WindMitigation from "./components/QuoteTabComponents/WindMitigation";
import ClaimQuestion from "./components/QuoteTabComponents/ClaimQuestion";
import Discount from "./components/QuoteTabComponents/Discount";
import UnderwritingQuestion from "./components/QuoteTabComponents/UnderwritingQuestion";
import FooterButton from "./components/FooterButton";
import QuoteButtons from "./components/QuoteTabComponents/QuoteButtons";
import { getEditEmptyQuoteData} from "../../../services/quoteService";
import { useTranslation } from "react-i18next";
const QuoteTab = (props) => {
  const { t } = useTranslation();
  const [date1, setDate1] = useState(new Date());
  const [isPriorAddr, setIsPriorAddr] = useState( props.quoteData.personPriorAddr.n_PersonAddressesId_PK != null && props.quoteData.personPriorAddr.n_PersonAddressesId_PK > 0 ? true : false );
  const dispatch = useDispatch();
  const setPriorAddrFunc = (data) => {
	  if(data == 'YES') {
      setIsPriorAddr(true);
      const tempArray = props.quoteData;
      tempArray['personPriorAddr']['isNewPurchase'] = "Yes";
      dispatch(getEditEmptyQuoteData(tempArray));
	  } else {
		setIsPriorAddr(false);
    const tempArray = props.quoteData;
      tempArray['personPriorAddr']['isNewPurchase'] = "No";
      dispatch(getEditEmptyQuoteData(tempArray));
	  }
	
  }

  return (
    <Fragment>
      <div className="search-quote-tab-div">
        <div className="search-quote-btns-div">
          <QuoteButtons isBind={props.isBind} callGeneratePdf={props.callGeneratePdf} rateLoader={props.rateLoader}/>
        </div>

        <Accordion defaultActiveKey="0" className="custom-quote-accordion">
          {/* application information */}
          <Accordion.Item eventKey="0">
            <Accordion.Header className="search-quote-card-header">
              <span>{t("Applicant Information")}</span>
            </Accordion.Header>
            <Accordion.Body className="search-quote-card-body">
				<ApplicantInfo handleChange={props.handleChange} handleRadioChange={props.handleRadioChange} getZipCodeDetails={props.getZipCodeDetails} zipAddrFor={'personAddr'} isBind={props.isBind}/>
              
            </Accordion.Body>
          </Accordion.Item>
		 {props.quoteData.personInfo.s_IsMailingDiff == 'YES' ? 
          <Accordion.Item eventKey="0">
            <Accordion.Header className="search-quote-card-header">
              <span>Mailing Address</span>
            </Accordion.Header>
            <Accordion.Body className="search-quote-card-body">
				<CurrentAddress addressType={'personMailingAddr'} addressData = {props.quoteData.personMailingAddr} handleChange={props.handleChange} isBind={props.isBind}/>
            </Accordion.Body>
          </Accordion.Item>
		  : null }
          {/* property details */}
          <Accordion.Item eventKey="1">
            <Accordion.Header className="search-quote-card-header">
              <span>{t("Property Details")}</span>
            </Accordion.Header>
            <Accordion.Body className="search-quote-card-body">
				<PropertyDetails handleChange={props.handleChange} handleRadioChange={props.handleRadioChange} isBind={props.isBind} setPriorAddrFunc={setPriorAddrFunc} isPriorAddr={isPriorAddr}/>
              
            </Accordion.Body>
          </Accordion.Item>

          {/* prior address */}
          {isPriorAddr ? <Accordion.Item eventKey="2">
            <Accordion.Header className="search-quote-card-header">
              <span>Prior or Current Address</span>
            </Accordion.Header>
            <Accordion.Body className="search-quote-card-body">
				<CurrentAddress addressType={'personPriorAddr'} addressData = {props.quoteData.personPriorAddr} handleChange={props.handleChange} isBind={props.isBind} />
              
            </Accordion.Body>
          </Accordion.Item> : null }
          {/* coverage */}
          <Accordion.Item eventKey="3">
            <Accordion.Header className="search-quote-card-header">
              <span>{t("Coverage")}</span>
            </Accordion.Header>
            <Accordion.Body className="search-quote-card-body">
				<Coverage handleChange={props.handleChange} handleRadioChange={props.handleRadioChange} handleCheckboxChange={props.handleCheckboxChange} isBind={props.isBind} />
              
            </Accordion.Body>
          </Accordion.Item>
          {/* deductible */}
          <Accordion.Item eventKey="4">
            <Accordion.Header className="search-quote-card-header">
              <span>{t("Deductible")}</span>
            </Accordion.Header>
            <Accordion.Body className="search-quote-card-body">
				<Deductible handleChange={props.handleChange} handleRadioChange={props.handleRadioChange} handleCheckboxChange={props.handleCheckboxChange} isBind={props.isBind} />
              
            </Accordion.Body>
          </Accordion.Item>
          {/* wind mitigation */}
          <Accordion.Item eventKey="5">
            <Accordion.Header className="search-quote-card-header">
              <span>{t("Wind Mitigation")}</span>
            </Accordion.Header>
            <Accordion.Body className="search-quote-card-body">
				<WindMitigation handleChange={props.handleChange} handleRadioChange={props.handleRadioChange} handleCheckboxChange={props.handleCheckboxChange} isBind={props.isBind} />
              
            </Accordion.Body>
          </Accordion.Item>

          {/* discount */}
          <Accordion.Item eventKey="6">
            <Accordion.Header className="search-quote-card-header">
              <span>{t("Discount")}</span>
            </Accordion.Header>
            <Accordion.Body className="search-quote-card-body">
				<Discount handleChange={props.handleChange} handleRadioChange={props.handleRadioChange} handleCheckboxChange={props.handleCheckboxChange} isBind={props.isBind} />
              
            </Accordion.Body>
          </Accordion.Item>
          {/* claim question */}
          <Accordion.Item eventKey="7">
            <Accordion.Header className="search-quote-card-header">
              <span>{t("Claim Question")}</span>
            </Accordion.Header>
            <Accordion.Body className="search-quote-card-body">
				<ClaimQuestion setDefaultData={props.setDefaultData} handleChange={props.handleChange} handleRadioChange={props.handleRadioChange} handleCheckboxChange={props.handleCheckboxChange} isBind={props.isBind} />
              
            </Accordion.Body>
          </Accordion.Item>
          {/* underwriting questions */}
          <Accordion.Item eventKey="8">
            <Accordion.Header className="search-quote-card-header">
              <span>{t("Underwriting Question")}</span>
            </Accordion.Header>
            <Accordion.Body className="search-quote-card-body">
				<UnderwritingQuestion handleChange={props.handleChange} handleRadioChange={props.handleRadioChange} handleCheckboxChange={props.handleCheckboxChange} isBind={props.isBind} />
              
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <FooterButton rateSubmit={props.rateSubmit} isBind={props.isBind} />
    </Fragment>
  );
};

function mapStateToProps(state) {
	return {
	  	quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	};
  } 
  export default connect(mapStateToProps)(QuoteTab);

