import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Navigate } from "react-router-dom";
import { pivotApiCall, updateWhichSection, updateLastSection, updateInitQuoteApi } from "../../../../../../services/floodService";
import Validation from "../../Validation";
import "./starting-estimate.scss";
import "react-overlay-loader/styles.css";

const StartingEstimate = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { emptyQuoteData, initApiObject, ZDDetails, whichSection, lastSection, apiLoading } = useSelector(state => state.flood);
  const currentStep = 2;

  // Call Pivot Api To Get Estimate Premium
  useEffect(() => {
    if (emptyQuoteData && initApiObject && Object.keys(ZDDetails).length > 0) {
      const reqJson = { ...initApiObject }; // Copy
      // To update
      if (currentStep > lastSection) {
        dispatch(updateWhichSection('DEFAULT'));
        dispatch(updateLastSection(currentStep));
      }

      reqJson.addressLine1 = emptyQuoteData.personAddr.s_AddressLine1
      reqJson.addressLine2 = emptyQuoteData.personAddr.s_AddressLine1
      reqJson.addressType = 'PROPERTY_ADDRESS'
      reqJson.city = emptyQuoteData.personAddr.s_CityName
      reqJson.countyFips = 0
      reqJson.deleteAddress = false
      reqJson.propertyBeginningStreetNumber = emptyQuoteData.personAddr.s_HouseNo
      reqJson.nameOrDescriptiveType = 'N'
      reqJson.state = emptyQuoteData.personAddr.s_StateName
      reqJson.stateFips = 0
      reqJson.zipCode = emptyQuoteData.personAddr.s_PostalCode
      reqJson.zipPlus4 = emptyQuoteData.personAddr.s_PostalCodeSuffix
      reqJson.constructionType = emptyQuoteData.propertyInfo.s_BldgConstructionType
      reqJson.foundationType = emptyQuoteData.propertyInfo.s_Foundation
      reqJson.numberOfFloors = emptyQuoteData.propertyInfo.n_NoOfFloors
      reqJson.originalConstructionDate = emptyQuoteData.propertyInfo.d_DateOfConstruction
      reqJson.totalSquareFootage = emptyQuoteData.propertyInfo.s_TotalSqFootage
      reqJson.enclosureSize = emptyQuoteData.propertyInfo.s_AreaInSqFt
      reqJson.communityNumber = emptyQuoteData.propertyInfo.s_CommunityNumber
      reqJson.floodZone = emptyQuoteData.propertyInfo.s_FloodZone
      reqJson.postFirmConstruction = ''
      reqJson.preFirmSubsidyEligible = ''
      reqJson.sqft = emptyQuoteData.propertyInfo.s_TotalSqFootage
      reqJson.whichSection = whichSection;
      reqJson.buildingDescription = emptyQuoteData.propertyInfo.s_BuildingUse
      reqJson.floorOfUnit = emptyQuoteData.propertyInfo.s_UnitLocatedOnFloor
      reqJson.numberOfUnits = emptyQuoteData.propertyInfo.s_NoOfUnits
      reqJson.uniqueKey = localStorage.getItem('uniqueKey');

      // Overwrite County & State Fips From ZD Details
      if (ZDDetails.success && Object.keys(ZDDetails.data).length > 0) {
        const zDData = ZDDetails.data.PROPERTY
        if (zDData && zDData._IDENTIFICATION && Object.keys(zDData._IDENTIFICATION.data).length > 0) {
          var identification = zDData._IDENTIFICATION.data;
          reqJson.countyFips = identification.CountyFIPSCode;
          reqJson.stateFips = identification.StateFIPSCode;
        }
      }

      // To update
      dispatch(updateInitQuoteApi(reqJson));

      const code = 'QUOTE_NEWBUSINESS';
      const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0 };
      // Call Api
      dispatch(pivotApiCall(code, request));
    }
  }, [emptyQuoteData]);

  return (
    <LoadingOverlay >
      <Loader loading={apiLoading} />
      {
        Object.keys(emptyQuoteData).length === 0 && <Navigate to={'/flood/instantquote/step/address'} />
      }
      <section className="starting-estimate-section">
        <Row className="justify-content-center">
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="8"
            xl="8"
            className="starting-estimate-form-section"
          >
            <div className="starting-estimate-header">
              <h5 className="starting-estimate-title">{t('Starting Estimate')}</h5>
            </div>
            <Row className="justify-content-center">
              <Col
                xs="11"
                sm="11"
                md="6"
                lg="9"
                xl="9"
                className="starting-estimate-content"
              >
                <div className="starting-estimate-icon"></div>
                <p className="starting-estimate-price">
                  ${props.premium}<span className="se-slash">/</span>{" "}
                  <span className="se-year">{t('Year')}</span>
                </p>
                <p className="starting-estimate-desc">
                  {t('This estimate is using basic information, please continue to get the most accurate rate')}.
                </p>
              </Col>
              <Col xs="12" className="form-btns">
                <Validation currentStep={2} />
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </LoadingOverlay>
  );
};

export default StartingEstimate;
