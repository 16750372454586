import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { BsDownload } from "react-icons/bs";
import CommonHeader from "./CommonHeader";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { formCopyData } from "../../../services/coreSystemService";
import { toast } from 'react-toastify';
import { Loader, LoadingOverlay } from "react-overlay-loader";
import "react-overlay-loader/styles.css";

const FormTab = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const getFormCopy = async (formInitial) => {
    setLoading(true);
    await dispatch(formCopyData(formInitial, props.n_POTransactionFK))
      .then((res) => {
        if (res.status == 'Y' && res.docURL !== '') {
          window.open(res.docURL);
        }
        else if (res.docURL == '') {
          toast.error(t(res.msg));
        }
      });
    setLoading(false);
  }

  return (
    <LoadingOverlay>
      <Loader loading={loading} />
      <Row>
        <Col xs="12" className="billing-tab-row1">
          <CommonHeader />
        </Col>
        <Col xs="12" id="forms-tab-btn-group">
          <Button id="common-btn" variant="primary" onClick={() => getFormCopy('P')}>
            <BsDownload size={20} />
            {t('Download/Print Policyholder Copy')}
          </Button>{" "}
          <Button id="common-btn" variant="primary" onClick={() => getFormCopy('A')}>
            <BsDownload size={20} />
            {t('Download/Print Agent Copy')}
          </Button>{" "}
          <Button id="common-btn" variant="primary" onClick={() => getFormCopy('M')}>
            <BsDownload size={20} />
            {t('Download/Print Mortgagee Copy')}
          </Button>{" "}
        </Col>
      </Row>
    </LoadingOverlay>
  );
};

const mapStateToProps = (state) => {
  return {
    n_POTransactionFK: state.coreSystem.n_POTransactionFK,
  };
};
export default connect(mapStateToProps)(FormTab);
