import React, { Fragment ,useState, useEffect} from "react";
import {
  Button,
  Row,
  Col,
  Form,
  ListGroup,
  Accordion,
  Modal,
  Table,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect , useDispatch } from 'react-redux';
import {getEditEmptyQuoteData } from "../../../../../services/quoteService";
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
const RenovationDetails = (props) => {
    const [electricityInfo, setElectricityInfoData] = useState({});
    const [plumbingInfo, setPlumbingInfoData] = useState({});
    const [roofInfo, setRoofInfoData] = useState({});
    const [waterHeaterInfo, setWaterHeaterInfoData] = useState({});
    const [heatingInfo, setHeatingInfoData] = useState({});
    //Electrical
    const [isValidElectrical, setIsValidElectrical] = useState(false);
    const [electricChecked, setElectricChecked] = useState(false);

    //Heating
    const [isValidHeating, setIsValidHeating] = useState(false);
    const [heatingChecked, setHeatingChecked] = useState(false);

    //Hotwater
    const [isValidHotwater, setIsValidHotwater] = useState(false);
    const [hotwaterChecked, setHotwaterChecked] = useState(false);

    //Plumbing
    const [isValidPlumbing, setIsValidPlumbing] = useState(false);
    const [plumbingChecked, setPlumbingChecked] = useState(false);

    
    const [quoteData, setQuoteData] = useState(props.quoteData);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    
    useEffect(() => {
      dispatch(getEditEmptyQuoteData(quoteData));
    }, [quoteData]);
    
    useEffect(() => {
        const map = quoteData.riskRenovationTypeData;
        setElectricityInfoData(map.electricityInfo )
        setPlumbingInfoData(map.plumbingInfo)
        setRoofInfoData(map.roofInfo)
        setWaterHeaterInfoData(map.waterHeaterInfo)
        setHeatingInfoData(map.HeatingInfo)
    }, [quoteData]); 
    
    const handleChange = (e) => {
      const { name, value , title, attributes } = e.target; 
      if(attributes.alt != undefined){
        if(attributes.alt.value == "number"){
          const reg = /^[0-9\b]+$/
          let num=e.target.value
          if (e.target.value === '' || reg.test(e.target.value)) {
            
            var quoteDataVar = props.quoteData;
            const tempArr = props.quoteData.riskRenovationTypeData;
            tempArr[name]['s_RenovationYear'] = value;
            setQuoteData({...quoteDataVar, 'riskRenovationTypeData': tempArr}); 
          
          }
          else e.target.value = num.substring(0,(num.length-1))
        }

      }
      // const name = e.target.name;
      // const value = e.target.value;
	    

    }

    const handleChangeDropDown = (e) => {
      const name = e.target.name;
      const value = e.target.value;
	  var quoteDataVar = props.quoteData;
      const tempArr = props.quoteData.riskRenovationTypeData;
      tempArr[name]['s_RenovationTypeCode'] = value;
      setQuoteData({...quoteDataVar, 'riskRenovationTypeData': tempArr});
    }
    
      const renovationProducts = [
        {
          renovations: "Electrical",
          type: "1",
          year: "2",
        },
        {
          renovations: "Heating",
          type: "1",
          year: "2",
        },
        {
          renovations: "Hotwater Heat",
          type: "1",
          year: "2",
        },
        {
          renovations: "Plumbing",
          type: "1",
          year: "2",
        },
        {
          renovations: "Roofing",
          type: "1",
          year: "2",
        },
      ];
      
      const electricBlur = (e) => {
        const d = new Date();
        let year = d.getFullYear();
        if(e != "" ){
            if(year >= e){
              setIsValidElectrical(true);
              setElectricChecked(true);
            }else{
              setIsValidElectrical(false);
              setElectricChecked(false);
              toast.error("Electrical Year Should Not Be Greater Than Current Year");
            }
            
        }else{
          setElectricChecked(false);
        }
      }

      const heatingBlur = (e) => {
        const d = new Date();
        let year = d.getFullYear();
        if(e != "" ){
            if(year >= e){
              setIsValidHeating(true);
              setHeatingChecked(true);
            }else{
              setIsValidHeating(false);
              setHeatingChecked(false);
              toast.error("Heating Year Should Not Be Greater Than Current Year");
            }
            
        }else{
          setHeatingChecked(false);
        }
      }

      const hotWaterBlur = (e) => {
        const d = new Date();
        let year = d.getFullYear();
        if(e != "" ){
            if(year >= e){
              setIsValidHotwater(true);
              setHotwaterChecked(true);
            }else{
              setIsValidHotwater(false);
              setHotwaterChecked(false);
              toast.error("HotWater Year Should Not Be Greater Than Current Year");
            }
            
        }else{
          setHotwaterChecked(false);
        }
      }

      const plumberBlur = (e) => {
        const d = new Date();
        let year = d.getFullYear();
        if(e != "" ){
            if(year >= e){
              setIsValidPlumbing(true);
              setPlumbingChecked(true);
            }else{
              setIsValidPlumbing(false);
              setPlumbingChecked(false);
              toast.error("Plumber Year Should Not Be Greater Than Current Year");
            }
            
        }else{
          setPlumbingChecked(false);
        }
      }

    return (
        <Fragment>
          <Table className="p-0 m-0" size="sm" responsive striped>
          <thead>
            <tr>
            <th>Renovations</th>
            <th>Type</th>
            <th>Year</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="checkbox-table-row">
                  <Form.Check
                    className="table-checkbox"
                    type="checkbox"
                    label=""
                    id="custom-checker"
                    // checked={electricChecked}
                    checked={(electricityInfo != undefined && electricityInfo.s_RenovationTypeCode != null) ? true : false}
                    disabled={true}
                  />
                  {t("Electrical")}
                </div>
              </td>
              <td>
                <Form.Select
                    className="dashboard-group-input"
                    id="select-ym-option"
                    style={{ maxWidth: "200px" }}
                    value={electricityInfo ? electricityInfo.s_RenovationTypeCode : null}
                    // disabled={electricityInfo.s_RenovationTypeCode != null ? true : false}
                    
                    name='electricityInfo'
                    onChange={(e) => handleChangeDropDown(e)}
                  >
                    <option>Select</option>
                    <option value="FULL">Full</option>
                    <option value="PARTIAL">Partial</option>
                  </Form.Select>
              </td>
              <td>
                <Form.Control
                  name='electricityInfo'
                  className="dashboard-group-input"
                  type="text"
                  maxLength="4"
                  // pattern="^[0-9\b]+$"
                  alt={"number"}
                  onBlur={(e) => electricBlur(e.target.value)}
                  style={{ maxWidth: "200px" }}
                  value={electricityInfo ?electricityInfo.s_RenovationYear : null }
                  isValid={!isValidElectrical ? "" : "Please Select Proper Year"}
                  onKeyPress="/^[0-9\b]+$/"
                  onChange={(e) => {
                    handleChange(e)
                    
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>
                <div className="checkbox-table-row">
                  <Form.Check
                    className="table-checkbox"
                    type="checkbox"
                    label=""
                    id="custom-checker"
                    // checked={heatingChecked}
                    checked={(heatingInfo != undefined && heatingInfo.s_RenovationTypeCode != null) ? true : false}
                    disabled={true}
                  />
                  {t("Heating")}
                </div>
              </td>
              <td>
                <Form.Select
                    className="dashboard-group-input"
                    id="select-ym-option"
                    style={{ maxWidth: "200px" }}
                    value={heatingInfo ? heatingInfo.s_RenovationTypeCode : null}
                    name='HeatingInfo'
                    onChange={(e) => handleChangeDropDown(e)}
                  >
                    <option>Select</option>
                    <option value="FULL">Full</option>
                    <option value="PARTIAL">Partial</option>
                  </Form.Select>
              </td>
              <td>
                <Form.Control
                  className="dashboard-group-input"
                  type="text"
                  style={{ maxWidth: "200px" }}
                  name='HeatingInfo'
                  maxLength="4"
                  alt={"number"}
                  onKeyPress="/^[0-9\b]+$/"
                  onBlur={(e) => heatingBlur(e.target.value)}
                  value={heatingInfo ? heatingInfo.s_RenovationYear : null} 
                  isValid={!isValidHeating ? "" : "Please Select Proper Year"}
                  onChange={(e) => handleChange(e)}
                />
              </td>
            </tr>

            <tr>
              <td>
                <div className="checkbox-table-row">
                  <Form.Check
                    className="table-checkbox"
                    type="checkbox"
                    label=""
                    id="custom-checker"
                    // checked={hotwaterChecked}
                    checked={(waterHeaterInfo != undefined && waterHeaterInfo.s_RenovationTypeCode != null) ? true : false}
                    disabled={true}
                  />
                  {t("Hotwater Heat")}
                </div>
              </td>
              <td>
                <Form.Select
                    className="dashboard-group-input"
                    id="select-ym-option"
                    style={{ maxWidth: "200px" }}
                    value={waterHeaterInfo ? waterHeaterInfo.s_RenovationTypeCode : null}
                    onChange={(e) => handleChangeDropDown(e)}
                    name='waterHeaterInfo'
                  >
                    <option>Select</option>
                    <option value="FULL">Full</option>
                    <option value="PARTIAL">Partial</option>
                  </Form.Select>
              </td>
              <td>
                <Form.Control
                  className="dashboard-group-input"
                  type="text"
                  style={{ maxWidth: "200px" }}
                  name='waterHeaterInfo'
                  maxLength="4"
                  alt={"number"}
                  onKeyPress="/^[0-9\b]+$/"
                  onBlur={(e) => hotWaterBlur(e.target.value)}
                  isValid={!isValidHotwater ? "" : "Please Select Proper Year"}
                  value={waterHeaterInfo ? waterHeaterInfo.s_RenovationYear : null}
                  onChange={(e) => handleChange(e)}
                />
              </td>
            </tr>

            <tr>
              <td>
                <div className="checkbox-table-row">
                  <Form.Check
                    className="table-checkbox"
                    type="checkbox"
                    label=""
                    id="custom-checker"
                    //checked={plumbingChecked}
                    checked={(plumbingInfo != undefined && plumbingInfo.s_RenovationTypeCode != null) ? true : false}
                    disabled={true}
                  />
                  {t("Plumbing")}
                </div>
              </td>
              <td>
                <Form.Select
                    className="dashboard-group-input"
                    id="select-ym-option"
                    style={{ maxWidth: "200px" }}
                    value={plumbingInfo ? plumbingInfo.s_RenovationTypeCode : null}
                    onChange={(e) => handleChangeDropDown(e)}
                    name='plumbingInfo'
                  >
                    <option>Select</option>
                    <option value="FULL">Full</option>
                    <option value="PARTIAL">Partial</option>
                  </Form.Select>
              </td>
              <td>
                <Form.Control
                  className="dashboard-group-input"
                  type="text"
                  style={{ maxWidth: "200px" }}
                  name='plumbingInfo'
                  maxLength="4"
                  alt={"number"}
                  onKeyPress="/^[0-9\b]+$/"
                  onBlur={(e) => plumberBlur(e.target.value)}
                  isValid={!isValidPlumbing ? "" : "Please Select Proper Year"}
                  value={plumbingInfo ? plumbingInfo.s_RenovationYear : null}
                  onChange={(e) => handleChange(e)}
                />
              </td>
            </tr>

            <tr>
              <td>
                <div className="checkbox-table-row">
                  <Form.Check
                    className="table-checkbox"
                    type="checkbox"
                    label=""
                    id="custom-checker"
                    checked={(roofInfo != undefined && roofInfo.s_RenovationTypeCode != null) ? true : false}
                    disabled={true}
                  />
                  {t("Roofing")}
                </div>
              </td>
              <td>
                <Form.Select
                    className="dashboard-group-input"
                    id="select-ym-option"
                    style={{ maxWidth: "200px" }}
                    value={(roofInfo != undefined) ? roofInfo.s_RenovationTypeCode : null}
                    disabled={(roofInfo != undefined && roofInfo.s_RenovationTypeCode != null) ? true : false}
                    name='roofInfo'
                  >
                    <option>Select</option>
                    <option value="FULL">Full</option>
                    <option value="PARTIAL">Partial</option>
                  </Form.Select>
              </td>
              <td>
                <Form.Control
                  className="dashboard-group-input"
                  type="text"
                  style={{ maxWidth: "200px" }}
                  name='roofInfo'
                  maxLength="4"
                  alt={"number"}
                  onKeyPress="/^[0-9\b]+$/"
                  // disabled={roofInfo.s_RenovationYear != null ? true : false}
                  value={(roofInfo != undefined) ? roofInfo.s_RenovationYear : null}
                  disabled={true}
                  onChange={(e) => handleChange(e)
                    }
                />
              </td>
            </tr>
          </tbody>
          </Table>
        </Fragment>
    );
};


function mapStateToProps(state) {
	return {
    quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	};
  } 
  export default connect(mapStateToProps)(RenovationDetails);
