import React, { useContext, useEffect,useState  } from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import userFill from "../../../assets/user-fill.png";
import phoneFill from "../../../assets/Phone_fill.png";
import { GrNotification } from "react-icons/gr";
import profileImg from "../../../assets/profile.png";
import usFlag from "../../../assets/usa_flag.png";
import { LangContext } from "../../../contexts/LangContext";
import { useTranslation } from "react-i18next";
import { logout } from "../../../services/authService";
import { useDispatch } from 'react-redux';
import "./header.scss";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { EncryptOrDecrypt } from "../../../services/commonService";
import AWS from 'aws-sdk';
import {
  FaComments,
  FaTimes
} from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
//import logo from "../../../assets/unifimga_logo.png";
import logoicon from "../../../assets/logoicon.png";
import RefreshToken from "../../../pages/login/RefreshToken";

const Header = () => {
  const navigate = useNavigate();
  const { selectedLng, handleDropdownChange } = useContext(LangContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = localStorage.getItem('access_token') || ''
  const userDatas = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
  const [question, setQuestion] = useState("");
  const [sessionAttributes , setSessionAttributes] = useState({});
  const [lexUserId,setlexUserId] = useState('chatbot-demo' + Date.now());
  const [showModel, setShowModel] = useState(false);
  const [logo, setLogo] = useState(localStorage.getItem('company_logo'));
  
  AWS.config.region = 'us-east-1'; // Region
  AWS.config.credentials = {
    accessKeyId : 'AKIARCGCSTWOMDR2QWWP',
    secretAccessKey : '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',
  };

  AWS.config.CognitoIdentityCredentials = {
    IdentityPoolId: 'us-east-1:076524e1-12e3-4853-94fe-f9d7bb86bbcc',
  }

  var lexruntime = new AWS.LexRuntime();
  //var lexUserId = 'chatbot-demo' + Date.now();
  //let sessionAttributes = {};

  useEffect(() => {
    if (token.length === 0) {
      navigate('/login');
    }
  }, [])

  useEffect(() => {
    // 240000 = 4 Min
    const interval = setInterval(() => {
      checkTimeDifference();
    }, 240000);
    return () => clearInterval(interval);
  }, []);

  const checkTimeDifference = () => {
    var now = new Date();
    var tokenExpiresAt = EncryptOrDecrypt(localStorage.getItem('access_token_expires_at'), 'D');
    tokenExpiresAt = new Date(tokenExpiresAt);
    var milliSec = (tokenExpiresAt - now); // milliseconds between now & tokenExpiresAt
    var minutes = Math.round((milliSec / 1000) / 60);

    // 300000 = 5 Min
    if (minutes <= 5) {
      setShowModel(true);
    }
  }  

  const handleSelect = async (eventKey) => {
    if (eventKey === 'logout') {
      await dispatch(logout());
      localStorage.clear();
      navigate('/login');
    }
  }

  const showchatDiv = () => {
    
    var element1 = document.getElementById('chatbot');
    element1.style.visibility = 'visible';
    var element2 = document.getElementById('chatbotIcon');
    element2.style.visibility = 'hidden';
    var conversationDiv = document.getElementById('conversation');
    var responseDiv = document.createElement("div");
    responseDiv.className = 'lexResponse';
    var responseIcon = document.createElement("img");
    responseIcon.src = logoicon;
    responseIcon.className = 'lexResponseIcon';
    responseIcon.alt = 'chatbot';
    var responsePara = document.createElement("div");
    responsePara.className = 'lexResponseText';
    responsePara.innerHTML = "Hi, it's great to see you!";
    var responseIconDiv = document.createElement("div");
    responseIconDiv.className = 'lexResponseIconDiv';
    responseIconDiv.appendChild(responseIcon);
    responseDiv.appendChild(responsePara);
    responseDiv.appendChild(responseIconDiv);
    conversationDiv.appendChild(responseDiv);
    conversationDiv.scrollTop = conversationDiv.scrollHeight;
    var conversationDiv = document.getElementById('conversation');
    var responseDiv = document.createElement("div");
    responseDiv.className = 'lexResponse';
    var responseIcon = document.createElement("img");
    responseIcon.src = logoicon;
    responseIcon.className = 'lexResponseIcon';
    responseIcon.alt = 'chatbot';
    var responsePara = document.createElement("div");
    responsePara.className = 'lexResponseText';
    responsePara.innerHTML = "What information are you looking for?";
    var responseIconDiv = document.createElement("div");
    responseIconDiv.className = 'lexResponseIconDiv';
    responseIconDiv.appendChild(responseIcon);
    responseDiv.appendChild(responsePara);
    responseDiv.appendChild(responseIconDiv);
    conversationDiv.appendChild(responseDiv);
    conversationDiv.scrollTop = conversationDiv.scrollHeight;
  }

  const hidechatDiv = () => {
    var element1 = document.getElementById('chatbot');
    element1.style.visibility = 'hidden';
    var element2 = document.getElementById('chatbotIcon');
    element2.style.visibility = 'visible';
    var conversationDiv = document.getElementById('conversation');
    conversationDiv.innerHTML = '';
  }


  const showRequest = (daText) => {

    var conversationDiv = document.getElementById('conversation');
    var requestPara = document.createElement("P");
    requestPara.className = 'userRequest';
    requestPara.appendChild(document.createTextNode(daText));
    conversationDiv.appendChild(requestPara);
    conversationDiv.scrollTop = conversationDiv.scrollHeight;
  }

  const showError = (daText) => {

    var conversationDiv = document.getElementById('conversation');
    var errorPara = document.createElement("P");
    errorPara.className = 'lexError';
    errorPara.appendChild(document.createTextNode(daText));
    conversationDiv.appendChild(errorPara);
    conversationDiv.scrollTop = conversationDiv.scrollHeight;
  }

  const showResponse = (lexResponse) => {

    var conversationDiv = document.getElementById('conversation');
    var responseDiv = document.createElement("div");
    responseDiv.className = 'lexResponse';
    var responseIcon = document.createElement("img");
    responseIcon.src = logoicon;
    responseIcon.className = 'lexResponseIcon';
    responseIcon.alt = 'chatbot';
    var responsePara = document.createElement("div");
    responsePara.className = 'lexResponseText';
    responsePara.innerHTML = lexResponse.message;
    var responseIconDiv = document.createElement("div");
    responseIconDiv.className = 'lexResponseIconDiv';
    responseIconDiv.appendChild(responseIcon);
    responseDiv.appendChild(responsePara);
    responseDiv.appendChild(responseIconDiv);
    conversationDiv.appendChild(responseDiv);
    conversationDiv.scrollTop = conversationDiv.scrollHeight;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // if there is text to be sent...
    var wisdomText = document.getElementById('wisdom');
    if (wisdomText && wisdomText.value && wisdomText.value.trim().length > 0) {

      // disable input to show we're sending it
      var wisdom = wisdomText.value.trim();
      wisdomText.value = '...';
      wisdomText.locked = true;
      // send it to the Lex runtime
      var params = {
        botAlias: '$LATEST',
        botName: 'AgentPortal',
        inputText: question,
        userId: lexUserId,
        sessionAttributes: sessionAttributes
      };
      showRequest(question);
      lexruntime.postText(params, function(err, data) {
        if (err) {
          console.log(err, err.stack);
          showError('Error:  ' + err.message + ' (see console for details)')
        }
        if (data) {
          // capture the sessionAttributes for the next cycle
          setSessionAttributes(data.sessionAttributes);
          //sessionAttributes = data.sessionAttributes;
          // show response and/or error/dialog status
          showResponse(data);
        }
        // re-enable input
        wisdomText.value = '';
        wisdomText.locked = false;
      });
    }
    // we always cancel form submission
    return false;
  }

  const floodpage = () => {
    navigate('/flood/instantquote/step/address');
  }
  

  return (
    <>

    <div id="chatbotIcon" onClick={showchatDiv}> 
      <FaComments size={30} style={{color:'white' }}/> 
    </div>
    <div id="chatbot">
      <div id="chatbox">
        <div style={{textAlign : 'right'}}>
            <span style={{color:'#ccc'}} onClick={hidechatDiv}> <FaTimes /></span>
        </div>
        <div style={{textAlign : 'center'}}>
            <span> <img src={logo} style={{ width:"170px"}}  /></span>
        </div>
      </div>
      <div id="conversation"></div> 
      <div style={{marginTop: '-10px'}}>
          <form onSubmit={handleSubmit}>
              <input type="text" id="wisdom" size="80" placeholder="Reply Here..." onChange={(e) => setQuestion(e.target.value)}/>
          </form>
      </div> 
    </div>
    <Navbar expand="lg" className="dashboard-navbar">
      <div className="navbar-info-div">
        {/* <div className="navbar-info-main">
          <img src={userFill} />
          <span>{t('Account Executive')}: Patrick Brennan</span>
        </div>
        <div className="vertical-line-bar"></div> */}
        <div className="navbar-info-main">
          <img src={phoneFill} />
          <span>{t('Phone No')}: (813) 559-1335</span>
        </div>
      </div>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className="justify-content-end">
        <Nav style={{ alignItems: "center" }} onSelect={handleSelect}>
          <Button  onClick={floodpage} style={{display: "flex", fontSize : "13.5px"}}>
            <AiOutlinePlus size={18} style={{color:'white', height: "23px" }}/>
            Create Quote
          </Button>
          &nbsp;
          <NavDropdown
            className="lng-selector-drop"
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginRight: "10px",
                }}
              >
                <img
                  style={{ width: "20px", height: "20px", marginRight: "10px" }}
                  src={usFlag}
                />
                <span>{selectedLng}</span>
              </div>
            }
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item onClick={() => handleDropdownChange("English", "en")}>English</NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleDropdownChange("Spanish", "spa")}>Spanish</NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            className="noti-selector-drop"
            title={
              <div className="noti-div">
                <div className="notification-alert"></div>
                <GrNotification size={22} color={"pink"} />
              </div>
            }
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item>
              <span className="noti-text">New claims are in pending.</span>
              <span className="noti-time">2hr</span>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <span className="noti-text">New claims are in pending.</span>
              <span className="noti-time">2hr</span>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <span className="noti-text">New claims are in pending.</span>
              <span className="noti-time">2hr</span>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <span className="noti-text">New claims are in pending.</span>
              <span className="noti-time">2hr</span>
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            className="avatar-selector-drop"
            title={
              <div className="noti-div">
                { userDatas.profileImage != undefined ?
                <img id="img-profile" src={userDatas.profileImage} style={{ borderRadius: '50%', height: '45px'}} />
                :
                <img src={profileImg} />
                }
              </div>
            }
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item className="avatar-name">
              {userDatas.s_ScreenName}
            </NavDropdown.Item>
            
            <NavDropdown.Item className="avatar-edit" onClick={() => navigate(`/profile`)} >
              Edit Profile
            </NavDropdown.Item>

            <NavDropdown.Divider />
            <NavDropdown.Item className="avatar-logout" eventKey="logout">
              Log out
            </NavDropdown.Item>
          </NavDropdown>
          {/* Notification */}
          <ToastContainer
            style={{
              zIndex: 20000
            }}
            theme={'light'}
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
      {
        showModel &&
        (
          <RefreshToken
            showModel={showModel}
            setShowModel={setShowModel}
          />
        )
      }
    </>
  );
};

export default Header;
