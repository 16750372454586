import React, { Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  sizePerPageRenderer,
  pageButtonRenderer,
} from "../../../components/CustomTablePagination";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const MarketingTab = () => {
  const productsGenerator = (quantity) => {
    const items = [
      {insured_name:'Letter Templates', data : [{label:'Risk Rating 2.0 – Homeowner, Now is the Time to Purchase Flood Coverage',value:'https://www2.floodresource.com/hubfs/Agent%20Letter%20Templates/RR-2.0-Homeowner-Its-Not-Too-Late-to-Purchase-Flood-Coverage%208.20.21.docx'},
                                          {label:'Flood Season',value:'https://www2.floodresource.com/hubfs/Agent%20Letter%20Templates/Flood-Season.docx'},
                                          {label:'Protect Yourself from the Financial Impact of a Flood',value:'https://www2.floodresource.com/hubfs/Agent%20Letter%20Templates/Protect-yourself-from-financial-loss-this-flood-season.docx'},
                                          {label:'New Home Owner ',value:'https://cdn2.hubspot.net/hubfs/5282508/Agent%20Toolkit/Letter%204%20%E2%80%93%20New%20Home%20Low-Moderate%20Risk.docx'},
                                          {label:'New Condominium Owner',value:'https://www2.floodresource.com/hubfs/Agent%20Letter%20Templates/New%20Condo.docx'},
                                          {label:'Homeowner has paid off their mortgage',value:'https://www2.floodresource.com/hubfs/Agent%20Letter%20Templates/Mortgage%20Paid%20Off.docx'},
                                          {label:'Renter',value:'https://www2.floodresource.com/hubfs/Agent%20Letter%20Templates/Renter.docx'},
                                          {label:'Commercial Properties',value:'https://www2.floodresource.com/hubfs/Agent%20Letter%20Templates/Commercial%20Properties.docx'},
                                          {label:'Impact of Urbanization on Flood Risk',value:'https://www2.floodresource.com/hubfs/Agent%20Letter%20Templates/Impact%20of%20Urbanization%20on%20Flood%20Risk.docx'},
                                          {label:'Encourage the Addition of Contents Coverage to Policy',value:'https://www2.floodresource.com/hubfs/Agent%20Letter%20Templates/Add%20Contents%20Coverage%20to%20your%20Flood%20Policy.docx'},
                                          {label:'Flood Risk Increases Following Wildfires',value:'https://www2.floodresource.com/hubfs/Agent%20Letter%20Templates/Flood%20Risk%20After%20Wildfire.docx'}]},
                                          
      {insured_name:'Print Postcard Templates' , data : [{label:'Retention – Just One Inch of Water',value:'https://www2.floodresource.com/hubfs/Agent%20Print%20Postcard%20Templates/agent-templates_postcards_print-ready-2-1.pdf'} , 
                                          {label:'Retention – Federal Disaster Assistance Facts',value:'https://www2.floodresource.com/hubfs/Agent%20Print%20Postcard%20Templates/agent-templates_postcards_print-ready-3-1.pdf'},
                                          {label:'Retention – 77% of Small Businesses Do Not have a Disaster Recovery Plan',value:'https://www2.floodresource.com/hubfs/Agent%20Print%20Postcard%20Templates/agent-templates_postcards_print-ready-4-1.pdf'},
                                          {label:'Commercial Properties',value:'https://www2.floodresource.com/hubfs/Agent%20Print%20Postcard%20Templates/Postcard%20Design_NFS%20Commercial%20Fillable.pdf'},
                                          {label:'Did you know?',value:'https://www2.floodresource.com/hubfs/Agent%20Print%20Postcard%20Templates/Postcard%20Design_NFS%20Did%20you%20know%20Fillable.pdf'},
                                          {label:'Everyone is at risk',value:'https://www2.floodresource.com/hubfs/Agent%20Print%20Postcard%20Templates/Postcard%20Design_NFS%20Everyone%20At%20Risk%20Fillable.pdf'},
                                          {label:'Low risk doesn’t mean no risk',value:'https://www2.floodresource.com/hubfs/Agent%20Print%20Postcard%20Templates/Postcard%20Design_NFS%20Low%20Risk%20No%20Risk%20Fillable.pdf'},
                                          {label:'Your home may be at risk',value:'https://www2.floodresource.com/hubfs/Agent%20Print%20Postcard%20Templates/Postcard%20Design_NFS%20v3%20Fillable.pdf'}
                                              ]},

      {insured_name:'Digital Postcard Templates' , data : [{label:'Risk Rating 2.0 – Property Owners',value:'https://www2.floodresource.com/hubfs/Agent%20Digital%20Postcard%20Templates/RR-2.0-Just-One-Inch-of-Water_digital_template.pdf'} , 
                                          {label:'Risk Rating 2.0 – Business Owners',value:'https://www2.floodresource.com/hubfs/Agent%20Digital%20Postcard%20Templates/RR-2.0-Small-Business-Owners_digital_template.pdf'},
                                          {label:'2021 Hurricane Season Forecast',value:'https://www2.floodresource.com/hubfs/Agent%20Digital%20Postcard%20Templates/2021-Forecast-agent-postcards_digital_template.pdf'},
                                          {label:'Retention – Just One Inch of Water',value:'https://www2.floodresource.com/hubfs/Agent%20Digital%20Postcard%20Templates/Retention%20Just%20One%20Inch%20Of%20Water.pdf'},
                                          {label:'Retention – 77% of Small Businesses Do Not Have a Disaster Plan',value:'https://www2.floodresource.com/hubfs/Agent%20Digital%20Postcard%20Templates/Retention%2077%20percent%20of%20small%20businesses%20do%20not%20have%20a%20disaster%20plan.pdf'},
                                          {label:'Retention – 40% Small Business will Not Reopen',value:'https://www2.floodresource.com/hubfs/Agent%20Digital%20Postcard%20Templates/Retention%2040%20percent%20small%20businesses%20will%20not%20reopen.pdf'}
      ]},
    ];
    return items;
  };
  const products = productsGenerator(5);

  const columns = [
    {
      dataField: "insured_name",
      text: "",
    },
  ];

  const options = {
    sizePerPageRenderer,
    pageButtonRenderer,
    showTotal: true,
    alwaysShowAllBtns: true,
  };

  const expandRow = {
    renderer: row => (
      <div>
        {
          row.data.map((val) =>(
            <div>
              <div>
              <a href={val.value} target="_blank">{val.label}</a>
              </div>
              <br></br>
            </div>
            )
          )
        }
      </div>
    )
  };

  return (
    <Fragment>
      <div className="custom-tab-header-fill">
        <span></span>
      </div>
      <div className="managment-tab-body">
        <div
          className="management-reports-table"
          id="management-reports-table-outer-div"
        >
          <BootstrapTable
            wrapperClasses="management-functional-table"
            bootstrap4
            keyField="insured_name"
            data={products}
            columns={columns}
            hover
            bordered={false}
            expandRow={ expandRow }
          />
        </div>
      </div>
    </Fragment>
  );
};

export default MarketingTab;
