import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useTranslation } from 'react-i18next';
import { Loader, LoadingOverlay } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { pivotRespLog } from '../../../../../services/floodService';
import parse from "html-react-parser";
import ViewJson from './ViewJson';

const PivotLog = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let { poTransPk } = useParams();
  const [apiLoading, setApiLoading] = useState(false);
  const [isShow, setIsShow] = useState(true);
  const [respLog, setRespLog] = useState([]);
  const [total, setTotal] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [viewJson, setViewJson] = useState(null);
  const [filter, setFilter] = useState({
    poTransPK: poTransPk || props.poTransPK,
    page: 1,
    pageLength: 10,
  });

  useEffect(() => {
    getRespLog();
  }, [])

  const getRespLog = async () => {
    const param = {
      page: 1, pageLength: 15, poTransPK: poTransPk || props.poTransPK
    }
    setApiLoading(true);
    await dispatch(pivotRespLog(param)).then((res) => {
      setRespLog(res.data);
      setTotal(res.total);
    });
    setApiLoading(false);
    setIsChanged(false);
  }

  useEffect(() => {
    if (isChanged) {
      getRespLog();
    }
  }, [filter.page, filter.pageLength]);

  const onTableChange = (type, { page, sizePerPage }) => {
    setFilter({ ...filter, page: page, pageLength: sizePerPage });
    setIsChanged(true);
  }

  const renderShowsTotal = (from, to, size) => (
    <span style={{ paddingLeft: 10 }}>Showing {from} to {to} of {size} Results</span>
  );

  // to check whether json string is parsable or not
  const isJsonParsable = string => {
    try {
      JSON.parse(string);
    } catch (e) {
      return false;
    }
    return true;
  }

  // To Format Response (Error / Success)
  const respFormat = (cell, row) => {
    // Only For Error Response Log
    if (row.status === 'ERROR') {
      if (/<\/?[a-z][\s\S]*>/i.test(row.errorResponse)) {
        return (
          <span>
            {parse(row.errorResponse.replace(/(\\n)|(["]+)/g, ''))}
          </span>
        )
      } else if (isJsonParsable(row.errorResponse)) { // to check is json string parsable or not
        if (JSON.parse(row.errorResponse).length > 0) {
          return (
            <React.Fragment>
              <span>
                {JSON.parse(row.errorResponse)[0].message}
              </span>
              <a href={'#'} onClick={() => showFullJson(row.request)} >Request JSON</a>&nbsp;&nbsp;&nbsp;<a href={'#'} onClick={() => showFullJson(row.errorResponse)} >Response JSON</a>
            </React.Fragment>
          )
        } else {
          var log = null;
          // Input Validation Message
          if (JSON.parse(row.errorResponse).fieldErrors && JSON.parse(row.errorResponse).fieldErrors.length > 0) {
            log = (
              <React.Fragment>
                <span style={{ color: 'red' }}>
                  {JSON.parse(row.errorResponse).message}
                </span>
                {
                  JSON.parse(row.errorResponse).fieldErrors &&
                  <ul>
                    {
                      JSON.parse(row.errorResponse).fieldErrors.map((val, key) => {
                        return (
                          <li key={key}>{val.field} - {val.message} </li>
                        )
                      })
                    }
                  </ul>
                }
                <a href={'#'} onClick={() => showFullJson(row.request)} >Request JSON</a>&nbsp;&nbsp;&nbsp;<a href={'#'} onClick={() => showFullJson(row.errorResponse)} >Response JSON</a>
              </React.Fragment>
            );
          }

          // Rule Validation Message
          if (JSON.parse(row.errorResponse).ruleFailures && JSON.parse(row.errorResponse).ruleFailures.length > 0) {
            log = (
              <React.Fragment>
                <span style={{ color: 'red' }}>
                  {JSON.parse(row.errorResponse).message}
                </span>
                {
                  JSON.parse(row.errorResponse).ruleFailures &&
                  <ul>
                    {
                      JSON.parse(row.errorResponse).ruleFailures.map((val, key) => {
                        if (val.exception && val.exception !== '') {
                          return (
                            <li key={key}>{val.exception} </li>
                          )
                        }
                      })
                    }
                  </ul>
                }
                <a href={'#'} onClick={() => showFullJson(row.request)} >Request JSON</a>&nbsp;&nbsp;&nbsp;<a href={'#'} onClick={() => showFullJson(row.errorResponse)} >Response JSON</a>
              </React.Fragment>
            );
          }
          return log;
        }
      } else {
        return row.errorResponse; // To Display Normal String
      }
    }
    // Success Response
    if (row.status === 'SUCCESS') {
      if (row.response && Object.keys(JSON.parse(row.response)).length > 0) {
        return (
          <React.Fragment>
            <span style={{ color: 'green' }}>
              {JSON.parse(row.response).message}
            </span>
            <br />
            <a href={'#'} onClick={() => showFullJson(row.request)} >Request JSON</a>&nbsp;&nbsp;&nbsp;<a href={'#'} onClick={() => showFullJson(row.response)} >View JSON</a>
          </React.Fragment>
        )
      }
    }
  }

  // To Show Full Json
  const showFullJson = (json) => {
    setViewJson(<ViewJson json={json} setViewJson={setViewJson} />);
  }

  const columns = [
    {
      dataField: "id",
      hidden: true
    },
    {
      dataField: "errorResponse",
      text: t("Response"),
      formatter: respFormat,
    },
    {
      dataField: "status",
      text: t("Status"),
    },
    {
      dataField: "d_CreatedDate",
      text: t("Date"),
    }
  ];

  const options = {
    page: filter.page,
    sizePerPage: filter.pageLength,
    sortIndicator: false,
    pageStartIndex: 1,
    totalSize: total,
    showTotal: true,
    paginationTotalRenderer: renderShowsTotal,
  };

  return (
    <Modal
      className="custom-dashboard-modal"
      size="lg"
      show={isShow}
    >
      <Modal.Header closeButton className="custom-dashboard-modal-header" onClick={() => { setIsShow(false); props.showPivotLog(null) }}>
        <Modal.Title>Pivot Api Response Log</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-dashboard-modal-body">
        <LoadingOverlay >
          <Loader loading={apiLoading} />
          <Row style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              <BootstrapTable
                wrapperClasses="dashboard-table-main"
                bootstrap4
                remote
                keyField="s_POAppNo"
                data={respLog}
                columns={columns}
                hover
                pagination={paginationFactory(options)}
                onTableChange={onTableChange}
              />
            </Col>
          </Row>
          {viewJson}
        </LoadingOverlay>
      </Modal.Body>
    </Modal>
  )
}

export default PivotLog