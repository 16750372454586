import * as ActionTypes from '../action-types';

const initialState = {
  getQuoteList: {},
  emptyQuoteData: {},
  getActivityListData:{},
  getBaseData: {},
  getProductList:{},
  getAppStatusDDList:{},
  getSystemDDInstant:{},
  emptyInstantQuoteData:{},
  propertyDataFromAdd:{},
  propertyInstantDataAutocompleteStored:{},
  finalPremiumInstaQuote:{},
  getMortgageeList: {},
}

const getQuoteListData = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    getQuoteList: payload,
  });
  return stateObj;
};

const getActivityListData = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    getACtivityList: payload,
  });
  return stateObj;
};

const getBaseData = (state, payload) => {
	  
	const stateObj = Object.assign({}, state, {
		getBaseData: payload,
	});
	return stateObj;
  };
  
  const getEmptyQuoteData = (state, payload) => {
	  
	const stateObj = Object.assign({}, state, {
		emptyQuoteData: payload,
	});
	return stateObj;
  };
  
  const getEditEmptyQuoteData = (state, payload) => {
	const stateObj = Object.assign({}, state, {
	  emptyQuoteData: payload,
	});
	return stateObj;
  };
  
  const getProductListData = (state, payload) => {
    const stateObj = Object.assign({}, state, {
      getProductList: payload,
    });
    return stateObj;
  };

  const getAppStatusDD = (state, payload) => {
    const stateObj = Object.assign({}, state, {
      getAppStatusDDList: payload,
    });
    return stateObj;
  };

  const getEditEmptyInstantQuoteData = (state, payload) => {
    const stateObj = Object.assign({}, state, {
      emptyInstantQuoteData: payload,
    });
    return stateObj;
  };

const getInstantPropertyData = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    propertyDataFromAdd: payload,
  });
  return stateObj;
};

const getEmptyInstantQuote = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    emptyInstantQuoteData: payload,
  });
  return stateObj;
};

const getInstantSystemDD = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    getSystemDDInstant: payload,
  });
  // localStorage.setItem('systemDD', payload);
  return stateObj;
};

const getInstantPropertyDataAutocompleteStore = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    propertyInstantDataAutocompleteStored: payload,
  });
  return stateObj;
};

const getSaveFinalPremiumInstantQuoteData = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    finalPremiumInstaQuote: payload,
  });
  return stateObj;
};

const getMortgageeListData = (state, payload) => {
  const stateObj = Object.assign({}, state, {
    getMortgageeList: payload,
  });
  return stateObj;
};
  

const Common = (state = initialState, action) => {
  switch (action.type) {
  case ActionTypes.GET_QUOTE_LIST_DATA:
    return getQuoteListData(state, action.payload);
  case ActionTypes.GET_ACTIVITY_LIST_DATA:
    return getActivityListData(state, action.payload);
	case ActionTypes.GET_BASE_DATA:
	  	return getBaseData(state, action.payload);
	case ActionTypes.EDIT_EMPTY_QUOTE_DATA:
		return getEditEmptyQuoteData(state, action.payload);
	case ActionTypes.GET_EMPTY_QUOTE_DATA:
		return getEmptyQuoteData(state, action.payload);
  case ActionTypes.GET_PRODUCT_LIST_DATA:
		return getProductListData(state, action.payload);
  case ActionTypes.GET_APP_STATUS_DATA:
		return getAppStatusDD(state, action.payload);
  case ActionTypes.EDIT_EMPTY_INSTANT_QUOTE_DATA:
    return getEditEmptyInstantQuoteData(state, action.payload)
  case ActionTypes.GET_INSTANT_PROPERTY_DATA_FROM_ADDRESS:
    return  getInstantPropertyData(state, action.payload);
  case ActionTypes.GET_EMPTY_INSTANT_QUOTE_DATA:
    return getEmptyInstantQuote(state, action.payload);
  case ActionTypes.GET_INSTANT_SYSTEM_DD:
    return getInstantSystemDD(state, action.payload);
  case ActionTypes.GET_INSTANT_PROPERTY_AUTOCOMPLETE_STORED:
    return getInstantPropertyDataAutocompleteStore(state, action.payload);
  case ActionTypes.GET_SAVE_FINAL_PREMIUM_INSTA_QUOTE:
    return getSaveFinalPremiumInstantQuoteData(state, action.payload);
  case ActionTypes.GET_MORTGAGEE_LIST_DATA:
    return getMortgageeListData(state, action.payload);
    default:
      return state;
  }
}

export default Common;
