import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Row, Col, Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import CommonHeader from "./CommonHeader";
import { useTranslation } from "react-i18next";

const AdditionalInfoTab = (props) => {

  const [applicantData, setApplicantData] = useState({});
  const [additionalInfoData, setAdditionalInfoData] = useState({});
  const [household, setHoushold] = useState([]);
  const [additionalInterest, setAdditionalInterest] = useState([]);
  const [mortgagees, setMortgagees] = useState([]);
  const [priorPolicyLoss, setPriorPolicyLoss] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setApplicantData(props.applicationData)
  }, [props.applicationData]);

  useEffect(() => {
    setAdditionalInfoData(props.additionalData)
  }, [props.additionalData]);

  useEffect(() => {
    setMortgagees(props.mortgageeData)
  }, [props.mortgageeData]);

  useEffect(() => {
    setHoushold(props.householdData)
  }, [props.householdData]);

  useEffect(() => {
    setAdditionalInterest(props.addiIntData)
  }, [props.addiIntData]);

  useEffect(() => {
    setPriorPolicyLoss(props.priorPolicyLossData)
  }, [props.priorPolicyLossData]);

  const mortageColumns = [
    {
      dataField: "s_PoMortgageeTypeCode",
      text: t("TYPE"),
      sort: true,
    },
    {
      dataField: "s_PersonUniqueId",
      text: t("CLIENT ID"),
      sort: true,
    },
    {
      dataField: "s_FullLegalName",
      text: t("MORTGAGEES NAME"),
      sort: true,
    },
    {
      dataField: "address",
      text: t("MORTGAGEES ADDRESS LOAN"),
      sort: true,
    },
    // {
    //   dataField: "action",
    //   text: "ACTION",
    //   sort: true,
    // },
  ];

  const additionalColumns = [
    {
      dataField: "s_PartyInterestCode",
      text: t("INTEREST"),
      sort: true,
    },
    {
      dataField: "s_FullLegalName",
      text: t("INTEREST NAME"),
      sort: true,
    },
    // {
    //   dataField: "action",
    //   text: "ACTION",
    //   sort: true,
    // },
  ];

  const householdColumns = [
    {
      dataField: "s_POApplicantTypeCode",
      text: t("RELATION"),
      sort: true,
    },
    {
      dataField: "s_PersonRoleType",
      text: t("TYPE"),
      sort: true,
    },
    {
      dataField: "s_FirstName",
      text: t("FIRST"),
      sort: true,
    },
    {
      dataField: "s_LastOrganizationName",
      text: t("LAST"),
      sort: true,
    },
    // {
    //   dataField: "action",
    //   text: "ACTION",
    //   sort: true,
    // },
  ];

  const priorPolicyLossColumns = [
    {
      dataField: "d_LossDate",
      text: t("LOSS DATE"),
      sort: true,
    },
    {
      dataField: "s_LossType",
      text: t("LOSS TYPE"),
      sort: true,
    },
    {
      dataField: "s_LossDescription",
      text: t("LOSS DESC"),
      sort: true,
    },
    {
      dataField: "s_LossCatNo",
      text: t("LOSS CATNO"),
      sort: true,
    },
    {
      dataField: "n_LossAmount",
      text: t("LOSS AMT"),
      sort: true,
    },
    // {
    //   dataField: "action",
    //   text: "ACTION",
    //   sort: true,
    // },
  ];

  return (
    <Row>
      <Col xs="12" className="billing-tab-row1">
        <CommonHeader />
      </Col>

      <Col xs="12" className="billing-tab-row2">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">{t('Claim Information')}</span>
          </div>

          <Row
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(27, 43, 64, 0.2)",
              padding: "32px",
            }}
          >
            <Col xs="12" sm="12" md="12" lg="6" xl="6">
              <Table borderless className="policy-details-info-table">
                <tbody>
                  <tr>
                    <th>{t('Total Claim')}</th>
                    <td>{Object.keys(applicantData).length > 0 && applicantData.totalClaims}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col xs="12" sm="12" md="12" lg="6" xl="6">
              <Table borderless className="policy-details-info-table">
                <tbody>
                  <tr>
                    <th> {t('Total Payment')}</th>
                    <td>{Object.keys(applicantData).length > 0 && applicantData.totalPayment}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Col>
      <Col xs="12" className="billing-tab-row2">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">
              {t('Prior Insurance Information')}
            </span>
          </div>

          <Row
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(27, 43, 64, 0.2)",
              padding: "32px",
            }}
          >
            <Col xs="12" sm="12" md="6" lg="4" xl="4">
              <Table borderless className="policy-details-info-table">
                <tbody>
                  <tr>
                    <th>{t('Prior Carrier Name')}</th>
                    <td>{Object.keys(additionalInfoData).length > 0 && additionalInfoData.s_PriorCarrierName}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col xs="12" sm="12" md="6" lg="4" xl="4">
              <Table borderless className="policy-details-info-table">
                <tbody>
                  <tr>
                    <th>{t('Prior Policy No')}</th>
                    <td>{Object.keys(additionalInfoData).length > 0 && additionalInfoData.s_PriorPolicyNo}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col xs="12" sm="12" md="6" lg="4" xl="4">
              <Table borderless className="policy-details-info-table">
                <tbody>
                  <tr>
                    <th>{t('Policy Exp. Date')}</th>
                    <td>{Object.keys(additionalInfoData).length > 0 && additionalInfoData.d_PolicyExpirationDate}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Col>

      <Col xs="12" className="billing-tab-row2">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">
              {t('Mortgagees Information')}
            </span>
          </div>
          <BootstrapTable
            wrapperClasses="policy-detail-table-main pdtmm"
            bootstrap4
            keyField="s_PoMortgageeTypeCode"
            data={mortgagees}
            columns={mortageColumns}
            hover
            bordered={false}
          />
        </div>
      </Col>

      <Col xs="12" className="billing-tab-row2">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">{t('Household Members')}</span>
          </div>
          <BootstrapTable
            wrapperClasses="policy-detail-table-main pdtmm"
            bootstrap4
            keyField="relation"
            data={household}
            columns={householdColumns}
            hover
            bordered={false}
          />
        </div>
      </Col>

      <Col xs="12" className="billing-tab-row2">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">{t('Additional Interests')}</span>
          </div>
          <BootstrapTable
            wrapperClasses="policy-detail-table-main pdtmm"
            bootstrap4
            keyField="relation"
            data={additionalInterest}
            columns={additionalColumns}
            hover
            bordered={false}
          />
        </div>
      </Col>

      <Col xs="12" className="billing-tab-row2">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">{t('List all losses in the last 5 years for all members of the household at any location')}</span>
          </div>
          <BootstrapTable
            wrapperClasses="policy-detail-table-main pdtmm"
            bootstrap4
            keyField="relation"
            data={priorPolicyLoss}
            columns={priorPolicyLossColumns}
            hover
            bordered={false}
          />
        </div>
      </Col>
    </Row>
  );
};
const mapStateToProps = (state) => {
  return {
    applicationData: state.coreSystem.applicationData,
    additionalData: state.coreSystem.additionalData,
    mortgageeData: state.coreSystem.mortgageeData,
    householdData: state.coreSystem.householdData,
    addiIntData: state.coreSystem.addiIntData,
    priorPolicyLossData: state.coreSystem.priorPolicyLossData
  };
};
export default connect(mapStateToProps)(AdditionalInfoTab);
