import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
//import logo from "../../assets/main-login-logo.svg";
//import logo from "../../assets/unifimga_logo.png";

import "./login.scss";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { authCheck, login,getSiteData } from "../../services/authService";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { EncryptOrDecrypt } from "../../services/commonService";
import Moment from "moment";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const token = localStorage.getItem('access_token') || '';
	const [logo, setLogo] = useState('');

	useEffect(() => {
		callGetSiteData();
	  }, []);
	  
	  const callGetSiteData = async () => {
		setLoading(true);
		await dispatch(getSiteData()).then((res) => { 
			console.log('res',res);
			if (res.data.success == true) {
				localStorage.setItem('company_logo',res.data.data);
				setLogo(localStorage.getItem('company_logo'));
				setLoading(false);
			} else {
				localStorage.setItem('company_logo','https://www.pngkey.com/png/full/246-2467574_your-company-slogen-here-your-company-logo-here.png');
				setLogo(localStorage.getItem('company_logo'));
				setLoading(false);
			}
		});
	  };
	
	
    useEffect(() => {
        if (token.length > 0) {
            window.location = '/';
        }
    }, [])
	 
	

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const request = {
            'username': username,
            'password': password,
        };
        dispatch(login(request)).then(async (response) => {
            var EncryptUserData = '';
            if (response.success === true) {
                if (Object.keys(response.data).length > 0 && response.data.status === 200) {
                    localStorage.setItem('access_token', response.data.access_token);
                    var tokenExpireAt = EncryptOrDecrypt(UTCDateToLocalDate(response.data.access_token_expires_at), 'E');
                    localStorage.setItem('access_token_expires_at', tokenExpireAt);
                    EncryptUserData = EncryptOrDecrypt(response.data.extraDetails, 'E');
                    localStorage.setItem('user', EncryptUserData);
                    await dispatch(authCheck());
                    window.location = '/';
                } else {
                    setUsername('');
                    setPassword('');
                    toast.error(response.data.errors);
                }
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            toast.error(t('Please Try again'));
        });

    }

    const UTCDateToLocalDate = (date) => {
        date = date.replace('UTC', '').trim();
        return Moment.utc(date).local().format();
    }

    return (
        <LoadingOverlay>
            <Loader loading={loading} />
            <div className="login-page-section">
                <Form onSubmit={handleSubmit} >
                    <Row className="justify-content-center">
                        <Col xs="12" sm="12" md="8" lg="6" xl="5">
                            <div className="login-page-card">
                                <img src={logo} />
                                <span className="login-card-title">
                                    {t("Login to Agent Portal")}
                                </span>
                                <span className="login-card-subtitle">{t("Welcome Back")}!</span>
                                <div className="login-card-line-breaker"> </div>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="dashboard-group-label">
                                        {t("Username")}
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input"
                                        type="text"
                                        placeholder=""
                                        value={username}
                                        onChange={(e) => setUsername(e.currentTarget.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="dashboard-group-label">
                                        {t("Password")}
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input"
                                        type="password"
                                        placeholder=""
                                        value={password}
                                        onChange={(e) => setPassword(e.currentTarget.value)}
                                        required
                                    />
                                    <Form.Text id="login-page-form-subtext">
                                        {t("Forgot Password")} ?
                                    </Form.Text>
                                </Form.Group>
                                <Button
                                    id="common-btn"
                                    variant="primary"
                                    type="submit"
                                    style={{ padding: "12px" }}
                                    disabled={loading}
                                >
                                    {t("Login")}
                                </Button>{" "}
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </LoadingOverlay>
    );
};

export default Login;