import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import {
  PieChart,
  Pie,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from "recharts";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./dashboard.scss";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();

  const pieChartData = [
    {
      data: [
        { name: "Bound", value: 247, fill: "#64D6CD" },
        { name: "Quote", value: 90, fill: "#D68928" },
      ],
    },
  ];

  const lineChartData = [
    {
      name: "Jan",
      Bound: 20,
      Quote: 100,
      Renewals: 200,
      Transactions: 300,
    },
    {
      name: "Feb",
      Bound: 400,
      Quote: 500,
      Renewals: 600,
      Transactions: 700,
    },
    {
      name: "Mar",
      Bound: 800,
      Quote: 900,
      Renewals: 100,
      Transactions: 110,
    },
    {
      name: "Apr",
      Bound: 120,
      Quote: 500,
      Renewals: 200,
      Transactions: 340,
    },
    {
      name: "May",
      Bound: 20,
      Quote: 90,
      Renewals: 70,
      Transactions: 500,
    },
    {
      name: "Jun",
      Bound: 20,
      Quote: 550,
      Renewals: 600,
      Transactions: 500,
    },
    {
      name: "Jul",
      Bound: 20,
      Quote: 200,
      Renewals: 100,
      Transactions: 500,
    },
  ];

  // const productsGenerator = (quantity) => {
  //   const items = [];
  //   for (let i = 0; i < quantity; i++) {
  //     items.push({
  //       policy_no: "HO32014012226",
  //       insured_name: "ROGE DAMEUS",
  //       product_type: "HO6 (Homeowners)",
  //       renew_date: "MM/DD/YYYY",
  //     });
  //   }
  //   return items;
  // };

  // const products = productsGenerator(5);

  // const columns = [
  //   {
  //     dataField: "policy_no",
  //     text: "Policy No",
  //   },
  //   {
  //     dataField: "insured_name",
  //     text: "Insured Name",
  //   },
  //   {
  //     dataField: "product_type",
  //     text: "Product Type",
  //   },
  //   {
  //     dataField: "renew_date",
  //     text: "Renew Date",
  //   },
  // ];

  return (
    <div className="dashboard-page-container">
      {/* SELECT DATE & YEAR */}
      <Row className="align-items-center">
        <Col xs="12">
          <div className="dashbaord-select-div">
            <p className="select-ym-text">Select By Year and Month</p>
            <div className="dashboard-page-selections">
              <Form.Select className="d-main-select" id="select-ym-option">
                <option>2021</option>
                <option>2020</option>
                <option>2019</option>
              </Form.Select>
              <Form.Select className="d-main-select" id="select-ym-option">
                <option>December</option>
                <option>November</option>
                <option>October</option>
              </Form.Select>
            </div>
          </div>
        </Col>
      </Row>

      {/* CARDS */}
      <Row>
        <Col xs="12" sm="12" md="6" lg="3" xl="3">
          <div
            className="dashboard-stat-card c1"
            onClick={() => navigate("/payment-due")}
          >
            <div className="dashboard-stat-svg1"></div>
            <div className="dashboard-stat-svg2"></div>
            <p className="dashboard-stat-title">In Force Policies</p>
            <p className="dashboard-stat-value">20</p>
            <p className="dashboard-stat-value">$12,768</p>
          </div>
        </Col>
        <Col xs="12" sm="12" md="6" lg="3" xl="3">
          <div
            className="dashboard-stat-card c2"
            onClick={() => navigate("/cancel-transactions")}
          >
            <div className="dashboard-stat-svg1"></div>
            <div className="dashboard-stat-svg2"></div>
            <p className="dashboard-stat-title">Expired Renewals</p>
            <p className="dashboard-stat-value">5</p>
            <p className="dashboard-stat-value">$3,000</p>
          </div>
        </Col>
        <Col xs="12" sm="12" md="6" lg="3" xl="3">
          <div
            className="dashboard-stat-card c3"
            onClick={() => navigate("/upcoming-renewals")}
          >
            <div className="dashboard-stat-svg1"></div>
            <div className="dashboard-stat-svg2"></div>
            <p className="dashboard-stat-title">Upcoming Renewals</p>
            <p className="dashboard-stat-value">31</p>
            <p className="dashboard-stat-value">$19,242</p>
          </div>
        </Col>
        <Col xs="12" sm="12" md="6" lg="3" xl="3">
          <div
            className="dashboard-stat-card c4"
            onClick={() => navigate("/recent-transactions")}
          >
            <div className="dashboard-stat-svg1"></div>
            <div className="dashboard-stat-svg2"></div>
            <p className="dashboard-stat-title">Transactions</p>
            <div>
              <table style={{color : "white"}}>
                <tr>
                  <th>New Business</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>3</td>
                </tr>
                <tr>
                  <th>Renewals</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>2</td>
                </tr>
                <tr>
                  <th>Endosment</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>5</td>
                </tr>
              </table>
            </div>
          </div>
        </Col>
        <Col xs="12" sm="12" md="6" lg="3" xl="3">
          <div
            className="dashboard-stat-card c1"
            onClick={() => navigate("/recent-transactions")}
          >
            <div className="dashboard-stat-svg1"></div>
            <div className="dashboard-stat-svg2"></div>
            <p className="dashboard-stat-title">Claims</p>
              <div>
                <table>
                  <tr>
                    <th>Opened</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>&nbsp;0</td>
                  </tr>
                  <tr>
                    <th>Payments</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>$0</td>
                  </tr>
                  <tr>
                    <th>Reserves</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>$0</td>
                  </tr>
                </table>
              </div>
          </div>
        </Col>
      </Row>

      {/* TABLE */}
      {/* <Row>
        <Col xs="12" style={{ padding: 0 }}>
          <div className="dashboard-table-div" id="dashboard-table-section">
            <Row>
              <Col xs="12">
                <div className="table-main-title-div">
                  <span className="table-main-title-name">
                    Upcoming Renewals In 60 Days
                  </span>
                  <div className="table-main-title-icons">
                    <div className="table-square"></div>
                    <FaBars
                      size={15}
                      color={"#fff"}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/new-claims")}
                    />
                  </div>
                </div>
              </Col>
              <Col xs="12">
                <BootstrapTable
                  wrapperClasses="dashboard-table-main"
                  bootstrap4
                  keyField="policy_no"
                  data={products}
                  columns={columns}
                  hover
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row> */}

      {/* CHARTS */}
      <Row>
        <Col xs="12" sm="12" md="12" lg="4" xl="4">
          <div className="dashboard-page-chart-div">
            <p className="dp-chart-title">Today's Quote Count</p>
            <ResponsiveContainer width="100%" height={250}>
              <PieChart width="100%" height={250}>
                {pieChartData.map((s) => (
                  <Pie
                    dataKey="value"
                    nameKey="name"
                    data={s.data}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    fill="#fff"
                    label
                  />
                ))}
              </PieChart>
            </ResponsiveContainer>
            <div className="dp-chart-footer">
              <div className="dp-chart-footer-title">
                <div className="dp-chart-circle1"></div>
                Bound
              </div>
              <div className="dp-chart-footer-title">
                <div className="dp-chart-circle2"></div>
                Quote
              </div>
            </div>
          </div>
        </Col>
        <Col xs="12" sm="12" md="12" lg="8" xl="8">
          <div className="dashboard-page-chart-div">
            <p className="dp-chart-title" id="dp-chart2-title">
              Quote Count
            </p>

            <ResponsiveContainer
              width="95%"
              height={300}
              style={{ marginBottom: "6px" }}
            >
              <LineChart
                width="100%"
                height={250}
                data={lineChartData}
                margin={{
                  left: 0,
                  right: 20,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />

                <Tooltip />
                <Legend />

                <Line type="monotone" dataKey="Bound" stroke="#64D6CD" />
                <Line type="monotone" dataKey="Quote" stroke="#D68928" />
                <Line type="monotone" dataKey="Renewals" stroke="#33D14D" />
                <Line type="monotone" dataKey="Transactions" stroke="#2661AD" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
