import React, { Fragment ,useState, useEffect} from "react";
import {
  Button,
  Row,
  Col,
  Form,
  ListGroup,
  Accordion,
  Modal,
  Table,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from 'react-redux';
import { FaPlus } from "react-icons/fa";
import Select from "react-select";
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch } from 'react-redux';
import GooglePlacesAutocomplete, { geocodeByAddress } from "react-google-places-autocomplete";
import { getZipDetails , getEditEmptyQuoteData,deletePolicyInterest,getQuoteData} from "../../../../../services/quoteService";
import { getSearchMortgagee } from "../../../../../services/quoteService";
import delIcon from "../../../../../assets/delete-icon.svg";
import { FaEdit, FaSearch } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const PolicyInterests = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [clearsData, setClearsData] = useState(false);
  const [show, setShow] = useState(false);
  const [selectInterest, setSelectInterest] = useState("PRIMARY");
  const [selectedName, setSelectedName] = useState(null);
  const [inputFields, setInputFields] = useState([]);
  const [streetName, setStreetName] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [googlePlace, setGooglePlace] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [zipData, setZipData] = useState([]);
   const [interestOptions, setIntrestOptions] = useState([]);
  const [search, setSearchProperty] = useState(null);
  const [options, setOptions] = useState([]);
  const [interestValue, setInterestValue] = useState({
    value: "PRIMARY",
    label: "First Mortgagee",
  });
  const [validated, setValidated] = useState(false);
  const [isValid, setisValid] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [datas, setDatas] = useState([]);
  const [inputDatas, setInputDatas] = useState({});
  const [data, setData] = useState({
    name: "",
    fullname: "",
    firstName: "",
    middleName: "",
    lastName: "",
    type: "",
    mailAddress1: "",
    mailAddress2: "",
    city: "",
    state: "",
    zipCode: null,
    s_LoanNo: "",
    PersonInfoIdPK: "",
    PersonAddressesIdPK: null,
    n_PolicyAddInterest_PK : "",
    n_CityId_FK: "",
    n_CountryId_FK: "",
    n_StateId_FK: "",
    n_CorePersonPK:"",
    n_CorePersonAddressPK:"",
    n_POTransaction_FK:"",
    n_PersonInfoId_PK:"",
    s_PersonType:"",
    person:{s_FullLegalName:""},
    address:{s_AddressLine1:""}
  });

  const interestOptionsDDForShowing = [{ value: "PRIMARY", label: "First Mortgagee" },
  { value: "SECOND", label: "Second Mortgagee" },
  { value: "THIRD", label: "Third Mortgagee" },
  { value: "ADDITIONALINSURED", label: "Additional Insured" },
  { value: "ADDITIONALINTEREST", label: "Additional Interest" }];
  const [quoteData, setQuoteData] = useState(props.quoteData);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ?
        '#ddd' : isValid ?
          '#ddd' : 'red',
    }),
  };

  useEffect(() => {
    if (validated && googlePlace == '') {
      setisValid(false);
    } else {
      setisValid(true);
    }
  }, [validated]);

  useEffect(() => {
		dispatch(getEditEmptyQuoteData(quoteData));
	}, [quoteData]);

  useEffect(() => {
     setQuoteData(props.quoteData);
	}, [props.quoteData]);
  

  useEffect(() => {
    setDatas(quoteData.mortgageeData);
	}, [quoteData.mortgageeData]);
  
  useEffect(() => {
    if (search != null) {
      setStreetName(search.value.structured_formatting.main_text);
      getGeoCode(search.value.description);
      setGooglePlace(search);
    }
  }, [search]);

  useEffect(() => {
    let interestOptionsDD = [{ value: "PRIMARY", label: "First Mortgagee" },
    { value: "SECOND", label: "Second Mortgagee" },
    { value: "THIRD", label: "Third Mortgagee" },
    { value: "ADDITIONALINSURED", label: "Additional Insured" },
    { value: "ADDITIONALINTEREST", label: "Additional Interest" }];
    let selectedInterestOptions = [];
    if (datas.length === 0) {
      selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'SECOND' && item.value !== 'THIRD');
      setSelectInterest('PRIMARY');
    } else {
      datas.filter(function (item) {
        if (item.s_PersonType == 'PRIMARY') {
          selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'PRIMARY' && item.value !== 'THIRD');
          setSelectInterest('SECOND');
        }
        if (item.s_PersonType == 'SECOND') {
          selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'SECOND' && item.value !== 'PRIMARY');
          setSelectInterest('THIRD');
        }
        if (item.s_PersonType == 'THIRD') {
          selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'SECOND' && item.value !== 'THIRD' && item.value !== 'PRIMARY');
          setSelectInterest('ADDITIONALINSURED');
        }
        var interestOptionAdditional = '';
        var firstStatusExist = false;
        var secondStatusExist = false;
        var thirdStatusExist = false;
        var additionalStatusExist = false;
        if (item.s_PersonType == 'ADDITIONALINSURED') {
          datas.map((value) => {
            if(value.s_PersonType == 'PRIMARY'){
              firstStatusExist = true;
            } else if(value.s_PersonType == 'SECOND') {
              secondStatusExist = true;
            } else if(value.s_PersonType == 'THIRD') {
              thirdStatusExist = true;
            } else if(value.s_PersonType == 'ADDITIONALINSURED') {
              additionalStatusExist = true;
            }
            if(additionalStatusExist && !firstStatusExist){
              selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'SECOND' && item.value !== 'THIRD');
              setSelectInterest('PRIMARY');
            }
            if(additionalStatusExist && firstStatusExist && !secondStatusExist){
              selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'PRIMARY' && item.value !== 'THIRD');
              setSelectInterest('SECOND');
            }
            if(additionalStatusExist && firstStatusExist && secondStatusExist && !thirdStatusExist){
              selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'SECOND' && item.value !== 'PRIMARY');
              setSelectInterest('THIRD');
            }
            if(additionalStatusExist && firstStatusExist && secondStatusExist && thirdStatusExist){
              selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'SECOND' && item.value !== 'THIRD' && item.value !== 'PRIMARY');
              setSelectInterest('ADDITIONALINSURED');
            }
            })
        }

        var interestOptionAdditional = '';
        var firstStatusInterestExist = false;
        var secondStatusInterestExist = false;
        var thirdStatusInterestExist = false;
        var additionalStatusInterestExist = false;
        if (item.s_PersonType == 'ADDITIONALINTEREST') {
          datas.map((value) => {
            if(value.s_PersonType == 'PRIMARY'){
              firstStatusInterestExist = true;
            } else if(value.s_PersonType == 'SECOND') {
              secondStatusInterestExist = true;
            } else if(value.s_PersonType == 'THIRD') {
              thirdStatusInterestExist = true;
            } else if(value.s_PersonType == 'ADDITIONALINTEREST') {
              additionalStatusInterestExist = true;
            }
            if(additionalStatusInterestExist && !firstStatusInterestExist){
              selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'SECOND' && item.value !== 'THIRD');
              setSelectInterest('PRIMARY');
            }
            if(additionalStatusInterestExist && firstStatusInterestExist && !secondStatusInterestExist){
              selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'PRIMARY' && item.value !== 'THIRD');
              setSelectInterest('SECOND');
            }
            if(additionalStatusInterestExist && firstStatusInterestExist && secondStatusInterestExist && !thirdStatusInterestExist){
              selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'SECOND' && item.value !== 'PRIMARY');
              setSelectInterest('THIRD');
            }
            if(additionalStatusInterestExist && firstStatusInterestExist && secondStatusInterestExist && thirdStatusInterestExist){
              selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'SECOND' && item.value !== 'THIRD' && item.value !== 'PRIMARY');
              setSelectInterest('ADDITIONALINSURED');
            }
          })
        }
      });
    }
      setIntrestOptions(selectedInterestOptions);
  }, [datas.length]);

    // Inserting style only for address field
    useEffect(() => {
      document.head.insertAdjacentHTML("beforeend", `<style>input[id^='react-select-'] { opacity: 2 !important; width: 250px !important;}</style>`)
    }, [])

    const getGeoCode = (description) => {
      geocodeByAddress(description)
        .then(addr => {
          addr && addr[0].address_components.forEach(e => {
            if (e.types.includes('postal_code')) {
              setZipCode(e.short_name);
              setLoadingStatus(true);
              dispatch(getZipDetails('13', e.short_name, 'APPLICANT')).then((res) => {
                setZipData(res.data.zipData);
                const values = data;
                values.city = res.data.zipData[0].s_CityName;
                values.state = res.data.zipData[0].s_StateCode;
                values.zipCode = e.short_name;
                values.n_CityId_FK = res.data.zipData[0].n_CityId_PK;
                values.n_CountryId_FK = res.data.zipData[0].n_CountryId_FK;
                values.n_StateId_FK = res.data.zipData[0].n_StateId_PK;
  
                setData(values);
  
                setLoadingStatus(false);
              });
            }
          });
        })
    }
  
    const handleRemoveFields = async (index) => {
		const values = [...datas];
		const deleteObj = values[index];
  
		const firstMortgageefound = values.some(el => el.s_PersonType === 'PRIMARY');
		const secondMortgageefound = values.some(el => el.s_PersonType === 'SECOND');
		const thirdMortgageefound = values.some(el => el.s_PersonType === 'THIRD');
		if (values[index]['s_PersonType'] == "PRIMARY") {
		  if (thirdMortgageefound) {
			toast.error(t('Please Remove Third Mortgagee First!'));
			return false;
		  }
		  if (secondMortgageefound) {
			toast.error(t('Please Remove Second Mortgagee First!'));
			return false;
		  }
		}
  
		if (values[index]['s_PersonType'] == "SECOND") {
		  if (thirdMortgageefound) {
			toast.error(t('Please Remove Third Mortgagee First!'));
			return false;
  
		  }
		}
		await Swal.fire({
		  title: 'Are you sure?',
		  text: "Do you want to delete this?",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Yes'
		}).then((result) => {
		  if (result.isConfirmed) {
		  var quoteDataVar = props.quoteData;
		  if(deleteObj.n_PolicyAddInterest_PK){
			const params = {
			  transPK : props.quoteData.transactionInfo.n_potransaction_PK,
			  n_PolicyAddInterest_PK : deleteObj.n_PolicyAddInterest_PK
			}
			 dispatch(deletePolicyInterest(params));
		  }
		  values.splice(index, 1);
		  setDatas(values);
		  setQuoteData({...quoteDataVar, 'mortgageeData': values});
			Swal.fire(
			  'Deleted!',
			  'Record has been deleted.',
			  'success'
			)
		  }
		})
	  };


    const handleEditFields = async (index) => {
      setEditMode(true);
      setEditIndex(index);
    };

    useEffect(() => {
      if(editMode == true && editIndex != null){
        setShowModal(true);
        const values = [...datas];
        const editObj = values[editIndex];
        setSelectInterest(editObj.s_PersonType);
        const setValues = data;
        setValues.firstName = editObj.person.s_FirstName;
        setValues.middleName = editObj.person.s_MiddleName;
        setValues.lastName = editObj.person.s_LastOrganizationName;
        setValues.fullname = editObj.person.s_FullLegalName;
        setValues.type = editObj.s_AddionalIntType;
        setValues.s_PersonType = editObj.s_PersonType;
        setValues.zipCode = editObj.address.s_PostalCode;
        setValues.city = editObj.address.s_CityName;
        setValues.state = editObj.address.s_StateName;
        setValues.PersonInfoIdPK = ( editObj.n_PersonInfoId_PK == undefined || editObj.n_PersonInfoId_PK == '' ) ? null : editObj.n_PersonInfoId_PK;
        setValues.PersonAddressesIdPK = (editObj.address.n_PersonAddressesId_PK != undefined) ? editObj.address.n_PersonAddressesId_PK : (editObj.n_PersonAddressesId_PK != undefined) ? editObj.n_PersonAddressesId_PK : null;
        setValues.n_CityId_FK = editObj.address.n_CityId_FK;
        setValues.n_CountryId_FK = editObj.address.n_CountryId_FK;
        setValues.n_StateId_FK = editObj.address.n_StateId_FK;
        setValues.n_CorePersonAddressPK = editObj.n_CorePersonAddressPK;
        setValues.n_CorePersonPK = editObj.n_CorePersonPK;
        setValues.n_PolicyAddInterest_PK = editObj.n_PolicyAddInterest_PK == undefined ? null : editObj.n_PolicyAddInterest_PK;
        setValues.s_LoanNo = editObj.s_LoanNo;
        setValues.mailAddress1 = editObj.address.s_AddressLine1;
        setZipCode(editObj.address.s_PostalCode);
        setStreetName(editObj.address.s_AddressLine1);
        setData(setValues);
         if(editObj.s_PersonType !== "ADDITIONALINSURED" && editObj.s_PersonType !== "ADDITIONALINTEREST") {
          const mainData = {};
          mainData.n_PolicyAddInterest_PK = editObj.n_PolicyAddInterest_PK == undefined ? null : editObj.n_PolicyAddInterest_PK;
          mainData.n_CorePersonAddressPK = editObj.n_CorePersonAddressPK;
          mainData.n_CorePersonPK = editObj.n_PersonInfoId_PK;
          mainData.n_POTransaction_FK = props.quoteData.transactionInfo.n_potransaction_PK;
          mainData.n_PersonInfoId_PK =  editObj.n_PersonInfoId_PK;
          mainData.s_PersonType =  editObj.s_PersonType;
          mainData.n_PersonInfoId_PK = ( editObj.n_PersonInfoId_PK == undefined || editObj.n_PersonInfoId_PK == '' ) ? null : editObj.n_PersonInfoId_PK;
          mainData.n_PersonAddressesId_PK = (editObj.address.n_PersonAddressesId_PK != undefined) ? editObj.address.n_PersonAddressesId_PK : (editObj.n_PersonAddressesId_PK != undefined) ? editObj.n_PersonAddressesId_PK : null;
          mainData['person'] = {
            's_FullLegalName': editObj.person.s_FullLegalName,
            'n_PersonInfoId_PK': editObj.person.n_PersonInfoId_PK
          };      
          // 'n_PersonAddressesId_PK': editObj.address.n_PersonAddressesId_PK,
          // 'n_PersonInfoId_PK': editObj.person.n_PersonInfoId_PK,
          mainData['address'] = {
            's_AddressLine1': editObj.address.s_AddressLine1,
            's_CityName': editObj.address.s_CityName,
            's_PostalCode': editObj.address.s_PostalCode,
            's_StateName': editObj.address.s_StateName
          };
          const selectName = {} 
          selectName.label = editObj.person.s_FullLegalName + ' - ' + editObj.address.s_AddressLine1 + ',' + editObj.address.s_CityName + ',' + editObj.address.s_StateName + ',' + editObj.address.s_PostalCode;
          setSelectedName(selectName);
          setInputDatas(mainData);
        }
        
      }
    }, [editMode]);


    const zipCodeApi = () => {
      if (zipCode != '' && zipData.length >= 1) {
        dispatch(getZipDetails('13', zipCode, 'APPLICANT')).then((res) => {
          setZipData(res.data.zipData);
          setShowModal(true);
        });
      }
    }
    const handleInputDataChange = (event) => {
      setData({
        ...data,
        [event.target.name]: event.target.value,
      });
    };
    
      const handleSaveInterest = () => {
        const values = [...datas];
        var status = 1;
        if (values.length === 1) {
          if (values[0].type === "") {
            values.splice(0, 1);
          }
        }
		var quoteDataVar = props.quoteData;
        if (selectInterest === "ADDITIONALINSURED" || selectInterest === "ADDITIONALINTEREST") {

          if (data.firstName == "" || data.lastName == "" || streetName == "" || data.city == "") {
              status = 0;
          }
          if (selectInterest === "ADDITIONALINTEREST" && data.type == "") {
              status = 0;
          }
          const mainData = {};
          mainData.n_POTransaction_FK = props.quoteData.transactionInfo.n_potransaction_PK;
          mainData.s_PersonType = selectInterest;
          mainData.s_AddionalIntType = data.type
          mainData.n_CorePersonPK = data.n_CorePersonPK
          mainData.n_CorePersonAddressPK = data.n_CorePersonAddressPK;
          mainData.n_PolicyAddInterest_PK = ( data.n_PolicyAddInterest_PK == undefined || data.PersonInfoIdPK == '') ? null : data.n_PolicyAddInterest_PK;
          mainData.n_PersonInfoId_PK = (data.PersonInfoIdPK == undefined || data.PersonInfoIdPK == '' ) ? null : data.PersonInfoIdPK;
          mainData.n_PersonAddressesId_PK = ( data.PersonAddressesIdPK == undefined ||  data.PersonAddressesIdPK == '' ) ? null : data.PersonAddressesIdPK;
          mainData['person'] = {
            's_FirstName': data.firstName,
            's_MiddleName': data.middleName,
            's_LastOrganizationName': data.lastName,
            's_FullLegalName':  `${data.firstName} ${data.middleName} ${data.lastName}`,
          };
            mainData['address'] = {
              's_AddressLine1': streetName,
              'n_StateId_FK': data.n_StateId_FK,
              'n_CityId_FK':data.n_CityId_FK,
              'n_CountryId_FK': data.n_CountryId_FK,
              's_CityName': data.city,
              's_StateName': data.state,
              's_PostalCode': data.zipCode,
            };
             
            //It was written for add not for edit
            // mainData['address'] = {
            //   's_AddressLine1': streetName,
            //   'n_StateId_FK': zipData[0].n_StateId_PK,
            //   'n_CityId_FK':zipData[0].n_CityId_FK,
            //   'n_CountryId_FK': zipData[0].n_CountryId_FK,
            //   's_CityName': zipData[0].s_CityName,
            //   's_StateName': zipData[0].s_StateCode,
            //   's_PostalCode':zipData[0].s_ZipCode,
            // };

          mainData.s_LoanNo = '';
          if(status == 1) {
            if(editMode == true && editIndex != null){
              values[editIndex] = mainData;
            }
            else {
              values.push(mainData);
            }
            setDatas(values);
            setQuoteData({...quoteDataVar, 'mortgageeData': values});
          }
        } else {
          inputDatas.s_LoanNo = data.s_LoanNo;
          if (inputDatas.s_LoanNo == "" || inputDatas.fullname == "") {
              status = 0;
          }

          if(status == 1) {
            if(editMode == true && editIndex != null){
              values[editIndex] = inputDatas;
            }
            else {
              values.push(inputDatas);
            }
              setDatas(values);
              setQuoteData({...quoteDataVar, 'mortgageeData': values});
          }
        }


        
        if(status == 1) {
        setInputFields(values);
        setZipCode('');
        setValidated(false);
        setData({});
        setShowModal(false);
        setEditIndex(null);
        setEditMode(false);
        setInputDatas({});
        setOptions([]);
        setSearchProperty(null);
        setStreetName('');
        setGooglePlace('');
        setSelectInterest('PRIMARY');
        }
        else {
          setValidated(true);
        }
      };
      
      const changeZip = (e) => {
        setZipCode(e.target.value);
      }

      const setInterestValueFunc = (e) => {
        if (e === "PRIMARY") {
          setInterestValue({ value: "PRIMARY", label: "First Mortgagee" });
        } else if (e === "SECOND") {
          setInterestValue({ value: "SECOND", label: "Second Mortgagee" });
        } else if (e === "THIRD") {
          setInterestValue({ value: "THIRD", label: "Third Mortgagee" });
        } else if (e === "ADDITIONALINSURED") {
          setInterestValue({ value: "ADDITIONALINSURED", label: "Additional Insured" });
        } else if (e === "ADDITIONALINTEREST") {
          setInterestValue({ value: "ADDITIONALINTEREST", label: "Additional Interest" });
        }
    
      }

      const setSelectInterestFunc = (e) => {
        setSelectInterest(e);
      }

      const handleOnChange = (event) => {
        if (event.length > 0) {
          const eventData = event[0];
          setSelectedName(eventData);
          const values = data;
          values.fullname = eventData.s_FullLegalName;
          values.firstName = "";
          values.middleName = "";
          values.lastName = "";
          values.type = interestValue.label;
          values.mailAddress1 = eventData.s_AddressLine1;
          values.mailAddress2 = "";
          values.city = eventData.s_CityName;
          values.state = eventData.s_StateCode;
          values.zipCode = eventData.s_PostalCode;
          values.name = eventData.s_FullLegalName;
          // values.PersonInfoIdPK = eventData.n_PersonInfoId_PK;
          // values.PersonAddressesIdPK = eventData.n_PersonAddressesId_PK;
          values.n_CityId_FK = eventData.n_CityId_FK;
          values.n_CountryId_FK = eventData.n_CountryId_FK;
          values.n_StateId_FK = eventData.n_StateId_FK;
          

          const mainData = {};
          mainData.n_CorePersonAddressPK = eventData.n_PersonAddressesId_PK;
          mainData.n_CorePersonPK = eventData.n_PersonInfoId_PK;

          mainData.n_PersonInfoId_PK = ( data.PersonInfoIdPK == undefined || data.PersonInfoIdPK == '') ? null : data.PersonInfoIdPK;
          mainData.n_PersonAddressesId_PK = ( data.PersonAddressesIdPK == undefined || data.PersonAddressesIdPK == '') ? null : data.PersonAddressesIdPK;
          
          mainData.n_POTransaction_FK = props.quoteData.transactionInfo.n_potransaction_PK;
          mainData.n_PolicyAddInterest_PK = data.n_PolicyAddInterest_PK == undefined ? null : data.n_PolicyAddInterest_PK;
          mainData.s_PersonType =  selectInterest;
          mainData['person'] = {
            's_FullLegalName': eventData.s_FullLegalName,
            'n_PersonInfoId_PK': eventData.n_PersonInfoId_PK
          };      
          // 'n_PersonAddressesId_PK': eventData.n_PersonAddressesId_PK,
          // 'n_PersonInfoId_PK': eventData.n_PersonInfoId_PK,
          mainData['address'] = {
            's_AddressLine1': eventData.s_AddressLine1,
            's_CityName': eventData.s_CityName,
            's_PostalCode': eventData.s_PostalCode,
            's_StateName':eventData.s_StateCode
          };
          setInputDatas(mainData);
          setData(values);
        }
      };
      

      const handleChange = (event) => {
        if (event != "") {
          setSelectedName(event);
          if (event != '' && event.length >= 4) {
            const params = { searchField: event }
            let actions = dispatch(getSearchMortgagee(params)).then((res) => {
              const data = res.data.map(({ s_FullLegalName: label, n_PersonAddressesId_PK: value, ...rest }) => ({ label, value, ...rest }));
              data.map((item) => {
                item.s_FullLegalName = item.label;
                item.n_PersonAddressesId_PK = item.value;
                item.label = item.s_FullLegalName + ' - ' + item.s_AddressLine1 + ',' + item.s_CityName + ',' + item.s_CountyName + ',' + item.s_StateCode + ',' + item.s_PostalCode;
              })
              setOptions(data);
            })
          }
        }
      };

      const handleGooglAutoComplete = (val, status) => {
        if (status.action == "input-change") {
          setStreetName(val);
        } else if (["set-value", "menu-close", "input-blur", "menu-close"].includes(status.action)) {
          setStreetName(streetName.toUpperCase());
        }
      }

      const clearData = () => {
        setData({});
        setValidated(false);
        setShowModal(false);
        setEditMode(false);
        setEditIndex(null);
        setOptions([]);
        setSearchProperty(null);
        setStreetName('');
        setClearsData(true);
        setGooglePlace('');
        setSelectInterest('PRIMARY')
        setSelectedName('');
        setInputDatas({});
        setData({
          ...data,
          name: "",
          fullname: "",
          firstName: "",
          middleName: "",
          lastName: "",
          type: "",
          mailAddress1: "",
          mailAddress2: "",
          city: "",
          state: "",
          zipCode: data.zipCode != '' ? null : data.zipCode == undefined ? null : null,
          s_LoanNo: "",
          PersonInfoIdPK: "",
          PersonAddressesIdPK: null,
    
          n_CityId_FK: "",
          n_CountryId_FK: "",
          n_StateId_FK: ""
        });
        setZipCode('');
      }

      const clearDataOnChange = () => {
        setData({});
        setOptions([]);
        setSearchProperty(null);
        setValidated(false);
        setStreetName('');
        setGooglePlace('');
        setClearsData(true);
        setData({
          ...data,
          name: "",
          fullname: "",
          firstName: "",
          middleName: "",
          lastName: "",
          type: "",
          mailAddress1: "",
          mailAddress2: "",
          city: "",
          state: "",
          zipCode: data.zipCode != '' ? null : data.zipCode == undefined ? null : null,
          s_LoanNo: "",
          PersonInfoIdPK: "",
          PersonAddressesIdPK: "",
    
          n_CityId_FK: "",
          n_CountryId_FK: "",
          n_StateId_FK: ""
        });
        setZipCode('');
      }
  return (
    <Fragment>
        <div className="application-tab-body" style={{ border: "none" }}>
            <div
                className="management-reports-table"
                id="management-reports-table-outer-div"
                style={{ paddingBottom: "20px" }}
            >
                <Row className="justify-content-center">
                  <Col xs="12" className="add-mortage-form-main">
                    <div className="add-mortage-headings">
                      <h3>{t("Add Mortgages and Additional Interests")}</h3>
                    </div>
                    <div className="add-mortage-form">
                      <div className="add-mortage-add-btn">
                        <div></div>
                        {/*<div className="add-mortage-searchbar">
                      <input
                        className="add-mortage-input"
                        placeholder={t("Input search text")}
                      />
                      <Button
                        variant="primary"
                        className="mybtn"
                        id="add-mortage-search-icon"
                      >
                        <img src={searchIcon} />
                      </Button>
                    </div>*/}
                        <Button
                          id="common-btn"
                          variant="primary"
                          className="mybtn"
                          onClick={() => setShowModal(true)}
                        >
                          {" "}
                          <span> + </span> {t("Add")}
                        </Button>
                      </div>
                      <div className="add-mortage-table-div">
                        <Table className="add-mortage-table" borderless responsive>
                          <thead>
                            <tr>
                              <th>{t("Name")}</th>
                              <th>{t("Interest Type")}</th>
                              <th>{t("Loan No")}.</th>
                              <th>{t("Action")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {datas.length > 0 && (
                              <>
                                {datas.map((field, index) => (
                                  <tr key={index}>
                                    <td>
                                      <Form.Label className="myform-label">{field.person.s_FullLegalName}</Form.Label>
                                    </td>
                                    {/* {interestOptionsDDForShowing.filter((item) => item.value === field.s_PersonType)[0].label} */}
                                    <td>
                                      <Form.Label className="myform-label">{interestOptionsDDForShowing.filter((item) => item.value === field.s_PersonType)[0].label}</Form.Label>
                                    </td>
                                    <td>
                                      <Form.Label className="myform-label">{field.s_LoanNo}</Form.Label>
                                    </td>
                                    <td className="add-mortage-action-row">
                                      <div className="add-mortage-action">
                                        <img
                                          src={delIcon}
                                          onClick={() => handleRemoveFields(index)}
                                        />&nbsp;&nbsp;
                                        <FaEdit
                                          size={25}
                                          color={"#64D6CD"}
                                          style={{ cursor: "pointer" }}
                                          onClick={() => handleEditFields(index)}
                                          // onClick={() => setStatusModal(true)}
                                        /> 
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </>
                            )}
                          </tbody>
                          <tfoot>

                          </tfoot>
                        </Table>
                        <Form.Label style={{ color: 'red' }}>{errorMsg}</Form.Label>
                      </div>
                    </div>
                  </Col>
                </Row>
            </div>
        </div>
        <Modal
        className="custom-dashboard-modal"
        size="lg"
        show={showModal}
        onHide={() => { setShowModal(false);
          setEditMode(false);
          setEditIndex(null);
          clearData();
        }}
      >
        <Modal.Header closeButton className="custom-dashboard-modal-header">
          <Modal.Title>{ editMode ? 'Update Interest' : 'Add Interest' }</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="custom-dashboard-modal-body"
          style={{ paddingTop: "40px", paddingBottom: "40px" }}
        >
          <Form validated={validated}>
                <Row>
                  <Col xs="12" sm="12" md="12" lg="6" xl="6">
                    <Form.Select className="myform-input custom-select-autocomplete"
                      onChange={(e) => {
                        setInterestValueFunc(e.target.value);
                        setSelectInterestFunc(e.target.value);
                        setData({});
                        setInputDatas({});
                        clearDataOnChange();
                      }}
                      value={ selectInterest }
                      disabled = { editMode }
                      required
                    >
                     { editMode == true ?
                        interestOptionsDDForShowing.map((v, i) => {
                          return <option className="myform-input custom-select-autocomplete" key={i} value={v.value} >{v.label}</option>
                        })
                      :
                        interestOptions.map((v, i) => {
                          return <option className="myform-input custom-select-autocomplete" key={i} value={v.value} >{v.label}</option>
                        })
                      }
                    </Form.Select>
                  </Col>
                </Row>
                <br></br>
                {(selectInterest === "PRIMARY" ||
                  selectInterest === "SECOND" ||
                  selectInterest === "THIRD") && (
                    <Row>
                      <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Form.Group
                          className="account-form-group"
                          controlId="formBasicEmail"
                        >
                          <Typeahead
                          id="select"
                            className="is-invalid"
                            onChange={(e) => {
                              handleOnChange(e)
                            }}
                            onInputChange={(e) => { handleChange(e) }}
                            options={options}
                            placeholder="Search name..."
                            value={selectedName}
                            inputProps={{ required: true }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                <Row>
                  {selectInterest === "ADDITIONALINSURED" ||
                    selectInterest === "ADDITIONALINTEREST" ? (
                    <Fragment>
                      <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                          controlId="formBasicEmail"
                          className="add-mortage-form-group"
                        >
                          <Form.Label className="myform-label">First Name</Form.Label>
                          <Form.Control
                            className="myform-input"
                            type="text"
                            placeholder=""
                            name="firstName"
                            value={data.firstName}
                            onChange={(e) => handleInputDataChange(e)}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                          controlId="formBasicEmail"
                          className="add-mortage-form-group"
                        >
                          <Form.Label className="myform-label">
                            Middle Name
                          </Form.Label>
                          <Form.Control
                            className="myform-input"
                            type="text"
                            placeholder=""
                            name="middleName"
                            value={data.middleName}
                            onChange={(e) => handleInputDataChange(e)}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                          controlId="formBasicEmail"
                          className="add-mortage-form-group"
                        >
                          <Form.Label className="myform-label">Last Name</Form.Label>
                          <Form.Control
                            className="myform-input"
                            type="text"
                            placeholder=""
                            name="lastName"
                            value={data.lastName}
                            onChange={(e) => handleInputDataChange(e)}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Fragment>
                  ) : (
                    <Col xs="12">
                      <Form.Group
                        controlId="formBasicEmail"
                        className="add-mortage-form-group"
                      >
                        <Form.Label className="myform-label">Name</Form.Label>
                        <Form.Control
                          className="myform-input"
                          type="text"
                          placeholder=""
                          disabled
                          name="name"
                          value={data.fullname}
                          onChange={(e) => handleInputDataChange(e)}
                        />
                      </Form.Group>
                    </Col>
                  )}
                  {selectInterest === "ADDITIONALINTEREST" && (
                    <Col xs="12">
                      <Row>
                        <Col xs="12" sm="12" md="12" lg="6" xl="6">
                          <Form.Group
                            controlId="formBasicEmail"
                            className="add-mortage-form-group"
                          >
                            <Form.Label className="myform-label">Type</Form.Label>
                            <Form.Select
                              className="myform-input"
                              name="type"
                              value={data.type}
                              onChange={(e) => handleInputDataChange(e)}
                              required
                            >
                              <option value="">Select</option>
                              <option value="PROPMGR">Property Manager</option>
                              <option value="OTHERS">Others</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  )}

                  {selectInterest === "ADDITIONALINSURED" ||
                    selectInterest === "ADDITIONALINTEREST" ? (
                    <Fragment>
                      <Col xs="12">
                        <Form.Group
                          controlId="formBasicEmail"
                          className="add-mortage-form-group"
                        >
                          <Form.Label className="myform-label">
                            Mailing Address 1{" "}
                          </Form.Label>
                          <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            selectProps={{
                              styles: customStyles,
                              inputValue: streetName,
                              value: googlePlace,
                              onInputChange: handleGooglAutoComplete,
                              onChange: setSearchProperty,
                              name: "googlePlacesStreetName",
                              id: "googlePlacesStreetName",
                              placeholder: "Enter a location",
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Col xs="12">
                        <Form.Group
                          controlId="formBasicEmail"
                          className="add-mortage-form-group"
                        >
                          <Form.Label className="myform-label">
                            Mailing Address 1{" "}
                          </Form.Label>
                          <Form.Control
                            className="myform-input"
                            type="text"
                            placeholder=""
                            name="mailAddress1"
                            value={data.mailAddress1}
                            onChange={(e) => handleInputDataChange(e)}
                            disabled={
                              selectInterest === "PRIMARY" ||
                              selectInterest === "SECOND" ||
                              selectInterest === "THIRD"
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Fragment>
                  )}
                  <Col xs="12" sm="12" md="4" lg="4" xl="4">
                    <Form.Group
                      controlId="formBasicEmail"
                      className="add-mortage-form-group"
                    >
                      <Form.Label className="myform-label">City</Form.Label>
                      <Form.Control
                        className="myform-input"
                        type="text"
                        placeholder=""
                        disabled={
                          selectInterest === "PRIMARY" ||
                          selectInterest === "SECOND" ||
                          selectInterest === "THIRD" ||
                          selectInterest === "ADDITIONALINSURED" ||
                          selectInterest === "ADDITIONALINTEREST"
                        }
                        name="city"
                        value={data.city}
                        onChange={(e) => handleInputDataChange(e)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" md="4" lg="4" xl="4">
                    <Form.Group
                      controlId="formBasicEmail"
                      className="add-mortage-form-group"
                    >
                      <Form.Label className="myform-label">State</Form.Label>
                      <Form.Control
                        className="myform-input"
                        type="text"
                        placeholder=""
                        disabled={
                          selectInterest === "PRIMARY" ||
                          selectInterest === "SECOND" ||
                          selectInterest === "THIRD" ||
                          selectInterest === "ADDITIONALINSURED" ||
                          selectInterest === "ADDITIONALINTEREST"
                        }
                        name="state"
                        value={data.state}
                        onChange={(e) => handleInputDataChange(e)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" md="4" lg="4" xl="4">
                    {selectInterest === "ADDITIONALINSURED" ||
                      selectInterest === "ADDITIONALINTEREST" ? (
                      <Fragment>
                        <Form.Group
                          controlId="formBasicEmail"
                          className="add-mortage-form-group"
                        >
                          <Form.Label className="myform-label">Zip Code</Form.Label>
                          <Form.Control
                            className="myform-input"
                            type="text"
                            placeholder=""
                            name="zipCode"
                            value={zipCode}
                            onChange={(e) => changeZip(e)}
                            onBlur={() => zipCodeApi()}
                            disabled={true}

                          />
                        </Form.Group>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Form.Group
                          controlId="formBasicEmail"
                          className="add-mortage-form-group"
                        >
                          <Form.Label className="myform-label">Zip Code</Form.Label>
                          <Form.Control
                            className="myform-input"
                            type="text"
                            placeholder=""
                            disabled={
                              selectInterest === "PRIMARY" ||
                              selectInterest === "SECOND" ||
                              selectInterest === "THIRD"
                            }
                            name="zipCode"
                            value={data.zipCode}
                            onChange={(e) => handleInputDataChange(e)}
                          // disabled={true}
                          />
                        </Form.Group>
                      </Fragment>
                    )}
                  </Col>
                  <Col xs="12" sm="12" md="4" lg="4" xl="4">
                    {selectInterest === "PRIMARY" || selectInterest === "SECOND" || selectInterest === "THIRD" ? (
                      <Form.Group
                        controlId="formBasicEmail"
                        className="add-mortage-form-group"
                      >
                        <Form.Label className="myform-label">Loan Number</Form.Label>
                        <Form.Control
                          className="myform-input"
                          type="text"
                          placeholder=""
                          name="s_LoanNo"
                          value={data.s_LoanNo}
                          onChange={(e) => handleInputDataChange(e)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                        {t("Please Enter Loan Number Error")}
                        </Form.Control.Feedback>
                      </Form.Group>
                    ) : null}
                  </Col>
                </Row>
              </Form>
        </Modal.Body>
        <Modal.Footer className="add-mortage-modal-footer">
              <Button
                id="common-btn"
                onClick={() => clearData() }
                className="mybtn-light"
                style={{
                  marginRight: "1rem",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                Cancel
              </Button>

              <Button
                id="common-btn"
                onClick={() => handleSaveInterest()}
                disabled={loadingStatus}
                className="mybtn"
                style={{ paddingLeft: "20px", paddingRight: "20px" }}
                variant="primary"
              >
                Save
              </Button>
            </Modal.Footer>
      </Modal>
    </Fragment>
  );
};


function mapStateToProps(state) {
	return {
	  	quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		  getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	};
  } 
  export default connect(mapStateToProps)(PolicyInterests);
