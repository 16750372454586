import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import Validation from "../../Validation";
import Select from 'react-select';
import alertImg from "../../../../../../assets/flood/question-mark.png";
import { getEditEmptyQuoteData, updateInitQuoteApi, pivotApiCall } from "../../../../../../services/floodService";
import { customStyles } from "../../../../../../components/reactSelectCustomStyle";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "./discount.scss";
import "react-overlay-loader/styles.css";

const DiscountFormOne = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { emptyQuoteData, initApiObject, apiLoading, quoteResponseDetails, floodSystemDD } = useSelector(state => state.flood);
  const [showOptions, setShowOptions] = useState(false);
  const [doVerify, setDoVerify] = useState(false);
  const [validated, setValidated] = useState(false);
  const [verified, setVerified] = useState(false);
  const [isNewPurchase, setIsNewPurchase] = useState('NO');
  const [priorPolicyNo, setPriorPolicyNo] = useState(null);
  const [priorCompanyNo, setPriorCompanyNo] = useState(null);
  const [isStateSet, setIsStateSet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [NAICList, setNAICList] = useState({});

  // set Initial State from reducer and get NAIC
  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0) {
      setIsNewPurchase(emptyQuoteData.propertyInfo.s_IsNewPurchase);
      setPriorPolicyNo(emptyQuoteData.propertyInfo.s_PriorPolicyNo);
      setPriorCompanyNo(emptyQuoteData.propertyInfo.s_PriorCompanyNAIC);
      setIsStateSet(true);
      // check reducer state 
      if (Object.keys(floodSystemDD).length > 0) {
        setNAICList(floodSystemDD.WYOList);
      }
    }
  }, []);

  const handleChange = (e) => {
    var quoteDataVar = emptyQuoteData;
    quoteDataVar['propertyInfo']['s_PriorCompanyNAIC'] = e.s_NAICNumber;
    setPriorCompanyNo(e.s_NAICNumber);
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  useEffect(() => {
    if (isStateSet === true) {
      if (emptyQuoteData && initApiObject && doVerify) {
        setLoading(true);
        const callPivot = async () => {
          const reqJson = { ...initApiObject }; // Copy
          reqJson.isNewPurchase = isNewPurchase;
          reqJson.priorPolicyNumber = priorPolicyNo;
          reqJson.priorNaicNumber = priorCompanyNo;
          reqJson.whichSection = 'DISCOUNT';

          // To update
          dispatch(updateInitQuoteApi(reqJson));

          const code = 'QUOTE_NEWBUSINESS';
          const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0 };
          // Call Api
          const resp = await dispatch(pivotApiCall(code, request));
          if (resp.success && resp.data && resp.data.code === 201) {
            setVerified(true);
          } else {
            setVerified(false);
          }
          setLoading(false);
        }
        callPivot();
      }
    }
  }, [doVerify]);

  /*useEffect(() => {
    if (emptyQuoteData && initApiObject && priorPolicyNo !== "" && priorCompanyNo !== "" && doVerify) {
      verifyPolicy();
    }
  }, [doVerify]);

  // To Verify Prior Policy With NAIC Number
  const verifyPolicy = async () => {
    const reqJson = { policyNumber: priorPolicyNo, naicNumber: priorCompanyNo, 'uniqueKey': localStorage.getItem('uniqueKey') };

    const code = 'GET_POLICY';
    const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0 };
    // Call Api
    const res = await dispatch(pivotApiCall(code, request));
    if (res.success && res.data && res.data.quoteUuid && res.data.id) {
      setVerified(true);
      toast.success(t('Prior Policy Verified!'));

      const reqJson = { ...initApiObject }; // Copy
      reqJson.isNewPurchase = isNewPurchase;
      reqJson.priorPolicyNumber = priorPolicyNo;
      reqJson.priorNaicNumber = priorCompanyNo;
      reqJson.whichSection = 'DISCOUNT';

      // To update
      dispatch(updateInitQuoteApi(reqJson));

      const emptyQuote = { ...emptyQuoteData }; // Copy
      emptyQuote.propertyInfo.s_IsPriorNFIPPolicy = 'YES';

      dispatch(getEditEmptyQuoteData(emptyQuote));
    } else {
      setVerified(false);

      const emptyQuote = { ...emptyQuoteData }; // Copy
      emptyQuote.propertyInfo.s_PriorPolicyNo = '';
      emptyQuote.propertyInfo.s_PriorCompanyNAIC = '';
      emptyQuote.propertyInfo.s_IsPriorNFIPPolicy = '';
      emptyQuote.propertyInfo.s_IsPriorNFIPLapsed = '';
      emptyQuote.propertyInfo.s_IsCommunitySuspension = '';

      dispatch(getEditEmptyQuoteData(emptyQuote));
    }
  }*/

  useEffect(() => {
    doVerify && setDoVerify(false);
  }, [quoteResponseDetails]);

  useEffect(() => {
    if (isStateSet === true) {
      if (isNewPurchase !== 'YES' || priorPolicyNo === "" || priorCompanyNo === "") {
        var tempPriorPolicyNo = '';
        var tempPriorCompanyNo = '';
        if (isNewPurchase === 'YES') {
          tempPriorPolicyNo = priorPolicyNo;
          tempPriorCompanyNo = priorCompanyNo;
        }
        const emptyQuote = { ...emptyQuoteData }; // Copy
        emptyQuote.propertyInfo.s_PriorPolicyNo = tempPriorPolicyNo;
        emptyQuote.propertyInfo.s_PriorCompanyNAIC = tempPriorCompanyNo;
        emptyQuote.propertyInfo.s_IsPriorNFIPPolicy = '';
        emptyQuote.propertyInfo.s_IsPriorNFIPLapsed = '';
        emptyQuote.propertyInfo.s_IsCommunitySuspension = '';

        dispatch(getEditEmptyQuoteData(emptyQuote));

        const reqJson = { ...initApiObject }; // Copy
        delete reqJson.priorPolicyNumber;
        delete reqJson.s_PriorCompanyNAIC;
        reqJson.whichSection = 'DISCOUNT';

        // To update
        dispatch(updateInitQuoteApi(reqJson));

        setVerified(false);
      }
    }
  }, [isNewPurchase, priorPolicyNo, priorCompanyNo]);

  const toggle = (e) => {
    setValidated(e);
  }

  // handle switch Change
  const handleSwitchChange = (e) => {
    var temp = 'NO';
    const { attributes, checked } = e.target;
    if (checked) {
      temp = attributes.checkedvalue.value;
    }
    setIsNewPurchase(temp);
    props.handleSwitchChange(e);
  }

  return (
    <LoadingOverlay >
      <Loader loading={apiLoading || loading} />
      <section className="flood-discount-section">
        {
          Object.keys(emptyQuoteData).length === 0 && <Navigate to={'/flood/instantquote/step/address'} />
        }
        {/* Form */}
        <Row className="justify-content-center">
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="8"
            xl="8"
            className="flood-discount-form-section"
          >
            <Row className="justify-content-center">
              <Col xs="11">
                <div className="flood-discount-header">
                  <div className="flood-discount-title">
                    <h4 className="flood-discount-main-title">{t('Discounts')}</h4>
                    <p className="flood-discount-main-desc">
                      {t('Watch your premium drop as you add your discounts')}
                    </p>
                  </div>
                  <div className="flood-discount-pricing">
                    <h5 className="flood-discount-pricing-title">
                      {t('Current Premium')}
                    </h5>
                    <p className="flood-discount-pricing-value">
                      {'$' + props.premium}<span>/{t('Year')}</span>
                    </p>
                  </div>
                </div>
                <Row className="mb-4">
                  {isNewPurchase !== 'YES' && (
                    <Col xs="12">
                      <div className="discount-house-check-icon"></div>
                    </Col>
                  )}
                  <Col xs="12">
                    <div
                      className="flood-discount-row"
                      id="flood-discount-one-row"
                    >
                      <div className="flood-discount-question-div">
                        <p>
                          {t('Do you have a prior policy or is this a new purchase')}?
                        </p>
                        <div className="flood-discount-row-actions">
                          <span>{t('No')}</span>
                          <div className="custom-main-swtich">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              className="flood-discount-row-switch"
                              label=""
                              name="s_IsNewPurchase"
                              parentobject="propertyInfo"
                              checked={isNewPurchase === "YES" ? true : false}
                              onChange={(e) => { setShowOptions(!showOptions); handleSwitchChange(e); }}
                              checkedvalue='YES'
                              uncheckedvalue='NO'
                            />
                          </div>
                          <span>{t('Yes')}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12">
                    <Alert variant="primary" className="discount-form-alert">
                      <img src={alertImg} />
                      <p className="discount-form-alert-text">
                        {" "}
                        {t("You may qualify for a statutory discount if the prior owner had a National Flood Insurance Program (NFIP) policy that had statutory discounts applied. In order to obtain any applicable discounts, please enter the prior owner's policy information.")}.
                      </p>
                    </Alert>
                  </Col>
                  {isNewPurchase === 'YES' && (
                    <Form validated={validated}>
                      <Row>
                        <Col xs="12">
                          <Row>
                            <Col
                              xs="12"
                              sm="6"
                              md="4"
                              lg="4"
                              xl="4"
                              id="discount-one-fields"
                            >
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label
                                  className="myform-label"
                                  id="discount-form-policy-label"
                                >
                                  {t('Policy NO#')}
                                </Form.Label>
                                <Form.Control
                                  className="myform-input"
                                  type="number"
                                  placeholder="0000000000"
                                  name="s_PriorPolicyNo"
                                  title={t('Policy NO#')}
                                  parentobject="propertyInfo"
                                  value={priorPolicyNo || ''}
                                  min={0}
                                  onChange={(e) => {
                                    props.handleChange(e);
                                    setPriorPolicyNo(e.currentTarget.value);
                                  }}
                                  required={isNewPurchase === "YES" ? true : false}
                                />
                              </Form.Group>
                              <Form.Control.Feedback type="invalid">
                                {t("Enter Policy NO")}
                              </Form.Control.Feedback>
                            </Col>
                            <Col xs="12" sm="6" md="8" lg="8" xl="8">
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label
                                  className="myform-label"
                                  id="discount-form-policy-label"
                                >
                                  {t('Prior Company NAIC')}
                                </Form.Label>
                                {
                                  Object.keys(NAICList).length > 0 && (
                                    <Select
                                      styles={customStyles}
                                      title="propertyInfo"
                                      name="s_PriorCompanyNAIC"
                                      options={floodSystemDD.WYOList}
                                      value={NAICList.filter(item => item.s_NAICNumber === priorCompanyNo)}
                                      getOptionLabel={(option) => option.s_WYOCompany}
                                      getOptionValue={(option) => option.s_NAICNumber}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setPriorCompanyNo(e.currentTarget.value);
                                      }}
                                      onBlur={() => setDoVerify(true)}
                                    />
                                  )
                                }
                              </Form.Group>
                            </Col>

                            {/* <Col xs="12" className="discount-footer-btn">
                                <Button type="button" variant="outline-dark" className="previous-btn" onClick={() => setDoVerify(true)}>
                                  {t('Verify')}
                                </Button>
                              </Col> */}
                          </Row>
                        </Col >
                      </Row>
                    </Form>
                  )}
                </Row>
                <Row>
                  <Col xs="12" className="discount-two-form-btns">
                    <Validation currentStep={8} toggle={toggle} verified={verified} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </LoadingOverlay >
  );
};

export default DiscountFormOne;
