import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./policy-details.scss";
import { Row, Col, Form, Table, Button } from "react-bootstrap";
import CommonHeader from "./CommonHeader";
import { useTranslation } from "react-i18next";
import { saveEmail } from "../../../services/coreSystemService";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

function ApplicationTab(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [applicantData, setApplicantData] = useState({});
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setApplicantData(props.applicationData);
    if (Object.keys(props.applicationData).length > 0 && props.applicationData.locationAddr) {
      setEmail(props.applicationData.locationAddr.s_EmailAddress || '');
    }
  }, [props.applicationData]);

  const setEmailAction = async () => {
    const params = {
      personID: applicantData.insuredID,
      personAddrID: applicantData.locationAddr.n_PersonAddressesId_PK,
      policyNo: applicantData.Policy_No,
      email: email
    }
    setLoading(true);
    await dispatch(saveEmail(params)).then((res) => {
      if (res.status === 'Y') {
        toast.success(res.msg);
      } else {
        toast.error(res.errors.email[0]);
      }
    });
    setLoading(false);
  };

  return (
    <Row>
      <Col xs="12" className="billing-tab-row1">
        <CommonHeader />
      </Col>

      <Col xs="12" id="policy-details-tab1-col1" style={{ marginTop: "24px" }}>
        <Row>
          <Col xs="12" sm="12" md="12" lg="12" xl="6">
            <Table borderless className="policy-details-info-table">
              <tbody>
                <tr>
                  <th>{t('Agency Name')}</th>
                  <td>
                    {Object.keys(applicantData).length > 0 &&
                      applicantData.agencyData.agencyName}
                  </td>
                </tr>
                <tr>
                  <th>{t('Producer Name')}</th>
                  <td>
                    {Object.keys(applicantData).length > 0 &&
                      applicantData.agencyData.subAgentName}
                  </td>
                </tr>
                <tr>
                  <th>{t('Agency Address')}</th>
                  <td>
                    {Object.keys(applicantData).length > 0 &&
                      applicantData.agencyData.s_AddressLine1 +
                      " " +
                      Object.keys(applicantData).length >
                      0 &&
                      applicantData.agencyData.s_CityName +
                      " " +
                      Object.keys(applicantData).length >
                      0 &&
                      applicantData.agencyData.s_StateCode +
                      " " +
                      Object.keys(applicantData).length >
                      0 &&
                      applicantData.agencyData.s_PostalCode}
                  </td>
                </tr>
                <tr>
                  <th>{t('Agency Phone')} #</th>
                  <td>
                    {Object.keys(applicantData).length > 0 &&
                      applicantData.agencyData.phone}
                  </td>
                </tr>
                <tr>
                  <th>{t('Agency Email')}</th>
                  <td>
                    {Object.keys(applicantData).length > 0 &&
                      applicantData.agencyData.s_EmailAddress}
                  </td>
                </tr>
                <tr>
                  <th>{t('Insured Name')}</th>
                  <td>
                    {Object.keys(applicantData).length > 0 &&
                      applicantData.s_FullLegalName}
                  </td>
                </tr>
                <tr>
                  <th>{t('Name(s) On Dec')}</th>
                  <td>
                    {Object.keys(applicantData).length > 0 &&
                      applicantData.s_FullLegalName}
                  </td>
                </tr>
                <tr>
                  <th>{t('Policy Address')}</th>
                  <td>
                    {Object.keys(applicantData).length > 0 &&
                      applicantData.locationAddr.fullAddress}
                  </td>
                </tr>
                <tr>
                  <th>{t('Prior Address')}</th>
                  <td>
                    {Object.keys(applicantData).length > 0 &&
                      Object.keys(applicantData.priorAddr).length > 0 &&
                      applicantData.priorAddr.fullAddress}
                  </td>
                </tr>

                <tr>
                  <th>{t('Insured Phone')} #</th>
                  <td>
                    {Object.keys(applicantData).length > 0 &&
                      applicantData.locationAddr.s_PhoneNumber}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col xs="12" sm="12" md="12" lg="12" xl="6">
            <Table borderless className="policy-details-info-table">
              <tbody>
                <tr>
                  <th>{t('Agency Code')}</th>
                  <td>
                    {Object.keys(applicantData).length > 0 &&
                      applicantData.agencyData.s_PersonUniqueId}
                  </td>
                </tr>
                <tr>
                  <th>{t('Agency Fax')} #</th>
                  <td>
                    {Object.keys(applicantData).length > 0 &&
                      applicantData.agencyData.fax}
                  </td>
                </tr>
                <tr>
                  <th>{t('DOB')}</th>
                  <td>
                    {Object.keys(applicantData).length > 0 &&
                      applicantData.d_BirthDate}
                  </td>
                </tr>
                <tr>
                  <th>{t('Occupation')}</th>
                  <td>
                    {Object.keys(applicantData).length > 0 &&
                      applicantData.s_OccupationDesc}
                  </td>
                </tr>
                <tr>
                  <th>{t('Mailing Address')}</th>

                  <td>
                    {Object.keys(applicantData).length > 0 &&
                      Object.keys(applicantData.mailingAddr).length > 0 &&
                      applicantData.mailingAddr.fullAddress}
                  </td>
                </tr>
                <tr>
                  <th>{t('Email')}</th>
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Form.Control
                      className="dashboard-group-input"
                      type="email"
                      placeholder="Enter email"
                      style={{
                        width: 250,
                        borderBottomWidth: "1px",
                        marginRight: "10px",
                      }}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={loading}
                    />
                    <Button id="common-btn" size="sm" variant="primary" onClick={() => setEmailAction()} style={{ padding: "2px 8px" }} disabled={loading}>
                      {email !== '' ? t('Update') : t('Save')}
                    </Button>{" "}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    applicationData: state.coreSystem.applicationData,
  };
};
export default connect(mapStateToProps)(ApplicationTab);
