import axios from 'axios';
import store from './store';
import * as actions from './store/actions';

const token = localStorage.getItem('access_token') || '';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common.Authorization = `Bearer ${token}`;

axios.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(actions.authLogout());
      window.location = "/login";
    }
    return Promise.reject(error);
  },
);

export default axios;
