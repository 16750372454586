import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Navigate } from "react-router-dom";
import { updateInitQuoteApi, pivotApiCall, updateWhichSection, updateLastSection } from "../../../../../../services/floodService";
import Validation from "../../Validation";
import "react-overlay-loader/styles.css";
import "./construction.scss";

const ConstructionFormTwo = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { emptyQuoteData, initApiObject, apiLoading, whichSection, lastSection } = useSelector(state => state.flood);
  const currentStep = 6;
  const [buildingOverWater, setBuildingOverWater] = useState('');
  const [noOfElevator, setNoOfElevator] = useState('');
  const [noOfDetatchedStruct, setNoOfDetatchedStruct] = useState('');
  const [isBldgContainME, setIsBldgContainME] = useState('');
  const [allMachinaryElevated, setAllMachinaryElevated] = useState('NO');
  const [isStateSet, setIsStateSet] = useState(false);

  // set Initial State from reducer
  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0) {
      setBuildingOverWater(emptyQuoteData.propertyInfo.s_BuildingOverWater);
      setNoOfElevator(emptyQuoteData.propertyInfo.n_NoOfElevator);
      setNoOfDetatchedStruct(emptyQuoteData.propertyInfo.n_NoOfDetatchedStruct);
      setIsBldgContainME(emptyQuoteData.propertyInfo.s_IsBldgContainME);
      setAllMachinaryElevated(emptyQuoteData.propertyInfo.s_AllMachinaryElevated);
      setIsStateSet(true);
    }
  }, [])

  useEffect(() => {
    if (isStateSet === true) {
      if (emptyQuoteData && initApiObject) {
        const reqJson = { ...initApiObject }; // Copy
        reqJson.buildingOverWaterType = buildingOverWater;
        reqJson.machineryOrEquipmentAbove = allMachinaryElevated;
        // To update step
        if (currentStep > lastSection) {
          dispatch(updateWhichSection('SECOND'));
          dispatch(updateLastSection(currentStep));
        }

        reqJson.whichSection = whichSection;

        // To update
        dispatch(updateInitQuoteApi(reqJson));

        const code = 'QUOTE_NEWBUSINESS';
        const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0 };
        // Call Api
        dispatch(pivotApiCall(code, request));
      }
    }
  }, [buildingOverWater, allMachinaryElevated]);

  // handle radio Change
  const handleChange = (e, state) => {
    var temp = 'NO';
    const { value, attributes, checked } = e.target;
    if (checked && ['isBldgContainME', 'allMachinaryElevated'].includes(state)) {
      temp = attributes.checkedvalue.value;
    }

    switch (state) {
      case 'buildingOverWater':
        setBuildingOverWater(value);
        break;
      case 'isBldgContainME':
        setIsBldgContainME(temp);
        break;
      case 'allMachinaryElevated':
        setAllMachinaryElevated(temp);
        break;
      default:
        break;
    }

    if (['isBldgContainME', 'allMachinaryElevated'].includes(state)) {
      props.handleSwitchChange(e);
    } else {
      props.handleRadioChange(e);
    }
  }

  return (
    <LoadingOverlay >
      <Loader loading={apiLoading} />
      <section className="flood-construction-section">
        {
          Object.keys(emptyQuoteData).length === 0 && <Navigate to={'/flood/instantquote/step/address'} />
        }
        {/* Form */}
        <Row className="justify-content-center">
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="8"
            xl="8"
            className="flood-construction-form-section"
          >
            <Row className="justify-content-center">
              <Col xs="11" sm="10" md="10" lg="10" xl="10">
                <div className="flood-construction-header">
                  <div className="flood-construction-title">
                    <h4 className="flood-construction-main-title">
                      {t('Construction')}
                    </h4>
                    <p className="flood-construction-main-desc">
                      {t('Please answer all the construction related questions:')}.
                    </p>
                  </div>
                  <div className="flood-construction-pricing">
                    <h5 className="flood-construction-pricing-title">
                      {t('Current Premium')}
                    </h5>
                    <p className="flood-construction-pricing-value">
                      {'$' + props.premium}<span>/{t('Year')}</span>
                    </p>
                  </div>
                </div>
                <Row>
                  <Col xs="12">
                    <div className="flood-construction2-row">
                      <div
                        className="flood-construction2-question-div"
                        id="contruction-two-div"
                      >
                        <p>{t('Is the building over water')}?</p>
                        <div className="flood-construction2-row-actions">
                          <Form.Check
                            type="radio"
                            id="propertyInfo"
                            name="s_BuildingOverWater"
                            label={t('No')}
                            className="custom-radio-check0"
                            style={{ marginRight: "24px" }}
                            title="propertyInfo"
                            value={'NOTOVERWATER'}
                            checked={buildingOverWater === "NOTOVERWATER" ? true : false}
                            onChange={(e) => handleChange(e, 'buildingOverWater')}
                          />
                          <Form.Check
                            type="radio"
                            id="propertyInfo"
                            name="s_BuildingOverWater"
                            label={t('Partially')}
                            className="custom-radio-check0"
                            style={{ marginRight: "24px" }}
                            title="propertyInfo"
                            value={'PARTIALLYOVERWATER'}
                            checked={buildingOverWater === "PARTIALLYOVERWATER" ? true : false}
                            onChange={(e) => handleChange(e, 'buildingOverWater')}
                          />
                          <Form.Check
                            type="radio"
                            id="propertyInfo"
                            name="s_BuildingOverWater"
                            label={t('Fully')}
                            className="custom-radio-check0"
                            title="propertyInfo"
                            value={'FULLOVERWATER'}
                            checked={buildingOverWater === "FULLOVERWATER" ? true : false}
                            onChange={(e) => handleChange(e, 'buildingOverWater')}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="flood-construction2-row">
                      <div
                        className="flood-construction2-question-div"
                        id="contruction-two-fields"
                      >
                        <p>{t('Number of Elevators')}</p>
                        <div className="flood-construction2-row-actions">
                          <Form.Control
                            className="myform-input"
                            type="number"
                            placeholder="0000"
                            id="flood-constructon-input"
                            name="n_NoOfElevator"
                            title={t('Number of Elevators')}
                            parentobject="propertyInfo"
                            value={noOfElevator || ''}
                            min={0}
                            onChange={(e) => {
                              props.handleChange(e);
                              setNoOfElevator(e.currentTarget.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="flood-construction2-row">
                      <div
                        className="flood-construction2-question-div"
                        id="contruction-two-fields"
                      >
                        <p>{t('Number of Detached Structures')}?</p>
                        <div className="flood-construction2-row-actions">
                          <Form.Control
                            className="myform-input"
                            type="number"
                            placeholder="0000"
                            id="flood-constructon-input"
                            name="n_NoOfDetatchedStruct"
                            title={t('Number of Detached Structures')}
                            parentobject="propertyInfo"
                            value={noOfDetatchedStruct || ''}
                            min={0}
                            onChange={(e) => {
                              props.handleChange(e);
                              setNoOfDetatchedStruct(e.currentTarget.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="flood-construction-row">
                      <div className="flood-construction-question-div">
                        <p>
                          {t('Does the building contain Machinery and equipment that services the building')}?
                        </p>
                        <div className="flood-construction-row-actions">
                          <span>{t('No')}</span>
                          <div className="custom-main-swtich">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              className="flood-construction-row-switch"
                              label=""
                              parentobject="propertyInfo"
                              name="s_IsBldgContainME"
                              checked={isBldgContainME === 'YES' ? true : false}
                              onChange={(e) => handleChange(e, 'isBldgContainME')}
                              checkedvalue='YES'
                              uncheckedvalue='NO'
                            />
                          </div>
                          <span>{t('Yes')}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {isBldgContainME === 'YES' && (
                    <Col xs="12">
                      <div className="flood-construction-row">
                        <div className="flood-construction-question-div">
                          <p>
                            {t('Is all machinery elevated to the height of attic, second floor, or higher')}?
                          </p>
                          <div className="flood-construction-row-actions">
                            <span>{t('No')}</span>
                            <div className="custom-main-swtich">
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                className="flood-construction-row-switch"
                                label=""
                                parentobject="propertyInfo"
                                name="s_AllMachinaryElevated"
                                checked={allMachinaryElevated === 'YES' ? true : false}
                                onChange={(e) => handleChange(e, 'allMachinaryElevated')}
                                checkedvalue='YES'
                                uncheckedvalue='NO'
                              />
                            </div>
                            <span>{t('Yes')}</span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col xs="12" className="form-btns">
                    <Validation currentStep={6} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </LoadingOverlay>
  );
};

export default ConstructionFormTwo;
