import React, { Fragment, useState,useEffect } from "react";
import { Button, Row, Col, Form, Table, Accordion, Modal } from "react-bootstrap";
import basicBadge from "../../../../../assets/badges/basic.png";
import optionalBadge from "../../../../../assets/badges/optional.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MapComponent from "../../../../../components/MapComponent";
import { useDispatch, useSelector , connect } from 'react-redux';
import { getZipDetails,getEditEmptyQuoteData, getPersonAddrWithMapData } from "../../../../../services/quoteService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "./address.scss";
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";

const ApplicantInfo = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [date1, setDate1] = useState(new Date());
  const [onLoad, setOnLoad] = useState(true);

  //Applicant Information
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nameOnDec, setNameOnDec] = useState("");
  
  //Zip 
  const [quoteData, setQuoteData] = useState(props.quoteData);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [showModal, setShowModal] = useState(false);
  
  const [zipCode, setZipCode] = useState();
  const [zipData, setZipData] = useState([]);
  
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  
  
  const changeZip = (e) => {
    setZipCode(e.target.value);
  }
  
  useEffect(() => {
	setZipCode(props.quoteData.personAddr.s_PostalCode);
	setLat(props.quoteData.personAddr.s_Latitude);
	setLong(props.quoteData.personAddr.s_Longitude);
  }, []);
  
  
    useEffect(() => {
		dispatch(getEditEmptyQuoteData(quoteData));
		//Applicant Name
		setFirstName(props.quoteData.personInfo.s_FirstName);
		setMiddleName(props.quoteData.personInfo.s_MiddleName);
		setLastName(props.quoteData.personInfo.s_LastOrganizationName);
		setNameOnDec(props.quoteData.personInfo.s_FullLegalName);
	}, [quoteData]);

	useEffect(() => {
		var first = "";
		if(firstName != null){
			first = firstName;
		}
		var last = "";
		if(lastName != null){
			last = lastName;
		}
        var fullName = "";
		fullName = first + ' ' + last;
        if(onLoad != true){
			setNameOnDec(fullName);
		}
    }, [firstName, lastName])

  
  const zipCodeApi = () => {
    if (props.quoteData.personAddr.s_PostalCode != zipCode &&
		zipCode != '' && 
		zipCode >= 1) {
		
		setLoadingStatus(true);
        dispatch(getZipDetails('13', zipCode, 'APPLICANT')).then((res) => {
		  
		
			if(res.data.status == 'Y'){
				if(res.data.count > 1) {
					  	setZipData(res.data.zipData);
						setLoadingStatus(false);
						setShowModal(true);  
				}else{
				  setZipDetailsData(res.data.zipData[0]);
				  setLoadingStatus(false);
				}
						  
			}else{
			  setLoadingStatus(false);
			  toast.error(res.data.errorMsg);
			  setZipCode('');
			}
		
		
      });
    }
  }
  
  const setZipDetailsData = (data) => {
	var quoteDataVar = props.quoteData;
	const tempArr = props.quoteData;
	var zipAddrFor = props.zipAddrFor;
  	tempArr[zipAddrFor]['s_CountyName'] = data.s_ZipCounty;
	quoteDataVar[zipAddrFor]['s_CountyName'] = data.s_ZipCounty;
  	quoteDataVar[zipAddrFor]['s_StateName'] = data.s_StateCode;
  	quoteDataVar[zipAddrFor]['s_CityName'] = data.s_CityName;
	quoteDataVar[zipAddrFor]['s_PostalCode'] = zipCode;
	quoteDataVar[zipAddrFor]['n_CountyId_FK'] = data.n_CountyId_FK;
	quoteDataVar[zipAddrFor]['n_CityId_FK'] = data.n_CityId_PK;
	quoteDataVar[zipAddrFor]['n_StateId_FK'] = data.n_StateId_PK;
	quoteDataVar[zipAddrFor]['n_CountryId_FK'] = data.n_CountyId_FK;
	
	
	

	getMapDetails(quoteDataVar);
	
  }
  
  const getMapDetails = async(quoteDataVar) => {
	var zipAddrFor = props.zipAddrFor;

	quoteDataVar[zipAddrFor]['s_Latitude'] = null;
	quoteDataVar[zipAddrFor]['s_Longitude'] = null;
	quoteDataVar[zipAddrFor]['s_PostalCodeSuffix'] = null;
	await dispatch(getPersonAddrWithMapData(quoteDataVar.personAddr)).then((res) => {
		if(res){
			setLat(res.data.s_Latitude);
			setLong(res.data.s_Longitude);
			quoteDataVar[zipAddrFor]['s_Latitude'] = res.data.s_Latitude;
			quoteDataVar[zipAddrFor]['s_Longitude'] = res.data.s_Longitude;
			quoteDataVar[zipAddrFor]['s_PostalCodeSuffix'] = res.data.s_PostalCodeSuffix;
			
			const tempArr = props.quoteData;
		  	tempArr[zipAddrFor]['s_Latitude'] = res.data.s_Latitude;
			
			setQuoteData(quoteDataVar);
			dispatch(getEditEmptyQuoteData(quoteDataVar));
		    setShowModal(false);
		}
	})
  }


  return (
	<LoadingOverlay >
		<Loader loading={loadingStatus} />
	  {
	<Row className="align-items-end">
		<Col xs="12" sm="12" md="6" lg="2" xl="2">
		  <Form.Group
			className="search-quote-form-group"
			controlId="formBasicEmail"
		  >
			<Form.Label className="dashboard-group-label">
			  {t("First Name")} <span className="label-asterisk">*</span>
			</Form.Label>
			<Form.Control
			  type="text"
			  placeholder=""
			  value={firstName}
			  name="s_FirstName"
	          title="personInfo"
			  onChange = {(e) => {
				  props.handleChange(e);
				  setFirstName(e.target.value);
				  setOnLoad(false);
			  }}
			  required
			  disabled={props.isBind}
			/>
		  </Form.Group>
		</Col>
		<Col xs="12" sm="12" md="6" lg="2" xl="2">
		  <Form.Group
			className="search-quote-form-group"
			controlId="formBasicEmail"
		  >
			<Form.Label className="dashboard-group-label">
			  {t("Middle Name")}
			</Form.Label>
			<Form.Control
			  type="text"
			  placeholder=""
			  value={middleName}
			  name="s_MiddleName"
	          title="personInfo"
			  onChange = {(e) => {
				  props.handleChange(e);
				  setMiddleName(e.target.value);
			  }}
			  disabled={props.isBind}
			/>
		  </Form.Group>
		</Col>
		<Col xs="12" sm="12" md="6" lg="2" xl="2">
		  <Form.Group
			className="search-quote-form-group"
			controlId="formBasicEmail"
		  >
			<Form.Label className="dashboard-group-label">
			  {t("Last Name")} <span className="label-asterisk">*</span>
			</Form.Label>
			<Form.Control
			  type="text"
			  placeholder=""
			  value={lastName}
			  name="s_LastOrganizationName"
	          title="personInfo"
			  onChange = {(e) => {
				  props.handleChange(e);
				  setLastName(e.target.value);
				  setOnLoad(false);
			  }}
			  required
			  disabled={props.isBind}
			/>
		  </Form.Group>
		</Col>
		<Col xs="12" sm="12" md="6" lg="2" xl="2">
		  <Form.Group
			className="search-quote-form-group"
			controlId="formBasicEmail"
		  >
			<Form.Label className="dashboard-group-label">
			  {t('Name(s) On Dec')} <span className="label-asterisk">*</span>
			</Form.Label>
			<Form.Control
			  className="dashboard-group-input"
			  type="text"
			  placeholder=""
			  value={nameOnDec}
			  name="s_FullLegalName"
	          title="personInfo"
			  onChange = {(e) => {
				  props.handleChange(e);
				  setNameOnDec(e.target.value);
			  }}
			//   onBlur={(e) => changenameandsername(e)}
			  disabled={props.isBind}
			/>
		  </Form.Group>
		</Col>
		<Col xs="12" sm="12" md="6" lg="2" xl="2">
		  <Form.Group
			className="search-quote-form-group"
			controlId="formBasicEmail"
		  >
			<Form.Label className="dashboard-group-label">
			  {t("Date Of Birth")}
			</Form.Label>
			<Form.Control
			  className="dashboard-group-input"
			  type="date"
			  value={props.quoteData.personInfo.d_BirthDate}
			  name="d_BirthDate"
	          title="personInfo"
			  onChange = {props.handleChange}
			  disabled={props.isBind}
			/>
		  </Form.Group>
		</Col>
		<Col xs="12" sm="12" md="6" lg="2" xl="2">
		  <Form.Group
			className="search-quote-form-group"
			controlId="formBasicEmail"
		  >
			<Form.Label className="dashboard-group-label">
			  {t("Occupation")}
			</Form.Label>
			<Form.Control
			  className="dashboard-group-input"
			  type="text"
			  placeholder=""
			  value={props.quoteData.personInfo.s_Occupation}
			  name="s_Occupation"
	          title="personInfo"
			  onChange = {props.handleChange}
			  disabled={props.isBind}
			/>
		  </Form.Group>
		</Col>
		<Col xs="12" sm="12" md="6" lg="2" xl="2">
		  <Form.Group
			className="search-quote-form-group"
			controlId="formBasicEmail"
		  >
			<Form.Label className="dashboard-group-label">
			  {t("House No")} <span className="label-asterisk">*</span>
			</Form.Label>
			<Form.Control
			  className="dashboard-group-input"
			  type="text"
			  placeholder=""
			  value={props.quoteData && props.quoteData.personAddr && props.quoteData.personAddr.s_HouseNo ? props.quoteData.personAddr.s_HouseNo : null}
			  name="s_HouseNo"
	          title="personAddr"
			  onChange = {props.handleChange}
			  disabled={props.isBind}
			  required
			/>
		  </Form.Group>
		</Col>
		<Col xs="12" sm="12" md="6" lg="3" xl="3">
		  <Form.Group
			className="search-quote-form-group"
			controlId="formBasicEmail"
		  >
			<Form.Label className="dashboard-group-label">
			  {t("Street Name")} <span className="label-asterisk">*</span>
			</Form.Label>
			<Form.Control
			  className="dashboard-group-input"
			  type="text"
			  placeholder=""
			  value={props.quoteData && props.quoteData.personAddr && props.quoteData.personAddr.s_StreetName ? props.quoteData.personAddr.s_StreetName : null}
			  name="s_StreetName"
	          title="personAddr"
			  onChange = {props.handleChange}
			  disabled={props.isBind}
			  required
			/>
		  </Form.Group>
		</Col>
		<Col xs="12" sm="12" md="6" lg="1" xl="1">
		  <Form.Group
			className="search-quote-form-group"
			controlId="formBasicEmail"
		  >
			<Form.Label className="dashboard-group-label">
			  {t("Zip")} <span className="label-asterisk">*</span>
			</Form.Label>
			<Form.Control
			  className="dashboard-group-input"
			  type="text"
			  placeholder=""
			  name="s_PostalCode"
	          title="personAddr"
			  //  oonChange={(e) => changeZip(e)}
			  value={zipCode}
			  onBlur={() => zipCodeApi()}
			  required
			  onChange={(e) => changeZip(e)}
			  disabled={props.isBind}
			/>
		  </Form.Group>
		</Col>
		<Col xs="12" sm="12" md="6" lg="2" xl="2">
		  <Form.Group
			className="search-quote-form-group"
			controlId="formBasicEmail"
		  >
			<Form.Label className="dashboard-group-label">
			  {t("County")} <span className="label-asterisk">*</span>
			</Form.Label>
			<Form.Control
			  className="dashboard-group-input"
			  type="text"
			  placeholder=""
			  value={props.quoteData && props.quoteData.personAddr && props.quoteData.personAddr.s_CountyName ? props.quoteData.personAddr.s_CountyName : null}
			  name="s_CountyName"
	          title="personAddr"
			  onChange = {props.handleChange}
			  disabled
			  required
			/>
		  </Form.Group>
		</Col>

		<Col xs="12" sm="12" md="6" lg="1" xl="1">
		  <Form.Group
			className="search-quote-form-group"
			controlId="formBasicEmail"
		  >
			<Form.Label className="dashboard-group-label">
			  {t("State")} <span className="label-asterisk">*</span>
			</Form.Label>
			<Form.Control
			  className="dashboard-group-input"
			  type="text"
			  placeholder=""
			  disabled
			  value={props.quoteData && props.quoteData.personAddr && props.quoteData.personAddr.s_StateName ? props.quoteData.personAddr.s_StateName : null}
			  name="s_StateName"
	          title="personAddr"
			  onChange = {props.handleChange}
			  required
			/>
		  </Form.Group>
		</Col>
		<Col xs="12" sm="12" md="6" lg="2" xl="2">
		  <Form.Group
			className="search-quote-form-group"
			controlId="formBasicEmail"
		  >
			<Form.Label className="dashboard-group-label">
			  {t("City")}<span className="label-asterisk">*</span>
			</Form.Label>
			<Form.Control
			  className="dashboard-group-input"
			  type="text"
			  placeholder=""
			  disabled
			  value={props.quoteData && props.quoteData.personAddr && props.quoteData.personAddr.s_CityName ? props.quoteData.personAddr.s_CityName : null}
			  name="s_CityName"
	          title="personAddr"
			  onChange = {props.handleChange}
			  required
			/>
		  </Form.Group>
		</Col>
		<Col xs="12" sm="12" md="6" lg="1" xl="1">
		  <Form.Group
			className="search-quote-form-group"
			controlId="formBasicEmail"
		  >
			<Form.Label className="dashboard-group-label">
			  {t("Sufix")}
			</Form.Label>
			<Form.Control
			  className="dashboard-group-input"
			  type="text"
			  placeholder=""
			  value={props.quoteData && props.quoteData.personAddr && props.quoteData.personAddr.s_PostalCodeSuffix ? props.quoteData.personAddr.s_PostalCodeSuffix : null}
			  name="s_PostalCodeSuffix"
	          title="personAddr"
			  onChange = {props.handleChange}
			  disabled={props.isBind}
			/>
		  </Form.Group>
		</Col>
		<Col xs="12" sm="12" md="6" lg="2" xl="2">
		  <Form.Group
			className="search-quote-form-group"
			controlId="formBasicEmail"
		  >
			<Form.Label className="dashboard-group-label">
			  {t("Email Address")}<span className="label-asterisk">*</span>
			</Form.Label>
			<Form.Control
			  className="dashboard-group-input"
			  type="text"
			  placeholder=""
			  value={props.quoteData.personEmailContact.s_CommValue}
			  name="s_CommValue"
	          title="personEmailContact"
			  onChange = {props.handleChange}
			  disabled={props.isBind}
			  required
			/>
		  </Form.Group>
		</Col>
		<Col xs="12" sm="12" md="6" lg="2" xl="2">
		  <Form.Group
			className="search-quote-form-group"
			controlId="formBasicEmail"
		  >
			<Form.Label className="dashboard-group-label">
			  {t("Phone No")} <span className="label-asterisk">*</span>
			</Form.Label>
			<MaskedFormControl
			  className="dashboard-group-input"
			  type="text"
			  placeholder=""
			  value={props.quoteData.personPhoneContact.s_CommValue}
			  mask="(111)111-1111"
			  name="s_CommValue"
	          title="personPhoneContact"
			  onChange = {props.handleChange}
			  disabled={props.isBind}
			  required
			/>
		  </Form.Group>
		</Col>
		<Col xs="12" sm="12" md="6" lg="4" xl="4">
		  <Form.Group
			className="search-quote-form-group"
			controlId="formBasicEmail"
		  >
			<Form.Label className="dashboard-group-label">
			  {t("Is mailing address same as location address")}
			  <span className="label-asterisk">*</span>
			</Form.Label>
			<div
			  style={{ display: "flex", justifyContent: "flex-start" }}
			  className="custom-radio-check0"
			>
			  <Form.Check
				type="radio"
				label="Yes"
				style={{ marginRight: "10%" }}
				value={'NO'}
				checked={(props.quoteData.personInfo.s_IsMailingDiff === "NO" ||  props.quoteData.personInfo.s_IsMailingDiff == "" || props.quoteData.personInfo.s_IsMailingDiff == null) ? true : false}
				name="s_IsMailingDiff"
                id="personInfo"
				onChange = {props.handleRadioChange}
				disabled={props.isBind}
			  />
			  <Form.Check 
			  	type="radio" 
				label="No"
				value={'YES'}
				checked={props.quoteData.personInfo.s_IsMailingDiff === "YES" ? true : false}
				name="s_IsMailingDiff"
                id="personInfo"
				onChange = {props.handleRadioChange}
				disabled={props.isBind}
				/>
			</div>
		  </Form.Group>
		</Col>
		<Col xs="12" sm="12" md="12" lg="6" xl="6">
		  <MapComponent lat={lat} long={long}/>
		</Col>
		{/* Modal */}
		<Modal size="md" show={showModal} className="add-mortage-modal">
		  <Modal.Header id="add-mortage-modal-header" >
			<Modal.Title>Select City</Modal.Title>
		  </Modal.Header>
		  <Modal.Body className="add-mortage-modal-body">
			<Row>
			  <Table className="p-0 m-0" size="sm" responsive striped>
				<thead>
				  <tr>
					<th>City</th>
					<th>County</th>
				  </tr>
				</thead>
				<tbody>
				  {zipData.map((data, i) => {
					return (
					  <tr key={i}>
						<td><a href='#' onClick={() => setZipDetailsData(data)}>{data.s_CityName}</a></td>
						<td>{data.s_ZipCounty}</td>
					  </tr>
					)
				  })}
				</tbody>
			  </Table>
			</Row>
		  </Modal.Body>
		</Modal>
	  </Row>
	  
	  }
	  </LoadingOverlay>
	);
	
};
  function mapStateToProps(state) {
	return {
	  	quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	};
  } 
  export default connect(mapStateToProps)(ApplicantInfo);