import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import Validation from "../../Validation";
import MaskedFormControl from 'react-bootstrap-maskedinput'
import "./flood-contact-information.scss";

const FloodContactInformation = (props) => {
  const { t } = useTranslation();
  const { emptyQuoteData } = useSelector(state => state.flood);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const toggle = (e) => {
    setValidated(e);
  }

  // Set State from reducer
  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0) {
      setName(emptyQuoteData.personInfo.s_FullLegalName);
      setEmail(emptyQuoteData.personEmailContact.s_CommValue);
      setPhone(emptyQuoteData.personPhoneContact.s_CommValue);
    }
  }, [emptyQuoteData])

  // Set First Name -Last Name / Additional Interest
  useEffect(() => {
    if (name) {
      props.handleChange({}, name)
    }
  }, [name])

  return (
    <section className="property-details-section">
      {
        Object.keys(emptyQuoteData).length === 0 && <Navigate to={'/flood/instantquote/step/address'} />
      }

      {/* Form */}
      <Row className="justify-content-center">
        <Col
          xs="12"
          sm="12"
          md="10"
          lg="8"
          xl="8"
          className="p-form"
          id="flood-cif"
        >
          <Row className="justify-content-center" id="contact-info-div">
            <Col xs="12" sm="10" md="10" lg="10" xl="10">
              <h1>{t('Please Add Insured Contact Information')}</h1>
              <Row className="justify-content-start">
                <Col xs="12">
                  <Form validated={validated} className="confirm-address-form">
                    <Row>
                      <Col xs="12">
                        <Form.Group
                          className="confirm-address-field"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className="confirm-address-label">
                            {t('Full Name')}
                          </Form.Label>
                          <Form.Control
                            className="confirm-address-input"
                            type="text"
                            placeholder={t("Enter your name")}
                            name="s_FullLegalName"
                            title={t('Full Name')}
                            parentobject="personInfo"
                            value={name}
                            defaultValue={''}
                            onChange={(e) => {
                              props.handleChange(e);
                              setName(e.currentTarget.value);
                            }}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t("Please Add Full Name Error")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs="6">
                        <Form.Group
                          controlId="formBasicPassword"
                          className="confirm-address-field"
                        >
                          <Form.Label className="confirm-address-label">
                            {t('Email')}
                          </Form.Label>
                          <Form.Control
                            className="confirm-address-input"
                            type="email"
                            placeholder={t("Enter Email")}
                            name="s_CommValue"
                            title={t('Email')}
                            parentobject="personEmailContact"
                            value={email}
                            defaultValue={''}
                            onChange={(e) => {
                              props.handleChange(e);
                              setEmail(e.currentTarget.value);
                            }}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t("Please Add Valid Email Address Error")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs="6">
                        <Form.Group
                          className="confirm-address-field"
                          controlId="formBasicPassword"
                        >
                          <Form.Label className="confirm-address-label">
                            {t('Phone Number')}
                          </Form.Label>
                          <MaskedFormControl
                            type='text'
                            name='s_CommValue'
                            mask="(111)111-1111"
                            placeholder=""
                            title={t('Phone Number')}
                            parentobject="personPhoneContact"
                            value={phone}
                            defaultValue={''}
                            onChange={(e) => {
                              props.handleChange(e);
                              setPhone(e.currentTarget.value);
                            }}
                            className="confirm-address-input"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t("Please Add Mobile Number Error")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col
                  xs="12"
                  id="flood-contact-btns"
                  className="contact-form-btns"
                >
                  <Validation currentStep={3} toggle={toggle} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
};

export default FloodContactInformation;