import React, { Fragment, useState, useEffect } from "react";
import { Button, Row, Col, Form, Table, Accordion,ListGroup } from "react-bootstrap";
import basicBadge from "../../../../../assets/badges/basic.png";
import optionalBadge from "../../../../../assets/badges/optional.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MapComponent from "../../../../../components/MapComponent";
import { useDispatch, useSelector , connect } from 'react-redux';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { getQuoteData,getEmptyQuoteData,getBaseQuoteData,getEditEmptyQuoteData } from "../../../../../services/quoteService";
import ClaimInformation from "./ClaimInformation";
import { useTranslation } from "react-i18next";

const ClaimQuestion = (props) => {

  const [date1, setDate1] = useState(new Date());
  const [questionValue1, setQuestionValue1] = useState();
  const [questionValue2, setQuestionValue2] = useState();
  const [quoteData, setQuoteData] = useState(props.quoteData);
  const [questionData, setQuestionData] = useState(props.quoteData.allAnswerData);
  const [newKey, setNewKey] = useState('');
  const [newObj, setNewObj] = useState({'abs': '123'});
  const [newNode, setNewNode] = useState();
  const [showLossList, setShowLossList] = useState(props.quoteData.allAnswerData.Question_12984370.s_PoAdditionalStatsAnswer == 'NO' ? false : true);
  const { t } = useTranslation();
  
  
  
  const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getEditEmptyQuoteData(quoteData));
	}, [quoteData]);
	


  
const handleQRadioChange = (e) => {
	const { name, value , title,questionFK,attributes,checked,id } = e.target; 
	var questionKey = 'Question_'+attributes.questionFK.value;
	const questionKeyy = 'Question_'+attributes.questionFK.value;
	var uWkey = props.quoteData.allAnswerData;
	const temArrCount = props.quoteData;
	var quoteDataVar = props.quoteData;
	const tempArr = props.quoteData.allAnswerData;
	//var tempArr = quoteData.allAnswerData;
	
	if(attributes.questionFK.value == 12984370 && value == 'NO') {
		setShowLossList(false);
		props.setDefaultData('lossHistoryData', []);
		temArrCount['propertyInfo']['n_PriorClaimCount'] = null;
	    quoteDataVar['propertyInfo']['n_PriorClaimCount'] = null;
	    setQuoteData({...quoteDataVar,quoteData});
	}else{
		setShowLossList(true);
	}

	if (uWkey != null && uWkey.hasOwnProperty(questionKey)) {
		
		
		tempArr[questionKey]['s_PoAdditionalStatsAnswer'] = value;
		var key = uWkey[questionKey]['s_PoAdditionalStatsAnswer'];
		setQuoteData({...quoteDataVar, key: value});
	}else{

		const questionD = props.quoteData.allAnswerData;
		questionD[questionKeyy] = {
			's_PoAdditionalStatsAnswer': value
		};
		setQuoteData({...quoteDataVar,quoteData});

	}
}
  
  
  const handleChange = (e) => {

	const { name, value , title,questionFK,attributes,id } = e.target; 
	
	const questionKey = 'Question_'+attributes.questionFK.value;

	const uWkey = props.quoteData.allAnswerData;
	
	const tempArr = props.quoteData.allAnswerData;
	var quoteDataVar = props.quoteData;
	
	if (uWkey != null && uWkey.hasOwnProperty(questionKey)) {
		tempArr[questionKey]['s_PoAdditionalStatsAnswer'] = value;
		var key = uWkey[questionKey]['s_PoAdditionalStatsAnswer'];
		setQuoteData({...quoteDataVar, key: value});
	}else{

		const questionD = props.quoteData.allAnswerData;
		questionD[questionKey] = {
			's_PoAdditionalStatsAnswer': value
		};
		setQuoteData({...quoteDataVar,quoteDataVar});

	}
	
 }

 const setCountForLosses = (count) => {
	var quoteDataVar = props.quoteData;
	const tempArr = props.quoteData;
    if(count > 0){
		tempArr['propertyInfo']['n_PriorClaimCount'] = count;
	    quoteDataVar['propertyInfo']['n_PriorClaimCount'] = count;
	}
	else {
        tempArr['propertyInfo']['n_PriorClaimCount'] = null;
	    quoteDataVar['propertyInfo']['n_PriorClaimCount'] = null;
	}
	setQuoteData({...quoteDataVar,quoteDataVar});
 }
  

  return (
	  <>
	<div className="search-quote-question-header">
        <span className="search-quote-question-text">Questions</span>
        <span className="search-quote-question-text">Answer </span>
    </div>
      <div className="search-quote-question-body">
        <div className="search-questions-subdiv">
          <ListGroup className="search-questions-list" as="ol" numbered>
            <ListGroup.Item as="li">
              <span>
			  {t("Month & Year the property to be insured was purchased by the applicant")}? *
              </span>
              <div
                id="sear-question-list-answers"
                className="custom-radio-check0"
              >
                <MaskedFormControl 
					type='text' 
					className="confirm-address-input" 
					id='contact-phone-number' 
					mask="1111-11" 
					placeholder="" 
					name='s_CommValue' 
					questionFK="12984369"
					title="personPhoneContact" 
					parentobject="propertyCoverageInfo"
					value={quoteData.allAnswerData.Question_12984369 && quoteData.allAnswerData.Question_12984369.s_PoAdditionalStatsAnswer && quoteData.allAnswerData.Question_12984369.s_PoAdditionalStatsAnswer ? quoteData.allAnswerData.Question_12984369.s_PoAdditionalStatsAnswer : ''}
					onChange = {handleChange} 
					disabled={props.isBind}
					required
				/>
              </div>
            </ListGroup.Item>

            <ListGroup.Item as="li">
              <span>
                {t("Will the residence premises be occupied by the applicant and/or co-applicant")}?
              </span>
              <div
                id="sear-question-list-answers"
                className="custom-radio-check0"
              >
                <Form.Check
                  type="radio"
                  label="Yes"
                  className="check-question-radio"
                  name="check-ques-radio2_12984370"
				  questionFK="12984370"
				//  checked={questionValue2}
				  value={'YES'}
				  checked={quoteData.allAnswerData.Question_12984370 && quoteData.allAnswerData.Question_12984370.s_PoAdditionalStatsAnswer && quoteData.allAnswerData.Question_12984370.s_PoAdditionalStatsAnswer === "YES" ? true : false}
				  onChange = {handleQRadioChange}
				  disabled={props.isBind}
				  
                />
                <Form.Check
                  type="radio"
                  label="No"
                  name="check-ques-radio2_12984370"
				  questionFK="12984370"
				//  checked={questionValue2}
				  value={'NO'}
				  checked={quoteData.allAnswerData.Question_12984370 && quoteData.allAnswerData.Question_12984370.s_PoAdditionalStatsAnswer && quoteData.allAnswerData.Question_12984370.s_PoAdditionalStatsAnswer === "NO" ? true : false}
				  onChange = {handleQRadioChange}
				  disabled={props.isBind}
                />
              </div>
            </ListGroup.Item>
          </ListGroup>
        </div>
		{showLossList ?
		
			
		<div className="search-questions-subdiv" style={{ width: "95%",margin: 'auto' }}> 
		  	<Accordion defaultActiveKey="0" className="custom-quote-accordion">
	          {/* application information */}
	          <Accordion.Item eventKey="0">
	            <Accordion.Header className="search-quote-card-header" style={{ padding: '5px',margin: 'auto' }}>
	              <span>{t("LIST ALL LOSSES IN THE LAST 5 YEARS FOR ALL MEMBERS OF THE HOUSEHOLD AT ANY LOCATION")}</span>
	            </Accordion.Header>
	            <Accordion.Body className="search-quote-card-body">
					<ClaimInformation setDefaultData={props.setDefaultData} isBind={props.isBind} setCountForLosses = {setCountForLosses} />
	              
	            </Accordion.Body>
	          </Accordion.Item>
			</Accordion>
		</div>
		: null
		}
	</div>
	  
	  
	  </>
	);
	
};
  function mapStateToProps(state) {
	return {
	  	quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	};
  } 
  export default connect(mapStateToProps)(ClaimQuestion);