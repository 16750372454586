import React, { useState } from "react";
import { Modal, ModalDialog, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { refreshToken } from "../../services/authService";
import { toast } from 'react-toastify';
import Moment from "moment";
import Draggable from 'react-draggable';
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { EncryptOrDecrypt } from "../../services/commonService";

const RefreshToken = (props) => {
    const { showModel, setShowModel } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const getRefreshToken = async () => {
        setLoading(true);
        await dispatch(refreshToken()).then((response) => {
            setLoading(false);
            var res = response.data;
            if (res.status === 'Y') {
                localStorage.setItem('access_token', res.access_token);
                var tokenExpireAt = EncryptOrDecrypt(UTCDateToLocalDate(res.access_token_expires_at), 'E');
                localStorage.setItem('access_token_expires_at', tokenExpireAt);
                setShowModel(false);
            } else {
                var msg = res.msg || 'Please Try again'
                toast.error(t(msg));
            }
        }).catch(() => {
            setLoading(false);
            toast.error(t('Please Try again'));
        });
    }

    const UTCDateToLocalDate = (date) => {
        date = date.replace('UTC', '').trim();
        return Moment.utc(date).local().format();
    }

    // Draggable Modal
    const DraggableModalDialog = (props) => {
        return (
            <Draggable handle={'#refreshTokenModel'}>
                <ModalDialog {...props} />
            </Draggable>
        )
    }

    return (

        <Modal size="lg" id='refreshTokenModel' dialogAs={DraggableModalDialog} show={showModel}>
            <Modal.Header>
                <Modal.Title>{t('Login')}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'justify' }}>
                <LoadingOverlay>
                    <Loader loading={loading} />
                    <h4>{t("Your Session will expire soon. Please click 'Next' to continue")}.</h4>
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'center' }}>
                <div className="flood-bts-div">
                    <Button
                        id="primary-grey-btn-flood"
                        variant="outline-dark"
                        onClick={() => {
                            setShowModel(false);
                            setLoading(false);
                        }}
                        disabled={loading}
                    >
                        {t('Close')}
                    </Button>{" "}
                    <Button
                        id="primary-colored-btn"
                        variant="primary"
                        onClick={() => getRefreshToken()}
                        disabled={loading}
                    >
                        {t('Next')}
                    </Button>{" "}
                </div>
            </Modal.Footer>
        </Modal >
    );
};

export default RefreshToken;