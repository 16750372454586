import React, { Fragment, useState, useEffect } from "react";
import { Button, Form, Accordion } from "react-bootstrap";
import { FaInbox, FaEye, FaPlus, FaFilter } from "react-icons/fa";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  sizePerPageRenderer,
  pageButtonRenderer,
} from "../../../components/CustomTablePagination";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ShowActivity from "../../../pages/activity-log/inbox/Inbox";
import DetailActivity from "../../../pages/activity-log/message-view/MessageView.js";
import AddActivity from "../../../pages/activity-log/reply/Reply.js";
import QuoteButtons from "./components/QuoteTabComponents/QuoteButtons";


const ActivityTab = (props) => {
  const [viewPart, setViewPart] = useState('');
  const [id, setId] = useState('0');
  
  useEffect(() => {
    if(props.flag == "VIEW"){
      setViewPart(props.flag);
    }
  }, [props])

  const setValuesCondition = (type,id) =>{
     setId(id);
     setViewPart(type);
  }
  const setViewPartCondition = (e) =>{
     setId('0');
     setViewPart(e);
  }

  return (
    <Fragment>
      <div className="search-quote-tab-div">
        <div className="search-quote-btns-div">
          <QuoteButtons isBind={props.isBind} callGeneratePdf={props.callGeneratePdf} rateLoader={props.rateLoader}/>
        </div>

        {/* <span className="activity-log-tab-title">Communication</span>
        <Button
          id="primary-outlined-btn"
          variant="primary"
          style={{float:"right", marginRight: "10px", color:"#424242" }}
        >
         <FaPlus size={15} /> Add New
        </Button>{" "}
        <Button
          id="primary-outlined-btn"
          style={{float:"right", marginRight:"10px", color:"#424242"}}
          variant="primary"
        >
          <FaEye size={21} /> View Log
        </Button>{" "} */}
        {/* billing info */}
        <Accordion defaultActiveKey="0" className="custom-quote-accordion">
          {/* application information */}
          <Accordion.Item eventKey="0">
            <Accordion.Header
              className="search-quote-card-header"
              style={{ marginTop: "1rem" }}
            >
              <span>Activity Log</span>
            </Accordion.Header>
            <Accordion.Body>
                  <div className="activity-log-tab-table"
                  id="activity-log-tab-table-outer-div"
                  style={{ padding: 0, marginBottom: "20px" }}>
                  </div>
                  { viewPart == "VIEW" ?
                    <ShowActivity activityType='POLICY' 
                    sourceCode={props.sourceCode} 
                    viewPart={(e) => setViewPartCondition(e)}
                    setValuesCondition={(type,id) => setValuesCondition(type,id)}
                 />
                 :
                  viewPart == "DETAIL" ?
                 <DetailActivity
                    sourceCode={props.sourceCode} 
                    viewPart={(e) => setViewPartCondition(e)}
                    messageId= {id}
                    setValuesCondition={(type,id) => setValuesCondition(type,id)}
                 />
                 :
                  viewPart == "NEW" ?
                  <AddActivity activityType='POLICY' 
                  sourceCode={props.sourceCode} 
                  viewPart={(e) => setViewPartCondition(e)}
                  messageId= {id}
                  />
                  : null
                  }
                  
                  {/* sourceCode={this.props.PolicyNo} */}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      {/* <div className="activity-log-tab-bts-div">
        <Button variant="primary" id="primary-outlined-btn">
          {" "}
          Save & Rate{" "}
        </Button>{" "}
        <Button
          id="primary-colored-btn"
          variant="primary"
          onClick={() => props.setKey("Submit")}
        >
          Previous
        </Button>{" "}
        <Button id="primary-grey-btn" variant="primary" disabled>
          Next
        </Button>{" "}
      </div> */}
    </Fragment>
  );
};

export default ActivityTab;
