import Http from '../Http';
import * as action from '../store/actions';

/**
 * To get Empty Quote Object
 * @param {Array} selectedPropertyData as Selected Property Data
 * @returns 
 */
export function getPropertyData(selectedPropertyData) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.post('/api/quote/getPropertyDataAddr/10', selectedPropertyData)
        .then((res) => {
          dispatch(action.getPropertyDataFromAddress(res.data));
          dispatch(action.setIsLoading(false));
          return resolve(res);
        }).catch((err) => {
          if (err.response) {
            return reject(err.response);
          }
          dispatch(action.setIsLoading(false));
        });
    })
  );
}

/**
 * To get Empty Quote Object
 * @param {String} productPk as Product Pk
 * @returns 
 */
export function getEmptyQuoteData(productPk, type = '') {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/quote/emptyQuoteData/' + productPk + '/' + type)
        .then((res) => {
          dispatch(action.getEmptyQuoteData(res.data));
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
          dispatch(action.setIsLoading(false));
          if (err.response) {
            return reject(err.response);
          }
        });
    })
  );
}

/**
 * To get Empty Quote Object
 * @param {String} productPk as Product Pk
 * @returns 
 */
export function getBaseData(productPk) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/quote/getBaseData/' + productPk)
        .then((res) => {
          if (productPk === 10) {
            dispatch(action.getFloodSystemDD(res.data));
          }
          dispatch(action.setIsLoading(false));
          return resolve();
        }).catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
          dispatch(action.setIsLoading(false));
        });
    })
  );
}

/**
 * To Get Initial Quote Api Object
 * @returns Object
 */
export function getInitQuoteApi() {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/flood/getInitQuoteApiObject')
        .then((res) => {
          dispatch(action.getInitQuoteApi(res.data));
          dispatch(action.setIsLoading(false));
          return resolve();
        }).catch(function (error) { // catch
          dispatch(action.setIsLoading(false));
          return reject(error);
        });
    })
  );
}

/**
 * To get Empty Quote Object
 * @param {String} code as Pivot Api Identifier
 * @param {String} view as Source Screen
 * @returns 
 */
export function pivotApiCall(code, request, plan = '') {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.post('/api/flood/pivotApiCall', request)
        .then((res) => {
          if (['ADDRANALYSISGEO'].includes(code) && res.data.data.uniqueKey) {
            localStorage.setItem('uniqueKey', res.data.data.uniqueKey || '');
          }
          if (code === 'ZONE_DETERMINATION') {
            dispatch(action.getZDDetails(res.data));
          } else {
            if (!['ADDRANALYSISGEO', 'GET_POLICY'].includes(code)) {
              dispatch(action.getQuoteResponseDetails(res.data));
            }
          }
          if (plan === 'Basic') {
            dispatch(action.getBasicCvgResponse(res.data));
          }
          if (plan === 'Recommended') {
            dispatch(action.getRecommendedCvgResponse(res.data));
          }
          if (plan === 'Custom') {
            dispatch(action.getCustomCvgResponse(res.data));
          }
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
          dispatch(action.setIsLoading(false));
          return reject(err)
        })
    })
  );
}

/**
 * To Get Basic Plan Options
 * @returns Object
 */
export function getPayPlanOptions(request) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.post('/api/flood/getPlanOptions', request)
        .then((res) => {
          if (res.data.data && res.data.data.Basic) {
            dispatch(action.getBasicCvgDetails(res.data.data.Basic));
            dispatch(action.getCustomCvgDetails(res.data.data.Basic));
          }
          if (res.data.data && res.data.data.Recommended) {
            dispatch(action.getRecommendedCvgDetails(res.data.data.Recommended));
          }

          dispatch(action.setIsLoading(false));
          return resolve();
        }).catch(function (error) { // catch
          dispatch(action.setIsLoading(false));
          return reject(error);
        });
    })
  );
}

/**
 * To Get Deductibles Options
 * @returns Object
 */
export function getDeductibles() {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/flood/getDeductible')
        .then((res) => {
          if (res.data.success) {
            dispatch(action.getDeductibles(res.data.data));
          }
          dispatch(action.setIsLoading(false));
          return resolve();
        }).catch(function (error) { // catch
          dispatch(action.setIsLoading(false));
          return reject(error);
        });
    })
  );
}

/**
 * To Get Zip details
 * @returns Object
 */
export function getZipDetails(product, zipCode, source) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/quote/getZipCodeDetails/' + product + '/' + zipCode + '/' + source)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch(function (error) { // catch
          dispatch(action.setIsLoading(false));
          return reject(error);
        });
    })
  );
}

/**
 * To Get Mortgagee
 * @returns Object
 */
export function getSearchMortgagee(data) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.post('/api/quote/searchMortgagee', data)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch(function (error) { // catch
          dispatch(action.setIsLoading(false));
          return reject(error);
        });
    })
  )
}


/**
 * To Get quote data based on poTransPk
 * @returns Object
 */
export function getQuoteData(poTransPk) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/flood/getQuoteData/' + poTransPk)
        .then((res) => {
          dispatch(action.getEmptyQuoteData(res.data));
          dispatch(action.setIsLoading(false));
          return resolve();
        }).catch(function (error) { // catch
          dispatch(action.setIsLoading(false));
          return reject(error);
        });
    })
  );
}

/**
 * To Get Agency List based on value
 * @returns Object
 */
export function agencyList(search) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.post('/api/quote/agencyList', search)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch(function (error) { // catch
          dispatch(action.setIsLoading(false));
          return reject(error);
        });
    })
  );
}

/**
 * To Get Agent List based on agencyID
 * @returns Object
 */
export function agentList(agencyID, productPk) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/quote/agentList/' + agencyID + '/' + productPk)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch(function (error) { // catch
          dispatch(action.setIsLoading(false));
          return reject(error);
        });
    })
  );
}

/**
 * To get Flood Payment Info 
 * @param {Array} poTransPk as poTransPk
 * @returns 
 */
export function getFloodPaymentInfo(poTransPk) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/flood/getInitPaymentData/' + poTransPk)
        .then((res) => {
          if (res.data) {
            dispatch(action.getInitPaymentData(res.data));
          }
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
          dispatch(action.setIsLoading(false));
          return reject(err)
        })
    })
  );
}

/**
 * Flood Payment 
 * @lokeshrangani 
 * @param {Array} request as Request
 * @returns 
 */
export function payOnline(request, type = '') {
  var url = '/api/flood/eCheckPayment';
  if (type === 'ACH') {
    url = '/api/flood/achPayment';
  }
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.post(url, request)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve([res.data, null]);
        }).catch((err) => {
          dispatch(action.setIsLoading(false));
          if (err.response) {
            return resolve([[], err.response.data.errors.text])
          } else {
            return resolve([[], err.message])
          }
        })
    })
  );
}

/**
 * Flood Rate/Save 
 * @param {Array} request as Request
 * @returns 
 */
export function rateorSaveApplication(request, type = '') {
  var url = '/api/flood/storeFloodQuoteData';
  if (type === 'Rate') {
    url = '/api/flood/storeFloodQuoteData';
  }
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.post(url, request)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
          dispatch(action.setIsLoading(false));
          return reject(err);
        })
    })
  );
}

/**
 * Flood Create Application 
 * @param {Array} poTransPk as poTransPk
 * @returns 
 */
export function createApplication(poTransPk) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/flood/createQuoteCore/' + poTransPk)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
          dispatch(action.setIsLoading(false));
          return reject(err);
        })
    })
  );
}

/**
 * Flood Bind Application
 * @param {String} poTransPK as poTransPK
 * @returns 
 */
export function bindApplication(coreTransPK, poTransPK) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/flood/bindApplication/' + coreTransPK + '/' + poTransPK)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
          dispatch(action.setIsLoading(false));
          return reject(err.message);
        })
    })
  );
}

/**
 * FEMA - Pivot Api Response Log
 * @param {Object} as request
 * @returns 
 */
export function pivotRespLog(request) {
  return dispatch => (
    new Promise((resolve, reject) => {
      var url = `/api/flood/getPivotApiLog?`;
      Object.entries(request).forEach(v => {
        if (v[1] !== '') {
          url = url + '&' + v[0] + '=' + v[1];
        }
      });
      Http.get(url)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          return reject(err.message);
        })
    })
  );
}

/**
 * To updated Empty Quote Object
 * @param {Object} reqJson 
 * @returns 
 */
export function getEditEmptyQuoteData(data) {
  return dispatch => (
    dispatch(action.getEditEmptyQuoteData(data))
  );
}

/**
 * To updated Property Data Autocomplete 
 * @param {Object} reqJson 
 * @returns 
 */
export function propertyDataAutocompleteStore(data) {
  return dispatch => (
    dispatch(action.getPropertyDataAutocompleteStore(data))
  );
}

/**
 * To updated Initial Quote Object
 * @param {Object} reqJson 
 * @returns 
 */
export function updateInitQuoteApi(reqJson) {
  return dispatch => (
    dispatch(action.getInitQuoteApi(reqJson))
  );
}

/**
 * To updated Which Section For Pivot API
 * @param String whichSection
 * @returns 
 */
export function updateWhichSection(whichSection) {
  return dispatch => (
    dispatch(action.getWhichSection(whichSection))
  );
}

/**
 * To updated Last Section
 * @param String lastSection
 * @returns 
 */
export function updateLastSection(lastSection) {
  return dispatch => (
    dispatch(action.getLastSection(lastSection))
  );
}

/**
 * update payment details
 * @param Object data
 * @returns 
 */
export function getEditPaymentData(data) {
  return dispatch => (
    dispatch(action.getInitPaymentData(data))
  );
}

/**
 * reset store
 * @param Object data
 * @returns 
 */
export function resetFloodStore() {
  return dispatch => (
    dispatch(action.resetFloodStore())
  );
}

/**
 * To generated documents
 * @returns 
 */
export function getGeneratedDocs(source, poTransPK) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/reports/generate/' + source + '/' + poTransPK)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
          if (err.response) {
            dispatch(action.setIsLoading(false));
            return reject(err.response);
          }
        });
    })
  );
}

/**
 * To generated documents
 * @returns 
 */
export function getPremiumDetails(sourceID, isUuid) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/flood/premium/' + sourceID + '/' + isUuid)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
          if (err.response) {
            dispatch(action.setIsLoading(false));
            return reject(err.response);
          }
        });
    })
  );
}