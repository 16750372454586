import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getEditEmptyQuoteData } from '../../../../services/floodService';
import "react-datepicker/dist/react-datepicker.css";

const ElevationCertificate = (props) => {
    const { emptyQuoteData, floodSystemDD, validated, isEC, baseFloodEle, floodZone, occupancyType, isBind, handleChange, handleDateChange, checkDateisValid } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isHidden, setIsHidden] = useState(false);
    const [isHaveValidCertificate, setIsHaveValidCertificate] = useState('');
    const [CSD, setCSD] = useState(null);
    const [BDM, setBDM] = useState('');
    const [sectionForCertificate, setSectionForCertificate] = useState('');
    const [ToBF, setToBF] = useState('');
    const [ToNHF, setToNHF] = useState('');
    const [LAG, setLAG] = useState('');
    const [ToBFAboveBelow, setToBFAboveBelow] = useState('');
    const [baseFlood, setBaseFlood] = useState('');
    const [floodProofing, setFloodProofing] = useState('');
    const [THFAboveBelow, setTHFAboveBelow] = useState('');

    // set state
    useEffect(() => {
        if (Object.keys(emptyQuoteData).length > 0) {
            if (emptyQuoteData.propertyInfo) {
                setIsHaveValidCertificate(emptyQuoteData.propertyInfo.s_isValidElevationCert || null);
                if (checkDateisValid(emptyQuoteData.propertyInfo.d_CertificateDate)) {
                    setCSD(new Date(emptyQuoteData.propertyInfo.d_CertificateDate));
                }
                setSectionForCertificate(emptyQuoteData.propertyInfo.s_SectionCompleted || null);
                setBDM(emptyQuoteData.propertyInfo.s_BuildingDiagramNoCode || null);
                setToBF(emptyQuoteData.propertyInfo.n_TopBottomFloor || null);
                setToNHF(emptyQuoteData.propertyInfo.n_TopHigherFloor || null);
                setLAG(emptyQuoteData.propertyInfo.n_LowestAdjacent || null);
                setToBFAboveBelow(emptyQuoteData.propertyInfo.s_TBFAboveBelow || null);
                setBaseFlood(emptyQuoteData.propertyInfo.s_BaseFlood || null);
                setFloodProofing(emptyQuoteData.propertyInfo.s_FloodProofingReview || null);
                setTHFAboveBelow(emptyQuoteData.propertyInfo.s_THFAboveBelow || null);
            }
        }
    }, [emptyQuoteData])

    useEffect(() => {
        var isTrue = isEC;
        if (isTrue) {
            setIsHaveValidCertificate('');
            setCSD('');
        }
        var section = 'C';
        if (['A', 'AO'].includes(floodZone) && [null, '', 0].includes(baseFlood)) {
            // A and BFE Condition will come
            section = 'E';
        }
        setSectionForCertificate(section);
        setIsHidden(isTrue);
        handleSectionChange(section);
    }, [isEC, sectionForCertificate, baseFlood])

    // set base flood elevation
    useEffect(() => {
        setBaseFlood(baseFloodEle);
    }, [baseFloodEle])

    // update state in reducer
    useEffect(() => {
        if (emptyQuoteData.propertyInfo) {
            var emptyQuote = emptyQuoteData;
            if (isHaveValidCertificate !== 'YES') {
                emptyQuote['propertyInfo']['s_ElevationDatum'] = null;
                emptyQuote['propertyInfo']['s_isValidElevationCert'] = null;
                emptyQuote['propertyInfo']['d_CertificateDate'] = null;
                emptyQuote['propertyInfo']['s_SectionCompleted'] = null;
                emptyQuote['propertyInfo']['s_BuildingDiagramNoCode'] = null;
                emptyQuote['propertyInfo']['n_TopBottomFloor'] = null;
                emptyQuote['propertyInfo']['n_TopHigherFloor'] = null;
                emptyQuote['propertyInfo']['n_LowestAdjacent'] = null;
                emptyQuote['propertyInfo']['s_TBFAboveBelow'] = null;
                emptyQuote['propertyInfo']['s_BaseFlood'] = null;
                emptyQuote['propertyInfo']['s_FloodProofingReview'] = null;
                emptyQuote['propertyInfo']['s_THFAboveBelow'] = null;
                // null state
                setCSD(null); setSectionForCertificate(null); setBDM(null); setToBF(null); setToNHF(null); setLAG(null); setToBFAboveBelow(null); setBaseFlood(null); setFloodProofing(null); setTHFAboveBelow(null);
            }
            dispatch(getEditEmptyQuoteData(emptyQuote));
        }
    }, [isHaveValidCertificate])

    // update state in reducer
    const handleSectionChange = (section) => {
        var emptyQuote = emptyQuoteData;
        emptyQuote['propertyInfo']['s_SectionCompleted'] = section;
        if (section === 'E') {
            emptyQuote['propertyInfo']['n_TopBottomFloor'] = null;
            emptyQuote['propertyInfo']['n_TopHigherFloor'] = null;
            emptyQuote['propertyInfo']['n_LowestAdjacent'] = null;
            setToBF(null); setToNHF(null); setLAG(null);
        }
        if (section === 'C') {
            emptyQuote['propertyInfo']['n_TopBottomFloor'] = null;
            emptyQuote['propertyInfo']['s_TBFAboveBelow'] = null;
            emptyQuote['propertyInfo']['n_TopHigherFloor'] = null;
            emptyQuote['propertyInfo']['s_THFAboveBelow'] = null;
            setToBF(null); setToNHF(null); setToBFAboveBelow(null); setTHFAboveBelow(null);
        }
        dispatch(getEditEmptyQuoteData(emptyQuote));
    }

    return (
        <Form validated={validated}>
            <Row>
                {
                    isHidden ?
                        <h1 className='text-danger'>NOT REQUIRED - DO NOT NEED EC</h1>
                        :
                        <>
                            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t('Do you have a valid elevation certificate')}?
                                    </Form.Label>
                                    <Form.Select
                                        className="table-show-entries-select"
                                        name="s_isValidElevationCert"
                                        title={t('Do you have a valid elevation certificate')}
                                        parentobject="propertyInfo"
                                        value={isHaveValidCertificate}
                                        onChange={(e) => {
                                            setIsHaveValidCertificate(e.currentTarget.value);
                                            handleChange(e);
                                        }}
                                        disabled={isBind}
                                    >
                                        <option value={''}>{t('Select')}</option>
                                        {
                                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                                return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            {
                                isHaveValidCertificate === 'YES' && (
                                    <>
                                        <Col xs="12" sm="12" md="6" lg="2" xl="2">
                                            <Form.Group
                                                className="search-quote-form-group"
                                                controlId="formBasicEmail"
                                            >
                                                <Form.Label className="dashboard-group-label">
                                                    {t('Certificate Signature date')}
                                                </Form.Label>
                                                <DatePicker
                                                    id="dashboard-datepicker"
                                                    className="dashboard-datepicker-main"
                                                    placeholderText="MM/DD/YYYY"
                                                    selected={CSD}
                                                    onChange={(date) => {
                                                        setCSD(date);
                                                        handleDateChange(date, 'd_CertificateDate', 'propertyInfo');
                                                    }}
                                                    autoComplete="off"
                                                    disabled={isBind}
                                                />
                                            </Form.Group>
                                        </Col>
                                        {
                                            (['A', 'AO'].includes(floodZone) && [null, '', 0].includes(baseFlood)) && (
                                                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                                                    <Form.Group
                                                        className="search-quote-form-group"
                                                        controlId="formBasicEmail"
                                                    >
                                                        <Form.Label className="dashboard-group-label">
                                                            {t('Please select the section of the elevation certificate that has been completed')}:
                                                        </Form.Label>
                                                        <Form.Select
                                                            className="table-show-entries-select"
                                                            name="s_SectionCompleted"
                                                            title={t('Please select the section of the elevation certificate that has been completed')}
                                                            parentobject="propertyInfo"
                                                            value={sectionForCertificate}
                                                            onChange={(e) => {
                                                                setSectionForCertificate(e.currentTarget.value);

                                                                handleChange(e);
                                                            }}
                                                            disabled={isBind}
                                                        >
                                                            <option value={''}>{t('Select')}</option>
                                                            {
                                                                Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODECCOMPLETEDSECTION.map((v, i) => {
                                                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }
                                        {
                                            ['NONRESIDENTIALUNIT20', 'NONRESIDENTIALBUILD20'].includes(occupancyType) && (
                                                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                                                    <Form.Group
                                                        className="search-quote-form-group"
                                                        controlId="formBasicEmail"
                                                    >
                                                        <Form.Label className="dashboard-group-label">
                                                            {t('Please select the section of the elevation certificate that has been completed')}:
                                                        </Form.Label>
                                                        <Form.Select
                                                            className="table-show-entries-select"
                                                            name="s_FloodProofingReview"
                                                            title={t('Please select the section of the elevation certificate that has been completed')}
                                                            parentobject="propertyInfo"
                                                            value={floodProofing}
                                                            onChange={(e) => {
                                                                setFloodProofing(e.currentTarget.value);
                                                                handleChange(e);
                                                            }}
                                                            disabled={isBind}
                                                        >
                                                            <option value={''}>{t('Select')}</option>
                                                            {
                                                                Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }
                                        {
                                            <Row className='p-0 m-0'>
                                                <Col md='6' className='p-1'>
                                                    <Card className='mb-1'>
                                                        <Card.Body>
                                                            <Card.Title><b>SECTION A - PROPERTY INFORMATION</b></Card.Title>
                                                            <hr />
                                                            <Col xs="12" sm="12" md="12" lg="6" xl="6">
                                                                <Form.Group
                                                                    className="search-quote-form-group"
                                                                    controlId="formBasicEmail"
                                                                >
                                                                    <Form.Label className="dashboard-group-label">
                                                                        <b>A7.</b>{t('Diagram Number')}:
                                                                    </Form.Label>
                                                                    <Form.Select
                                                                        className="table-show-entries-select"
                                                                        name="s_BuildingDiagramNoCode"
                                                                        title={t('Diagram Number')}
                                                                        parentobject="propertyInfo"
                                                                        value={BDM}
                                                                        onChange={(e) => {
                                                                            setBDM(e.currentTarget.value);
                                                                            handleChange(e);
                                                                        }}
                                                                        disabled={isBind}
                                                                    >
                                                                        <option value={''}>{t('Select')}</option>
                                                                        {
                                                                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODECBUILDDIAGRAMNO.map((v, i) => {
                                                                                return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                                                            })
                                                                        }
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </Col>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>

                                                {
                                                    sectionForCertificate === 'C' && (
                                                        <Col md='6' className='p-1'>
                                                            <Card className='mb-1'>
                                                                <Card.Body>
                                                                    <Card.Title><b>SECTION C - BUILDING ELEVATION INFORMATION (SURVEY REQUIRED)</b></Card.Title>
                                                                    <hr />
                                                                    <Row>
                                                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                                                            <Form.Group
                                                                                className="search-quote-form-group"
                                                                                controlId="formBasicEmail"
                                                                            >
                                                                                <Form.Label className="dashboard-group-label">
                                                                                    <b>b)</b>{t('Top of Bottom floor (including basement,crawlspace, or enclosure flood)')}
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    className="dashboard-group-input-flood"
                                                                                    type="number"
                                                                                    placeholder="000"
                                                                                    name="n_TopBottomFloor"
                                                                                    title={t('Top of Bottom floor (including basement,crawlspace, or enclosure flood)')}
                                                                                    parentobject="propertyInfo"
                                                                                    value={ToBF || ''}
                                                                                    min={0}
                                                                                    onChange={(e) => {
                                                                                        handleChange(e);
                                                                                        setToBF(e.currentTarget.value);
                                                                                    }}
                                                                                    autoComplete="off"
                                                                                    disabled={isBind}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                                                            <Form.Group
                                                                                className="search-quote-form-group"
                                                                                controlId="formBasicEmail"
                                                                            >
                                                                                <Form.Label className="dashboard-group-label">
                                                                                    <b>b)</b>{t('Top of the next higher floor')}
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    className="dashboard-group-input-flood"
                                                                                    type="number"
                                                                                    placeholder="000"
                                                                                    name="n_TopHigherFloor"
                                                                                    title={t('Top of the next higher floor')}
                                                                                    parentobject="propertyInfo"
                                                                                    value={ToNHF || ''}
                                                                                    min={0}
                                                                                    onChange={(e) => {
                                                                                        handleChange(e);
                                                                                        setToNHF(e.currentTarget.value);
                                                                                    }}
                                                                                    autoComplete="off"
                                                                                    disabled={isBind}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                                                            <Form.Group
                                                                                className="search-quote-form-group"
                                                                                controlId="formBasicEmail"
                                                                            >
                                                                                <Form.Label className="dashboard-group-label">
                                                                                    <b>c)</b>{t('Lowest adjacent (finished) grade next to building (LAG)')}
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    className="dashboard-group-input-flood"
                                                                                    type="number"
                                                                                    placeholder="000"
                                                                                    name="n_LowestAdjacent"
                                                                                    title={t('Lowest adjacent (finished) grade next to building (LAG)')}
                                                                                    parentobject="propertyInfo"
                                                                                    value={LAG || ''}
                                                                                    min={0}
                                                                                    onChange={(e) => {
                                                                                        handleChange(e);
                                                                                        setLAG(e.currentTarget.value);
                                                                                    }}
                                                                                    autoComplete="off"
                                                                                    disabled={isBind}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    )
                                                }

                                                {
                                                    sectionForCertificate === 'E' && (
                                                        <Col md='6' className='p-1'>
                                                            <Card className='mb-1'>
                                                                <Card.Body>
                                                                    <Card.Title><b>SECTION E - BUILDING ELEVATION INFORMATION (SURVEY NOT REQUIRED) FOR ZONE AO AND ZONE A (WITHOUT BFE)</b></Card.Title>
                                                                    <Form.Label className="dashboard-group-label">
                                                                        <b>E1.</b>{t('Provide elevation information for the following and check the appropriate boxes to show whether the elevation is above or below the highest adjacent grade (HAG) and the Lowest adjacent grade (LAG)')}
                                                                    </Form.Label>
                                                                    <Row>
                                                                        <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                                                            <Form.Group
                                                                                className="search-quote-form-group"
                                                                                controlId="formBasicEmail"
                                                                            >
                                                                                <Form.Label className="dashboard-group-label">
                                                                                    <b>a)</b>{t('Top of Bottom floor (including basement,crawlspace, or enclosure flood) is')}
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    className="dashboard-group-input-flood"
                                                                                    type="number"
                                                                                    placeholder="000"
                                                                                    name="n_TopBottomFloor"
                                                                                    title={t('Top of Bottom floor (including basement,crawlspace, or enclosure flood)')}
                                                                                    parentobject="propertyInfo"
                                                                                    value={ToBF || ''}
                                                                                    min={0}
                                                                                    onChange={(e) => {
                                                                                        handleChange(e);
                                                                                        setToBF(e.currentTarget.value);
                                                                                    }}
                                                                                    autoComplete="off"
                                                                                    disabled={isBind}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs="12" sm="12" md="12" lg="2" xl="2">
                                                                            <Form.Group
                                                                                className="search-quote-form-group"
                                                                                controlId="formBasicEmail"
                                                                            >
                                                                                <Form.Label className="dashboard-group-label">
                                                                                    {t('Above/Below')}
                                                                                </Form.Label>
                                                                                <Form.Select
                                                                                    className="table-show-entries-select"
                                                                                    name="s_TBFAboveBelow"
                                                                                    title=''
                                                                                    parentobject="propertyInfo"
                                                                                    value={ToBFAboveBelow}
                                                                                    onChange={(e) => {
                                                                                        setToBFAboveBelow(e.currentTarget.value);
                                                                                        handleChange(e);
                                                                                    }}
                                                                                    disabled={isBind}
                                                                                >
                                                                                    <option value={''}>{t('Select')}</option>
                                                                                    {
                                                                                        Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODECHAGLAG.map((v, i) => {
                                                                                            return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                                                                        })
                                                                                    }
                                                                                </Form.Select>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                                                            <Form.Group
                                                                                className="search-quote-form-group"
                                                                                controlId="formBasicEmail"
                                                                            >
                                                                                <Form.Label className="dashboard-group-label">
                                                                                    <b>b)</b>{t('The next higher floor of the building (E2) is')}
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    className="dashboard-group-input-flood"
                                                                                    type="number"
                                                                                    placeholder="000"
                                                                                    name="n_TopHigherFloor"
                                                                                    title={t('The next higher floor of the building (E2) is')}
                                                                                    parentobject="propertyInfo"
                                                                                    value={ToNHF || ''}
                                                                                    min={0}
                                                                                    onChange={(e) => {
                                                                                        handleChange(e);
                                                                                        setToNHF(e.currentTarget.value);
                                                                                    }}
                                                                                    autoComplete="off"
                                                                                    disabled={isBind}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs="12" sm="12" md="12" lg="2" xl="2">
                                                                            <Form.Group
                                                                                className="search-quote-form-group"
                                                                                controlId="formBasicEmail"
                                                                            >
                                                                                <Form.Label className="dashboard-group-label">
                                                                                    {t('Above/Below')}
                                                                                </Form.Label>
                                                                                <Form.Select
                                                                                    className="table-show-entries-select"
                                                                                    name="s_THFAboveBelow"
                                                                                    title=''
                                                                                    parentobject="propertyInfo"
                                                                                    value={THFAboveBelow}
                                                                                    onChange={(e) => {
                                                                                        setTHFAboveBelow(e.currentTarget.value);
                                                                                        handleChange(e);
                                                                                    }}
                                                                                    disabled={isBind}
                                                                                >
                                                                                    <option value={''}>{t('Select')}</option>
                                                                                    {
                                                                                        Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODECHAGLAG.map((v, i) => {
                                                                                            return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                                                                        })
                                                                                    }
                                                                                </Form.Select>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    )
                                                }
                                            </Row>
                                        }
                                    </>
                                )
                            }
                        </>
                }
            </Row>
        </Form >
    )
}

export default ElevationCertificate;