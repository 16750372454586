import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { getPropertyData, getBaseData, propertyDataAutocompleteStore, pivotApiCall, resetFloodStore } from '../../../../../services/floodService';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import "./flood-address.scss";

const FloodAddress = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigation = useNavigate();

    const { apiLoading } = useSelector(state => state.flood);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [selectedPropertyData, setSelectedPropertyData] = useState({
        street_number: "",
        street_name: "",
        city: "",
        CountyName: "",
        PostalCode: "",
        country: "",
        latitude: null,
        longitude: null,
        value: "",
        autocomplete: "",
        state: "",
        s_PostalCodeSuffix: ""
    });

    // React-Select Custome Style
    const customStyles = {
        control: (base) => ({
            ...base,
        }),
    };

    useEffect(() => {
        dispatch(resetFloodStore());
    }, [])
    

    useEffect(() => {
        setLoading(apiLoading)
    }, [apiLoading])

    useEffect(() => {
        if (search != null) {
            getGeoCode(search.value.description);
        }
    }, [search]);

    useEffect(() => {
        var temp = false;
        if (selectedPropertyData.street_name === '' && selectedPropertyData.PostalCode === '') {
            temp = true;
        }
        setDisabled(temp);
    }, [selectedPropertyData]);

    const getGeoCode = (description) => {
        geocodeByAddress(description)
            .then(addr => {
                const propData = { ...selectedPropertyData };
                addr && addr[0].address_components.forEach(e => {
                    if (e.types.includes('postal_code')) {
                        propData.PostalCode = e.short_name
                    } else if (e.types.includes('street_number')) {
                        propData.street_number = e.short_name
                    } else if (e.types.includes('route')) {
                        propData.street_name = e.short_name
                    } else if (e.types.includes('locality', 'political')) {
                        propData.city = e.short_name
                    } else if (e.types.includes('country', 'political')) {
                        propData.country = e.short_name
                    } else if (e.types.includes('administrative_area_level_2', 'political')) {
                        propData.CountyName = e.short_name
                    } else if (e.types.includes('administrative_area_level_1', 'political')) {
                        propData.state = e.short_name
                    } else if (e.types.includes('postal_code_suffix')) { // Require For Flood Product
                        propData.zipPlus4 = e.short_name
                        propData.s_PostalCodeSuffix = e.short_name
                    }
                });
                propData.autocomplete = addr[0].formatted_address;
                if (addr && addr[0]) {
                    getLatLng(addr[0]).then(({ lat, lng }) => {
                        propData.latitude = lat.toFixed(7);
                        propData.longitude = lng.toFixed(7);
                    }
                    );
                }
                setSelectedPropertyData(propData);
            })
    }

    // Check Address is Severe Repetitive Loss
    const getGeoApi = async (selectedPropertyData) => {
        const reqJson = {
            'addressLine1': selectedPropertyData.street_number + ' ' + selectedPropertyData.street_name,
            'addressLine2': '',
            'city': selectedPropertyData.city,
            'state': selectedPropertyData.state,
            'zipCode': selectedPropertyData.PostalCode,
            'zipPlus4': selectedPropertyData.zipPlus4,
            'latitude': selectedPropertyData.latitude,
            'longitude': selectedPropertyData.longitude,
            'uniqueKey': localStorage.getItem('uniqueKey')
        }
        const code = 'ADDRANALYSISGEO';
        const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0 };
        // Call Api
        return await dispatch(pivotApiCall(code, request));
    }

    const onAutocompleteSubmit = async () => {
        if (selectedPropertyData.street_name === '' && selectedPropertyData.PostalCode === '') {
            toast.error(t('Please enter address!'));
            return false;
        }
        await dispatch(getBaseData(10));
        await dispatch(getPropertyData(selectedPropertyData)).then((res) => {
            if (res.status == 200) {
                dispatch(propertyDataAutocompleteStore(selectedPropertyData));
                getGeoApi(selectedPropertyData).then((result) => {
                    if (result.data.severeRepetitiveLoss === false) {
                        navigation('/flood/instantquote/step/home-type');
                    }
                    if (result.data.severeRepetitiveLoss === true) {
                        navigation('/flood/error/srl');
                    }
                    if (![null, ''].includes(result.data.cbrsFloodInsuranceProhibitionDate)) {
                        navigation('/flood/error/cbrs');
                    }
                }).catch(() => {
                    toast.error(t('Something Went Wrong!'));
                })
            } else {
                toast.error(t('We are not writing business in this area'));
            }
        });
    }

    return (
        <LoadingOverlay>
            <Loader loading={loading} />
            <section className="property-details-section">
                <Row className="justify-content-center">
                    <Col xs="12" sm="12" md="10" lg="8" xl="8" className="p-form">
                        <div className="hero-section-headings">
                            <h6 className="h-1">{t('GET YOUR INSTANT QUOTE')}</h6>
                            <h2>{t('FAST FLOOD QUOTES')}</h2>
                        </div>
                        <div className="form-search-input">
                            <Col xs="6" className="">
                                <GooglePlacesAutocomplete
                                    id="places-input"
                                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                    selectProps={{
                                        search,
                                        onChange: (e) => { setSearch(e); },
                                        styles: customStyles,
                                        placeholder: "Enter Your Address",
                                    }}
                                />
                            </Col>
                        </div>
                        <Col xs="12" className="form-btns">
                            <div className="flood-bts-div">
                                <Button
                                    id="primary-colored-btn"
                                    variant="primary"
                                    onClick={() => onAutocompleteSubmit()}
                                    disabled={disabled}
                                >
                                    {t('Next')}
                                </Button>{" "}
                            </div>
                        </Col>
                    </Col>
                </Row>
            </section>
        </LoadingOverlay>
    );
};

export default FloodAddress;
