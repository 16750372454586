const Validation = (request, checkDateisValid) => {
  var toastArray = [],
    indexArray = [],
    status = true;

  const pushToArray = (index, error = null) => {
    toastArray.push(error);
    indexArray.indexOf(index.toString()) === -1 && indexArray.push(index.toString());
    status = false;
  };

  const returnError = (key) => {
    switch (key) {
      case "s_BillTo":
        return ["Please Select Renewal Bill To", 0];
      case "s_EntityType":
        return ["Please Select Applicant Type", 0];
      case "s_AddressLine1":
        return [
          "Please enter the House No,Street Name,Direction of the Location separately. This is required to get the correct valuation of the property.",
          0,
        ];
      case "s_PostalCode":
        return ["Zip code should not be empty", 0];
      case "s_PostalCodeSuffix":
        return ["Please enter valid Suffix address", 0];
      case "s_CommType":
        return ["Please Select Phone Type", 0];
      case "s_IsInsuredNonProfitEnt":
        return ["Is the insured a non-profit entity?", 0];
      case "s_IsSmallBusinessEmp":
        return [
          "Is the insured a small business with less than 100 employees?",
          0,
        ];
      case "s_CondoOwnership":
        return ["Is the policyholder a condominium association?", 0];
      case "s_PolicyWaitingPeriod":
        return ["Waiting Period is invalid?", 2];
      case "s_FloodProgramType":
        return ["Program type must be selected!.", 3];
      case "s_CommunityNumber":
        return ["The Current Community Number is missing or invalid.", 3];
      case "s_PanelNumber":
        return ["Enter valid Panel Number.", 3];
      case "s_MapSuffix":
        return ["Enter valid Map SUffix.", 3];
      case "d_InitialFirmDate":
        return [" Initial Firm Date is missing or invalid.", 3];
      case "s_CountyName":
        return ["The County Name is missing or invalid.", 3];
      case "s_FloodZone":
        return ["Flood zone must be selected!.", 3];
      case "d_CurrentFirmDate":
        return ["Current Firm Date is missing or invalid.", 3];
      case "s_CBRSorOPA":
        return [
          "Is the building located in Coastal Barrier Resources System Area (CBRS) or an Other Protected Area (OPA)",
          3,
        ];
      case "s_ManufactureMobileHome":
        return ["Please Select Is the Structure a Mobile home?", 4];
      case "d_DateOfConstruction":
        return ["Please enter date of construction", 4];
      case "s_SubstantialImprovement":
        return [
          "Please Select Has the building been substantially improved?",
          4,
        ];
      case "s_BuildingCourseConstruction":
        return [
          "Please Select Is the building currently under construction?",
          4,
        ];
      case "s_Occupancy":
        return ["Occupancy type must be a valid description.", 5];
      case "s_BuildingUse":
        return ["Please select the value that best describes the building", 5];
      case "s_Foundation":
        return ["Foundation type is not a valid description.", 6];
      case "s_BuildingOverWater":
        return ["The Building Over Water indicator is missing or invalid.", 6];
      case "s_TotalSqFootage":
        return ["Please Enter Total sq footage of building.", 6];
      case "s_NoOfFloors":
        return ["Please Enter Total No Of Floor", 6];
      case "s_BldgConstructionType":
        return ["Please Select Building Construction Type?", 6];
      case "s_NoOfDetatchedStruct":
        return ["Please Enter Number Of Detached Structures", 6];
      case "s_IsBldgContainME":
        return [
          "Please Select Does the building contain Machinery and equipment that services the building ?",
          7,
        ];
      case "s_AllMachinaryElevated":
        return [
          "Is all machinery elevated to the height of attic, second floor, or higher?",
          7,
        ];
      case "n_ContentCvg":
        return ["Content coverage is missing or invalid", 9];
      case "s_ContentDeductible":
        return ["Content Deductible is missing or invalid", 9];
      case "s_NoOfElevator":
        return ["Please Enter No Of Elevators", 6];
    }
  };

  for (const [key, val] of Object.entries(request)) {
    // main Object
    if (
      [
        "propertyInfo",
        "policyInfo",
        "personInfo",
        "personAddr",
        "personEmailContact",
        "personPhoneContact",
        "propertyCoverageInfo",
      ].includes(key) &&
      Object.keys(val).length > 0
    ) {
      // inner Object
      for (const [innerKey, innerVal] of Object.entries(val)) {
        // check value except date
        if (
          [
            "s_BillTo",
            "s_EntityType",
            "s_AddressLine1",
            "s_PostalCode",
            "s_PostalCodeSuffix",
            "s_CommValue",
            "s_CommType",
            "s_IsInsuredNonProfitEnt",
            "s_IsSmallBusinessEmp",
            "s_CondoOwnership",
            "s_PolicyWaitingPeriod",
            "s_FloodProgramType",
            "s_CommunityNumber",
            "s_PanelNumber",
            "s_MapSuffix",
            "s_FloodZone",
            "s_CountyName",
            "d_InitialFirmDate",
            "d_CurrentFirmDate",
            "s_CBRSorOPA",
            "s_ManufactureMobileHome",
            "d_DateOfConstruction",
            "s_SubstantialImprovement",
            "s_BuildingCourseConstruction",
            "s_Occupancy",
            "s_BuildingUse",
            "s_Foundation",
            "s_BuildingOverWater",
            "s_TotalSqFootage",
            "s_NoOfFloors",
            "s_BldgConstructionType",
            "s_NoOfDetatchedStruct",
            "s_IsBldgContainME",
            "s_AllMachinaryElevated",
            "n_ContentCvg",
            "s_ContentDeductible",
            "s_NoOfElevator"
          ].includes(innerKey)
        ) {
          var errorData = [];
          // Check Date
          if ([
            "d_InitialFirmDate",
            "d_CurrentFirmDate",
            "d_DateOfConstruction"
          ].includes(innerKey) &&
            !checkDateisValid(innerVal)) {
            errorData = returnError(innerKey);
          }
          if (![
            "d_InitialFirmDate",
            "d_CurrentFirmDate",
            "d_DateOfConstruction"
          ].includes(innerKey) && [null, ""].includes(innerVal)) {
            errorData = returnError(innerKey);
          }
          if (errorData && errorData.length > 0) {
            pushToArray(errorData[1], errorData[0]);
          }
        }
        if (innerKey === "s_EntityType") {
          if (innerVal === "INDIVIDUAL") {
            if (
              [null, ""].includes(
                request["personInfo"]["s_LastOrganizationName"]
              ) ||
              [null, ""].includes(request["personInfo"]["s_FirstName"])
            ) {
              if (
                [null, ""].includes(
                  request["personInfo"]["s_LastOrganizationName"]
                )
              ) {
                pushToArray(0, "Last name should not be empty");
              }
              if ([null, ""].includes(request["personInfo"]["s_FirstName"])) {
                pushToArray(0, "First name should not be empty");
              }
            }
          }
          if (["BUSINESS", "ASSOCIATION"].includes(innerVal)) {
            if (
              [null, ""].includes(
                request["personInfo"]["s_LastOrganizationName"]
              )
            ) {
              pushToArray(0, "Organization name should not be empty");
            }
          }
        }

        if (innerKey === "s_IsMailingDiff" && innerVal === "YES") {
          if (
            [null, ""].includes(request["personAddrMail"]["s_AddressLine1"])
          ) {
            pushToArray(
              0,
              "Please enter the House No,Street Name,Direction of the Location separately. This is required to get the correct valuation of the property."
            );
          }

          if ([null, ""].includes(request["personAddrMail"]["s_PostalCode"])) {
            pushToArray(0, "Please Enter Zip code");
          }
        }

        if (innerKey === "s_IsTempMailing" && innerVal === "YES") {
          if (
            [null, ""].includes(request["tempMailingAddr"]["s_AddressLine1"])
          ) {
            pushToArray(
              0,
              "Please enter the House No,Street Name,Direction of the Location separately. This is required to get the correct valuation of the property."
            );
          }

          if ([null, ""].includes(request["tempMailingAddr"]["s_PostalCode"])) {
            pushToArray(0, "Please Enter Zip code");
          }
        }

        if (innerKey === "s_IsPolicyMortgage" && innerVal === "YES") {
          var Mortgage = request.mortgageeData;
          if (Mortgage.length == 0) {
            pushToArray(1, "Please enter Mortgagee Details");
          }
          if (Mortgage.length > 0) {
            Mortgage.map((v) => {
              if (v.s_PersonType === "DISASTER") {
                if ([null, ""].includes(v.person.s_FullLegalName)) {
                  pushToArray(1, "Please enter Mortgagee Full Name");
                }
                if ([null, ""].includes(v.address.s_AddressLine1)) {
                  pushToArray(1, "Please enter Mortgagee Address");
                }
                if ([null, ""].includes(v.address.s_PostalCode)) {
                  pushToArray(1, "Please enter Mortgagee Zip Code");
                }
              }
            });
          }
        }
        //policy wating period
        if (innerKey === "s_PolicyWaitingPeriod") {
          if (innerVal === "LOANNOWAIT") {
            if (!checkDateisValid(request.propertyInfo.d_FloodLoanClosingDt)) {
              pushToArray(2, "Please enter Loan Closing Date ");
            }
          }
          if (innerVal === "ROLLOVER_RENEWAL") {
            if (!checkDateisValid(request.propertyInfo.s_PriorPolicyExpDt)) {
              pushToArray(2, "Please enter Prior Policy Exp Date");
            }
            if ([null, ""].includes(request.propertyInfo.s_PriorCompanyNAIC)) {
              pushToArray(2, "Please enter Prior NAIC Number");
            }
            if ([null, ""].includes(request.propertyInfo.s_PriorPolicyNo)) {
              pushToArray(2, "Please enter Prior Policy Number");
            }
          }
        }

        //community CBRS or OPA
        if (innerKey === "s_CBRSorOPA" && innerVal === "YES") {
          if (!checkDateisValid(request.propertyInfo.d_CBRSOPADate)) {
            pushToArray(3, "CBRS/OPA Designation Date");
          }
        }

        //construction Location of Mobile home
        if (innerKey === "s_ManufactureMobileHome" && innerVal === "YES") {
          if (
            [null, ""].includes(request.propertyInfo.s_DtOfConstructionSource)
          ) {
            pushToArray(4, "Please enter Date of Constuction Mobile Home");
          }
        }
        if (innerKey === "s_SubstantialImprovement" && innerVal === "YES") {
          if ([null, ""].includes(request.propertyInfo.s_IsBldgNRHP)) {
            pushToArray(4, "Please enter Substantial Improvement");
          }
          if (request.propertyInfo.s_IsBldgNRHP === "NO") {
            if (
              !checkDateisValid(
                request.propertyInfo.d_SubstantialImprovmentDate
              )
            ) {
              pushToArray(4, "Please enter Substantial Improvement Date");
            }
          }
        }

        if (request.propertyInfo.s_Occupancy !== 'RESIDENTIALUNIT20') {
          if (innerKey === "n_ReplacementCost" && innerVal <= 0) {
            pushToArray(9, "Replacement Cost is missing or invalid");
          }
          if (innerKey === "n_BuildingCvg" && innerVal <= 0) {
            pushToArray(9, "Building Coverage is missing or invalid");
          }
          if (innerKey === "s_BuildingDeductible" && [0, null, ''].includes(innerVal)) {
            pushToArray(9, "Building Deductible is missing or invalid");
          }
          if (innerKey === "n_ReplacementCostRatio" && innerVal <= 0) {
            pushToArray(9, "Replacement Cost Ratio is missing or invalid");
          }
        }

        if (innerKey === "s_isValidElevationCert" && innerVal === 'YES') {
          if (!checkDateisValid(request.propertyInfo.d_CertificateDate)) {
            pushToArray(12, "Certificate Signature date is missing or invalid");
          }
        }

        if (innerKey === 's_IsPropNewlyMapped' && innerVal === 'YES') {
          var priorObj = {
            'Prior Community Number is missing or invalid': request.propertyInfo.s_PriorCommunityNumber,
            'Prior Panel Number is missing or invalid': request.propertyInfo.s_PriorPanelNumber,
            'Prior Map Suffix is missing or invalid': request.propertyInfo.s_PriorMapSuffix,
            'Prior Flood Zone is missing or invalid': request.propertyInfo.s_PriorFloodZone,
            'Prior Map Date is missing or invalid': request.propertyInfo.d_PriorMapDate
          }
          for (const [key, value] of Object.entries(priorObj)) {
            if (["Prior Community Number is missing or invalid",
              "Prior Panel Number is missing or invalid",
              "Prior Map Suffix is missing or invalid",
              "Prior Flood Zone is missing or invalid"].includes(key) && [null, ""].includes(value)) {
              pushToArray(8, key);
            }
            if (["Prior Map Date is missing or invalid"].includes(key) && !checkDateisValid(value)) {
              pushToArray(8, key);
            }
          }
        }
      }
    }

    //occupancy

    // effective date
    if (key === "effectiveDate") {
      if (!checkDateisValid(val)) {
        pushToArray(2, "Please enter Effective Date");
      }
    }
  }
  //coverage check based on Occupancy
  var occupancy = request.propertyInfo.s_Occupancy;
  var isBldgCvgExceed = false;
  var isContentCvgExceed = false;
  var maxLimit = "";
  var NOU = "",
    RC = "";

  // Building coverage maxes out at 250k and contents at 100k for residential
  if (
    [
      "SINGLEFAMILY20",
      "TWOFOURFAMILY20",
      "RESIDENTIALUNIT20",
      "RESIDENTIALMOBILEHOME20",
    ].includes(occupancy)
  ) {
    if (request.propertyCoverageInfo.n_BuildingCvg > 250000) {
      isBldgCvgExceed = true;
      maxLimit = 250000;
    }
    if (request.propertyCoverageInfo.n_ContentCvg > 100000) {
      isContentCvgExceed = true;
      maxLimit = 100000;
    }
  }
  // Building coverage maxes out at 250k and contents at 100k for residential
  if (["OTHERRESIDENTIAL20"].includes(occupancy)) {
    if (request.propertyCoverageInfo.n_BuildingCvg > 500000) {
      isBldgCvgExceed = true;
      maxLimit = 500000;
    }
    if (request.propertyCoverageInfo.n_ContentCvg > 100000) {
      isContentCvgExceed = true;
      maxLimit = 100000;
    }
  }
  // For a non-residential, it is 500k and 500k.
  if (
    [
      "NONRESIDENTIALUNIT20",
      "NONRESIDENTIALBUILD20",
      "NONRESIDENTIALMOBILEHOME20",
    ].includes(occupancy)
  ) {
    if (request.propertyCoverageInfo.n_BuildingCvg > 500000) {
      isBldgCvgExceed = true;
    }
    if (request.propertyCoverageInfo.n_ContentCvg > 500000) {
      isContentCvgExceed = true;
    }
    maxLimit = 500000;
  }
  // RCB max for building can be the RCE value or 250k multiplied by the number of units. wich ever is less. Contents max is 100k
  if (["RESIDENTIALCONDOBUILD20"].includes(occupancy)) {
    if (
      (RC < 250000 * NOU && request.propertyCoverageInfo.n_BuildingCvg > RC) ||
      (250000 * NOU < RC &&
        request.propertyCoverageInfo.n_BuildingCvg > 250000 * NOU)
    ) {
      isBldgCvgExceed = true;
    }
    if (request.propertyCoverageInfo.n_ContentCvg > 100000) {
      isContentCvgExceed = true;
      maxLimit = 100000;
    }
  }

  if (isBldgCvgExceed == true) {
    pushToArray(
      5,
      "Coverage Max Limit Exceed!, building coverage should be upto " + maxLimit
    );
  }
  if (isContentCvgExceed == true) {
    pushToArray(
      5,
      "Coverage Max Limit Exceed!, content coverage should be upto " + maxLimit
    );
  }
  if (request.propertyInfo.s_IsPolicyMortgage == true) {
    pushToArray(5, "Policy Mortgage is missing or invalid");
  }
  return {
    toastArray,
    indexArray,
    status,
  };
};

export default Validation;
