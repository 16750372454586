import React, { Fragment , useEffect,useState } from "react";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaInbox } from "react-icons/fa";
import { useDispatch, useSelector , connect } from 'react-redux';
import {
  sizePerPageRenderer,
  pageButtonRenderer,
} from "../../../components/CustomTablePagination";
import QuoteButtons from "./components/QuoteTabComponents/QuoteButtons";

const SubmitTab = (props) => {
  const [documentTableData, setDocumentTableData] = useState([]);
  const productsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        document_name: "HO62015005355",
        attached: "RICHARD FOX AND LINA FOX",
        file_name: "File",
        action: "Closed",
      });
    }
    return items;
  };

  	
useEffect(() => {
  setDocumentTableData(props.documentData);
}, [props]);

  const products = productsGenerator(100);

  const columns = [
    {
      dataField: "s_SourceName",
      text: "Document Name",
    },
    {
      dataField: "s_IsAttached",
      text: "Attached",
    },
    {
      dataField: "docName",
      text: "File Name",
    },
    {
      dataField: "action",
      text: "Action",
    },
  ];

  const options = {
    sizePerPageRenderer,
    pageButtonRenderer,
    showTotal: true,
    alwaysShowAllBtns: true,
  };
  
  const handleSubmitBound = () => {
	props.handleSubmitBound();
  }

  return (
    <Fragment>
      <div className="search-quote-tab-div">
        <div className="search-quote-btns-div">
          <QuoteButtons isBind={props.isBind} callGeneratePdf={props.callGeneratePdf} rateLoader={props.rateLoader}/>
        </div>
        {/* billing info card */}

        <div
          className="activity-log-tab-table"
          id="activity-log-tab-table-outer-div"
          style={{ paddingBottom: "2rem" }}
        >
          <BootstrapTable
            wrapperClasses="management-functional-table"
            bootstrap4
            keyField="document_name"
            data={documentTableData}
            columns={columns}
            bordered={false}
            pagination={paginationFactory(options)}
            noDataIndication="No data available in table , Please add Policy interest by clicking blue button."
          />
        </div>
        <div className="text-center">
          <Button
		  disabled={props.isBind}
          id="primary-colored-btn"
          variant="primary"
          hidden={props.isBind}
		  onClick={() => handleSubmitBound()}
        >
        <FaInbox size={21} color={"#fff"} />&nbsp;&nbsp;
          Submit Bound
        </Button>
        </div>
      </div>
      {/* <div className="activity-log-tab-bts-div">
        <Button variant="primary" id="primary-outlined-btn">
          {" "}
          Rate{" "}
        </Button>{" "}
        <Button
          id="primary-colored-btn"
          variant="primary"
          onClick={() => setKey("Applicant")}
        >
          Previous
        </Button>{" "}
        <Button
          id="primary-colored-btn"
          variant="primary"
          onClick={() => setKey("Activity log")}
        >
          Next
        </Button>{" "}
      </div> */}
    </Fragment>
  );
};
export default SubmitTab;