import React from 'react'

// DataTable Paging Showing Start, To and Total Data Text
export function showDataTableTotalText(start, to, total) {
  return (
    <p style={{ color: 'blue' }}>
      From {start} to {to}, total is {total}.
    </p>
  );
}

// Curency Formater
export function currencyFormat(amount) {
  var amt = 0;
  var currencyType = 'en-US'; // en-IN
  var currency = 'USD' // INR

  let amountFloat = parseFloat(amount);
  if (amountFloat > 0) {

    amt = new Intl.NumberFormat(currencyType, {
      style: 'currency',
      currency: currency
    }).format(amountFloat);

  } else {
    if (amountFloat == 0 || isNaN(amountFloat)) {
      amt = '$0.00';
    } else {
      var value = new Intl.NumberFormat(currencyType, {
        style: 'currency',
        currency: currency
      }).format(amountFloat);
      amt = <span style={{ color: 'red' }}>{'$(' + value.replace(/[-$]/g, '') + ')'}</span>;
    }
  }

  return amt;
}

// to encrypt the given Object and store in localStorage
export function EncryptOrDecrypt(data, condition = 'E') {
	
	var CryptoJS = require("crypto-js");
	var key = process.env.REACT_APP_LOCALSTORAGE_ENCRYPTION_KEY;
	
	var returnData = '';
	if(condition == 'E') {
		
		returnData = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
	}
	
	if(condition == 'D') {
		var bytes = CryptoJS.AES.decrypt(data, key);
		returnData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	}
	return returnData;
}

