import React, { Fragment, useState,useEffect } from "react";
import { Button, Row, Col, Form, Table, Accordion,Modal } from "react-bootstrap";
import basicBadge from "../../../../../assets/badges/basic.png";
import optionalBadge from "../../../../../assets/badges/optional.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MapComponent from "../../../../../components/MapComponent";
import { useDispatch, useSelector , connect } from 'react-redux';
import { getZipDetails,getEditEmptyQuoteData } from "../../../../../services/quoteService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "./address.scss";
import { toast } from 'react-toastify';



const CurrentAddress = (props) => {
	const dispatch = useDispatch();
  
  	//Zip 
	  const [quoteData, setQuoteData] = useState(props.quoteData);
	  const [loadingStatus, setLoadingStatus] = useState(false);
	  const [showModal, setShowModal] = useState(false);
    const [zipCode, setZipCode] = useState();

	  

	  const [zipData, setZipData] = useState([]);
	  
	  const changeZip = (e) => {
		setZipCode(e.target.value);
	  }
	  
	  
		useEffect(() => {
			dispatch(getEditEmptyQuoteData(quoteData));
		}, [quoteData]);

    useEffect(() => {
			if(props.addressType == 'personMailingAddr'){
      setZipCode(props.quoteData.personMailingAddr.s_PostalCode);
    }else if(props.addressType == 'personPriorAddr'){
      setZipCode(props.quoteData.personPriorAddr.s_PostalCode);
    }
		}, []);
	  
	  const zipCodeApi = () => {
		  var zipAddrFor = props.addressType;
      if(zipAddrFor == 'personMailingAddr'){
        if (props.quoteData.personMailingAddr.s_PostalCode != zipCode &&
          zipCode != '' && 
          zipCode >= 1) {
           
          
          setLoadingStatus(true);
          dispatch(getZipDetails('13', zipCode, 'APPLICANT')).then((res) => {
			  if(res.data.status == 'Y'){
				  if(res.data.count > 1) {
						setZipData(res.data.zipData);
			          	setLoadingStatus(false);
			          	setShowModal(true);  
				  }else{
					setZipDetailsData(res.data.zipData[0]);
					setLoadingStatus(false);
				  }
				  		  
			  }else{
				setLoadingStatus(false);
				toast.error(res.data.errorMsg);
				setZipCode('');
			  }
          
    
          });
        }
      }else if(zipAddrFor == 'personPriorAddr'){
        if (props.quoteData.personPriorAddr.s_PostalCode != zipCode &&
          zipCode != '' && 
          zipCode >= 1) {
          
          setLoadingStatus(true);
          dispatch(getZipDetails('13', zipCode, 'APPLICANT')).then((res) => {
			if(res.data.status == 'Y'){
				if(res.data.count > 1) {
					  setZipData(res.data.zipData);
						setLoadingStatus(false);
						setShowModal(true);  
				}else{
				  setZipDetailsData(res.data.zipData[0]);
				  setLoadingStatus(false);
				}
						  
			}else{
			  setLoadingStatus(false);
			  toast.error(res.data.errorMsg);
			  setZipCode('');
			}
    
          });
        }
      }
	  }
	  
	  const setZipDetailsData = (data) => {
		var quoteDataVar = props.quoteData;
		const tempArr = quoteData;
		var zipAddrFor = props.addressType;
		  tempArr[zipAddrFor]['s_CountyName'] = data.s_ZipCounty;
		quoteDataVar[zipAddrFor]['s_CountyName'] = data.s_ZipCounty;
		  quoteDataVar[zipAddrFor]['s_StateName'] = data.s_StateCode;
		  quoteDataVar[zipAddrFor]['s_CityName'] = data.s_CityName;
		quoteDataVar[zipAddrFor]['s_PostalCode'] = zipCode;
		quoteDataVar[zipAddrFor]['n_CountyId_FK'] = data.n_CountyId_FK;
		quoteDataVar[zipAddrFor]['n_CityId_FK'] = data.n_CityId_PK;
		quoteDataVar[zipAddrFor]['n_StateId_FK'] = data.n_StateId_PK;
		quoteDataVar[zipAddrFor]['n_CountryId_FK'] = data.n_CountyId_FK;

		setShowModal(false);
		setQuoteData(quoteDataVar);
		dispatch(getEditEmptyQuoteData(quoteDataVar));
	  }
  
  return (
	<LoadingOverlay >
	<Loader loading={loadingStatus} />
  {
	<Row className="align-items-end">
        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              House No<span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Control
              className="dashboard-group-input"
              placeholder=""
			  value={props.addressData && props.addressData.s_HouseNo ? props.addressData.s_HouseNo : ''}
			  name="s_HouseNo"
              title={props.addressType}
			  onChange = {props.handleChange}
        disabled={props.isBind}
        required
            />
          </Form.Group>
        </Col>
        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              Street Name <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Control
              className="dashboard-group-input"
              type="text"
              placeholder=""
			  value={props.addressData && props.addressData.s_StreetName ? props.addressData.s_StreetName : ''}
			  name="s_StreetName"
              title={props.addressType}
			  onChange = {props.handleChange}
        disabled={props.isBind}
        required
            />
          </Form.Group>
        </Col>
        
        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              County<span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Control
              className="dashboard-group-input"
              type="text"
              placeholder=""
              disabled
			  value={props.addressData && props.addressData.s_CountyName ? props.addressData.s_CountyName : ''}
			  name="s_CountyName"
              title={props.addressType}
			  onChange = {props.handleChange}
            />
          </Form.Group>
        </Col>
        <Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              State <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Control
              className="dashboard-group-input"
              type="text"
              placeholder=""
              disabled
			  value={props.addressData && props.addressData.s_StateName ? props.addressData.s_StateName : ''}
			  name="s_StateName"
              title={props.addressType}
			  onChange = {props.handleChange}
            />
          </Form.Group>
        </Col>
		<Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              City <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Control
              className="dashboard-group-input"
              type="text"
              placeholder=""
              disabled
			  value={props.addressData && props.addressData.s_CityName ? props.addressData.s_CityName : ''}
			  name="s_CityName"
              title={props.addressType}
			  onChange = {props.handleChange}
            />
          </Form.Group>
        </Col>
		<Col xs="12" sm="12" md="6" lg="2" xl="2">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              Zip <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Control
              className="dashboard-group-input"
              placeholder=""
			  name="s_PostalCode"
              title={props.addressType}
			  value={zipCode}
			  onBlur={() => zipCodeApi()}
			  required
			  onChange={(e) => changeZip(e)}
        disabled={props.isBind}
            />
          </Form.Group>
        </Col>
		{/* Modal */}
		<Modal size="md" show={showModal} className="add-mortage-modal">
		  <Modal.Header id="add-mortage-modal-header" >
			<Modal.Title>Select City</Modal.Title>
		  </Modal.Header>
		  <Modal.Body className="add-mortage-modal-body">
			<Row>
			  <Table className="p-0 m-0" size="sm" responsive striped>
				<thead>
				  <tr>
					<th>City</th>
					<th>County</th>
				  </tr>
				</thead>
				<tbody>
				  {zipData.map((data, i) => {
					return (
					  <tr key={i}>
						<td><a href='#' onClick={() => setZipDetailsData(data)}>{data.s_CityName}</a></td>
						<td>{data.s_ZipCounty}</td>
					  </tr>
					)
				  })}
				</tbody>
			  </Table>
			</Row>
		  </Modal.Body>
		</Modal>
      </Row>
	  }
	  </LoadingOverlay>
	);
	
};

function mapStateToProps(state) {
	return {
	  	quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	};
  } 
  export default connect(mapStateToProps)(CurrentAddress);
