import React, { Fragment, useState } from "react";
import { Button, Row, Col, Form, Table, Accordion } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector , connect } from 'react-redux';
import { useTranslation } from "react-i18next";

const FooterButton = (props) => {
  const { t } = useTranslation();

  return (
    <>
      { props.isBind == false ?
        <div className="activity-log-tab-bts-div">
              <Button variant="primary" id="primary-outlined-btn"
            onClick={() => props.rateSubmit()}
          >
      
            {t("Save & Rate")}
          </Button>{" "}
          <Button id="primary-grey-btn" variant="primary" disabled hidden>
            {t("Previous")}
          </Button>{" "}
          <Button
            id="primary-colored-btn"
            variant="primary"
            onClick={() => props.setKey("Applicant")}
            hidden
          >
            {t("Next")}
          </Button>{" "}
        </div>
      : ""}
      </>
	);
	
};
  function mapStateToProps(state) {
	return {
	  	quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	};
  } 
  export default connect(mapStateToProps)(FooterButton);