import React, { useState,useEffect,useContext } from "react";
// import logo from "../../assets/main-nav-logo.png";
//import logo from "../../assets/unifimga_logo.png";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import usFlag from "../../assets/usa_flag.png";
import { useNavigate } from 'react-router-dom';
import { LangContext } from "../../contexts/LangContext";
import "./simple-navbar.scss";
import "../layout/header/header.scss";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SimpleNavbar = () => {
  const { selectedLng, handleDropdownChange } = useContext(LangContext);
  const navigation = useNavigate()
  const [logo, setLogo] = useState(localStorage.getItem('company_logo'));

  return (
    <Navbar className="simple-navbar">
      <img src={logo} style={{ marginBottom: "10px", cursor: 'pointer',width:"200px" }} onClick={() => navigation('/')} />
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Nav style={{ alignItems: "center" }}>
          <NavDropdown
            style={{ marginRight: 0 }}
            className="lng-selector-drop"
            title={
              <div className="lng-select-drop-title-div">
                <img
                  style={{ width: "20px", height: "20px", marginRight: "10px" }}
                  src={usFlag}
                />
                <span>{selectedLng}</span>
              </div>
            }
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item
              onClick={() => handleDropdownChange("English", "en")}
            >
              English
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={() => handleDropdownChange("Spanish", "spa")}
            >
              Spanish
            </NavDropdown.Item>
          </NavDropdown>
            {/* Notification */}
            <ToastContainer
              theme={'light'}
              position="top-center"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default SimpleNavbar;
