import React, { Fragment, useState } from "react";
import { Button, Row, Col, Form, Table, Accordion } from "react-bootstrap";
import basicBadge from "../../../../../assets/badges/basic.png";
import optionalBadge from "../../../../../assets/badges/optional.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MapComponent from "../../../../../components/MapComponent";
import { useDispatch, useSelector , connect } from 'react-redux';
import { useTranslation } from "react-i18next";

const Discount = (props) => {
  const [date1, setDate1] = useState(new Date());
  const { t } = useTranslation();
  return (
	<Row className="align-items-end">
        <Col xs="12" sm="12" md="6" lg="3" xl="3">
          <div className="quote-type-subdiv">
            <Form.Check
              type="checkbox"
              label={t("Central Fire Alarm")}
              id="custom-checker"
			  defaultChecked={props.quoteData.propertyInfo.s_FireAlarmCode && props.quoteData.propertyInfo.s_FireAlarmCode == 'YES' ? true : false}
			  name="s_FireAlarmCode"
	          parentobject="propertyInfo"
			  onChange = {props.handleCheckboxChange}
        disabled={props.isBind}
			  checkedvalue='YES'
              uncheckedvalue='NO'
            />
          </div>
        </Col>
        <Col xs="12" sm="12" md="6" lg="3" xl="3">
          <div className="quote-type-subdiv">
            <Form.Check
              type="checkbox"
              label={t("Central Burglar Alarm")}
              id="custom-checker"
			  defaultChecked={props.quoteData.propertyInfo.s_BurglarAlarmCode && props.quoteData.propertyInfo.s_BurglarAlarmCode == 'YES' ? true : false}
			  name="s_BurglarAlarmCode"
	          parentobject="propertyInfo"
			  onChange = {props.handleCheckboxChange}
        disabled={props.isBind}
			  checkedvalue='YES'
              uncheckedvalue='NO'
            />
          </div>
        </Col>
        <Col xs="12" sm="12" md="6" lg="3" xl="3">
          <div className="quote-type-subdiv">
            <Form.Check
              type="checkbox"
              label={t("Gated Community")}
              id="custom-checker"
			  defaultChecked={props.quoteData.riskAdditionalInfo.s_GatedComm && props.quoteData.riskAdditionalInfo.s_GatedComm == 'YES' ? true : false}
			  name="s_GatedComm"
	          parentobject="riskAdditionalInfo"
			  onChange = {props.handleCheckboxChange}
        disabled={props.isBind}
			  checkedvalue='YES'
              uncheckedvalue='NO'
            />
          </div>
        </Col>
        <Col xs="12" sm="12" md="6" lg="3" xl="3">
          <div className="quote-type-subdiv">
            <Form.Check
              type="checkbox"
              label={t("Smoker Surcharge")}
              id="custom-checker"
			  defaultChecked={props.quoteData.propertyInfo.s_Smoker && props.quoteData.propertyInfo.s_Smoker == 'YES' ? true : false}
			  name="s_Smoker"
	          parentobject="propertyInfo"
			  onChange = {props.handleCheckboxChange}
        disabled={props.isBind}
			  checkedvalue='YES'
              uncheckedvalue='NO'
            />
          </div>
        </Col>
        <Col xs="12" sm="12" md="6" lg="3" xl="3">
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
            style={{ marginTop: "1rem" }}
          >
            <Form.Label className="dashboard-group-label">
              {t("Automatic Sprinklers")}
            </Form.Label>
            <Form.Select
              className="dashboard-group-input"
              id="select-ym-option"
			  name="s_SprinklerCode"
			  parentobject="propertyInfo"
			  title="propertyInfo"
			  value={props.quoteData.propertyInfo.s_SprinklerCode ? props.quoteData.propertyInfo.s_SprinklerCode : ''}
			  onChange = {props.handleChange}
        disabled={props.isBind}
			>
			  <option value="">Select</option>
			  {props.getBaseData.MHSPRINKLER.map((x) => (
				<option value={x.s_AppCodeName}>
				  {x.s_AppCodeNameForDisplay}
				</option>
			  ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
	);
	
};
  function mapStateToProps(state) {
	return {
	  	quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	};
  } 
  export default connect(mapStateToProps)(Discount);