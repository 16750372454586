import React, { useState } from "react";
// import logo from "../../../assets/logo.png";
//import logo from "../../../assets/unifimga_logo.png";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";
import {BsCurrencyDollar, BsSearch, BsCardList} from "react-icons/bs";
import {FiAlertCircle} from "react-icons/fi";
import { useTranslation } from "react-i18next";
import {
  FaHome,
  FaCheck,
  FaUmbrella,
  FaTv,
  FaClipboardList,
  FaChartBar,
  FaExternalLinkAlt,
  FaListAlt,
  FaLuggageCart,
  FaShoppingCart,
  FaAngleDown,
  FaAngleUp,
} from "react-icons/fa";
import "./sidebar.scss";

const Sidebar = () => {
  const [compact, setCompact] = useState(1);
  const [toggle, setToggle] = useState(0);
  const [subMenu, setSubMenu] = useState(0);
  const [menu, setMenu] = useState();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const { t } = useTranslation();
  const [logo, setLogo] = useState(localStorage.getItem('company_logo'));

  return (
    <div className={`sidebar-main ${compact ? "compact" : ""}`}>
      <div className="sidebar-toggle" onClick={() => setCompact(!compact)}>
        {compact ? <AiOutlineCaretRight size={20} /> : <AiOutlineCaretLeft size={20} />}
        
      </div>

      {/* LOGO */}
      <div className="sidebar-logo">
        <img src={logo} style={{ width:"200px" , marginRight:"25px" }}  />
      </div>

      {/* LINKS */}
      <div className="sidebar-menu-links">
        <Link to={"/"}>
          <div
            className={`sidebar-main-link ${
              splitLocation[1] === "" ? "active" : ""
            }`}
          >
            <FaHome size={20} className="sidebar-link-icon" />
            <span className="sidebar-link-name">{t('Dashboard')}</span>
          </div>
        </Link>

        <Link to={"/quote"}>
          <div
            className={`sidebar-main-link ${
              splitLocation[1] === "quote" ? "active" : ""
            }`}
          >
            <BsCurrencyDollar size={20} className="sidebar-link-icon" />
            <span className="sidebar-link-name">{t('Quote & Submit')}</span>
          </div>
        </Link>

        <Link to={"/core/policy"}>
          <div
            className={`sidebar-main-link ${
              splitLocation[1] === "core" ? "active" : ""
            }`}
          >
            <BsSearch size={20} className="sidebar-link-icon" />
            <span className="sidebar-link-name">{t('Policy Search')}</span>
          </div>
        </Link>

        <Link to={"/active-log"}>
          <div
            className={`sidebar-main-link ${
              splitLocation[1] === "active-log" ? "active" : ""
            }`}
          >
            <BsCardList size={20} className="sidebar-link-icon" />
            <span className="sidebar-link-name">{t('Activity Log & Notes')}</span>
          </div>
        </Link>

        <Link to={"/management-reports"}>
          <div
            className={`sidebar-main-link ${
              splitLocation[1] === "management-reports" ? "active" : ""
            }`}
          >
            <FaClipboardList size={20} className="sidebar-link-icon" />
            <span className="sidebar-link-name"> {t('Management Reports')} </span>
          </div>
        </Link>

        <Link to={"/statistics"}>
          <div
            className={`sidebar-main-link ${
              splitLocation[1] === "statistics" ? "active" : ""
            }`}
          >
            <FaChartBar size={20} className="sidebar-link-icon" />
            <span className="sidebar-link-name"> {t('Statistics')} </span>
          </div>
        </Link>

        <Link to={"/resources"}>
          <div
            className={`sidebar-main-link ${
              splitLocation[1] === "resources" ? "active" : ""
            }`}
          >
            <FiAlertCircle size={20} className="sidebar-link-icon" />
            <span className="sidebar-link-name"> {t('Resources')} </span>
          </div>
        </Link>

        {/*<Link to={"/other-links"}>
          <div
            className={`sidebar-main-link ${
              splitLocation[1] === "other-links" ? "active" : ""
            }`}
          >
            <FaExternalLinkAlt size={20} className="sidebar-link-icon" />
            <span className="sidebar-link-name"> {t('Other Links')} </span>
          </div>
        </Link>*/}

        {/* <Link to={"/mortgagee"}>
          <div
            className={`sidebar-main-link ${
              splitLocation[1] === "mortgagee" ? "active" : ""
            }`}
          >
            <FaTv size={20} className="sidebar-link-icon" />
            <span className="sidebar-link-name">{t('Mortgagee Change')}</span>
          </div>
        </Link> */}

        {/* <div
          style={{ position: "relative" }}
          className={`sidebar-main-link ${
            splitLocation[1] === "management-reports" ? "active" : ""
          }`}
        >
          <Link to={"/management-reports"}>
            <FaListAlt size={20} className="sidebar-link-icon" />
            <span className="sidebar-link-name">{t('Management Reports')}</span>
          </Link>
          {toggle && menu === "management" ? (
            <FaAngleUp
              className="sidebar-menu-icons"
              size={20}
              color={"#fff"}
              style={{ position: "absolute", right: "37px" }}
              onClick={() => {
                setMenu("management");
                setToggle(!toggle);
              }}
            />
          ) : (
            <FaAngleDown
              className="sidebar-menu-icons"
              style={{ position: "absolute", right: "37px" }}
              size={20}
              onClick={() => {
                setMenu("management");
                setToggle(!toggle);
              }}
            />
          )}
        </div>

        {toggle && menu === "management" ? (
          <ul className="sidebar-menu">
            <li>
              <Link to="/management-reports/link1">
                <p>Link1</p>
              </Link>
            </li>
            <li>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {" "}
                <Link to="/management-reports/link2">
                  <p>Link2</p>
                </Link>
                {subMenu ? (
                  <FaAngleUp
                    size={20}
                    className="sidebar-menu-icons"
                    id="sidebar-submenu-icons"
                    onClick={() => setSubMenu(!subMenu)}
                  />
                ) : (
                  <FaAngleDown
                    size={20}
                    id="sidebar-submenu-icons"
                    className="sidebar-menu-icons"
                    onClick={() => setSubMenu(!subMenu)}
                  />
                )}
              </div>
              {subMenu ? (
                <ul style={{ display: "block", listStyle: "none" }}>
                  <li>
                    <Link to="/management-reports/link2/sublink1">
                      <p>Sublink1</p>
                    </Link>
                    <Link to="/management-reports/link2/sublink2">
                      <p>Sublink2</p>
                    </Link>
                  </li>
                </ul>
              ) : null}
            </li>
          </ul>
        ) : null} */}

        {/*<Link to={"/product-offerings"}>
          <div
            className={`sidebar-main-link ${
              splitLocation[1] === "product-offerings" ? "active" : ""
            }`}
          >
            <FaLuggageCart size={20} className="sidebar-link-icon" />
            <span className="sidebar-link-name">{t('Product Offerings')}</span>
          </div>{" "}
        </Link>*/}

        {/*<Link to={"/marketing-tools"}>
          <div
            className={`sidebar-main-link ${
              splitLocation[1] === "marketing-tools" ? "active" : ""
            }`}
          >
            <FaShoppingCart size={20} className="sidebar-link-icon" />
            <span className="sidebar-link-name">{t('Marketing Tools')}</span>
          </div>
        </Link>*/}
      </div>
    </div>
  );
};

export default Sidebar;
