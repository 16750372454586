import React from "react";
import { Route, Routes } from "react-router-dom";
import FloodError from "./instantQuote/flood-error";
import FloodAddress from "./instantQuote/flood-address/FloodAddress";
import FloodStep from "./instantQuote/Steps";
import FloodApplication from "./quote/FloodApplication";
import FloodPayment from "./quote/Payment";
import FloodThankYou from "./instantQuote/flood-thank-you/FloodThankYou";
import FloodPayNow from "./instantQuote/flood-pay-now/FloodPayNow";

const Floodroutes = () => {
    return (
        <Routes>
            <Route
                path="/*"
                element={<FloodAddress />}
            />
            <Route
                path="/instantquote/step/:number"
                element={<FloodStep />}
            />
            <Route
                path="/quote"
                element={<FloodApplication />}
            />
            <Route
                path="/quote/:poTransPk"
                element={<FloodApplication />}
            />
            <Route
                path="/payment/:type/:poTransPk"
                element={<FloodPayment />}
            />
            <Route
                path="/error/:type"
                element={<FloodError />}
            />
            <Route
                path="/pay-now/:corePoTransPK"
                element={<FloodPayNow />}
            />
            <Route
                path="/thank-you"
                element={<FloodThankYou />}
            />
        </Routes>
    );
};

export default Floodroutes;