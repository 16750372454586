import React, { Fragment, useState, useEffect } from "react";
import { Button, Row, Col, Form, Table, Accordion } from "react-bootstrap";
import basicBadge from "../../../../../assets/badges/basic.png";
import optionalBadge from "../../../../../assets/badges/optional.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MapComponent from "../../../../../components/MapComponent";
import { useDispatch, useSelector , connect } from 'react-redux';
import { getQuoteData,getEmptyQuoteData,getBaseQuoteData,getEditEmptyQuoteData } from "../../../../../services/quoteService";
import NumberFormat from 'react-number-format';
import "./coverage.css";
import { useTranslation } from "react-i18next";

const Coverage = (props) => {
	const [quoteData, setQuoteData] = useState(props.quoteData);
	const dispatch = useDispatch();
  const { t } = useTranslation();
  const [aDwelling, setDwelling] = useState(props.quoteData.propertyCoverageInfo && props.quoteData.propertyCoverageInfo.n_coverageA ? props.quoteData.propertyCoverageInfo.n_coverageA : '');
  const [BOtherStructuresTxt, setBOtherStructuresTxt] = useState(props.quoteData.propertyCoverageInfo && props.quoteData.propertyCoverageInfo.n_coverageB ? props.quoteData.propertyCoverageInfo.n_coverageB : '');
  const [BOtherStructuresSel, setBOtherStructuresSel] = useState(2);
  const [CPersonalPropertyTxt, setCPersonalPropertyTxt] = useState(props.quoteData.propertyCoverageInfo && props.quoteData.propertyCoverageInfo.n_coverageC ? props.quoteData.propertyCoverageInfo.n_coverageC : '');
  const [CPersonalPropertySel, setCPersonalPropertySel] = useState(50);
  const [DLossofUseTxt, setDLossofUseTxt] = useState(props.quoteData.propertyCoverageInfo && props.quoteData.propertyCoverageInfo.n_coverageD ? props.quoteData.propertyCoverageInfo.n_coverageD : '');
  const [DLossofUseSel, setDLossofUseSel] = useState(10);
  const [EPersonalLiabilitySel, setEPersonalLiabilitySel] = useState(props.quoteData.propertyCoverageInfo && props.quoteData.propertyCoverageInfo.n_coverageE ? props.quoteData.propertyCoverageInfo.n_coverageE : '');
  const [FMedicalPaymentsSel, setFMedicalPaymentsSel] = useState(props.quoteData.propertyCoverageInfo && props.quoteData.propertyCoverageInfo.n_CoverageF ? props.quoteData.propertyCoverageInfo.n_CoverageF : '');
  
	useEffect(() => {
    if(props.quoteData.transactionInfo.n_potransaction_PK != null){
      calculatePercentage();
    }

		dispatch(getEditEmptyQuoteData(props.quoteData));
	}, [props.quoteData.propertyCoverageInfo]);
	
  const calculatePercentage = () => {
    const aDwelling = Number(props.quoteData.propertyCoverageInfo && props.quoteData.propertyCoverageInfo.n_coverageA ? props.quoteData.propertyCoverageInfo.n_coverageA : '');
    const BOtherStructures = Number(props.quoteData.propertyCoverageInfo && props.quoteData.propertyCoverageInfo.n_coverageB ? props.quoteData.propertyCoverageInfo.n_coverageB : '');
    const CPersonalProperty = Number(props.quoteData.propertyCoverageInfo && props.quoteData.propertyCoverageInfo.n_coverageC ? props.quoteData.propertyCoverageInfo.n_coverageC : '');
    const DLossofUse = Number(props.quoteData.propertyCoverageInfo && props.quoteData.propertyCoverageInfo.n_coverageD ? props.quoteData.propertyCoverageInfo.n_coverageD : '');
    
    const BOtherStructuresPer = Math.round((100 * BOtherStructures) / aDwelling);
    setBOtherStructuresSel(BOtherStructuresPer);

    const CPersonalPropertyPer = Math.round((100 * CPersonalProperty) / aDwelling);
    setCPersonalPropertySel(CPersonalPropertyPer);

    const DLossofUsePer = Math.round((100 * DLossofUse) / aDwelling);
    setDLossofUseSel(DLossofUsePer);

    const removedDecimal = Math.trunc(props.quoteData.propertyCoverageInfo && props.quoteData.propertyCoverageInfo.n_coverageE ? props.quoteData.propertyCoverageInfo.n_coverageE : '');
    setEPersonalLiabilitySel(removedDecimal);

    if(removedDecimal == 100000){
      setFMedicalPaymentsSel(1000);
    } else if(removedDecimal == 300000){
      setFMedicalPaymentsSel(3000);
    } else if(removedDecimal == 500000){
      setFMedicalPaymentsSel(5000);
    } else {
      setFMedicalPaymentsSel('');
    }
  }
  
  const handleCheckboxChange = (e) => {
    var quoteDataVar = props.quoteData;
    const { name, value, id,attributes, checked } = e.target;
    var parentObjectName = attributes.parentobject.value;
    if(checked == true){

    }else {
      var key = quoteDataVar[parentObjectName][name];
      quoteDataVar[parentObjectName][name] = null;
      setQuoteData({...quoteDataVar,quoteDataVar});
    }
  }

  const handleChangeCoverage = (e) => {
    //remove comma
    var actualValue=e.target.value;
    actualValue=actualValue.replace(/\,/g,'');
    actualValue=actualValue.replace(/\$/g,'');
    setDwelling(roundAmount(actualValue));
    const calcB = (actualValue/100)* BOtherStructuresSel;
    setBOtherStructuresTxt(roundAmount(calcB));

    const calcC = (actualValue/100)* CPersonalPropertySel;
    setCPersonalPropertyTxt(roundAmount(calcC));
    
    const calcD = (actualValue/100)* DLossofUseSel;
    setDLossofUseTxt(roundAmount(calcD));

    const tempArr = props.quoteData;
  	tempArr['propertyCoverageInfo']['n_coverageA'] = actualValue;
    tempArr['propertyCoverageInfo']['n_coverageB'] = roundAmount(calcB);
    tempArr['propertyCoverageInfo']['n_coverageC'] = roundAmount(calcC);
    tempArr['propertyCoverageInfo']['n_coverageD'] = roundAmount(calcD);
    tempArr['propertyCoverageInfo']['n_coverageE'] = roundAmount(EPersonalLiabilitySel);
    dispatch(getEditEmptyQuoteData(tempArr));
  }
  
  const handleChangeSelect = (e) => {
    const tempArr = props.quoteData;
    let calcF ='';
    if(e.target.name == "BOtherStructurePer"){
      setBOtherStructuresSel(e.target.value);
      const calcB = (aDwelling/100)* e.target.value;
      setBOtherStructuresTxt(roundAmount(calcB));
      tempArr['propertyCoverageInfo']['n_coverageB'] = roundAmount(calcB);
    } else if(e.target.name == "CPersonalPropertyPer"){
      setCPersonalPropertySel(e.target.value);
      const calcC = (aDwelling/100)* e.target.value;
      setCPersonalPropertyTxt(roundAmount(calcC));
      tempArr['propertyCoverageInfo']['n_coverageC'] = roundAmount(calcC);
    } else if(e.target.name == "DLoosOfUsePer"){
      setDLossofUseSel(e.target.value);
      const calcD = (aDwelling/100)* e.target.value;
      setDLossofUseTxt(roundAmount(calcD));
      tempArr['propertyCoverageInfo']['n_coverageD'] = roundAmount(calcD);
    } else if(e.target.name == "EPersonalLiabilityPer"){
      setEPersonalLiabilitySel(e.target.value);
      if(e.target.value == 100000){
        calcF = 1000;
      } else if(e.target.value == 300000){
        calcF = 3000;
      } else if(e.target.value == 500000){
        calcF = 5000;
      } else {
        calcF = '';
      }
      setFMedicalPaymentsSel(calcF);
      tempArr['propertyCoverageInfo']['n_CoverageF'] = calcF;
      tempArr['propertyCoverageInfo']['n_coverageE'] = e.target.value;
    }
    dispatch(getEditEmptyQuoteData(tempArr));
  }

  const roundAmount = (amt) => {
    return Number.parseFloat(amt).toFixed(2);
  }

  const [date1, setDate1] = useState(new Date());
  return (
	<Row>
        <Col xs="12" sm="12" md="12" lg="6" xl="6">
          <div className="coverage-card-main-div">
            <img src={basicBadge} style={{ marginTop: "23px" }} />
            <div style={{ margin: "20px" }}>
              <Table borderless className="quote-tab-info-table">
                <tr>
                  <th>{t("A-Dwelling")}</th>
                  <td>
                    <Row className="justify-content-end">
                      <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        {/* <Form.Control
                          className="dashboard-group-input"
                          placeholder=""
                          value={props.quoteData.propertyCoverageInfo && props.quoteData.propertyCoverageInfo.n_coverageA ? props.quoteData.propertyCoverageInfo.n_coverageA : ''}
                          name="n_coverageA"
                          title="propertyCoverageInfo"
                          onChange = {props.handleChange}
                          disabled={props.isBind}
                        /> */}
                        <NumberFormat displayType={'input'} className="form-control form-control-md text-right" allowNegative={false} thousandSeparator={true}  name={"txt_basicCVG"} id={"txt_basicCVG"} value={aDwelling} prefix={'$'} disabled={props.isBind} onChange = {(e) => handleChangeCoverage(e)}
                        required />
                      </Col>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <th>{t("B-Other Structures")}</th>
                  <td>
                    <Row className="justify-content-end">
                      <Col
                        xs="12"
                        sm="12"
                        md="12"
                        lg="4"
                        xl="4"
                        style={{ marginBottom: "10px" }}
                      >
                        <Form.Select
                          className="dashboard-group-input"
                          id="select-ym-option"
                          value={BOtherStructuresSel}
                          name="BOtherStructurePer"
                                title="propertyCoverageInfo"
                          // onChange = {props.handleChange}
                          onChange = {(e) => handleChangeSelect(e)}
                          disabled={props.isBind}
                        >
                          <option value="">Select</option>
                          {props.getBaseData.BOtherStructurePer.map((x) => (
                            <option value={x.value}>
                              {x.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col xs="12" sm="12" md="12" lg="8" xl="8">
                        {/* <Form.Control
                          className="dashboard-group-input"
                          placeholder=""
                          value={props.quoteData.propertyCoverageInfo && props.quoteData.propertyCoverageInfo.n_coverageB ? props.quoteData.propertyCoverageInfo.n_coverageB : ''}
                          name="n_coverageB"
                          title="propertyCoverageInfo"
                          onChange = {props.handleChange}
                          disabled={props.isBind}
                          /> */}
                         <NumberFormat displayType={'input'} className="form-control form-control-md text-right" allowNegative={false} thousandSeparator={true} prefix={'$'} name={"txt_basicCVG"} id={"txt_basicCVG"} value={BOtherStructuresTxt}  disabled={props.isBind} readOnly
                         />
                      </Col>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <th>{t("C-Personal Property")}</th>
                  <td>
                    <Row className="justify-content-end">
                      <Col
                        xs="12"
                        sm="12"
                        md="12"
                        lg="4"
                        xl="4"
                        style={{ marginBottom: "10px" }}
                      >
                        <Form.Select
                          className="dashboard-group-input"
                          id="select-ym-option"
                          value={CPersonalPropertySel}
                          name="CPersonalPropertyPer"
                          title="propertyCoverageInfo"
                          // onChange = {props.handleChange}
                          onChange = {(e) => handleChangeSelect(e)}
                          disabled={props.isBind}
                        >
                          <option value="">Select</option>
		                  {props.getBaseData.CPersonalPropPer.map((x) => (
		                    <option value={x.value}>
		                      {x.label}
		                    </option>
		                  ))}
                        </Form.Select>
                      </Col>
                      <Col xs="12" sm="12" md="12" lg="8" xl="8">
                        {/* <Form.Control
                          className="dashboard-group-input"
                          placeholder=""
                          value={props.quoteData.propertyCoverageInfo && props.quoteData.propertyCoverageInfo.n_coverageC ? props.quoteData.propertyCoverageInfo.n_coverageC : ''}
                          name="n_coverageC"
                          title="propertyCoverageInfo"
                          onChange = {props.handleChange}
                          disabled={props.isBind}
                      /> */}
                      <NumberFormat displayType={'input'} className="form-control form-control-md text-right" allowNegative={false} thousandSeparator={true} prefix={'$'} name={"txt_basicCVG"} id={"txt_basicCVG"} value={CPersonalPropertyTxt}  disabled={props.isBind} readOnly
                         />
                      </Col>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <th>{t("D-Loss of Use")}</th>
                  <td>
                    <Row className="justify-content-end">
                      <Col
                        xs="12"
                        sm="12"
                        md="12"
                        lg="4"
                        xl="4"
                        style={{ marginBottom: "10px" }}
                      >
                        <Form.Select
                          className="dashboard-group-input"
                          id="select-ym-option"
                        value={DLossofUseSel}
                        name="DLoosOfUsePer"
                          title="propertyCoverageInfo"
                        // onChange = {props.handleChange}
                        onChange = {(e) => handleChangeSelect(e)}
                        disabled={props.isBind}
                        >
                          <option value="">Select</option>
                          {props.getBaseData.DLoosOfUsePer.map((x) => (
                            <option value={x.value}>
                              {x.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col xs="12" sm="12" md="12" lg="8" xl="8">
                        {/* <Form.Control
                          className="dashboard-group-input"
                          placeholder=""
                          value={DLossofUseTxt}
                          name="n_coverageD"
                                title="propertyCoverageInfo"
                          onChange = {props.handleChange}
                          disabled={props.isBind}
                          readOnly
                        /> */}
                        <NumberFormat displayType={'input'} className="form-control form-control-md text-right" allowNegative={false} thousandSeparator={true} prefix={'$'} name={"txt_basicCVG"} id={"txt_basicCVG"} value={DLossofUseTxt}  disabled={props.isBind} readOnly
                         />
                      </Col>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <th>{t("E-Personal Liability")}</th>
                  <td>
                    <Row className="justify-content-end">
                      <Col xs="12" sm="12" md="12" lg="6" xl="6">
					              <Form.Select
                          className="dashboard-group-input"
                          id="select-ym-option"
                          value={EPersonalLiabilitySel}
                          name="EPersonalLiabilityPer"
                                title="propertyCoverageInfo"
                          // onChange = {props.handleChange}
                          onChange = {(e) => handleChangeSelect(e)}
                          disabled={props.isBind}
                          required
                          >
                          <option value="">Select</option>
		                        {props.getBaseData.EPersonalLiability.map((x) => (
		                      <option value={x.value}>
		                        {x.label}
		                      </option>
		                  ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <th>{t("F-Medical Payments")}</th>
                  <td>
                    <Row className="justify-content-end">
                      <Col xs="12" sm="12" md="12" lg="6" xl="6">
					  	          <Form.Select
                          className="dashboard-group-input"
                          id="select-ym-option"
						  value={FMedicalPaymentsSel}
						  name="n_CoverageF"
			              title="propertyCoverageInfo"
						  onChange = {props.handleChange}
						  disabled
                        >
                          <option value="">Select</option>
                          {props.getBaseData.FMedicalPayment.map((x) => (
                            <option value={x.value}>
                              {x.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </td>
                </tr>
              </Table>
            </div>
          </div>
        </Col>
        <Col xs="12" sm="12" md="12" lg="6" xl="6">
          <div className="coverage-card-main-div">
            <img src={optionalBadge} style={{ marginTop: "23px" }} />
            <div style={{ margin: "20px" }}>
              <Table borderless className="quote-tab-info-table">
                <tr>
                  <th>
                    <div className="quote-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label={t("Wind Coverage Exclusion:")}
                        id="custom-checker1"
                        className="optional-cvg-checkbox"
                        defaultChecked={props.quoteData.propertyCoverageInfo.s_ExWind == "YES" ? true : false}
                        name="s_ExWind"
                        parentobject="propertyCoverageInfo"
                        onChange = {props.handleCheckboxChange}
                        disabled={props.isBind}
                        checkedvalue='YES'
                        uncheckedvalue='NO'
                      />
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>
                    <div className="quote-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label={t("Personal Property Replacement Cost:")}
                        id="custom-checker2"
                        className="optional-cvg-checkbox"
                        defaultChecked={props.quoteData.propertyCoverageInfo.s_hasPersonalPropRplCvg == "YES" ? true : false}
                        name="s_hasPersonalPropRplCvg"
                        parentobject="propertyCoverageInfo"
                        onChange = {props.handleCheckboxChange}
                        disabled={props.isBind}
                        checkedvalue='YES'
                        uncheckedvalue='NO'
                      />
                    </div>
                  </th>
                  <td>
                  </td>
                </tr>
                <tr>
                  <th>
                    <div className="quote-type-subdiv">
                      <Form.Check
                        label={t("Ordinance or Law:")}
                        type="checkbox"
                        id="custom-checker3"
                        className="optional-cvg optional-cvg-checkbox"
                        //checked={props.quoteData.propertyCoverageInfo.s_ordinanceLawFac != '' ? true : false}
                        // defaultChecked={props.quoteData.propertyCoverageInfo.s_ordinanceLawFac == null ? false : true}
                        checked={props.quoteData.propertyCoverageInfo.s_ordinanceLawFac == null || props.quoteData.propertyCoverageInfo.s_ordinanceLawFac == '' ? false : true}
                        name="s_ordinanceLawFac"
                        parentobject="propertyCoverageInfo"
                        onChange = {handleCheckboxChange}
                        // disabled={props.isBind}
                        disabled
                      />
                    </div>
                  </th>
                  <td>
                    <Row className="justify-content-end">
                      <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Form.Select
                          className="dashboard-group-input"
                          id="select-ym-option "
                          name="s_ordinanceLawFac"
                          parentobject="propertyCoverageInfo"
                          title="propertyCoverageInfo"
                          value={props.quoteData.propertyCoverageInfo.s_ordinanceLawFac ? props.quoteData.propertyCoverageInfo.s_ordinanceLawFac : ''}
                          onChange = {props.handleChange}
                          disabled={props.isBind}
                        >
                          <option value="">Select</option>
                          {props.getBaseData.OrdinanceLowDD.map((x) => (
                            <option value={x.value}>
                              {x.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <th>
                    <div className="quote-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label={t("Mold/Fungi Cvg Endt:")}
                        id="custom-checker4"
                        className="optional-cvg optional-cvg-checkbox"
                        checked={props.quoteData.propertyCoverageInfo.s_MoldFungi == null || props.quoteData.propertyCoverageInfo.s_MoldFungi == '' ? false : true}
                        name="s_MoldFungi"
                        parentobject="propertyCoverageInfo"
                        onChange = {handleCheckboxChange}
                        // disabled={props.isBind}
                        disabled
                        checkedvalue='YES'
                        uncheckedvalue='NO'
                      />
                    </div>
                  </th>
                  <td>
                    <Row className="justify-content-end">
                      <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Form.Select
                          className="dashboard-group-input"
                          id="select-ym-option"
                          name="s_MoldFungi"
                          parentobject="propertyCoverageInfo"
                          title="propertyCoverageInfo"
                          value={props.quoteData.propertyCoverageInfo.s_MoldFungi ? props.quoteData.propertyCoverageInfo.s_MoldFungi : ''}
                          onChange = {props.handleChange}
                          disabled={props.isBind}
                        >
                          <option value="">Select</option>
                          {props.getBaseData.MoldFungiDD.map((x) => (
                            <option value={x.value}>
                              {x.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <th>
                    <div className="quote-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label={t("Screened Enclosures:")}
                        id="custom-checker5"
                        className="optional-cvg optional-cvg-checkbox"
                        checked={props.quoteData.propertyCoverageInfo.s_ScreenEnclosure == null || props.quoteData.propertyCoverageInfo.s_ScreenEnclosure == '' ? false : true}
                        name="s_ScreenEnclosure"
                        parentobject="propertyCoverageInfo"
                        onChange = {handleCheckboxChange}
                        // disabled={props.isBind}
                        disabled
                        checkedvalue='YES'
                        uncheckedvalue='NO'
                      />
                    </div>
                  </th>
                  <td>
                    <Row className="justify-content-end">
                      <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Form.Select
                          className="dashboard-group-input"
                          id="select-ym-option"
                          name="s_ScreenEnclosure"
                          title="propertyCoverageInfo"
                          parentobject="propertyCoverageInfo"
                          value={props.quoteData.propertyCoverageInfo.s_ScreenEnclosure ? props.quoteData.propertyCoverageInfo.s_ScreenEnclosure : ''}
                          onChange = {props.handleChange}
                          disabled={props.isBind}
                        >
                          <option value="">Select</option>
                            {props.getBaseData.ScreenEnclosureDD.map((x) => (
                            <option value={x.value}>
                              {x.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <th>
                    <div className="quote-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label={t("Increased Loss Assessment Limit:")}
                        id="custom-checker6"
                        className="optional-cvg optional-cvg-checkbox"
                        checked={props.quoteData.propertyCoverageInfo.s_LossAssessmentCvg == null || props.quoteData.propertyCoverageInfo.s_LossAssessmentCvg == '' ? false : true}
                        name="s_LossAssessmentCvg"
                        parentobject="propertyCoverageInfo"
                        onChange = {handleCheckboxChange}
                        // disabled={props.isBind}
                        disabled
                        checkedvalue='YES'
                        uncheckedvalue='NO'
                      />
                    </div>
                  </th>
                  <td>
                    <Row className="justify-content-end">
                      <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Form.Select
                          className="dashboard-group-input"
                          id="select-ym-option"
                          name="s_LossAssessmentCvg"
                          title="propertyCoverageInfo"
                          parentobject="propertyCoverageInfo"
                          value={props.quoteData.propertyCoverageInfo.s_LossAssessmentCvg ? props.quoteData.propertyCoverageInfo.s_LossAssessmentCvg : ''}
                          onChange = {props.handleChange}
                          disabled={props.isBind}
                        >
                          <option value="">Select</option>
                          {props.getBaseData.LossAssessmentDD.map((x) => (
                            <option value={x.value}>
                              {x.label}
                            </option>
		                      ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <th>
                    <div className="quote-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label={t("Water Back up:")}
                        id="custom-checker7"
                        className="optional-cvg optional-cvg-checkbox"
                        checked={props.quoteData.propertyCoverageInfo.s_WaterBackup == null || props.quoteData.propertyCoverageInfo.s_WaterBackup == '' ? false : true}
                        name="s_WaterBackup"
                        parentobject="propertyCoverageInfo"
                        onChange = {handleCheckboxChange}
                        // disabled={props.isBind}
                        disabled
                        checkedvalue='YES'
                        uncheckedvalue='NO'
                      />
                    </div>
                  </th>
                  <td>
                    <Row className="justify-content-end">
                      <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Form.Select
                          className="dashboard-group-input"
                          id="select-ym-option"
                          name="s_WaterBackup"
                          title="propertyCoverageInfo"
                          parentobject="propertyCoverageInfo"
                          value={props.quoteData.propertyCoverageInfo.s_WaterBackup ? props.quoteData.propertyCoverageInfo.s_WaterBackup : ''}
                          onChange = {props.handleChange}
                          disabled={props.isBind}
                        >
                          <option value="">Select</option>
                          {props.getBaseData.WaterBackupDD.map((x) => (
                            <option value={x.value}>
                              {x.label}
                            </option>
		                      ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </td>
                </tr>
				        <tr>
                  <th>
                    <div className="quote-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label={t("Special Computer Coverage:")}
                        id="custom-checker8"
                        className="optional-cvg optional-cvg-checkbox"
                        checked={props.quoteData.propertyCoverageInfo.S_ComputerCvg == null || props.quoteData.propertyCoverageInfo.S_ComputerCvg == '' ? false : true}
                        name="S_ComputerCvg"
                        parentobject="propertyCoverageInfo"
                        onChange = {handleCheckboxChange}
                        // disabled={props.isBind}
                        disabled
                        checkedvalue='YES'
                        uncheckedvalue='NO'
                      />
                    </div>
                  </th>
                  <td>
                    <Row className="justify-content-end">
                      <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Form.Select
                          className="dashboard-group-input"
                          id="select-ym-option"
                          name="S_ComputerCvg"
                          title="propertyCoverageInfo"
                          parentobject="propertyCoverageInfo"
                          value={props.quoteData.propertyCoverageInfo.S_ComputerCvg ? props.quoteData.propertyCoverageInfo.S_ComputerCvg : ''}
                          onChange = {props.handleChange}
                          disabled={props.isBind}
                          >
                            <option value="">Select</option>
                          {props.getBaseData.WaterBackupDD.map((x) => (
                            <option value={x.value}>
                              {x.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </td>
                </tr>
				        <tr>
                  <th>
                    <div className="quote-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label={t("Permitted Incidental Occupancies:")}
                        id="custom-checker9"
                        className="optional-cvg optional-cvg-checkbox"
                        defaultChecked={props.quoteData.propertyCoverageInfo.s_PermittedIncidentalOcc == "YES" ? true : false}
                        name="s_PermittedIncidentalOcc"
                        //title="s_PermittedIncidentalOcc"
                        title ="propertyCoverageInfo"
                        parentobject="propertyCoverageInfo"
                        onChange = {props.handleCheckboxChange}
                        disabled={props.isBind}
                        // disabled
                        checkedvalue='YES'
                        uncheckedvalue='NO'
                      />
                    </div>
                  </th>
                  <td>
                  </td>
                </tr>
				        <tr>
                  <th>
                    <div className="quote-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label={t("Business Property:")}
                        id="10"
                        className="optional-cvg optional-cvg-checkbox"
                        checked={props.quoteData.propertyCoverageInfo.s_BusinessProp == null || props.quoteData.propertyCoverageInfo.s_BusinessProp == '' ? false : true}
                        name="s_BusinessProp"
                        parentobject="propertyCoverageInfo"
                        onChange = {handleCheckboxChange}
                        // disabled={props.isBind}
                        disabled
                        checkedvalue='NO'
                        uncheckedvalue='YES'
                      />
                    </div>
                  </th>
                  <td>
                    <Row className="justify-content-end">
                      <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Form.Select
                          className="dashboard-group-input"
                          id="select-ym-option"
                          name="s_BusinessProp"
                          title="propertyCoverageInfo"
                          parentobject="propertyCoverageInfo"
                          value={props.quoteData.propertyCoverageInfo.s_BusinessProp ? props.quoteData.propertyCoverageInfo.s_BusinessProp : ''}
                          onChange = {props.handleChange}
                          disabled={props.isBind}
                        >
                          <option value="">Select</option>
                          {props.getBaseData.BusinessPropDD.map((x) => (
                            <option value={x.value}>
                              {x.label}
                            </option>
		                      ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </td>
                </tr>
				
				        <tr>
                  <th>
                    <div className="quote-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label={t("Golf Cart Physical Damage & Liability:")}
                        id="custom-checker10"
                        className="optional-cvg-checkbox"
                        defaultChecked={props.quoteData.propertyCoverageInfo.s_GolfCart == "YES" ? true : false}
                        name="s_GolfCart"
                        // title="propertyCoverageInfo"
                        parentobject="propertyCoverageInfo"
                        onChange = {props.handleCheckboxChange}
                        disabled={props.isBind}
                        checkedvalue='YES'
                        uncheckedvalue='NO'
                      />
                    </div>
                  </th>
                  <td>
                    
                  </td>
                </tr>
				        <tr>
                  <th>
                    <div className="quote-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label={t("Personal Injury:")}
                        id="custom-checker11"
                        className="optional-cvg-checkbox"
                        defaultChecked={props.quoteData.propertyCoverageInfo.s_PersonalInjuary == "YES" ? true : false}
                        name="s_PersonalInjuary"
                        parentobject="propertyCoverageInfo"
                        onChange = {props.handleCheckboxChange}
                        disabled={props.isBind}
                        checkedvalue='YES'
                        uncheckedvalue='NO'
                      />
                    </div>
                  </th>
                  <td>
                  </td>
                </tr>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
	);
	
};
  function mapStateToProps(state) {
	return {
	  	quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	};
  } 
  export default connect(mapStateToProps)(Coverage);