import React, { Fragment ,useState, useEffect} from "react";
import {
  Button,
  Row,
  Col,
  Form,
  ListGroup,
  Accordion,
  Modal,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
const BillingInfo = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
      }, [props]);   
  return (
    <Fragment>
        <Row className="align-items-end">
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
                >
                <Form.Label className="dashboard-group-label">
                    {t("Bill To")}<span className="label-asterisk">*</span>
                </Form.Label>
                <Form.Select className="table-show-entries-select" title="policyInfo" name="s_BillTo" onChange={props.propsData.handleChange} value={props.quoteData.policyInfo.s_BillTo} disabled={props.isBind}>
                    <option value={''} className="myform-input custom-select-autocomplete">Select</option>
                    {
                    props.getBaseData.BILLTO && props.getBaseData.BILLTO.map((v, i) => {
                        return <option className="myform-input custom-select-autocomplete" key={i} value={v.s_AppCodeName} >{v.s_AppCodeNameForDisplay}</option>
                    })
                    }
                </Form.Select>
                </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
                >
                <Form.Label className="dashboard-group-label">
                    {t("Pay Plan")} <span className="label-asterisk">*</span>
                </Form.Label>
                <Form.Select
                    className="dashboard-group-input"
                    id="select-ym-option"
                    title="policyInfo" name="s_PayPlan_Code" onChange={props.propsData.handleChange} value={props.quoteData.policyInfo.s_PayPlan_Code} disabled={props.isBind}
                >
                   <option value={''} className="myform-input custom-select-autocomplete">Select</option>
                    {
                    props.getBaseData.PayPlanDD && props.getBaseData.PayPlanDD.map((v, i) => {
                        return <option className="myform-input custom-select-autocomplete" key={i} value={v.value} >{v.label}</option>
                    })
                    }
                </Form.Select>
                </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
                >
                <Form.Label className="dashboard-group-label">
                    {t("Amt Included with App")}
                    <span className="label-asterisk">*</span>
                </Form.Label>
                <Form.Control
                    className="dashboard-group-input"
                    type="text"
                    disabled
                    value={props.quoteData.premiumData && props.quoteData.premiumData.FinalPremium ? props.quoteData.premiumData.FinalPremium : null}
                />
                </Form.Group>
            </Col>
        </Row>
    </Fragment>
  );
};


function mapStateToProps(state) {
	return {
	  	quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
		getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
	};
  } 
  export default connect(mapStateToProps)(BillingInfo);
