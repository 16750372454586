import React, { useState,useEffect  } from 'react';
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { useDispatch, connect } from "react-redux";
import { getHistoryDataListData, callGenerateHistoryRateSheetApi } from "../../../../../../services/quoteService";
import { showDataTableTotalText } from "../../../../../../services/commonService";
import { toast } from 'react-toastify';
import {Link } from 'react-router-dom';

const ErrorLog = (props) => {
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);
    const [tableTotal, setTableTotal] = useState([]);
    const [loading, setLoading] = useState(false);

    const [filter, setFilter] = useState({
       n_potranpremdetails_PK : props.n_potranpremdetails_PK,
        page:1,
        pageLength:10
    });
    
    useEffect(() => {
        if(filter.n_potranpremdetails_PK != null && props.isShow == true){
            getHistoryDataList();
        }
    }, [props]);

    useEffect(() => {
      getHistoryDataList();
    }, [filter.page]);
    
    const getHistoryDataList = async () => {
      setLoading(true);
    await dispatch(getHistoryDataListData(filter)).then((res) => { 
        setTableData(res.data.data);
        setTableTotal(res.data.total);
    });
      setLoading(false);
    };

    const getReportOpen = async (dataLogPk) => {
      setLoading(true);
      await dispatch(callGenerateHistoryRateSheetApi(dataLogPk)).then((res) => { 
        console.log(res);
        if(res.status == 'Y'){
          setLoading(false);
          window.open(res.url)
        }else{
          toast.error('Something went wrong!');
          setLoading(false);
        }
      });
    };
     const columnFormatter = (cell, row, rowIndex, formatExtraData) => {
        return <a style={{ color:"#0d6efd", cursor: "pointer", fontSize : "16px", textDecoration : "underline" }} onClick={() => getReportOpen(row.n_datalog_PK)}>Print</a>
      };

      const columns = [
        {
          dataField: "d_CreatedDate",
          text: "Created Date",
        },
        {
          dataField: "s_PolicyQuoteNo",
          text: "Policy No",
        },
        {
          dataField: "n_FinalPremium",
          text: "Final Premium",
        },
        {
          text: "Action",
          formatter: columnFormatter
        },
        {
          dataField: "s_ScreenName",
          text: "Updated user",
        }
    
      ];
    
      const handlePageChange = (page, sizePerPage) => {
        setFilter({...filter,page:page,pageLength:sizePerPage});
      };
    
      const sizePerPageListChange = (sizePerPage) => {
        setFilter({...filter,pageLength:sizePerPage});
      };
    
      const renderShowsTotal = (start, to, total) => {
        return showDataTableTotalText(start, to, total);
      };
    
      const options = {
        page: filter.page,
        sizePerPage: filter.pageLength,
        sortIndicator: false,
        pageStartIndex: 1,
        paginationShowsTotal: renderShowsTotal,
        onPageChange: handlePageChange,
        totalSize:tableTotal
        //sizePerPageDropDown: renderSizePerPageDropDown
      };    
    

  return (
    <Modal
        className="custom-dashboard-modal"
        size="lg"
        show={props.isShow}
        onHide={() => {props.isOpen(false);}}
      >
        <Modal.Header closeButton  className="custom-dashboard-modal-header">
          <Modal.Title>Transaction History</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-dashboard-modal-body">
          <Row style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              <LoadingOverlay>
                <Loader loading={loading} />
                <BootstrapTable
                  wrapperClasses="dashboard-table-main"
                  bootstrap4
                  remote
                  keyField="n_PoPolicyUnBoundErrorsId_PK"
                  data={tableData}
                  columns={columns}
                  hover
                  pagination={paginationFactory(options)}
                />
              </LoadingOverlay>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
  )
}

export default ErrorLog