import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const Validation = ({ currentStep, toggle, verified = false, closeModal = null }) => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { emptyQuoteData } = useSelector(state => state.flood);
  const emptyQuote = { ...emptyQuoteData };

  const handleNextButton = () => {
    if (Object.keys(emptyQuote).length > 0) {
      if (currentStep === 1) {
        if (![null, ''].includes(emptyQuote.propertyInfo.s_YearBuilt) && emptyQuote.propertyInfo.s_YearBuilt.length !== 4) {
          toast.error(t('Enter Valid Year built'));
        }
        if (![null, ''].includes(emptyQuote.propertyInfo.s_TotalSqFootage) && ![null, ''].includes(emptyQuote.propertyInfo.s_Foundation) && ![null, ''].includes(emptyQuote.propertyInfo.s_BldgConstructionType) && ![null, ''].includes(emptyQuote.propertyInfo.n_NoOfFloors) && ![null, ''].includes(emptyQuote.propertyInfo.s_Usage)) {
          if (['ELEWATEDONCRAWLSPACE20', 'ELEVATEDWITHENCLOSURE20', 'ELEVATEDWITHENCLOSURENOTPPP20'].includes(emptyQuote.propertyInfo.s_Foundation) && [null, ''].includes(emptyQuote.propertyInfo.s_AreaInSqFt)) {
            toggle(true);
          } else {
            if (emptyQuote.viewType === 'MOBILEHOME') {
              if ([null, ''].includes(emptyQuote.propertyInfo.d_DateOfConstruction)) {
                var label = emptyQuote.propertyInfo.s_ManufactureMobileHome === 'YES' ? 'Permanent Placement' : 'Park/Subdivision was established';
                toast.error(t('Please select date of ' + label));
                toggle(true);
              } else {
                toggle(false);
                navigateStep(13);
              }
            } else if (emptyQuote.viewType === 'UNIT') {
              if ([null, ''].includes(emptyQuote.propertyInfo.n_NoOfFloors) || [null, ''].includes(emptyQuote.propertyInfo.s_NoOfUnits)) {
                toggle(true);
              } else if (emptyQuote.propertyInfo.n_NoOfFloors > 0 && [null, ''].includes(emptyQuote.propertyInfo.s_UnitLocatedOnFloor)) {
                toggle(true);
              } else if ([null, ''].includes(emptyQuote.propertyInfo.s_BuildingUse)) {
                toggle(true);
              } else {
                toggle(false);
                navigateStep(currentStep + 1);
              }
            } else if (emptyQuote.viewType === 'RESIDENTIALBLDG') {
              if ([null, ''].includes(emptyQuote.propertyInfo.n_NoOfFloors) || [null, ''].includes(emptyQuote.propertyInfo.s_NoOfUnits)) {
                toggle(true);
              } else if (emptyQuote.propertyInfo.s_NoOfUnits > 0 && [null, ''].includes(emptyQuote.propertyInfo.s_BuildingUse)) {
                toggle(true);
              } else {
                toggle(false);
                navigateStep(currentStep + 1);
              }
            } else if (emptyQuote.viewType === 'NONRESIDENTIALBLDG') {
              if ([null, ''].includes(emptyQuote.propertyInfo.n_NoOfFloors) || [null, ''].includes(emptyQuote.propertyInfo.s_NoOfUnits) || [null, ''].includes(emptyQuote.propertyInfo.s_BuildingUse)) {
                toggle(true);
              } else {
                toggle(false);
                navigateStep(currentStep + 1);
              }
            } else {
              toggle(false);
              navigateStep(currentStep + 1)
            }
          }
        } else {
          toggle(true);
        }
      } else if (currentStep === 3) {
        if (![null, ''].includes(emptyQuote.personInfo.s_FullLegalName) && ![null, ''].includes(emptyQuote.personEmailContact.s_CommValue) && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emptyQuote.personEmailContact.s_CommValue) && ![null, ''].includes(emptyQuote.personPhoneContact.s_CommValue)) {
          var phone = emptyQuote.personPhoneContact.s_CommValue;
          if (phone.indexOf('_') === -1) {
            toggle(false);
            navigateStep(currentStep + 1)
          } else {
            toggle(true);
            toast.error(t('Please Add Mobile Number Error'));
          }
        } else {
          if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emptyQuote.personEmailContact.s_CommValue))) {
            toast.error(t('Enter email address'));
          }
          toggle(true);
        }
      } else if (currentStep === 4) {
        if (![null, ''].includes(emptyQuote.propertyInfo.s_PolicyWaitingPeriod) && ![null, ''].includes(emptyQuote.effectiveDate)) {
          toggle(false);
          navigateStep(currentStep + 1)
        } else {
          if ([null, ''].includes(emptyQuote.effectiveDate)) {
            toast.error(t('Enter Effective Date'));
          }
          toggle(true);
        }
      } else if (currentStep === 5) {
        if (emptyQuote.propertyInfo.s_SubstantialImprovement === 'YES' && ((![null, ''].includes(emptyQuote.propertyInfo.d_SubstantialImprovmentDate) && (emptyQuote.propertyInfo.d_SubstantialImprovmentDate < emptyQuote.propertyInfo.d_DateOfConstruction)))) {
          toast.error(t('Original Construction Date must be less than Substantial Improvement Date'));
          toggle(true);
        } else if (emptyQuote.propertyInfo.s_SubstantialImprovement === 'YES' && [null, ''].includes(emptyQuote.propertyInfo.d_SubstantialImprovmentDate)) {
          toast.error(t('Select Substantial Improvement Date'));
          toggle(true);
        } else {
          toggle(false);
          navigateStep(currentStep + 1)
        }
      } else if (currentStep === 6) {
        // show only If usage = Primary OR tenant
        if (((emptyQuote.propertyInfo.s_Occupancy === 'RESIDENTIALUNIT20' && emptyQuote.propertyInfo.s_CondoOwnership === 'NO') || !['OTHERRESIDENTIAL20','NONRESIDENTIALBUILD20'].includes(emptyQuote.propertyInfo.s_Occupancy)) && ['PRIMARY', 'TENANT'].includes(emptyQuote.propertyInfo.s_Usage)) {
          navigateStep(currentStep + 1);
        } else {
          navigateStep(8)
        }
      } else if (currentStep === 8) {
        if (emptyQuote.propertyInfo.s_IsNewPurchase === 'YES' && ([null, '', undefined].includes(emptyQuote.propertyInfo.s_PriorPolicyNo) || [null, '', undefined].includes(emptyQuote.propertyInfo.s_PriorCompanyNAIC))
        ) {
          if ([null, '', undefined].includes(emptyQuote.propertyInfo.s_PriorCompanyNAIC)) {
            toast.error("Enter Prior Company NAIC");
          }
          if ([null, '', undefined].includes(emptyQuote.propertyInfo.s_PriorPolicyNo)) {
            toast.error("Enter Policy NO");
          }
          toggle(true);
        } else {
          if (emptyQuote.propertyInfo.s_FloodConstructionType === 'FLDPREFIRM' && verified) {
            navigateStep(11);
          } else if (['X', 'B', 'C', 'A99', 'D'].includes(emptyQuote.propertyInfo.s_FloodZone) && ['SLABONGRADE20', 'BASEMENT20', 'ELEVATEDWITHOUTENCLOSURE20'].includes(emptyQuote.propertyInfo.s_Foundation)) {
            navigateStep(12);
          } else if (['X', 'B', 'C', 'A99', 'D'].includes(emptyQuote.propertyInfo.s_FloodZone)) {
            navigateStep(10);
          } else {
            navigateStep(currentStep + 1)
            toggle(false);
          }
        }
      } else if (currentStep === 9) {
        if (emptyQuote.propertyInfo.s_isValidElevationCert === 'YES' && ([null, ''].includes(emptyQuote.propertyInfo.s_BuildingDiagramNoCode) || [null, ''].includes(emptyQuote.propertyInfo.d_CertificateDate))) {
          if ([null, ''].includes(emptyQuote.propertyInfo.d_CertificateDate)) {
            toast.error(t('Enter Certificate Signature Date'));
          }
          toggle(true);
        } else {
          toggle(false);
          if (['SLABONGRADE20', 'BASEMENT20', 'ELEVATEDWITHOUTENCLOSURE20'].includes(emptyQuote.propertyInfo.s_Foundation) && currentStep === 9) {
            navigateStep(12);
          } else {
            navigateStep(currentStep + 1)
          }
        }
      } else if (currentStep === 13) {
        navigateStep(2);
      } else {
        navigateStep(currentStep + 1);
      }
    }
  }

  const handlePreviousButton = () => {
    if (Object.keys(emptyQuote).length > 0) {
      if (currentStep === 8) {
        // show only If usage = Primary OR tenant
        if (((emptyQuote.propertyInfo.s_Occupancy === 'RESIDENTIALUNIT20' && emptyQuote.propertyInfo.s_CondoOwnership === 'NO') || !['OTHERRESIDENTIAL20','NONRESIDENTIALBUILD20'].includes(emptyQuote.propertyInfo.s_Occupancy)) && ['PRIMARY', 'TENANT'].includes(emptyQuote.propertyInfo.s_Usage) && currentStep === 8) {
          navigateStep(currentStep - 1);
        } else {
          navigateStep(6);
        }
      } else if (currentStep === 10) {
        if (['X', 'B', 'C', 'A99', 'D'].includes(emptyQuote.propertyInfo.s_FloodZone)) {
          navigateStep(8);
        } else {
          navigateStep(currentStep - 1);
        }
      } else if (currentStep === 2) {
        var step = currentStep - 1;
        if (emptyQuote.viewType === 'MOBILEHOME') {
          step = 13;
        }
        navigateStep(step);
      } else if (currentStep === 13) {
        var step = currentStep - 1;
        if (emptyQuote.viewType === 'MOBILEHOME') {
          step = 1;
        }
        navigateStep(step);
      } else if (emptyQuote.propertyInfo.s_IsNewPurchase === 'YES' && emptyQuote.propertyInfo.s_FloodConstructionType === 'FLDPREFIRM') {
        navigateStep(8);
      } else {
        navigateStep(currentStep - 1);
      }
    } else {
      navigateStep(currentStep - 1);
    }
  }

  const navigateStep = (step) => {
    navigation('/flood/instantquote/step/' + step);
  }

  return (
    <div className="flood-bts-div">
      {currentStep === 1 ? (
        <Button
          id="primary-grey-btn-flood"
          variant="outline-dark"
          onClick={() => closeModal(null)}
          disabled={false}
        >
          {t('Back')}
        </Button>
      ) : (
        <Button
          id="primary-grey-btn-flood"
          variant="outline-dark"
          onClick={() => handlePreviousButton()}
          disabled={false}
        >
          {t('Previous')}
        </Button>
      )
      }{" "}
      <Button
        id="primary-colored-btn"
        variant="primary"
        onClick={() => handleNextButton()}
      >
        {t('Next')}
      </Button>{" "}
    </div>
  );
};

export default Validation;