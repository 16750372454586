import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Icon1 from "../../../../../assets/flood/home-type/home-light.png";
import Icon2 from "../../../../../assets/flood/home-type/unit-light.png";
import Icon3 from "../../../../../assets/flood/home-type/mobile-home-light.png";
import Icon4 from "../../../../../assets/flood/home-type/residential-light.png";
import Icon5 from "../../../../../assets/flood/home-type/non-residential-light.png";
import { getEmptyQuoteData, getEditEmptyQuoteData, getInitQuoteApi, pivotApiCall } from "../../../../../services/floodService";
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { Loader, LoadingOverlay } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import './flood-home.scss';

const FloodHome = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { apiLoading, propertyDataFromAdd, propertyDataAutocompleteStored } = useSelector(state => state.flood);

  // Updated Type To The Object For The Further Process
  const navigatAction = (type) => {
    if (['HOME', 'MOBILEHOME', 'UNIT', 'RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(type)) {
      dispatch(getEmptyQuoteData(10, type)).then((res) => {
        if (res && Object.keys(res).length > 0) {
          const allData = res;
          allData.viewType = type;
          if (allData.propertyInfo) {
            if (propertyDataFromAdd && Object.keys(propertyDataFromAdd).length > 0) {
              allData.propertyInfo.s_YearBuilt = propertyDataFromAdd.PropDetails.ACT_YR_BLT.toString();
              allData.propertyInfo.d_DateOfConstruction = new Date(propertyDataFromAdd.PropDetails.ACT_YR_BLT + '-01-01');

              allData.propertyInfo.s_NoOfUnits = propertyDataFromAdd.PropDetails.NO_RES_UNTS;
              allData.propertyInfo.n_NoOfFloors = propertyDataFromAdd.PropDetails.NO_STORIES;
              allData.propertyInfo.s_BldgConstructionType = propertyDataFromAdd.ConstructionType;

              allData.propertyInfo.s_TotalSqFootage = propertyDataFromAdd.PropDetails.TOT_LVG_AR
              allData.propertyInfo.s_Usage = propertyDataFromAdd.Usage;
              allData.personAddr.s_Latitude = propertyDataFromAdd.s_Latitude ? parseFloat(propertyDataFromAdd.s_Latitude) : '';
              allData.personAddr.s_Longitude = propertyDataFromAdd.s_Longitude ? parseFloat(propertyDataFromAdd.s_Longitude) : '';
              allData.personInfo.s_FullLegalName = propertyDataFromAdd.PropDetails.OWN_NAME;
              allData.personAddr.s_ParcelId = propertyDataFromAdd.PropDetails.PARCEL_ID;
              allData.personInfo.s_IsMailingDiff = 'NO';
              allData.personPhoneContact.s_CommType = 'Home';
            }
            if (propertyDataAutocompleteStored) {
              allData.personAddr.s_CityName = propertyDataAutocompleteStored.city;
              allData.personAddr.s_CountyName = propertyDataAutocompleteStored.CountyName;
              allData.personAddr.s_StateName = propertyDataAutocompleteStored.state;
              allData.personAddr.s_CountryName = propertyDataAutocompleteStored.country;
              allData.personAddr.s_StreetName = propertyDataAutocompleteStored.street_number + ' ' + propertyDataAutocompleteStored.street_name;
              allData.personAddr.s_PostalCode = propertyDataAutocompleteStored.PostalCode;
              allData.personAddr.s_PostalCodeSuffix = propertyDataAutocompleteStored.s_PostalCodeSuffix;
              allData.personAddr.s_HouseNo = propertyDataAutocompleteStored.street_number;
              allData.personAddr.s_AddressLine1 = propertyDataAutocompleteStored.street_number + ' ' + propertyDataAutocompleteStored.street_name;
            }
          }
          dispatch(getEditEmptyQuoteData(allData));
          // To get Initial Quote Api Object
          dispatch(getInitQuoteApi()).then(() => {
            // To Get Zone Determination Details From Address
            getAddressAnalysis(allData);
          });
        }
      }).catch(() => toast.error(t('Something Went Wrong!')));
    } else {
      toast.info(t('Not Active'));
    }
  }

  /**
   * To Get Zome Determination Details Based on Address
   */
  const getAddressAnalysis = async (emptyQuoteData) => {
    if (emptyQuoteData && emptyQuoteData.personAddr) {
      const reqJson = {
        'addressLine1': emptyQuoteData.personAddr.s_AddressLine1,
        'addressLine2': emptyQuoteData.personAddr.s_AddressLine1,
        'city': emptyQuoteData.personAddr.s_CityName,
        'state': emptyQuoteData.personAddr.s_StateName,
        'zipCode': emptyQuoteData.personAddr.s_PostalCode,
        'zipPlus4': emptyQuoteData.personAddr.s_PostalCodeSuffix,
        'latitude': emptyQuoteData.personAddr.s_Latitude,
        'longitude': emptyQuoteData.personAddr.s_Longitude,
        'uniqueKey': localStorage.getItem('uniqueKey')
      }
      const code = 'ZONE_DETERMINATION';
      const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0 };
      // Call Api
      setLoading(true)
      dispatch(pivotApiCall(code, request)).then(() => {
        setLoading(false)
        // Navigat to the details screen
        navigation("/flood/instantquote/step/1")
      }).catch(() => {
        setLoading(false)
        toast.error(t('Something Went Wrong!'));
      });
    }
  }

  return (
    <LoadingOverlay>
      <Loader loading={apiLoading || loading} />
      <Container>
        {
          (propertyDataFromAdd && Object.keys(propertyDataFromAdd).length === 0) && <Navigate to={'/flood/instantquote/step/address'} />
        }
        <div className="justify-content-center">
          <Row>
            <Col xs="12" className="flood-div">
              <Row className="justify-content-center">
                <div className="home-type-headings">
                  <h4 className="home-type-title">{t('Select the property type')}</h4>
                  <p className="home-type-desc">
                    {/* {t('Choose a property you want to issue with Unify')} */}
                  </p>
                </div>
                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                  <div
                    className="flood-quote-page-cards-div"
                    onClick={() => navigatAction('HOME')}
                  >
                    <img className="flood-quote-page-cards-icon" src={Icon1} />
                    <div className="flood-quote-page-cards-title">{t('Home')}</div>
                  </div>
                </Col>

                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                  <div
                    className="flood-quote-page-cards-div"
                    onClick={() => navigatAction('MOBILEHOME')}
                  >
                    <img className="flood-quote-page-cards-icon" src={Icon3} />
                    <div className="flood-quote-page-cards-title">{t('Mobile Home')}</div>
                  </div>
                </Col>

                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                  <div
                    className="flood-quote-page-cards-div"
                    onClick={() => navigatAction('UNIT')}
                  >
                    <img className="flood-quote-page-cards-icon" src={Icon2} />
                    <div className="flood-quote-page-cards-title">{t('Unit')}</div>
                  </div>
                </Col>

                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                  <div
                    className="flood-quote-page-cards-div"
                    onClick={() => navigatAction('RESIDENTIALBLDG')}
                  >
                    <img className="flood-quote-page-cards-icon" src={Icon4} />
                    <div className="flood-quote-page-cards-title">{t('Residential Building')}</div>
                  </div>
                </Col>

                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                  <div
                    className="flood-quote-page-cards-div"
                    onClick={() => navigatAction('NONRESIDENTIALBLDG')}
                  >
                    <img className="flood-quote-page-cards-icon" src={Icon5} alt='flood' />
                    <div className="flood-quote-page-cards-title">{t('Non-Residential Building')}</div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </LoadingOverlay>
  );
};

export default FloodHome;