import React from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import PaymentTab from "./PaymentTab";
import PendingTab from "./PendingTab";
import RecentTab from "./RecentTab";
import UpcomingTab from "./UpcomingTab";
import Report from "../report/Report";
import "./management-reports.scss";
import { useTranslation } from "react-i18next";

const ManagementReports = () => {
  const { t } = useTranslation();
  return (
    <div className="management-page-section">
      <h5 className="management-page-title">{t("Management Reports")}</h5>

      <Row>
        <Col xs="12" className="custom-tabs">
          <Tabs defaultActiveKey="Payment Due" id="uncontrolled-tab-example">
            <Tab eventKey="Payment Due" title={t("Payment Due")}>
              <PaymentTab />
            </Tab>
            <Tab eventKey="Upcoming Renewals" title={t("Upcoming Renewals")}>
              <UpcomingTab />
            </Tab>
            <Tab eventKey="Pending Cancelation" title={t("Pending Cancelation")}>
              <PendingTab />
            </Tab>
            <Tab eventKey="Recent Transaction" title={t("Recent Transaction")}>
              <RecentTab />
            </Tab>
            <Tab eventKey="Download Reports" title={t("Download Reports")}>
              <Report />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default ManagementReports;
