import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ActivityLog from "../../activity-log/inbox/Inbox";
import MessageView from "../../activity-log/message-view/MessageView";
import Reply from "../../activity-log/reply/Reply";

const ActivityLogModal = (props) => {
    const { t } = useTranslation();
    const { show, setShow, policyNumber } = props;
    const [messageId, setMessageId] = useState(0);
    const [viewPart, setViewPart] = useState('VIEW');

    const setMessagePk = (type, id = null) => {
        console.log(type, id)
        id !== null ? setMessageId(id) : setMessageId(0);
        setViewPart(type);
    }

    return (
        <Modal
            size="xl"
            show={show}
            className="custom-dashboard-modal"
            onHide={() => setShow(!show)}
        >
            <Modal.Header
                closeButton
                className="custom-dashboard-modal-header"
            >
                <Modal.Title>{t('Activity Log')}</Modal.Title>
            </Modal.Header>
            <Modal.Body
                className="custom-dashboard-modal-body"
            >
                {
                    viewPart === "VIEW" ?
                        <ActivityLog
                            activityType='POLICY'
                            sourceCode={policyNumber}
                            viewPart={setMessagePk}
                            setValuesCondition={setMessagePk}
                        />
                        : viewPart === "DETAIL" ?
                            <MessageView
                                sourceCode={policyNumber}
                                viewPart={setMessagePk}
                                messageId={messageId}
                                setValuesCondition={setMessagePk}
                            />
                            : <Reply
                                activityType='POLICY'
                                sourceCode={policyNumber}
                                viewPart={setMessagePk}
                                messageId={messageId}
                            />
                }
            </Modal.Body>
        </Modal>
    )
}

export default ActivityLogModal;