import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { getFloodPaymentInfo, getEditPaymentData, payOnline } from "../../../../../services/floodService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MaskedFormControl from 'react-bootstrap-maskedinput'
import "react-overlay-loader/styles.css";
import "./Payment.scss";

const FloodACHPayment = (props) => {
    const { type, poTransPk } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const { apiLoading, initPaymentObject } = useSelector(state => state.flood);
    const [initPaymentInfo, setInitPaymentInfo] = useState({});
    const [validated, setValidated] = useState(false);

    const accountTypeDD = [
        {
            key: 'BusinessChecking',
            value: 'BusinessChecking',
        },
        {
            key: 'BusinessSavings',
            value: 'BusinessSavings',
        },
        {
            key: 'PersonalChecking',
            value: 'PersonalChecking',
        },
        {
            key: 'PersonalSavings',
            value: 'PersonalSavings',
        },
        {
            key: 'GeneralLedger',
            value: 'GeneralLedger',
        },
    ];

    // trigger on load
    useEffect(() => {
        getPaymentInfo(poTransPk);
    }, []);

    useEffect(() => {
        if (Object.keys(initPaymentObject).length > 0) {
            setInitPaymentInfo(initPaymentObject);
        }
    }, [initPaymentObject]);

    const getPaymentInfo = async (poTransPk) => {
        await dispatch(getFloodPaymentInfo(poTransPk));
    };

    const handleChange = (key, value) => {
        var paymentData = { ...initPaymentObject };
        paymentData[key] = value;
        dispatch(getEditPaymentData(paymentData));
    };

    const handleSubmit = async () => {
        var paymentData = { ...initPaymentObject };
        var isValid = true;
        // validation
        for (const [key, value] of Object.entries(paymentData)) {
            if (key === 's_EmailAddress' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                isValid = false;
                toast.error(t('Enter email address'));
                break;
            }
            if ((!['ccNumber', 'expiry', 'cvv'].includes(key)) && (['', null].includes(value))) {
                isValid = false;
                break;
            }
        }
        if (isValid === false) {
            setValidated(!isValid);
        } else {
            setValidated(!isValid);
            const [response, error] = await dispatch(payOnline(paymentData, type));
            if (error) {
                toast.error(error);
                // navigation("/flood/thank-you");
            }
            if (response.data.transaction_status === 'Success') {
                toast.success(response.data.return_detail);
                // navigation("/flood/thank-you");
            } else {
                toast.error(response.data.return_detail);
                // navigation("/flood/thank-you");
            }
        }
    }

    return (
        <LoadingOverlay >
            <Loader loading={apiLoading} />
            <section className="flood-payment-section">


                {/* Form */}
                <Row className="justify-content-center">
                    <Col
                        xs="12"
                        sm="12"
                        md="10"
                        lg="8"
                        xl="8"
                        className="flood-payment-form"
                    >
                        <Row className="justify-content-center" id="flood-payment-div">
                            <Col xs="12" sm="10" md="10" lg="10" xl="10">
                                <h1>{t('Pay Now')}</h1>
                                <Row className="justify-content-start">
                                    <Col xs="12">
                                        <Form validated={validated} className="payment-form">
                                            <Row>
                                                <Col xs="12">
                                                    <Form.Group className="payment-field" >
                                                        <Form.Label className="payment-field-label">
                                                            {t('Policy Number')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="payment-field-input"
                                                            type="text"
                                                            placeholder={t("Policy Number")}
                                                            name="policyNumber"
                                                            id="policyNumber"
                                                            title={t('Policy Number')}
                                                            value={initPaymentInfo.Risk_Id || ''}
                                                            required
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs="12">
                                                    <Form.Group className="payment-field">
                                                        <Form.Label className="payment-field-label">
                                                            {t('Account Number')}
                                                        </Form.Label>
                                                        <MaskedFormControl
                                                            className="payment-field-input"
                                                            name='accNumber'
                                                            id='accNumber'
                                                            type='text'
                                                            title={t('Account Number')}
                                                            value={initPaymentInfo.accNumber || ''}
                                                            mask="11111111111111"
                                                            placeholder={t("Account Number")}
                                                            onChange={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
                                                            size={14}
                                                            // showMask
                                                            required
                                                            disabled={apiLoading}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("Please Enter Account Number")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs="12" sm="12" md="7" lg="7" xl="7">
                                                    <Form.Group className="payment-field" >
                                                        <Form.Label className="payment-field-label">
                                                            {t('Routing Transit Number')}
                                                        </Form.Label>
                                                        <MaskedFormControl
                                                            className="payment-field-input"
                                                            name='rtnNumber'
                                                            id='rtnNumber'
                                                            type='text'
                                                            title={t('Routing Transit Number')}
                                                            value={initPaymentInfo.rtnNumber || ''}
                                                            mask="111111111"
                                                            placeholder={t("Routing Transit Number")}
                                                            onChange={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
                                                            size={9}
                                                            // showMask
                                                            required
                                                            disabled={apiLoading}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("Please Enter Routing Transit Number")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs="12" sm="12" md="5" lg="5" xl="5">
                                                    <Form.Group className="payment-field">
                                                        <Form.Label className="payment-field-label">
                                                            {t('Account Type')}
                                                        </Form.Label>
                                                        <Form.Select
                                                            className="payment-field-input"
                                                            name="accType"
                                                            id="accType"
                                                            title={t('Account Type')}
                                                            value={initPaymentInfo.accType || ''}
                                                            onChange={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
                                                            required
                                                            disabled={apiLoading}
                                                        >
                                                            <option value={''}>{t('Select')}</option>
                                                            {
                                                                accountTypeDD.map((v, i) => {
                                                                    return <option key={i} value={v.key}>{t(v.value)}</option>
                                                                })
                                                            }
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("Please Select Account Type")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                    <Form.Group className="payment-field" >
                                                        <Form.Label className="payment-field-label">
                                                            {t('Payment Amount')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="payment-field-input"
                                                            type="text"
                                                            placeholder={t("Payment Amount")}
                                                            name="Amount"
                                                            id="Amount"
                                                            title={t('Payment Amount')}
                                                            onChange={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
                                                            value={'$' + (initPaymentInfo.Amount || 0)}
                                                            readOnly
                                                            required
                                                        />
                                                    </Form.Group>
                                                    <Form.Control.Feedback type="invalid">
                                                        {t("Please Enter Payment Amount")}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col xs="12" sm="12" md="8" lg="8" xl="8">
                                                    <Form.Group
                                                        className="account-form-group"
                                                    >
                                                        <Form.Label className="myform-label">
                                                            {t('Confirmation Email To')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="myform-input"
                                                            type="email"
                                                            placeholder={t("Confirmation Email To")}
                                                            name="s_EmailAddress"
                                                            title={t('Email')}
                                                            id='s_EmailAddress'
                                                            value={initPaymentInfo.s_EmailAddress || ''}
                                                            onChange={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
                                                            required
                                                            disabled={apiLoading}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("Please Add Valid Email Address Error")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="12" className="contact-form-btns">
                                <div
                                    className="pay-now-action-btn"
                                    style={{ marginBottom: "18px" }}
                                >
                                    <Button
                                        id="primary-colored-btn"
                                        variant="primary"
                                        onClick={() => handleSubmit()}
                                    >
                                        <span>{t('Pay Now')}</span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </section>
        </LoadingOverlay >
    );
};

export default FloodACHPayment;
