import React, { Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  sizePerPageRenderer,
  pageButtonRenderer,
} from "../../../components/CustomTablePagination";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const LinksTab = () => {

  const tableData = () => {
    const items = [
    {title:'Community Status Book' , data : [{label:'https://www.fema.gov/flood-insurance/work-with-nfip/community-status-book',value:'https://www.fema.gov/flood-insurance/work-with-nfip/community-status-book'}]},
    {title:'Elevation Certification Resources', data : [{label:'https://www.elevationcert.com/#/home',value:'https://www.elevationcert.com/#/home'}]},
    {title:'FEMA Flood Map Service Center', data : [{label:'https://msc.fema.gov/portal/home',value:'https://msc.fema.gov/portal/home'}]},
    {title:'Flood Manual', data : [{label:'https://www.fema.gov/flood-insurance/work-with-nfip/manuals#flood-insurance',value:'https://www.fema.gov/flood-insurance/work-with-nfip/manuals#flood-insurance'}]},
    {title:'National Hurricane Center', data : [{label:'https://www.nhc.noaa.gov/',value:'https://www.nhc.noaa.gov/'}]},
    {title:'Policy Forms', data : [{label:'https://agents.floodsmart.gov/write-policy/resources#SFIP',value:'https://agents.floodsmart.gov/write-policy/resources#SFIP'}]},
    {title:'Public Records', data : [{label:'https://publicrecords.netronline.com/',value:'https://publicrecords.netronline.com/'}]},
    {title:'State Training Requirements for Agents', data : [{label:'https://nfipservices.floodsmart.gov/training/agent-requirements',value:'https://nfipservices.floodsmart.gov/training/agent-requirements'}]},
    {title:'Summary of Coverage',  data : [{label:'https://agents.floodsmart.gov/sites/default/files/fema_NFIP-summary-of-coverage_brochure_09-2021.pdf',value:'https://agents.floodsmart.gov/sites/default/files/fema_NFIP-summary-of-coverage_brochure_09-2021.pdf'}]},
    {title:'Summary of Coverage (Spanish)',data : [{label:'https://agents.floodsmart.gov/sites/default/files/fema_summary-of-coverage-GFIP_brochure_10-2021_Spanish.pdf',value:'https://agents.floodsmart.gov/sites/default/files/fema_summary-of-coverage-GFIP_brochure_10-2021_Spanish.pdf'}]},
    {title:'Summary of Coverage Commercial', data : [{label:'https://agents.floodsmart.gov/sites/default/files/fema_summary-of-coverage-for-commercial-property_brochure_09-2021.pdf',value:'https://agents.floodsmart.gov/sites/default/files/fema_summary-of-coverage-for-commercial-property_brochure_09-2021.pdf'}]},
    {title:'Summary of Coverage Commercial (Spanish)', data : [{label:'https://agents.floodsmart.gov/sites/default/files/fema_summary-of-coverage-for-commercial-property_brochure_spanish_10-2021.pdf',value:'https://agents.floodsmart.gov/sites/default/files/fema_summary-of-coverage-for-commercial-property_brochure_spanish_10-2021.pdf'}]},
  ];
    return items;
  };

  const listData = tableData();

  const columns = [
    {
      dataField: "title",
      text: "",
    },
  ];

  const options = {
    sizePerPageRenderer,
    pageButtonRenderer,
    showTotal: true,
    alwaysShowAllBtns: true,
  };

  const expandRow = {
    renderer: row => (
      <div>
        {
          row.data.map((val) =>(
            <div>
              <div>
              <a href={val.value} target="_blank">{val.label}</a>
              </div>
              <br></br>
            </div>
            )
          )
        }
      </div>
    )
  };

  return (
    <Fragment>
      <div className="custom-tab-header-fill">
        <span></span>
      </div>
      <div className="managment-tab-body">
        <div
          className="management-reports-table"
          id="management-reports-table-outer-div"
        >
          <BootstrapTable
            wrapperClasses="management-functional-table"
            bootstrap4
            keyField="title"
            data={listData}
            columns={columns}
            hover
            bordered={false}
            expandRow={ expandRow }
          />
        </div>
      </div>
    </Fragment>
  );
};

export default LinksTab;
