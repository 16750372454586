import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getEditEmptyQuoteData } from "../../../../services/floodService";
import { Button, Modal } from "react-bootstrap";
import FloodAddress from "./flood-address/FloodAddress";
import FloodHome from "./flood-home/FloodHome";
import StartingEstimate from "./flood-detail/starting-estimate/StartingEstimate";
import FloodPropertyDetail from "./flood-detail/flood-property-detail/FloodPropertyDetail";
import FloodContactInformation from "./flood-detail/flood-contact-information/FloodContactInformation";
import WaitingPeriod from "./flood-detail/waiting-period/WaitingPeriod";
import ConstructionFormOne from "./flood-detail/construction/ConstructionFormOne";
import ConstructionFormTwo from "./flood-detail/construction/ConstructionFormTwo";
import DiscountForm from "./flood-detail/discounts/DiscountForm";
import DiscountFormOne from "./flood-detail/discounts/DiscountFormOne";
import DiscountFormTwo from "./flood-detail/discounts/DiscountFormTwo";
import DiscountFormThree from "./flood-detail/discounts/DiscountFormThree";
import PreFirmDiscount from "./flood-detail/discounts/PreFirmDiscount";
import FloodPricingPlan from "./flood-detail/flood-pricing-plan/FloodPricingPlan";
import ConstructionFormMH from "./flood-detail/construction/ConstructionFormMH";

const Steps = () => {
  // get step Number
  let { number } = useParams();
  let view = null;
  const dispatch = useDispatch();
  const { emptyQuoteData, ZDDetails, quoteResponseDetails, createQuoteResponse } = useSelector(state => state.flood);
  const [errorModal, setErrorModal] = useState(null);
  const [premium, setPremium] = useState(0);
  const [isFirmUpdated, setIsFirmUpdated] = useState(false);

  // For All Input/Select Handler
  const handleChange = (e, fieldValue = '') => {
    var quoteDataVar = emptyQuoteData;
    if (fieldValue) {
      fieldValue = fieldValue.trim().toUpperCase();
      var firstName = '', lastName = '', fullName = '', char = '', result = [], addInter = [];

      if (fieldValue.indexOf("AND") !== -1) {
        char = "AND";
        fullName = fieldValue.slice(0, fieldValue.indexOf("AND"))
      } else if (fieldValue.indexOf("&") !== -1) {
        char = "&";
        fullName = fieldValue.slice(0, fieldValue.indexOf("&"))
      } else {
        fullName = fieldValue
      }

      // First Name and Last Name
      const [first, last] = getFirstLastName(fullName);
      firstName = first;
      lastName = last;

      // get all additional insured
      result = findAllSubString(fieldValue, char);
      if (result.length > 0) {
        for (var i = 1; i < result.length; i++) {
          if (result[i].length > 0) {
            const [first, last] = getFirstLastName(result[i], true);
            // additional interest JSON
            var addInJson = {
              "n_PolicyAddInterest_PK": null,
              "n_POTransaction_FK": null,
              "n_PersonInfoId_PK": null,
              "s_PersonType": "ADDITIONALINSURED",
              "s_MortgageeType": null,
              "s_LoanNo": null,
              "s_AddionalIntType": null,
              "s_RelationType": null,
              "s_HouseholdType": null,
              "s_isDeleted": "N",
              "n_CorePersonPK": null,
              "n_CorePersonAddressPK": null,
              "n_CreatedUser": null,
              "d_CreatedDate": null,
              "n_UpdatedUser": null,
              "d_UpdatedDate": null,
              "person": {
                "n_PersonInfoId_PK": null,
                "s_PersonUniqueId": null,
                "s_EntityType": "PERSON",
                "s_FirstName": first,
                "s_MiddleName": null,
                "s_LastOrganizationName": last.length === 0 ? lastName : last,
                "s_FullLegalName": first + ' ' + (last.length === 0 ? lastName : last),
                "d_BirthDate": null,
                "s_Occupation": null,
                "s_IsMailingDiff": null,
                "s_IsTempMailing": null,
                "n_CreatedUser": null,
                "d_CreatedDate": null,
                "n_UpdatedUser": null,
                "d_UpdatedDate": null
              },
              "address": {
                "n_PersonAddressesId_PK": null,
                "n_PersonInfoId_PK": null,
                "s_AddressTypeCode": "MAILING",
                "s_IsDefaultAddress": "Y",
                "s_AddressLine1": quoteDataVar['personAddr']['s_AddressLine1'],
                "n_CountyId_FK": null,
                "n_CityId_FK": null,
                "n_StateId_FK": null,
                "n_CountryId_FK": null,
                "s_PostalCode": quoteDataVar['personAddr']['s_PostalCode'],
                "s_HouseNo": quoteDataVar['personAddr']['s_HouseNo'],
                "s_StreetName": quoteDataVar['personAddr']['s_AddressLine1'],
                "s_PostalCodeSuffix": quoteDataVar['personAddr']['s_PostalCodeSuffix'],
                "s_Latitude": quoteDataVar['personAddr']['s_Latitude'],
                "s_Longitude": quoteDataVar['personAddr']['s_Longitude'],
                "s_CountryName": quoteDataVar['personAddr']['s_CountryName'],
                "s_StateName": quoteDataVar['personAddr']['s_StateName'],
                "s_CountyName": quoteDataVar['personAddr']['s_CountyName'],
                "s_CityName": quoteDataVar['personAddr']['s_CityName'],
                "s_ParcelId": quoteDataVar['personAddr']['s_ParcelId'],
                "n_CreatedUser": null,
                "d_CreatedDate": null,
                "n_UpdatedUser": null,
                "d_UpdatedDate": null
              }
            }
            addInter.push(addInJson);
          }
        }
      }
      if (addInter.length > 0) {
        quoteDataVar['propertyInfo']['s_IsAddInsureds'] = 'YES';
      } else {
        quoteDataVar['propertyInfo']['s_IsAddInsureds'] = 'NO';
      }

      quoteDataVar['additionaInsuredData'] = addInter;
      quoteDataVar['personInfo']['s_FirstName'] = firstName;
      quoteDataVar['personInfo']['s_LastOrganizationName'] = lastName;
    } else {
      const { name, value, attributes } = e.target;
      const parentKey = attributes.parentobject.value;
      quoteDataVar[parentKey][name] = value;
      // Update Date Of Construction If Year Built Changed
      if (name === 's_YearBuilt') {
        quoteDataVar[parentKey]['d_DateOfConstruction'] = value ? new Date(value + '-01-01') : '';
        setIsFirmUpdated(false);
      }
    }
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  /**
   * Get First Name And Last name 
   * @lokeshrangani
   * @param {String} fieldValue 
   * @returns 
   */
  const getFirstLastName = (fieldValue, isAddInsureds = false) => {
    var firstName = '';
    var lastName = '';
    var length = 0;
    fieldValue = fieldValue.trim();
    if (fieldValue.indexOf(",") === -1) {
      length = fieldValue.split(" ").length
      firstName = fieldValue.split(" ")[0] || '';
      lastName = fieldValue.substr(fieldValue.indexOf(" ") + 1) || '';

      if (length === 1) {
        lastName = firstName;
        firstName = ''
      }
    } else {
      firstName = fieldValue.substr(fieldValue.indexOf(",") + 1) || '';
      lastName = fieldValue.split(",")[0] || '';
    }
    if (isAddInsureds === true && firstName.length === 0) {
      firstName = lastName;
      lastName = ''
    }
    return [firstName.trim(), lastName.trim()];
  }

  /**
   * Find All Sub String After Split into AND or &
   * @lokeshrangani
   * @param {String} fieldValue
   * @param {String} char
   * @returns 
   */
  const findAllSubString = (fieldValue, char) => {
    if (char.length === 0) return [];
    var idArray = [], stringsArray = [];
    var id = 0, idx = fieldValue.indexOf(char);
    // Find If String present in given string of Not
    while (idx !== -1) {
      idArray.push(idx);
      idx = fieldValue.indexOf(char, idx + 1);
    }

    if (idArray.length > 0) {
      // Get Array of String After Split it
      for (var i = 0; i <= idArray.length; i++) {
        if (id !== 0) {
          stringsArray.push(fieldValue.slice(id, idArray[i]).trim())
        } else {
          stringsArray.push(fieldValue.slice(0, idArray[i]).trim())
        }
        char === 'AND' ? id = idArray[i] + 3 : id = idArray[i] + 1;
      }
    }
    return stringsArray;
  }

  // For All App Switch Handler
  const handleSwitchChange = (e) => {
    const { name, attributes, checked } = e.target;
    var parentObjectName = attributes.parentobject.value;
    var quoteDataVar = emptyQuoteData;
    if (checked) {
      quoteDataVar[parentObjectName][name] = attributes.checkedvalue.value;
    } else {
      quoteDataVar[parentObjectName][name] = attributes.unCheckedValue.value;

      // Updated Dependent Question Value
      if (name === 's_IsPriorNFIPLapsed' && ['', 'NO'].includes(attributes.unCheckedValue.value)) {
        quoteDataVar[parentObjectName]['s_IsCommunitySuspension'] = '';
      }
    }
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  // For All Date Handler
  const handleDateChange = (date, name, parent) => {
    var quoteDataVar = emptyQuoteData;
    if (parent) {
      quoteDataVar[parent][name] = date;
    } else {
      quoteDataVar[name] = date;
    }
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  // For All Radio Button Handler
  const handleRadioChange = (e) => {
    const { name, value, id, attributes } = e.target;
    var quoteDataVar = emptyQuoteData;
    quoteDataVar[id][name] = value;
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  // check date if valid or not 
  const checkDateisValid = (date) => {
    return !isNaN(Date.parse(date));
  }

  // To Updated ZD Details into QuoteData
  useEffect(() => {
    if (Object.keys(ZDDetails).length > 0 && Object.keys(ZDDetails.data).length > 0) {
      const emptyQuote = emptyQuoteData;

      // Setting ZD Details
      if (ZDDetails.success && ZDDetails.data) {
        const zDData = ZDDetails.data.FLOOD_DETERMINATION
        const property = ZDDetails.data.PROPERTY
        if (zDData && zDData._COMMUNITY_INFORMATION && Object.keys(zDData._COMMUNITY_INFORMATION.data).length > 0) {
          var community = zDData._COMMUNITY_INFORMATION.data
          var NFIPCommunityFIRMDate = (community.NFIPCommunityFIRMDate || '');
          var InitFirmDate = '';
          if (NFIPCommunityFIRMDate !== '' && NFIPCommunityFIRMDate !== null && NFIPCommunityFIRMDate !== '0000-00-00') {
            InitFirmDate = new Date(NFIPCommunityFIRMDate);
          }
          emptyQuote.propertyInfo.s_CountyName = (community.NFIPCounty || '');
          emptyQuote.propertyInfo.s_CommunityNumber = (community.NFIPCommunityIdentifier || '');
          emptyQuote.propertyInfo.d_InitialFirmDate = InitFirmDate
        }

        if (zDData && zDData._BUILDING_INFORMATION && Object.keys(zDData._BUILDING_INFORMATION.data).length > 0) {
          var building = zDData._BUILDING_INFORMATION.data
          var floodZone = (building.NFIPFloodZoneIdentifier || '');
          var CurrentFirmDate = '';
          var NFIPMapPanelDate = (building.NFIPMapPanelDate || '');
          if (NFIPMapPanelDate !== '' && NFIPMapPanelDate !== null && NFIPMapPanelDate !== '0000-00-00') {
            CurrentFirmDate = new Date(NFIPMapPanelDate);
          }

          emptyQuote.propertyInfo.d_CurrentFirmDate = CurrentFirmDate
          emptyQuote.propertyInfo.s_PanelNumber = (building.NFIPMapPanelIdentifier || '');
          emptyQuote.propertyInfo.s_MapSuffix = (building.NFIPMapPanelSuffixIdentifier || '');
          emptyQuote.propertyInfo.s_FloodZone = (['X500', 'X100'].includes(floodZone) ? 'X' : floodZone || '');
          emptyQuote.propertyInfo.s_BaseElevation = (building.BaseFloodElevationFeetCount || null);
        }

        if (zDData && Object.keys(zDData._INSURANCE_INFORMATION).length > 0) {
          var NFIPCommunityParticipationStatusType = zDData._INSURANCE_INFORMATION.data.NFIPCommunityParticipationStatusType || '';
          emptyQuote.propertyInfo.s_FloodProgramType = (NFIPCommunityParticipationStatusType === 'Regular' ? 'FLOODREGULAR' : NFIPCommunityParticipationStatusType === 'Emergency' ? 'FLOODEMERGENCY' : '') || null;

          var ProtectedAreaIndicator = zDData._INSURANCE_INFORMATION.data.ProtectedAreaIndicator || '';
          emptyQuote.propertyInfo.s_CBRSorOPA = ProtectedAreaIndicator === 'Y' ? 'YES' : ProtectedAreaIndicator === 'N' ? 'NO' : null;
        }

        if (property && property._IDENTIFICATION && Object.keys(property._IDENTIFICATION.data).length > 0) {
          var identification = property._IDENTIFICATION.data;
          emptyQuote.personAddr.s_countyFips = identification.CountyFIPSCode;
          emptyQuote.personAddr.s_stateFips = identification.StateFIPSCode;
        }
      }
      dispatch(getEditEmptyQuoteData(emptyQuote));
    }
  }, [dispatch, ZDDetails, emptyQuoteData]);

  useEffect(() => {
    if (Object.keys(quoteResponseDetails).length > 0) {
      if (quoteResponseDetails.data && Object.keys(quoteResponseDetails.data).length > 0) {
        var code = quoteResponseDetails.data.code || 0;
        if ([200, 201].includes(code) && Object.keys(quoteResponseDetails.data.rreQuote).length > 0) {
          setPremium(quoteResponseDetails.data.rreQuote.premium.totalAnnualPayment || 0);
        } else {
          showError();
          setPremium(0);
        }
      }
    }
  }, [quoteResponseDetails, createQuoteResponse]);

  // trigger to update Firm Type Based on d_DateOfConstruction & d_InitialFirmDate
  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0 && emptyQuoteData.propertyInfo.d_DateOfConstruction && emptyQuoteData.propertyInfo.d_InitialFirmDate && !isFirmUpdated) {
      var temp = false;
      if ((new Date(emptyQuoteData.propertyInfo.d_DateOfConstruction) <= new Date('1974-12-31')) || (new Date(emptyQuoteData.propertyInfo.d_DateOfConstruction) < new Date(emptyQuoteData.propertyInfo.d_InitialFirmDate))) {
        temp = true;
      }
      // default historical question to no if it is post firm, and if it is pre firm ask the question
      const emptyQuote = { ...emptyQuoteData };
      emptyQuote.propertyInfo.s_FloodConstructionType = temp ? 'FLDPREFIRM' : 'FLDPOSTFIRM';

      dispatch(getEditEmptyQuoteData(emptyQuote));
      setIsFirmUpdated(true);
    }
  }, [emptyQuoteData, isFirmUpdated]);

  const showError = () => {
    const resp = quoteResponseDetails && Object.keys(quoteResponseDetails.data).length > 0 ? quoteResponseDetails.data : {};

    resp && Object.keys(resp).length > 0 && resp.code && ![200, 201].includes(resp.code) && (
      setErrorModal(<Modal show={true}>
        <Modal.Header>
          <Modal.Title>Response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            resp && Object.keys(resp).length > 0 && ![200, 201].includes(resp.code) && (
              <React.Fragment>
                <span style={{ color: 'red' }}>
                  {resp.message}
                </span>
                {
                  resp.fieldErrors && resp.fieldErrors.length > 0 && (
                    <ul>
                      {
                        resp.fieldErrors.map((val, key) => {
                          return <li key={key}>{val.field} - {val.message}</li>
                        })
                      }
                    </ul>
                  )
                }
                {
                  resp.ruleFailures && resp.ruleFailures.length > 0 && (
                    <ul>
                      {
                        resp.ruleFailures.map((val, key) => {
                          if (val.rule && val.rule.severity !== 'WARNING' && val.exception && val.exception !== '') {
                            return <li key={key}>{val.exception}</li>
                          }
                        })
                      }
                    </ul>
                  )
                }
              </React.Fragment>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="outline-dark" onClick={() => setErrorModal(null)}>Close</Button>
        </Modal.Footer>
      </Modal>)
    )
  }

  switch (number) {
    case 'address':
      view = <FloodAddress />;
      break;
    case 'home-type':
      view = <FloodHome />;
      break;
    case '1':
      view = <FloodPropertyDetail handleChange={handleChange} handleDateChange={handleDateChange} handleSwitchChange={handleSwitchChange} checkDateisValid={checkDateisValid} />;
      break;
    case '2':
      view = <StartingEstimate premium={premium} />;
      break;
    case '3':
      view = <FloodContactInformation handleChange={handleChange} />;
      break;
    case '4':
      view = <WaitingPeriod handleChange={handleChange} handleDateChange={handleDateChange} checkDateisValid={checkDateisValid} />;
      break;
    case '5':
      view = <ConstructionFormOne handleSwitchChange={handleSwitchChange} handleDateChange={handleDateChange} checkDateisValid={checkDateisValid} premium={premium} />;
      break;
    case '6':
      view = <ConstructionFormTwo handleChange={handleChange} handleSwitchChange={handleSwitchChange} handleRadioChange={handleRadioChange} checkDateisValid={checkDateisValid} premium={premium} />;
      break;
    case '7':
      view = <DiscountForm handleSwitchChange={handleSwitchChange} premium={premium} />;
      break;
    case '8':
      view = <DiscountFormOne handleSwitchChange={handleSwitchChange} handleChange={handleChange} premium={premium} />;
      break;
    case '9':
      view = <DiscountFormTwo handleSwitchChange={handleSwitchChange} handleChange={handleChange} handleDateChange={handleDateChange} checkDateisValid={checkDateisValid} premium={premium} />;
      break;
    case '10':
      view = <DiscountFormThree handleSwitchChange={handleSwitchChange} handleChange={handleChange} premium={premium} />;
      break;
    case '11':
      view = <PreFirmDiscount handleSwitchChange={handleSwitchChange} premium={premium} />;
      break;
    case '12':
      view = <FloodPricingPlan handleChange={handleChange} />;
      break;
    case '13':
      view = <ConstructionFormMH handleChange={handleChange} handleSwitchChange={handleSwitchChange} />;
      break;
    default:
      view = <FloodAddress />;
  }

  return (
    <>
      {view}
      {errorModal}
    </>
  );
};

export default Steps;